import React from 'react';
import moment from 'moment';

import { 
  IonContent, IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonList, IonItem, 
  IonLabel, IonModal 
} from '@ionic/react';
import { chevronBack, server, cube, car, checkbox } from 'ionicons/icons';

const statusDetail: any = {
  "open": { text: "Item processed and packed", icon: server },
  "collecting": { text: "Picking up", icon: cube },
  "transit": { text: "Delivering", icon: car },
  "delivered": { text: "Received", icon: checkbox },
}

interface Props {
  isOpen: boolean
  setShowModal: (isOpen: boolean) => void
  status: any
}

const DeliveryTrackingModal: React.FC<Props> = ({ isOpen, setShowModal, status }) => {

  // const [loaded, error] = useScript("//www.tracking.my/track-button.js");

  // if (!status && loaded && !error) {
  //   // @ts-ignore
  //   TrackButton.embed({
  //     selector: "#embedTrack",
  //     tracking_no: delivery.courier.trackingNumber
  //   });
  // }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setShowModal(false)}>
      <IonHeader>
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => setShowModal(false)} >
            <IonIcon icon={chevronBack}></IonIcon>
          </IonButton>
          <IonTitle>Delivery Information</IonTitle>
        </IonToolbar>
      </IonHeader>
      {status &&
        <IonContent>
          <IonList>
            {status.map((s: any, i: number) => 
              <IonItem key={i} lines="inset">
                <IonIcon slot="start" icon={statusDetail[s.name].icon} />
                <IonLabel className="tracking-item">
                  <h3>{moment(s.date).calendar(undefined, { sameElse: 'DD/MM/YYYY HH:mm'})}</h3>
                  <p>{statusDetail[s.name].text}</p>
                </IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonContent>
      }
    </IonModal >
  )
}

export default DeliveryTrackingModal;