import React, { useState } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { RefresherEventDetail } from '@ionic/core';

import {
  IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent, IonSearchbar,
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonLoading, IonGrid, IonRow, IonCol, 
} from '@ionic/react';

import { useShopManyQuery, useSetShopsFilterMutation } from '../generated/graphql';
import ErrorPage from './ErrorPage';

import './Home.css';

const Home: React.FC<RouteComponentProps> = ({ history }) => {
  const [searchText, setSearchText] = useState("");
  const { data, loading, refetch, error } = useShopManyQuery({ fetchPolicy: "cache-and-network" })
  const [setShopsFilter] = useSetShopsFilterMutation();

  function onShopClick(shop: any) {
    //dispatch({ type: "setLastMerchantId", merchantId: merchant.id });
    // history.push(`/shop/${shop.url}`);
    history.push(`/${shop.type}/${shop.url}`);
    // if (shop._id === '60c178bfe07ee7102fe7cae8')
    //   history.push(`/${shop.type}/${shop.url}`);
    // else
    //   history.push(`/shop/${shop.url}`);
  };

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setSearchText("");
    refetch();
    // if (!loading) event.detail.complete();
    setTimeout(() => event.detail.complete(), 500);
  }

  if (!data?.public?.shops && loading) {
    console.log('loading')
    return (<IonPage>
      <IonLoading isOpen={loading} message="loading shops ..." />
    </IonPage>);
  }

  if (!data?.public?.shops && error) {
    console.log('error', error)
    return <ErrorPage refresh={() => refetch()} />
  }

  if (data?.public?.shops) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonSearchbar value={searchText}
              onIonChange={e => {
                if (e.detail.value !== undefined) {
                  setSearchText(e.detail.value);
                  setShopsFilter({ variables: { filter: e.detail.value } });
                }
              }}
            />
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonGrid>
            <IonRow>
              {data.public.shops.map((s: any) => {
                return (
                  <IonCol key={s._id} sizeMd="4" sizeXs="12" hidden={s.status !== "published" || !s.show}>
                    <IonCard button
                      // routerLink={`/shop/${m.id}`}
                      onClick={() => onShopClick(s)}
                      className="shop"
                    >
                      <img src={s.coverImage} alt="shop" />
                      <IonCardHeader>
                        <IonCardTitle>{s.name}</IonCardTitle>
                        <IonCardSubtitle>1.3km</IonCardSubtitle>
                        <IonCardContent>{s.description}</IonCardContent>
                      </IonCardHeader>
                    </IonCard>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
  return null;
};

export default Home;
