import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Share } from '@capacitor/share';

import {
  IonPage, IonHeader, IonContent, IonSlides, IonSlide, IonToolbar, IonButtons,
  IonLabel, isPlatform, IonButton, IonIcon, IonLoading, IonImg, IonText, IonRow, IonItem,
  IonGrid, IonCol, IonTitle
} from '@ionic/react';
import {
  chevronBack, shareSocial, add, remove, logoWhatsapp, cartOutline
} from 'ionicons/icons';

import {
  useAddToCartMutation, useRemoveFromCartMutation, useConsumerOneQuery, useShopOneQuery
} from '../../generated/graphql';

import { findIndexInArray, findObjInArray } from '../../util/arrayUtil';
import CartModal from './CartModal';
import { NotFound } from '../../pages/NotFound';
import ErrorPage from '../../pages/ErrorPage';
import { useToast } from '../../hooks/useToast';

import './ProductDetails.css';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  zoom: {
    maxRatio: 4
  }
};

interface Props extends RouteComponentProps<{ url: string, _id: string }> {
}

const ProductDetails: React.FC<Props> = ({ match, history, location }) => {
  const Toast = useToast();
  const { data: dataConsumer } = useConsumerOneQuery({ fetchPolicy: "cache-and-network" })
  const { data, loading, error, refetch } = useShopOneQuery({
    // use default cache-first to reduce traffic to server on every product detail view
    // fetchPolicy: "cache-and-network",
    variables: { filter: { url: match.params.url } }
  })
  const [addToCartMutation] = useAddToCartMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const [cartModalOpen, showCartModal] = useState(new URLSearchParams(location.search).get("openCart") === "true" ? true : false)
  const [transparentHeader, setTransparentHeader] = useState(true);

  async function onShareClick() {
    try {
      let shareRet = await Share.share({
        title: product.name,
        text: `RM ${product.pricing?.retail?.toFixed(2)} | ${product.name}`,
        url: match.url,
      });
      console.log("share successful: ", shareRet)
    } catch (err) {
      console.log("share error: ", err)
      Toast.error(err);
    }
  }

  function onAddCartClick() {
    addToCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: product._id,
          name: product.name,
          price: product.pricing?.retail,
          cartProduct: {
            name: product.name,
            imageUrl: product.imageUrl
          }
        }
      }
    });
  }

  function onRemoveCartClick() {
    removeFromCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: product._id,
          price: product.pricing?.retail
        }
      }
    })
  }

  function onScroll(event: any) {
    if (event.detail.currentY === 0) {
      setTransparentHeader(true);
    } else {
      setTransparentHeader(false);
    }
  }

  if (!data?.public?.shop && loading) {
    console.log('loading', loading)
    return <IonPage>
      <IonLoading
        isOpen={loading}
      />
    </IonPage>
  }

  if (!data?.public?.shop && error) {
    console.log('Product details error', error)
    return <ErrorPage refresh={() => refetch()} />
  }

  let product = data?.public?.shop?.products ? findObjInArray(data.public.shop.products, "_id", match.params._id) : undefined;

  if (data?.public?.shop && product) {
    const { shop } = data.public;
    let cartIndex = shop.orderLocal?.carts ? findIndexInArray(shop.orderLocal.carts, "productId", product._id) : null;

    return (
      <IonPage className="shop01-details">
        {isPlatform("desktop") ?
          <IonHeader>
            <IonToolbar>
              <IonItem slot="start" lines="none" routerLink={`/shop/${match.params.url}`} routerDirection="back">
                <img src={shop.logo ? shop.logo : ""} alt="logo" width="50px" height="50px"/>
                <IonTitle>{shop.name}</IonTitle>
              </IonItem>
              <IonButtons slot="end">
                <IonButton href={`https://api.whatsApp.com/send?phone=${shop.whatsapp_number}`}>
                  <IonIcon icon={logoWhatsapp} size={isPlatform("mobile") ? "default" : "large"} />
                </IonButton>
                <IonButton onClick={() => showCartModal(true)}>
                  <IonIcon icon={cartOutline} size="large" />
                  <IonLabel>{shop.orderLocal?.totalQty}</IonLabel>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          :
          <IonHeader className={transparentHeader ? "transparent-header ion-no-border" : undefined}>
            <IonToolbar>
              <IonButton slot="start" fill="clear" onClick={() => history.goBack()}>
                <IonIcon icon={chevronBack} slot="icon-only"></IonIcon>
              </IonButton>
              {!transparentHeader && <IonTitle >{product.name}</IonTitle>}
            </IonToolbar>
          </IonHeader>
        }

        <IonContent scrollEvents={isPlatform('mobile') ? true : false} onIonScroll={onScroll} >
          {isPlatform("desktop") ?
            <IonGrid>
              <IonRow>
                <IonCol sizeLg="6" sizeMd="7" sizeXs="12" className="ion-padding">
                  {/* the IonSlides got bug that it cannot handle dynamic add or remove of slide, so
                  we have to change the key of it to make the old destoy and recreate a new one when
                  there are slide added or deleted  */}
                  <IonSlides className={'desktop-ion-slides'} options={slideOpts} pager={true}
                    key={product.images?.length}>
                    {product.images?.map((img: any, index: any) => img.url !== "" ?
                      <IonSlide key={index}>
                        <IonImg src={img.url} />
                        {/* <div className="image-count">{`${index + 1}/${product.images.length}`}</div> */}
                      </IonSlide>
                      : null
                    )}
                  </IonSlides>
                </IonCol>
                <IonCol sizeLg="6" sizeMd="5" sizeXs="12" className="ion-padding">
                  <IonRow>
                    <IonText color="tertiary">
                      <h4>RM {product.pricing?.retail?.toFixed(2)}</h4>
                    </IonText>
                    <div className="h-right-margin-item">
                      {cartIndex !== null &&
                        <IonButton color="medium" onClick={onRemoveCartClick}>
                          <IonIcon icon={remove}></IonIcon>
                        </IonButton>}
                      {(shop.orderLocal?.carts && cartIndex !== null) &&
                        <IonText>&nbsp;{shop.orderLocal.carts[cartIndex]?.qty}&nbsp;</IonText>}
                      <IonButton onClick={onAddCartClick}
                        disabled={product.stock ? (!product.unlimitedStock && product.stock < 1) : false}>
                        <IonIcon icon={add}></IonIcon>
                      </IonButton>
                      {(!product.unlimitedStock && (product.stock && product.stock < 1)) && <p className="out-of-stock">Out of stock</p>}
                    </div>
                  </IonRow>
                  <IonRow>
                    <IonText><h4>{product.name}</h4></IonText>
                    <IonButton className="h-right-margin-item" fill="clear" onClick={onShareClick}>
                      <IonIcon icon={shareSocial} slot="icon-only" />
                    </IonButton>
                  </IonRow>
                  <IonRow>
                    <p>{product.longDesc}</p>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow>
                <div style={{ width: '100%' }}>
                  {product.descriptionImages?.map((img: any, index: any) => {
                    if (img.url !== "") 
                      return <IonImg key={index} src={img.url} />
                    else
                      return null ;
                  })}
                </div>
              </IonRow>
            </IonGrid>
            :
            <>
              <IonSlides options={slideOpts} pager={true}>
                {product.images?.map((img: any, index: any) =>
                  img.url !== "" ?
                    <IonSlide key={index}>
                      <IonImg src={img.url} />
                      {/* <div className="image-count">{`${index + 1}/${product.images.length}`}</div> */}
                    </IonSlide>
                    : null
                )}
              </IonSlides>
              <IonItem lines="none">
                <IonText color="tertiary">
                  <h4>RM {product.pricing?.retail?.toFixed(2)}</h4>
                </IonText>
                <IonItem slot="end" lines="none">
                  {cartIndex !== null &&
                    <IonButton color="medium" onClick={onRemoveCartClick}>
                      <IonIcon icon={remove}></IonIcon>
                    </IonButton>}
                  {(shop.orderLocal?.carts && cartIndex !== null) &&
                    <IonText>&nbsp;{shop.orderLocal.carts[cartIndex]?.qty}&nbsp;</IonText>}
                  <IonButton onClick={onAddCartClick}
                    disabled={product.stock ? (!product.unlimitedStock && product.stock < 1) : false}>
                    <IonIcon icon={add}></IonIcon>
                  </IonButton>
                  {(!product.unlimitedStock && (product.stock && product.stock < 1)) && <p className="out-of-stock">Out of stock</p>}
                </IonItem>
              </IonItem>
              <IonItem lines="none">
                <IonText><h4>{product.name}</h4></IonText>
                <IonButton slot="end" fill="clear" onClick={onShareClick}>
                  <IonIcon icon={shareSocial} slot="icon-only" />
                </IonButton>
              </IonItem>
              <IonItem lines="none">
                <p>{product.longDesc}</p>
              </IonItem>
              <IonItem lines="none">
                <div style={{ width: '100%' }}>
                  {product.descriptionImages?.map((img: any, index: any) => {
                    if (img.url !== "") 
                      return <IonImg key={index} src={img.url} />
                    else
                      return null ;
                  })}
                </div>
              </IonItem>
            </>
          }

          <CartModal
            user={dataConsumer?.consumer?.me}
            shop={shop}
            isOpen={cartModalOpen}
            setShowModal={showCartModal}
            redirectToLogin={() => history.push("/login", { prevLocation: location.pathname, openCart: true })}
          />

        </IonContent>
      </IonPage>
    )
  }

  return <NotFound />

}

export default ProductDetails;