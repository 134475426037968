import React from "react";
import { Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { IonPage, IonRouterOutlet, IonSplitPane } from "@ionic/react";

import ShopHome from '../components/shopGen02/ShopHome';
import Blog from '../components/shopGen02/Blog';
import Favourite from '../components/shopGen02/Favourite';
import History from '../components/shopGen02/History';
import Listing from '../components/shopGen02/Listing';
import ProductDetails from "../components/shopGen02/ProductDetails";

interface ShopProps extends RouteComponentProps {
}

const ShopGen02: React.FC<ShopProps> = ({ match }) => {

  // Cannot get to implement split plan with nested router
  // return (
  //   <IonPage>
  //     <IonSplitPane contentId="shopContent">

  //       <IonRouterOutlet id="shopContent">
  //         <Route path={`/ShopGen02/:url/product-details/:_id`} render={(props) => <ProductDetails {...props} />} />
  //         <Route path={`/ShopGen02/:url/blog/:_id`} render={(props) => <Blog {...props} />} />
  //         <Route path={`/ShopGen02/:url/history`} render={(props) => <History {...props} />} />
  //         <Route path={`/ShopGen02/:url/favourite`} render={(props) => <Favourite {...props} />} />
  //         <Route path={`/ShopGen02/:url/listing`} render={(props) => <Listing {...props} />} />
  //         <Route exact path={`/ShopGen02/:url`} render={(props) => <ShopHome {...props} />} />
  //       </IonRouterOutlet>
  //     </IonSplitPane>
  //   </IonPage>
  // )

  return (
    <IonRouterOutlet id="shopContent" ionPage>
      <Route path={`/ShopGen02/:url/product-details/:_id`} render={(props) => <ProductDetails {...props} />} />
      <Route path={`/ShopGen02/:url/blog/:_id`} render={(props) => <Blog {...props} />} />
      <Route path={`/ShopGen02/:url/history`} render={(props) => <History {...props} />} />
      <Route path={`/ShopGen02/:url/favourite`} render={(props) => <Favourite {...props} />} />
      <Route path={`/ShopGen02/:url/listing`} render={(props) => <Listing {...props} />} />
      <Route exact path={`/ShopGen02/:url`} render={(props) => <ShopHome {...props} />} />
    </IonRouterOutlet>
  )

  // The below code is same as above code
  // return (
  //   <IonPage>
  //     <IonRouterOutlet>
  //       <Route path={`/ShopGen02/:url/product-details/:_id`} render={(props) => <ProductDetails {...props} />} />
  //       <Route path={`/ShopGen02/:url/blog/:_id`} render={(props) => <Blog {...props} />} />
  //       <Route path={`/ShopGen02/:url/history`} render={(props) => <History {...props} />} />
  //       <Route path={`/ShopGen02/:url/favourite`} render={(props) => <Favourite {...props} />} />
  //       <Route path={`/ShopGen02/:url/listing`} render={(props) => <Listing {...props} />} />
  //       <Route exact path={`/ShopGen02/:url`} render={(props) => <ShopHome {...props} />} />
  //     </IonRouterOutlet>
  //   </IonPage>
  // )
}

export default ShopGen02;