import React, { useState, useEffect } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButton, IonIcon, IonContent, IonRow, IonItem, IonInput, IonLabel, IonLoading, IonAlert, IonCard, IonCardContent, IonCardTitle, isPlatform, IonText } from '@ionic/react';
import { chevronBack, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import './Login.css'
import { useForm, Controller } from 'react-hook-form';
import {
  useSendSmsOtpMutation, useVerifySmsOtpMutation, useSignUpMutation, useLoginUsingPasswordUsernameMutation,
  useUsernameExistQuery, usePhoneExistQuery, OtpChannelEnum, LoginEnum
} from '../generated/graphql';
import { useHistory, useLocation } from 'react-router';

let initialValues = {
  username: "",
  name: "",
  password: "",
  phone: "",
  verification_code: ""
};

let intervalId: NodeJS.Timeout;
let timeoutId: NodeJS.Timeout;
let prevLocation: any = null;

const Signup: React.FC = () => {
  const [codeTimeoutActivated, activateCodeTimeout] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const { control, handleSubmit, formState, getValues, errors, setError } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange"
  });
  const [showPassword, setShowPassword] = useState(false)
  const [verifiedPhone, setVerifiedPhone] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const history = useHistory()
  const location = useLocation();
  //@ts-ignore
  if (location.state && location.state.prevLocation) {
    //@ts-ignore
    prevLocation = location.state.prevLocation || "/";
  }

  const { refetch: checkUsername } = useUsernameExistQuery({
    variables: { username: "" },
    fetchPolicy: "network-only",
  })
  const { refetch: checkPhone } = usePhoneExistQuery({
    variables: { phone: "" },
    fetchPolicy: "network-only",
  })

  const [sendOtp] = useSendSmsOtpMutation();
  const [verifyOtp] = useVerifySmsOtpMutation();
  const [signUp] = useSignUpMutation();
  const [login] = useLoginUsingPasswordUsernameMutation();


  useEffect(() => {

    if (codeTimeoutActivated) {
      // exit early when we reach 0
      if (!timeLeft) {
        activateCodeTimeout(false)
        return;
      }

      // save intervalId to clear the interval when the
      // component re-renders
      intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }

  }, [timeLeft, codeTimeoutActivated]);

  const sendCode = () => {
    //call api to ask server send code
    sendOtp({
      variables: { record: { phone: `+60${getValues("phone")}`, channel: OtpChannelEnum.Sms } }
    }).then(res => {
      console.log("code sent", res)
      if (res.data?.public?.otp?.sms?.send) {
        activateCodeTimeout(true);
      }
    }).catch(error => {
      console.log("err send code ", error)
      setError("phone", "Error", `Unable to send code. ${error.message}.`)
    })
  }

  const verifyPhoneNumber = () => {
    verifyOtp({
      variables: { record: { phone: `+60${getValues("phone")}`, code: getValues("verification_code") } }
    }).then(res => {
      if (res.data?.public?.otp?.sms?.verify?.response.valid) {
        console.log("valid code")
        setVerifiedPhone(true)
      } else if (!res.data?.public?.otp?.sms?.verify?.response.valid) {
        throw new Error("Invalid code");
      }
    }).catch(error => {
      console.log("err invalid code ", error)
      setError("verification_code", "Invalid code", "Invalid code")
    })
  }

  /**
   *
   * @param _fieldName
   */
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="error-message">
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  /**
   *
   * @param data
   */
  const onSubmit = (data: any) => {
    setLoading(true)
    console.log(data)
    signUp({
      variables: { record: { username: data.username, name: data.name, password: data.password, phone: `+60${data.phone}`, phoneVerified: true } }
    }).then(res => {
      if (res.data?.public?.consumerCreate?.recordId) {
        login({
          variables: {
            record: {
              username: data.username,
              password: data.password,
              type: LoginEnum.Consumer
            }
          }
        }).then(res => {
          setLoading(false);
          if (res.data?.public?.login?.usingPassword?.byUsername) {
            const { accessToken, refreshToken } = res.data.public.login.usingPassword.byUsername;
            localStorage.setItem("accessToken", accessToken)
            localStorage.setItem("refreshToken", refreshToken)
            localStorage.setItem("isAuthenticated", "true");
            history.replace(prevLocation);
          } else {
            setAlert(true);
          }
        }).catch((err) => {
          setLoading(false);
          console.log(err)
          setAlert(true);
        });
      } else {
        setLoading(false);
        setAlert(true);
      }
    }).catch(error => {
      setLoading(false);
      setAlert(true);
      console.log("err create consumer ", error)
    })
  };


  const renderContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonItem>
          <IonLabel position="floating">Username</IonLabel>
          <Controller
            as={IonInput}
            placeholder="Enter unique username"
            control={control}
            onChangeName="onIonChange"
            onChange={([selected]) => {
              console.log("username", selected.detail.value);
              clearTimeout(timeoutId);
              timeoutId = setTimeout(() => {
                checkUsername({ username: selected.detail.value }).then(res => {
                  if (res.data.public?.isExist?.username) {
                    setError("username", "unique", "Username already exist")
                  }
                }).catch(err => console.log("error username checking ", err))
              }, 2000)
              return selected.detail.value;
            }}
            name="username"
            rules={{
              required: true,
            }}
          />
        </IonItem>
        {showError("username")}

        <IonItem>
          <IonLabel position="floating">Full name</IonLabel>
          <Controller
            as={IonInput}
            placeholder="Enter your full name"
            control={control}
            onChangeName="onIonChange"
            onChange={([selected]) => {
              console.log("name", selected.detail.value);
              return selected.detail.value;
            }}
            name="name"
            rules={{
              required: true,
            }}
          />
        </IonItem>
        {showError("name")}

        <IonItem>
          <IonLabel position="floating">Password</IonLabel>
          <IonRow className="ion-justify-content-between" style={{ width: "-webkit-fill-available" }}>
            <Controller
              as={IonInput}
              placeholder="Minimum 6 alphanumeric characters"
              type={showPassword ? "text" : "password"}
              control={control}
              onChangeName="onIonChange"
              onChange={([selected]) => {
                console.log("password", selected.detail.value);
                return selected.detail.value;
              }}
              name="password"
              rules={{
                required: true,
                minLength: { value: 6, message: "Password must at least 6 characters" },
                pattern: { value: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, message: "Password must contain alphabetic and numeric characters" }
              }}
            />
            <IonButton fill="clear" onClick={() => {
              if (showPassword) {
                setShowPassword(false)
              } else {
                setShowPassword(true)
              }
            }}>
              <IonIcon icon={showPassword ? eyeOutline : eyeOffOutline} size="20px" />
            </IonButton>
          </IonRow>
        </IonItem>
        {showError("password")}

        <IonItem>
          <IonLabel position="floating">Phone Number</IonLabel>
          <IonRow className="ion-justify-content-between ion-align-items-center" style={{ width: "-webkit-fill-available" }}>
            <IonText className="ion-margin-end">+60</IonText>
            <Controller
              as={IonInput}
              placeholder="Enter your phone number"
              inputMode="tel"
              maxlength={10}
              disabled={verifiedPhone}
              control={control}
              onChangeName="onIonChange"
              onChange={([selected]) => {
                console.log("phone", selected.detail.value);
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                  checkPhone({ phone: `+60${selected.detail.value}` }).then(res => {
                    if (res.data.public?.isExist?.phone) {
                      setError("phone", "unique", "This phone number already associated to an account")
                    }
                  }).catch(err => console.log("error phone checking ", err))
                }, 2000)
                return selected.detail.value;
              }}
              name="phone"
              rules={{
                required: true,
                minLength: { value: 9, message: "Invalid phone number" },
                pattern: { value: /^[1-9][0-9]*$/, message: "Invalid phone number" }
              }}
            />
            {verifiedPhone ? <span className="verified">
              Verified
          </span> : <IonButton disabled={codeTimeoutActivated || !getValues("phone") ? true : false} onClick={() => sendCode()}>
                {codeTimeoutActivated ? `${timeLeft} s` : "Send Code"}
              </IonButton>}
          </IonRow>
        </IonItem>
        {showError("phone")}

        {codeTimeoutActivated && !verifiedPhone ? <IonItem>
          <IonLabel position="floating">Verification code</IonLabel>
          <Controller
            as={IonInput}
            placeholder="6 digits"
            inputMode="numeric"
            maxlength={6}
            control={control}
            onChangeName="onIonChange"
            onChange={([selected]) => {
              console.log("verification_code", selected.detail.value);
              clearTimeout(timeoutId);
              timeoutId = setTimeout(() => verifyPhoneNumber(), 2000);
              return selected.detail.value;
            }}
            name="verification_code"
            rules={{
              required: true,
            }}
          />
        </IonItem> : null}
        {showError("verification_code")}

        <IonButton className="ion-margin-top" expand="block" type="submit" disabled={formState.isValid === false || !verifiedPhone}>Sign up</IonButton>
        <IonRow className="ion-justify-content-center">
          <p>By clicking sign up, I agree to Transformer's Terms of Use and Privacy Policy</p>
        </IonRow>
      </form>
    )
  }

  return isPlatform("mobile") ?
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => history.replace("/login", { prevLocation })} >
            <IonIcon icon={chevronBack}></IonIcon>
          </IonButton>
          <IonTitle>Sign up</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {renderContent()}

        <IonLoading
          isOpen={loading}
          message={'Please wait...'}
        />

        <IonAlert
          isOpen={alert}
          message={"Unable to sign up. Please try again."}
          onDidDismiss={() => setAlert(false)}
          buttons={[
            {
              text: "OK",
              handler: () => setAlert(false)
            }
          ]}
        />
      </IonContent>
    </IonPage>
    :
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonItem slot="start" lines="none" href="/home">
            <img src='/assets/icon/icon.png' alt="logo" width="50px" height="50px"/>
            <IonTitle>Transformer</IonTitle>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard className="card">
          <IonCardContent>
            <IonRow className="ion-justify-content-between ion-margin-bottom">
              <IonCardTitle>Sign up</IonCardTitle>
              <IonText>Already have account? <a href="/login">Login</a></IonText>
            </IonRow>
            {renderContent()}
          </IonCardContent>
        </IonCard>
        <IonLoading
          isOpen={loading}
          message={'Please wait...'}
        />

        <IonAlert
          isOpen={alert}
          message={"Unable to sign up. Please try again."}
          onDidDismiss={() => setAlert(false)}
          buttons={[
            {
              text: "OK",
              handler: () => setAlert(false)
            }
          ]}
        />
      </IonContent>
    </IonPage>
}

export default Signup;