import React, { useEffect, useState, useRef } from 'react';
import { Geolocation } from "@ionic-native/geolocation"
import { Map, TileLayer, Marker } from "react-leaflet";

import {
  IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
  IonButton, IonIcon, IonCol, IonRow,
} from '@ionic/react';
import { close } from 'ionicons/icons';

import ReactLeafletSearch from "react-leaflet-search";
import './AddressModal.css';


interface Props {
  updateLocation: any,
  location: any,
  isOpen: boolean,
  setShowModal: (isOpen: boolean) => void,
}

const MapModal: React.FC<Props> = ({ location, updateLocation, isOpen, setShowModal }) => {
  const mapRef = useRef<any>();
  const markerRef = useRef<any>();
  const [centerLocation, setCenterLocation] = useState({
    lat: 2.9935,
    lng: 101.7874
  })
  const [pinLocation, setPinLocation] = useState({
    lat: 2.9935,
    lng: 101.7874
  })

  useEffect(() => {
    if (location.coordinates) {
      setCenterLocation({ lat: location.coordinates[1], lng: location.coordinates[0] })
      setPinLocation({ lat: location.coordinates[1], lng: location.coordinates[0] })
    }
    if (isOpen) {
      setTimeout(function () {
        mapRef.current?.leafletElement.invalidateSize();
      }, 500);
    }
  }, [location.coordinates, isOpen])

  const updatePosition = () => {
    console.log("updatePosition ", markerRef.current?.leafletElement.getLatLng())
    setPinLocation(markerRef.current?.leafletElement.getLatLng())
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setShowModal(false)}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>Pin your home location</IonTitle>
          <IonButton onClick={() => setShowModal(false)} fill="clear" slot="secondary">
            <IonIcon icon={close}></IonIcon>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Map ref={mapRef} zoom={50} style={{ width: '100%', height: '100%' }}
          center={[centerLocation.lat, centerLocation.lng]}
          onclick={(e) => {
            setPinLocation({ "lat": e.latlng.lat, "lng": e.latlng.lng, });
            setCenterLocation({ "lat": e.latlng.lat, "lng": e.latlng.lng, });
          }}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ReactLeafletSearch
            position="topleft"
            inputPlaceholder="The default text in the search bar"
            zoom={15}
            providerOptions={{ region: 'my' }}
            openSearchOnLoad={false} // By default there's a search icon which opens the input when clicked. Setting this to true opens the search by default.
            closeResultsOnClick={true}
            popUp={(i) => {
              console.log("edfsa ", markerRef.current?.leafletElement)
              markerRef.current?.leafletElement.setLatLng(i.latLng)
              setPinLocation(i.latLng);
              return <div />;
            }}
          />
          <Marker ref={markerRef} position={[pinLocation.lat, pinLocation.lng]} draggable ondragend={updatePosition} />
        </Map>
      </IonContent>
      <IonFooter>
        <IonRow>
          <IonCol>
            <IonButton expand="block" onClick={() => {
              Geolocation.getCurrentPosition().then(resp => {
                setCenterLocation({ "lat": resp.coords.latitude, "lng": resp.coords.longitude });
                setPinLocation({ "lat": resp.coords.latitude, "lng": resp.coords.longitude });
              })
            }}>LOCATION NOW</IonButton>
          </IonCol>
          <IonCol>
            <IonButton expand="block" onClick={() => {
              updateLocation(pinLocation);
              setShowModal(false);
            }}>CONFIRM</IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonModal>
  )
}

export default MapModal;