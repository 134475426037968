import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Share } from '@capacitor/share';

import {
  IonPage, IonHeader, IonContent, IonSlides, IonSlide, IonToolbar, IonButtons,
  IonLabel, isPlatform, IonButton, IonIcon, IonLoading, IonImg, IonText, IonRow, IonItem,
  IonGrid, IonCol, IonTitle
} from '@ionic/react';
import {
  chevronBack, shareSocial, add, remove, logoWhatsapp, cartOutline, heartOutline, heart
} from 'ionicons/icons';

import {
  useConsumerOneQuery, useShopOneQuery,
  useAddToCartMutation, useRemoveFromCartMutation, 
  useConsumerLikesLazyQuery, useNewLikeMutation, useUnLikeMutation,
  EnumLikeType, 
} from '../../generated/graphql';

import { findIndexInArray, findObjInArray } from '../../util/arrayUtil';
import CartModal from './CartModal';
import { NotFound } from '../../pages/NotFound';
import ErrorPage from '../../pages/ErrorPage';
import { useToast } from '../../hooks/useToast';

import './ProductDetails.css';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  zoom: {
    maxRatio: 4
  }
};

interface Props extends RouteComponentProps<{ url: string, _id: string }> {
}

const ProductDetails: React.FC<Props> = ({ match, history, location }) => {
  const Toast = useToast();

  const { data: dataConsumer } = useConsumerOneQuery({ fetchPolicy: "cache-and-network" })
  const { data: dataShop, loading: loadingShop, error: errorShop, refetch: refetchShop } = useShopOneQuery({
    // use default cache-first to reduce traffic to server on every product detail view
    // fetchPolicy: "cache-and-network",
    variables: { filter: { url: match.params.url } }
  })
  const [getLikes, { data: dataLikes }] = useConsumerLikesLazyQuery({
    fetchPolicy: "cache-and-network"
  })
  
  const [addToCartMutation] = useAddToCartMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const [newLike] = useNewLikeMutation();
  const [unLike] = useUnLikeMutation();

  const [cartModalOpen, showCartModal] = useState(new URLSearchParams(location.search).get("openCart") === "true" ? true : false)
  const [transparentHeader, setTransparentHeader] = useState(true);

  const shop = dataShop?.public?.shop;
  const product = shop?.products ? findObjInArray(shop.products, "_id", match.params._id) : undefined;
  const likes = dataLikes?.consumer?.likes
  const liked = (likes && product) ? likes.filter(f => (f.type === EnumLikeType.Product && f.typeId === product._id))[0] : false ;

  useEffect(() => {
    if (shop)
      getLikes({ variables: { filter: { shopId: shop._id } } })
  }, [shop, getLikes])

  async function onShareClick() {
    try {
      let shareRet = await Share.share({
        title: product.name,
        text: `RM ${product.pricing?.retail?.toFixed(2)} | ${product.name}`,
        url: match.url,
      });
      console.log("share successful: ", shareRet)
    } catch (err) {
      console.log("share error: ", err)
      Toast.error(err);
    }
  }

  function onAddCartClick() {
    addToCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: product._id,
          name: product.name,
          price: product.pricing?.retail,
          cartProduct: {
            name: product.name,
            imageUrl: product.imageUrl
          }
        }
      }
    });
  }

  function onRemoveCartClick() {
    removeFromCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: product._id,
          price: product.pricing?.retail
        }
      }
    })
  }

  function onScroll(event: any) {
    if (event.detail.currentY === 0) {
      setTransparentHeader(true);
    } else {
      setTransparentHeader(false);
    }
  }

  function onLikeClick(id: string) {
    if (liked) {
      Toast.success("Remove from liked list");
      unLike({
        variables: {
          filter: {
            _id: liked._id
          }
        }
      }).then(() => getLikes({ variables: { filter: { shopId: shop?._id } } }))
    } else {
      Toast.success("Added to liked list");
      newLike({
        variables: {
          record: {
            consumerId: dataConsumer?.consumer?.me?._id,
            shopId: shop?._id,
            type: EnumLikeType.Product,
            typeId: id
          }
        }
      }).then(() => getLikes({ variables: { filter: { shopId: shop?._id } } }))
    }
  }

  if (!dataShop?.public?.shop && loadingShop) {
    return <IonPage><IonLoading isOpen={loadingShop} /></IonPage>
  }

  if (!dataShop?.public?.shop && errorShop) {
    console.log('ProductDetails error', errorShop)
    return <ErrorPage refresh={() => refetchShop()} />
  }

  if (shop && product) {
    let cartIndex = shop.orderLocal?.carts ? findIndexInArray(shop.orderLocal.carts, "productId", product._id) : null;

    return (
      <IonPage className="shop02-details">
        {isPlatform("desktop") ?
          <IonHeader>
            <IonToolbar>
              <IonButton slot="start" fill="clear" onClick={() => history.goBack()}>
                <IonIcon icon={chevronBack} slot="icon-only"></IonIcon>
              </IonButton>
              <IonTitle>{product.name}</IonTitle>
              <IonButtons slot="end">
                <IonButton href={`https://api.whatsApp.com/send?phone=${shop.whatsapp_number}`}>
                  <IonIcon icon={logoWhatsapp} size="large" color="primary" />
                </IonButton>
                <IonButton onClick={() => showCartModal(true)}>
                  <IonIcon icon={cartOutline} size="large" color="primary" />
                  <IonLabel>{shop.orderLocal?.totalQty}</IonLabel>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          :
          <IonHeader className={transparentHeader ? "transparent-header ion-no-border" : undefined}>
            <IonToolbar>
              <IonButton slot="start" fill="clear" onClick={() => history.goBack()}>
                <IonIcon icon={chevronBack} slot="icon-only"></IonIcon>
              </IonButton>
              {!transparentHeader && <IonTitle >{product.name}</IonTitle>}
            </IonToolbar>
          </IonHeader>
        }

        <IonContent scrollEvents={isPlatform('mobile') ? true : false} onIonScroll={onScroll} >
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="5" sizeMd="6" sizeXs="12" className="ion-padding">
                {/* the IonSlides got bug that it cannot handle dynamic add or remove of slide, so
                  we have to change the key of it to make the old destoy and recreate a new one when
                  there are slide added or deleted  */}
                <IonSlides options={slideOpts} pager={true} key={product.images?.length}>
                  {product.images?.map((img: any, index: any) => img.url !== "" ?
                    <IonSlide key={index}>
                      <IonImg src={img.url} />
                      <div className="image-count">{`${index + 1}/${product.images?.length}`}</div>
                    </IonSlide>
                    : null
                  )}
                </IonSlides>
              </IonCol>
              <IonCol sizeLg="7" sizeMd="6" sizeXs="12" className="ion-padding">
                <IonItem lines="none">
                  <IonText color="tertiary">
                    <h4>RM {product.pricing?.retail?.toFixed(2)}</h4>
                  </IonText>
                  <IonItem slot="end" lines="none">
                    {cartIndex !== null &&
                      <IonButton color="medium" onClick={onRemoveCartClick}>
                        <IonIcon icon={remove}></IonIcon>
                      </IonButton>}
                    {(shop.orderLocal?.carts && cartIndex !== null) &&
                      <IonText>&nbsp;{shop.orderLocal.carts[cartIndex]?.qty}&nbsp;</IonText>}
                    <IonButton onClick={onAddCartClick}
                      disabled={product.stock ? (!product.unlimitedStock && product.stock < 1) : false}>
                      <IonIcon icon={add}></IonIcon>
                    </IonButton>
                    {(!product.unlimitedStock && (product.stock && product.stock < 1)) && <p className="out-of-stock">Out of stock</p>}
                  </IonItem>
                </IonItem>
                <IonItem lines="none">
                  <IonText><h4>{product.name}</h4></IonText>
                  <IonButtons slot="end">
                    <IonButton fill="clear" onClick={() => onLikeClick(product._id)}>
                      <IonIcon icon={liked ? heart : heartOutline} color={liked ? "primary" : "medium"} slot="icon-only" />
                    </IonButton>
                    <IonButton fill="clear" onClick={onShareClick}>
                      <IonIcon icon={shareSocial} slot="icon-only" color="primary" />
                    </IonButton>
                  </IonButtons>
                </IonItem>
                <IonItem lines="none">
                  <p>{product.longDesc}</p>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>

          <div style={{ width: '100%' }}>
            {product.descriptionImages?.map((img: any, index: any) => {
              if (img.url !== "") 
                return <IonImg key={index} src={img.url} />
              else
                return null ;
            })}
          </div>

          <CartModal
            user={dataConsumer?.consumer?.me}
            shop={shop}
            isOpen={cartModalOpen}
            setShowModal={showCartModal}
            redirectToLogin={() => history.push("/login", { prevLocation: location.pathname, openCart: true })}
          />

        </IonContent>
      </IonPage>
    )
  }
  return <NotFound />
}

export default ProductDetails;