import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { 
  IonPage, IonHeader, IonToolbar, IonTitle, IonButton, IonIcon, IonContent, IonRow, IonItem, 
  IonInput, IonLoading, IonAlert, isPlatform, IonCard, IonCardContent, IonCardTitle, IonText 
} from '@ionic/react';
import { chevronBack, eyeOffOutline, eyeOutline } from 'ionicons/icons';

import {
  useLoginUsingPasswordUsernameMutation, useLoginUsingPasswordPhoneMutation,
  useLoginUsingPasswordEmailMutation, useLoginUsingOtpPhoneMutation,
  useLoginUsingOtpEmailMutation, LoginEnum, LoginPassPhoneEnum, LoginPassEmailEnum,
  useSendSmsOtpMutation, useSendEmailOtpMutation, OtpChannelEnum
} from '../generated/graphql';

import './Login.css'

let initialValues = {
  username_phone_email: "",
  password: "",
  code: ""
};
let intervalId: NodeJS.Timeout;
let prevLocation: any = null;
let openCart = false;

//const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;

const Login: React.FC = () => {
  const { control, handleSubmit, formState, getValues, errors, setError, reset } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange"
  });
  const [showPassword, setShowPassword] = useState(false)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loginWithOTP, setLoginWithOTP] = useState(isPlatform("mobile") ? true : false)
  const [codeTimeoutActivated, activateCodeTimeout] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const history = useHistory();
  const location = useLocation();
  //@ts-ignore
  if (location.state) {
    //@ts-ignore
    prevLocation = (location.state.prevLocation && location.state.prevLocation) || "/";
    //@ts-ignore
    openCart = (location.state.openCart && location.state.openCart) || false
  }

  const [sendSmsOtp] = useSendSmsOtpMutation();
  const [sendEmailOtp] = useSendEmailOtpMutation();
  const [loginPassUsername, { client }] = useLoginUsingPasswordUsernameMutation()
  const [loginPassPhone] = useLoginUsingPasswordPhoneMutation()
  const [loginPassEmail] = useLoginUsingPasswordEmailMutation()
  const [loginOtpPhone] = useLoginUsingOtpPhoneMutation()
  const [loginOtpEmail] = useLoginUsingOtpEmailMutation()

  useEffect(() => {

    if (codeTimeoutActivated) {
      // exit early when we reach 0
      if (!timeLeft) {
        activateCodeTimeout(false)
        return;
      }

      // save intervalId to clear the interval when the
      // component re-renders
      intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }

  }, [timeLeft, codeTimeoutActivated]);

  /**
   *
   * @param _fieldName
   */
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="error-message">
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  /**
   *
   * @param data
   */
  const onSubmit = async (data: any) => {
    setLoading(true);
    if (loginWithOTP) {
      if (phoneRegex.test(data.username_phone_email)) {
        loginOtpPhone({
          variables: {
            record: {
              phone: `+6${data.username_phone_email}`,
              code: data.code
            }
          }
        }).then(res => {
          setLoading(false);
          if (res.data?.public?.login?.usingOTP?.bySms) {
            const { accessToken, refreshToken } = res.data.public.login.usingOTP.bySms;
            localStorage.setItem("accessToken", accessToken)
            localStorage.setItem("refreshToken", refreshToken)
            localStorage.setItem("isAuthenticated", "true");
            client?.resetStore();
            window.location.replace(prevLocation);
          } else {
            setIsError(true);
          }
        }).catch((err) => {
          setLoading(false);
          console.log(err)
          setIsError(true);
        });
      } else if (emailRegex.test(data.username_phone_email)) {
        loginOtpEmail({
          variables: {
            record: {
              email: data.username_phone_email,
              code: data.code
            }
          }
        }).then(res => {
          setLoading(false);
          if (res.data?.public?.login?.usingOTP?.byEmail) {
            const { accessToken, refreshToken } = res.data.public.login.usingOTP.byEmail;
            localStorage.setItem("accessToken", accessToken)
            localStorage.setItem("refreshToken", refreshToken)
            localStorage.setItem("isAuthenticated", "true");
            client?.resetStore();
            window.location.replace(prevLocation);
          } else {
            setIsError(true);
          }
        }).catch((err) => {
          setLoading(false);
          console.log(err)
          setIsError(true);
        });
      } else {
        setError("username_phone_email", "invalid input", "Invalid phone number or email. (E.g. 0123456789 or user@mail.com)")
      }
    } else {
      if (phoneRegex.test(data.username_phone_email)) {
        loginPassPhone({
          variables: {
            record: {
              phone: `+6${data.username_phone_email}`,
              password: data.password,
              type: LoginPassPhoneEnum.Consumer
            }
          }
        }).then(res => {
          setLoading(false);
          if (res.data?.public?.login?.usingPassword?.byPhone) {
            const { accessToken, refreshToken } = res.data.public.login.usingPassword.byPhone;
            localStorage.setItem("accessToken", accessToken)
            localStorage.setItem("refreshToken", refreshToken)
            localStorage.setItem("isAuthenticated", "true");
            client?.resetStore();
            window.location.replace(prevLocation);
          } else {
            setIsError(true);
          }
        }).catch((err) => {
          setLoading(false);
          console.log(err)
          setIsError(true);
        });
      } else if (emailRegex.test(data.username_phone_email)) {
        loginPassEmail({
          variables: {
            record: {
              email: data.username_phone_email,
              password: data.password,
              type: LoginPassEmailEnum.Consumer
            }
          }
        }).then(res => {
          setLoading(false);
          if (res.data?.public?.login?.usingPassword?.byEmail) {
            const { accessToken, refreshToken } = res.data.public.login.usingPassword.byEmail;
            localStorage.setItem("accessToken", accessToken)
            localStorage.setItem("refreshToken", refreshToken)
            localStorage.setItem("isAuthenticated", "true");
            client?.resetStore();
            window.location.replace(prevLocation);
          } else {
            setIsError(true);
          }
        }).catch((err) => {
          setLoading(false);
          console.log(err)
          setIsError(true);
        });
      } else {
        loginPassUsername({
          variables: {
            record: {
              username: data.username_phone_email,
              password: data.password,
              type: LoginEnum.Consumer
            }
          }
        }).then(res => {
          setLoading(false);
          if (res.data?.public?.login?.usingPassword?.byUsername) {
            const { accessToken, refreshToken } = res.data.public.login.usingPassword.byUsername;
            localStorage.setItem("accessToken", accessToken)
            localStorage.setItem("refreshToken", refreshToken)
            localStorage.setItem("isAuthenticated", "true");
            client?.resetStore();
            window.location.replace(openCart ? `${prevLocation}?openCart=true` : prevLocation);
          } else {
            setIsError(true);
          }
        }).catch((err) => {
          setLoading(false);
          console.log(err)
          setIsError(true);
        });
      }
    }
  };

  const sendCode = () => {
    let input = getValues("username_phone_email")
    if (phoneRegex.test(input)) {
      sendSmsOtp({
        variables: { record: { phone: `+6${input}`, channel: OtpChannelEnum.Sms } }
      }).then(res => {
        console.log("code sent", res)
        if (res.data?.public?.otp?.sms?.send) {
          activateCodeTimeout(true);
        }
      }).catch(error => {
        console.log("err send code ", error)
        setError("username_phone_email", "Error", `Unable to send code. ${error.message}.`)
      })
    } else if (emailRegex.test(input)) {
      sendEmailOtp({
        variables: { record: { email: input } }
      }).then(res => {
        console.log("code sent", res)
        if (res.data?.public?.otp?.email?.send) {
          activateCodeTimeout(true);
        }
      }).catch(error => {
        console.log("err send code ", error)
        setError("username_phone_email", "Error", `Unable to send code. ${error.message}.`)
      })
    } else {
      setError("username_phone_email", "invalid input", "Invalid phone number or email. (E.g. 0123456789 or user@mail.com)")
    }
  }

  const renderContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonItem>
          <Controller
            as={IonInput}
            placeholder={loginWithOTP ? "Phone number / Email" : "Username / Phone number / Email"}
            control={control}
            onChangeName="onIonChange"
            onChange={([selected]) => {
              return selected.detail.value;
            }}
            name="username_phone_email"
            rules={{
              required: true,
            }}
          />
        </IonItem>
        {showError("username_phone_email")}

        {!loginWithOTP ?
          <div>
            <IonItem>
              <Controller
                as={IonInput}
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                control={control}
                onChangeName="onIonChange"
                onChange={([selected]) => {
                  return selected.detail.value;
                }}
                name="password"
                rules={{
                  required: true,
                }}
              />
              <IonButton disabled={loginWithOTP} fill="clear" onClick={() => setShowPassword(!showPassword)}>
                <IonIcon icon={showPassword ? eyeOutline : eyeOffOutline} size="20px" />
              </IonButton>
            </IonItem>
            {showError("password")}
            <IonRow className="ion-justify-content-end">
              <IonButton fill="clear" href="/forgot-password">Forgot password?</IonButton>
            </IonRow>
          </div>
          :
          <div>
            <IonItem>
              <Controller
                as={IonInput}
                placeholder="Code"
                type="text"
                control={control}
                onChangeName="onIonChange"
                onChange={([selected]) => {
                  return selected.detail.value;
                }}
                name="code"
                rules={{
                  required: true,
                }}
              />
              <IonButton fill="clear" disabled={codeTimeoutActivated || !getValues("username_phone_email") ? true : false} onClick={() => sendCode()}>
                {codeTimeoutActivated ? `${timeLeft} s` : "Send Code"}
              </IonButton>
            </IonItem>
            {showError("code")}
          </div>
        }

        <IonButton className="ion-margin-top" expand="block" type="submit" disabled={formState.isValid === false}>Login</IonButton>
        <IonRow className="ion-justify-content-between">
          {!loginWithOTP && isPlatform("mobile") ? <IonButton fill="clear" onClick={() => history.replace("/signup", { prevLocation })}>Sign up</IonButton> : <div />}
          <IonButton fill="clear" onClick={() => {
            reset(initialValues);
            setLoginWithOTP(loginWithOTP => !loginWithOTP)
          }}>{loginWithOTP ? "Login with password" : "Login with OTP"}</IonButton>
        </IonRow>
      </form>
    )
  }



  return isPlatform("mobile") ?
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => history.goBack()}>
            <IonIcon icon={chevronBack}></IonIcon>
          </IonButton>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="login ion-padding">
        <IonText>Please login first to continue.</IonText>
        <IonRow className="ion-justify-content-center ion-padding-vertical">
          <img src='/assets/icon/icon.png' alt="logo" />
        </IonRow>
        {renderContent()}
        <IonLoading
          isOpen={loading}
          message={'Please wait...'}
        />
        <IonAlert
          isOpen={isError}
          message="Unable to login. Please make sure your inputs are correct."
          onDidDismiss={() => setIsError(false)}
          buttons={[
            {
              text: "OK",
              handler: () => setIsError(false)
            }
          ]}
        />
      </IonContent>
    </IonPage >
    :
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonItem slot="start" lines="none" href="/home">
            <img src='/assets/icon/icon.png' alt="logo" width="50px" height="50px"/>
            <IonTitle>Transformer</IonTitle>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard className="card">
          <IonCardContent>
            <IonRow className="ion-justify-content-between ion-margin-bottom">
              <IonCardTitle>Please login first to continue</IonCardTitle>
              <IonText>New user? <a href="/signup">Sign up</a></IonText>
            </IonRow>
            {renderContent()}
          </IonCardContent>
        </IonCard>
        <IonLoading
          isOpen={loading}
          message={'Please wait...'}
        />
        <IonAlert
          isOpen={isError}
          message="Unable to login. Please make sure your inputs are correct."
          onDidDismiss={() => setIsError(false)}
          buttons={[
            {
              text: "OK",
              handler: () => setIsError(false)
            }
          ]}
        />
      </IonContent>
    </IonPage>
}

export default Login