import React, { useState } from "react";

import {
  IonPage, IonHeader, IonToolbar, IonContent, IonList, IonItem, IonTitle, IonIcon, IonLabel,
  isPlatform, IonGrid, IonRow, IonCard, IonCardContent,
  IonCardSubtitle, IonButton, IonToggle, IonSelect, IonSelectOption,
} from "@ionic/react";
import {
  call, location, person, logOut, key, moon, language, notifications
} from 'ionicons/icons';

import {
  useGetSettingQuery, useSetSettingMutation, useConsumerOneQuery, EnumAddressLocationType
} from "../generated/graphql";

import AddressModal from "../components/AddressModal";
import PhoneOTPModal from "../components/PhoneOtpModal";
import NameModal from "../components/NameModal";
import PasswordModal from "../components/PasswordModal";
import t from '../language/translate';

import "./Setting.css";

let emptyAddress = {
  name: "",
  phone: "",
  detailed_address: "",
  state: "",
  city: "",
  zipcode: "",
  location: {
    type: EnumAddressLocationType.Point,
    coordinates: null,
    __typename: "AddressLocation"
  },
  __typename: "Address"
};

const Setting: React.FC = () => {
  // Query is already context aware
  const { data: dataSetting } = useGetSettingQuery({ fetchPolicy: "cache-first" });
  const [setSetting] = useSetSettingMutation();
  const { client, data } = useConsumerOneQuery({ fetchPolicy: "cache-and-network" })
  const [nameModalOpen, showNameModal] = useState(false);
  const [passwordModalOpen, showPasswordModal] = useState(false);
  const [phoneOTPModalOpen, showPhoneOTPModal] = useState(false);
  const [addressModalOpen, showAddressModal] = useState(false);

  const l = dataSetting?.getSetting?.language ? dataSetting?.getSetting?.language : "en";
  const darkMode = dataSetting?.getSetting?.darkMode ? true : false;

  const logout = () => {
    localStorage.clear();
    client.clearStore()
    //need to reload after log out to fixed bug (login page not showing without reload)
    window.location.replace("/");
  }

  function addressModalResult(result: string, data?: any) {
    if (result === "save") {
    }
    showAddressModal(false);
  }

  function onDarkModeChange(e: any) {
    // console.log("onDarkModeChange: ", e.detail.checked)
    setSetting({ variables: { record: { "darkMode": e.detail.checked } } });
  }

  function onLanguageChange(e: any) {
    setSetting({ variables: { record: { "language": e.detail.value } } });
  }

  const publicVapidKey = "BDxB08uFViF3YLCde8Rj__QifQ9jt8qrWsA1D_syqJE1wcgCt3yNnPPdg70aY8vCae0Sy9xdrtP9sXOTkqEOCiw";

  function urlBase64ToUint8Array(base64String: any) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  function onSubscribePush(e: any) {
    if (!("Notification" in window)) {
      return alert("This browser does not support desktop notification");
    }

    console.log(Notification.permission);
    if (Notification.permission === "denied") {
      // Cant request again.. already blocked
      Notification.requestPermission();
      // Alternative is to ask user enable
      alert("Please enable your notification");
    } else {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          console.log("Notification permission granted");

          navigator.serviceWorker.ready.then(async (register) => {
            const subscription = await register.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
            });
            console.log("subscription:", subscription);
            await fetch("https://dev.deepfuture.com.my/subscribe", {
              method: "POST",
              body: JSON.stringify(subscription),
              headers: {
                "content-type": "application/json",
              },
            });
          });
        }
      });
    }
  }

  if (data?.consumer?.me) {
    const { me: user } = data.consumer;
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Setting</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isPlatform("mobile") ?
          <IonContent>
            <IonList>
              <IonItem detail onClick={() => showNameModal(true)}>
                <IonIcon icon={person} slot="start" />
                <IonLabel>
                  <h3>Name</h3>
                  <p>{user.name}</p>
                </IonLabel>
              </IonItem>
              <IonItem detail onClick={() => showPasswordModal(true)}>
                <IonIcon icon={key} slot="start" />
                <IonLabel>
                  <h3>Password</h3>
                  <p>******</p>
                </IonLabel>
              </IonItem>
              <IonItem detail onClick={() => showPhoneOTPModal(true)}>
                <IonIcon icon={call} slot="start" />
                <IonLabel>
                  <h3>Phone</h3>
                  <p>{user.phone?.slice(2)}</p>
                </IonLabel>
              </IonItem>
              <IonItem detail onClick={() => showAddressModal(true)}>
                <IonIcon icon={location} slot="start" />
                <IonLabel>
                  <h3>Address</h3>
                  <p>{user.addresses && user.addresses[0] ? `${user.addresses[0].detailed_address}, ${user.addresses[0].zipcode} ${user.addresses[0].city}, ${user.addresses[0].state}` : "None"}</p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon icon={moon} slot="start" />
                <IonLabel>{t['setting.darkmode'][l]}</IonLabel>
                <IonToggle checked={darkMode} onIonChange={onDarkModeChange} />
              </IonItem>
              <IonItem lines="inset">
                <IonIcon icon={language} slot="start" />
                <IonSelect value={l} onIonChange={onLanguageChange} interface="action-sheet">
                  <IonSelectOption value="en">{t['setting.language.english'][l]}</IonSelectOption>
                  <IonSelectOption value="bm">{t['setting.language.malay'][l]}</IonSelectOption>
                </IonSelect>
              </IonItem>
              {/* <IonItem detail>
              <IonIcon icon={wallet} slot="start" />
              <IonLabel>
                <h3>Payment</h3>
                <p>Visa card</p>
              </IonLabel>
            </IonItem> */}
              <IonItem onClick={() => logout()}>
                <IonIcon icon={logOut} slot="start" />
                <IonLabel>
                  <h3>Log out</h3>
                </IonLabel>
              </IonItem>
              <IonItem onClick={onSubscribePush}>
                <IonIcon icon={notifications} slot="start" />
                <IonLabel>
                  <h3>Subscribe Notification</h3>
                </IonLabel>
              </IonItem>
            </IonList>
          </IonContent>
          :
          <IonContent>
            <IonGrid>
              <IonCard className="setting">
                {/* <IonCardHeader>
                  <IonCardTitle>Setting</IonCardTitle>
                </IonCardHeader> */}
                <IonCardContent>
                  <IonRow>
                    <IonCardSubtitle>Name</IonCardSubtitle>
                    <p className="ion-padding-horizontal">{user.name}</p>
                    <IonButton fill="clear" onClick={() => showNameModal(true)}>
                      <IonLabel>Change</IonLabel>
                    </IonButton>
                  </IonRow>
                  <IonRow>
                    <IonCardSubtitle>Password</IonCardSubtitle>
                    <p className="ion-padding-horizontal">******</p>
                    <IonButton fill="clear" onClick={() => showPasswordModal(true)}>
                      <IonLabel>Change</IonLabel>
                    </IonButton>
                  </IonRow>
                  <IonRow>
                    <IonCardSubtitle>Phone</IonCardSubtitle>
                    <p className="ion-padding-horizontal">{user.phone ? user.phone.slice(2) : "Not set"}</p>
                    <IonButton fill="clear" onClick={() => showPhoneOTPModal(true)}>
                      <IonLabel>Change</IonLabel>
                    </IonButton>
                  </IonRow>
                  {/* <IonRow>
                    <IonCardSubtitle>Email</IonCardSubtitle>
                    <p className="ion-padding-horizontal">{user.email ? user.email : "Not set"}</p>
                    <IonButton fill="clear" onClick={() => { }}>
                      <IonLabel>Change</IonLabel>
                    </IonButton>
                  </IonRow> */}
                  <IonRow>
                    <IonCardSubtitle>Address</IonCardSubtitle>
                    <p className="ion-padding-horizontal">{user.addresses && user.addresses[0] ? `${user.addresses[0].detailed_address}, ${user.addresses[0].zipcode} ${user.addresses[0].city}, ${user.addresses[0].state}` : "None"}</p>
                    <IonButton fill="clear" onClick={() => showAddressModal(true)}>
                      <IonLabel>Change</IonLabel>
                    </IonButton>
                  </IonRow>
                  <IonRow>
                    <IonCardSubtitle>{t['setting.darkmode'][l]}</IonCardSubtitle>
                    <IonToggle checked={darkMode} onIonChange={onDarkModeChange} />
                  </IonRow>
                  <IonRow>
                    <IonCardSubtitle>{t['setting.language'][l]}</IonCardSubtitle>
                    <IonSelect value={l} onIonChange={onLanguageChange} interface="action-sheet">
                      <IonSelectOption value="en">{t['setting.language.english'][l]}</IonSelectOption>
                      <IonSelectOption value="bm">{t['setting.language.malay'][l]}</IonSelectOption>
                    </IonSelect>
                  </IonRow>
                </IonCardContent>
              </IonCard>
              <IonButton expand="block" onClick={() => logout()}>Log out</IonButton>
            </IonGrid>
          </IonContent>
        }

        <NameModal
          isOpen={nameModalOpen}
          setShowModal={showNameModal}
          user={user}
        />

        <PasswordModal
          isOpen={passwordModalOpen}
          setShowModal={showPasswordModal}
        />

        <PhoneOTPModal
          isOpen={phoneOTPModalOpen}
          setShowModal={showPhoneOTPModal}
          userId={user._id}
        />

        <AddressModal
          title="Deliver Address"
          user={user}
          deliveryAddress={(user.addresses && user.addresses.length > 0) ? user.addresses[0] : emptyAddress}
          isOpen={addressModalOpen}
          enableDelete={true}
          modalResult={addressModalResult}
        />
      </IonPage>
    );
  }

  return null;
}

export default Setting;