import React, { useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { RefresherEventDetail } from '@ionic/core';

import {
  IonPage, IonHeader, IonFooter,
  IonToolbar, IonContent, IonSearchbar,
  IonRefresher, IonRefresherContent, IonSegment, IonSegmentButton,
  IonItem, IonButtons, IonButton, IonTitle, IonIcon, IonLabel,
  IonGrid, IonRow, IonCol, IonLoading, isPlatform, IonImg,
} from "@ionic/react";
import { add, remove, cart, logoWhatsapp, cartOutline, chevronBack } from 'ionicons/icons';

import {
  useShopOneQuery, useConsumerOneQuery, useSetProductsFilterMutation, 
  useAddToCartMutation, useRemoveFromCartMutation, 
} from "../../generated/graphql";

import { findIndexInArray } from '../../util/arrayUtil';
import { NotFound } from "../../pages/NotFound";
import ErrorPage from "../../pages/ErrorPage";
import CartModal from "./CartModal";

import "./Listing.css";

interface Props extends RouteComponentProps<{ url: string; }> {
}

const Listing: React.FC<Props> = ({ match, location, history }) => {
  const [cartModalOpen, showCartModal] = useState(new URLSearchParams(useLocation().search).get("openCart") === "true" ? true : false)
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("All");

  const { data: dataConsumer } = useConsumerOneQuery({ fetchPolicy: "cache-first" })
  const { data: dataShop, loading: loadingShop, refetch: refetchShop, error: errorShop } = useShopOneQuery({
    variables: { filter: { url: match.params.url } },
    fetchPolicy: "cache-first",
  })

  const [setFilter] = useSetProductsFilterMutation();
  const [addToCartMutation] = useAddToCartMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();

  const shop = dataShop?.public?.shop ;

  function onFilter(category: any, searchText: any) {
    if (shop) {
      setFilter({
        variables: {
          filter: {
            shopId: shop._id,
            categoryId: category,
            searchText: searchText
          }
        }
      });
    }
  }

  function onCategoryChange(e: any) {
    if (e.detail.value !== undefined) {
      setCategory(e.detail.value);
      onFilter(e.detail.value, searchText);
    }
  }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setCategory("All");
    setSearchText("");
    refetchShop();
    // if (!loading) event.detail.complete();
    setTimeout(() => event.detail.complete(), 500);
  }

  function onCartRemove(e: any, p: any) {
    //prevent from going to product details page
    e.stopPropagation(); //stop parent event
    e.preventDefault(); //stop itself default event
    removeFromCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: p._id,
          price: p.pricing.retail
        }
      }
    })

  }

  function onCartAdd(e: any, p: any) {
    e.stopPropagation();
    e.preventDefault();
    addToCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: p._id,
          name: p.name,
          price: p.pricing.retail,
          cartProduct: {
            name: p.name,
            imageUrl: p.imageUrl
          }
        }
      }
    })
  }

  if (!shop && loadingShop) {
    return <IonPage><IonLoading isOpen={loadingShop} /></IonPage>
  }

  if (!shop && errorShop) {
    console.log('Listing error', errorShop)
    return <ErrorPage refresh={() => refetchShop()} />
  }

  if (shop) {
    return (
      <IonPage className="shop02-listing">
        <IonHeader >
          <IonToolbar>
            {isPlatform("desktop") ?
              <IonItem slot="start" lines="none" routerLink={`/ShopGen02/${match.params.url}`} routerDirection="back">
                <IonButton fill="clear">
                  <IonIcon icon={chevronBack} color="primary" />
                </IonButton>
                <img src={shop.logo ? shop.logo : ""} alt="logo" width="50px" height="50px" />
                <IonTitle>{shop.name}</IonTitle>
              </IonItem>
              :
              <IonItem slot="start" lines="none">
                <IonButton fill="clear" onClick={() => history.goBack()}>
                  <IonIcon icon={chevronBack} slot="icon-only" />
                </IonButton>
                <IonTitle>{shop.name}</IonTitle>
              </IonItem>
            }
            <IonButtons slot="end">
              <IonButton href={`https://api.whatsApp.com/send?phone=${shop.whatsapp_number}`}>
                <IonIcon icon={logoWhatsapp} size={isPlatform("mobile") ? "default" : "large"} color="primary" />
              </IonButton>
              {isPlatform("desktop") &&
                <IonButton onClick={() => { showCartModal(true) }}>
                  <IonIcon icon={cartOutline} size="large" color="primary" />
                  <IonLabel>{shop.orderLocal?.totalQty}</IonLabel>
                </IonButton>
              }
            </IonButtons>
          </IonToolbar>
          {shop.categories && shop.categories.length > 0 ?
            <IonToolbar>
              <IonSegment scrollable value={category} onIonChange={onCategoryChange}>

                <IonSegmentButton value="All">
                  <IonLabel>全部</IonLabel>
                </IonSegmentButton>
                {shop.categories?.map((cat: any, index: number) => {
                  return (
                    <IonSegmentButton key={index} value={cat._id}>
                      <IonLabel>{cat.name}</IonLabel>
                    </IonSegmentButton>
                  );
                })}
              </IonSegment>
            </IonToolbar> : null}
        </IonHeader>

        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          <IonGrid>
            <IonSearchbar value={searchText} onIonChange={e => {
              if (e.detail.value !== undefined) {
                setSearchText(e.detail.value);
                onFilter(category, e.detail.value);
              }
            }} />
            <IonRow>
              {shop.products?.map((p: any) => {
                let cartIndex = shop.orderLocal?.carts ? findIndexInArray(shop.orderLocal.carts, "productId", p._id) : null;
                return (
                  <IonCol key={p._id} sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" hidden={p.status !== "published" || !p.show}>
                    <IonItem routerLink={`/ShopGen02/${match.params.url}/product-details/${p._id}`}>
                      <IonImg slot="start" src={p.imageUrl} alt="product" />
                      <IonCol>
                        <IonRow><IonCol>
                          <IonLabel>
                            <h2>{p.name}</h2>
                            <p>{p.shortDesc}</p>
                            {(!p.unlimitedStock && p.stock < 1) && <p className="out-of-stock">Out of stock</p>}
                          </IonLabel>
                        </IonCol></IonRow>
                        <IonRow className="ion-align-items-center">
                          <IonCol  >
                            <IonLabel className="ion-text-nowrap">RM {p.pricing.retail.toFixed(2)}</IonLabel>
                          </IonCol>
                          <IonCol>
                            <IonRow className="ion-nowrap ion-justify-content-end ion-align-items-center">
                              {cartIndex !== null ?
                                <IonButton size="small" color="medium" onClick={(e) => onCartRemove(e, p)}>
                                  <IonIcon icon={remove} />
                                </IonButton>
                                : null}
                              {shop.orderLocal?.carts && cartIndex !== null ?
                                <IonLabel>&nbsp;{shop.orderLocal.carts[cartIndex]?.qty}&nbsp;</IonLabel>
                                : null}
                              <IonButton size="small" color="primary" onClick={(e) => onCartAdd(e, p)}
                                disabled={(!p.unlimitedStock && p.stock < 1)} >
                                <IonIcon icon={add} />
                              </IonButton>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonItem>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>

          <CartModal
            user={dataConsumer?.consumer?.me}
            shop={shop}
            isOpen={cartModalOpen}
            setShowModal={showCartModal}
            redirectToLogin={() => history.push("/login", { prevLocation: location.pathname, openCart: true })}
          />
        </IonContent>
        {isPlatform("mobile") && <IonFooter>
          {/* <IonItem detail routerLink={`${match.url}/cart`}> */}
          <IonItem detail color="primary" onClick={() => {
            showCartModal(true)
          }}>
            <IonIcon slot="start" icon={cart} />
            <IonLabel>{shop.orderLocal?.totalQty} items</IonLabel>
            <IonLabel>RM {shop.orderLocal?.subTotal?.toFixed(2)}</IonLabel>
          </IonItem>
        </IonFooter>}
      </IonPage>
    );
  }
  return <NotFound />;
}

export default Listing;