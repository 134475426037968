import React, { useState, useEffect } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { RefresherEventDetail } from "@ionic/core";
import { cloneDeep } from "lodash";
import Helmet from "react-helmet";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonItem,
  IonButtons,
  IonButton,
  IonTitle,
  IonIcon,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  isPlatform,
  IonImg,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";
import {
  logoWhatsapp,
  cartOutline,
  heartOutline,
  listOutline,
  calculatorOutline,
} from "ionicons/icons";

import {
  useShopOneQuery,
  useConsumerOneQuery,
  useCreateOrderMutation,
  useOrderUpdateMutation,
  ShopOneDocument,
  ConsumerOneDocument,
} from "../../generated/graphql";

import { findObjInArray, stripTypenames } from "../../util/arrayUtil";

import CartModal from "./CartModal";
import { NotFound } from "../../pages/NotFound";
import ErrorPage from "../../pages/ErrorPage";

import "./ShopHome.css";

let timeoutId: NodeJS.Timeout;

interface Props extends RouteComponentProps<{ url: string }> { }

const ShopHome: React.FC<Props> = ({ match, history, location }) => {
  const [cartModalOpen, showCartModal] = useState(
    new URLSearchParams(useLocation().search).get("openCart") === "true"
      ? true
      : false
  );

  const { data: dataConsumer } = useConsumerOneQuery({
    fetchPolicy: "cache-and-network",
  });
  const {
    data: dataShop,
    loading: loadingShop,
    refetch: refetchShop,
    error: errorShop,
  } = useShopOneQuery({
    variables: { filter: { url: match.params.url } },
    // variables: { filter: { _id: "5eb249bcc56c325ee4361c41" } },
    fetchPolicy: "cache-and-network",
  });

  const [createOrder] = useCreateOrderMutation({
    update(cache, { data }) {
      //@ts-ignore
      let { shop: shopOne } = cache.readQuery({
        query: ShopOneDocument,
        variables: { filter: { url: match.params.url } },
      }).public;
      let shopCopy = cloneDeep(shopOne);
      shopCopy.orderLocal._id = data?.consumer?.orderCreate?.record?._id;
      cache.writeQuery({
        query: ShopOneDocument,
        data: { public: { shop: shopCopy, __typename: "PublicQuery" } },
      });
    },
    refetchQueries: [
      {
        query: ConsumerOneDocument,
      },
    ],
  });
  const [orderUpdate] = useOrderUpdateMutation();

  const me = dataConsumer?.consumer?.me;
  const orders = me?.orders;
  const shop = dataShop?.public?.shop;

  // only need to trigger update the cart in one single place top level. do not update at other place.
  useEffect(() => {
    if (shop && orders) {
      // console.log("carts change")
      if (orders.length > 0) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          if (shop) {
            let order = findObjInArray(orders, "shopId", shop._id);
            if (order && order.status === "incart") {
              let localOrder = cloneDeep(stripTypenames(shop.orderLocal));
              // need to delete the unmatch data from localOrder before pass to graphql
              delete localOrder._id;
              localOrder.carts?.forEach((c: any) => delete c.cartProduct);
              // console.log("updateOrder ", localOrder)
              orderUpdate({
                variables: {
                  id: order._id,
                  record: localOrder,
                },
              })
                .then(
                  (res) =>
                    res.data?.consumer?.orderUpdate &&
                    console.log(
                      "order updated: ",
                      res.data.consumer.orderUpdate.record
                    )
                )
                .catch((err) =>
                  console.log("update order in server error: ", err)
                );
            } else {
              let newOrder = cloneDeep(stripTypenames(shop.orderLocal));
              newOrder.consumerId = me?._id;
              newOrder.shopId = shop._id;
              newOrder.delivery.deliverFrom = cloneDeep(
                stripTypenames(shop.address)
              );
              newOrder.delivery.deliverFrom.name = shop.name;
              newOrder.delivery.deliverFrom.phone = shop.phone;
              // need to delete the unmatch data from localOrder before pass to graphql
              delete newOrder._id;
              newOrder.carts?.forEach((c: any) => delete c.cartProduct);
              // console.log("newOrder ", newOrder)
              createOrder({
                variables: {
                  record: newOrder,
                },
              })
                .then(
                  (res) =>
                    res.data?.consumer?.orderCreate &&
                    console.log(
                      "order created: ",
                      res.data.consumer.orderCreate.record
                    )
                )
                .catch((err) =>
                  console.log("create order in server error: ", err)
                );
            }
          }
        }, 2000);
      }
    }
  }, [shop, me, orders, createOrder, orderUpdate]);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    refetchShop();
    window.location.reload();
    // if (!loading) event.detail.complete();
    setTimeout(() => event.detail.complete(), 500);
  }

  if (!shop && loadingShop) {
    return (
      <IonPage>
        <IonLoading isOpen={loadingShop} />
      </IonPage>
    );
  }

  if (!shop && errorShop) {
    console.log("ShopHome error", errorShop);
    return <ErrorPage refresh={() => refetchShop()} />;
  }

  if (shop) {
    const iconSize = isPlatform("desktop") ? "large" : "default";
    return (
      <>
        <Helmet>
          <title>{shop.name}</title>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={shop.name} />
          <meta property="og:description" content={shop.description} />
          {/*
          Best practices : https://developers.facebook.com/docs/sharing/best-practices
          
          Images must be minimum 200px * 200px in size, width & height must be defined for newly uploaded url's 
          or not image will now show.. Will take some time for facebook to crawl and kick in.
          
          To see changes : https://developers.facebook.com/tools/debug/
          Always use different url to see changes. netlify will cache for 24-48 hours. New deploy will not invalidate cache

          For more information on open graph protocol
          https://ogp.me/ 

          For Whatsapp does not support image with size > 300KB
          https://stackoverflow.com/questions/25100917/showing-thumbnail-for-link-in-whatsapp-ogimage-meta-tag-doesnt-work

          */}
          <meta property="og:image" content={shop.coverImage} />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="300" />
          <meta property="fb:app_id" content="147853774107064" />
        </Helmet>
        <IonPage className="shop02-home">
          <IonHeader>
            <IonToolbar>
              {isPlatform("desktop") ? (
                <IonItem slot="start" lines="none">
                  <IonImg
                    src={shop.logo ? shop.logo : ""}
                    alt="logo"
                    style={{ width: "50px", height: "50px" }}
                  />
                  <IonTitle>{shop.name}</IonTitle>
                </IonItem>
              ) : (
                <IonItem slot="start" lines="none">
                  <IonImg
                    src={shop.logo ? shop.logo : ""}
                    alt="logo"
                    style={{ width: "30px", height: "30px" }}
                  />
                  {/* <IonTitle slot="start">{shop.name}</IonTitle> */}
                </IonItem>
              )}
              <IonButtons slot="end">
                <IonButton routerLink={`${match.url}/listing`}>
                  <IonIcon icon={listOutline} size={iconSize} color="primary" />
                  <IonLabel>产品</IonLabel>
                </IonButton>
                <IonButton routerLink={`${match.url}/favourite`}>
                  <IonIcon
                    icon={heartOutline}
                    size={iconSize}
                    color="primary"
                  />
                  <IonLabel>喜欢</IonLabel>
                </IonButton>
                <IonButton routerLink={`${match.url}/history`}>
                  <IonIcon
                    icon={calculatorOutline}
                    size={iconSize}
                    color="primary"
                  />
                  <IonLabel>定单</IonLabel>
                </IonButton>
                <IonButton
                  href={`https://api.whatsApp.com/send?phone=${shop.whatsapp_number}`}
                >
                  <IonIcon
                    icon={logoWhatsapp}
                    size={iconSize}
                    color="primary"
                  />
                  <IonLabel>联络</IonLabel>
                </IonButton>
                {shop.orderLocal?.totalQty ? (
                  <IonButton
                    onClick={() => {
                      showCartModal(true);
                    }}
                  >
                    <IonIcon
                      icon={cartOutline}
                      size={iconSize}
                      color="primary"
                    />
                    <IonLabel>{shop.orderLocal?.totalQty}件</IonLabel>
                  </IonButton>
                ) : null}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>

            <IonGrid>
              <IonRow>
                {shop.blogs.map((b: any, index: number) => {
                  return (
                    <IonCol key={index} sizeMd="4" sizeXs="12">
                      <IonCard button routerLink={`${match.url}/blog/${b._id}`}>
                        <IonImg src={b.cardImageUrl} />
                        <IonCardHeader>
                          <IonCardTitle>{b.cardTitle}</IonCardTitle>
                          <IonCardSubtitle>{b.cardText}</IonCardSubtitle>
                        </IonCardHeader>
                      </IonCard>
                    </IonCol>
                  );
                })}
              </IonRow>
            </IonGrid>

            <CartModal
              user={dataConsumer?.consumer?.me}
              shop={shop}
              isOpen={cartModalOpen}
              setShowModal={showCartModal}
              redirectToLogin={() =>
                history.push("/login", {
                  prevLocation: location.pathname,
                  openCart: true,
                })
              }
            />
          </IonContent>
        </IonPage>
      </>
    );
  }
  return <NotFound />;
};

export default ShopHome;
