import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { RefresherEventDetail } from '@ionic/core';
import moment from "moment";

import {
  IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonRow, IonIcon, isPlatform,
  IonGrid, IonCol, IonButton, IonRefresher, IonRefresherContent, IonText, IonLoading, IonLabel,
  IonButtons
} from '@ionic/react';
import { cartOutline, chevronBack } from 'ionicons/icons';

import {
  useShopOneQuery, useOrdersLazyQuery,
  Order, useConsumerOneQuery, EnumOrderStatus
} from '../../generated/graphql';

import HistoryDetailsModal from './HistoryDetailsModal';
import { NotFound } from "../../pages/NotFound";
import CartModal from "./CartModal";

import './History.css';

const statusForDelivery: any = {
  "open": { text: "处理中" },
  "confirmed": { text: "送货中" },
  "completed": { text: "已签收" },
  "cancelled": { text: "取消" },
}

const statusForSelfPickup: any = {
  "open": { text: "处理中" },
  "confirmed": { text: "自行领取" },
  "completed": { text: "已签收" },
  "cancelled": { text: "取消" },
}

interface Props extends RouteComponentProps<{ url: string }> {
}

const History: React.FC<Props> = ({ match, location, history }) => {
  const [cartModalOpen, showCartModal] = useState(new URLSearchParams(location.search).get("openCart") === "true" ? true : false)
  const [order, setOrder] = useState<Order>();
  const [showDetail, setShowDetail] = useState(false);

  const { data: dataConsumer } = useConsumerOneQuery({ fetchPolicy: "cache-first" })
  const { data: dataShop } = useShopOneQuery({
    variables: { filter: { url: match.params.url } },
    fetchPolicy: "cache-first",
  })
  const [getOrders, { data: dataOrders, loading: loadingOrders, refetch: refetchOrders }] = useOrdersLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const shop = dataShop?.public?.shop ;
  const orders = dataOrders?.consumer?.orders ;

  useEffect(() => {
    if (shop) {
      getOrders({
        variables: {
          filter: {
            AND: [
              { shopId: shop._id },
              {
                OR: [
                  { status: EnumOrderStatus.Open },
                  { status: EnumOrderStatus.Confirmed },
                  { status: EnumOrderStatus.Completed },
                  { status: EnumOrderStatus.Cancelled }
                ]
              }
            ]
          }
        }
      });
    }
  }, [shop, getOrders]);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    refetchOrders();
    // if (!loading) event.detail.complete()
    setTimeout(() => event.detail.complete(), 500);
  }

  if (shop) {
    const iconSize = isPlatform("desktop") ? "large" : "default";
    return (
      <IonPage className="shop02-history">
        <IonHeader>
          <IonToolbar>
            {isPlatform("desktop") ?
              <IonItem slot="start" lines="none" routerLink={`/ShopGen02/${match.params.url}`} routerDirection="back">
                <IonButton fill="clear">
                  <IonIcon icon={chevronBack} color="primary" />
                </IonButton>
                <img src={shop.logo ? shop.logo : ""} alt="logo" width="50px" height="50px" />
                <IonTitle>我的定单</IonTitle>
              </IonItem>
              :
              <IonItem slot="start" lines="none">
                <IonButton fill="clear" onClick={() => history.goBack()}>
                  <IonIcon icon={chevronBack} slot="icon-only"></IonIcon>
                </IonButton>
                <IonTitle>我的定单</IonTitle>
              </IonItem>
            }
            {shop.orderLocal?.totalQty ?
              <IonButtons slot="end">
                <IonButton fill="clear" onClick={() => { showCartModal(true) }}>
                  <IonIcon icon={cartOutline} color="primary" size={iconSize} />
                  <IonLabel slot="end">{shop.orderLocal?.totalQty}</IonLabel>
                </IonButton>
              </IonButtons>
              : null}
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          {orders?.map((o: any, i: number) => {
            return (
              <IonItem detail key={i} lines="full" onClick={() => {
                setOrder(o);
                setShowDetail(true);
              }}>
                <IonGrid>
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeLg="4">
                      <IonText>下单时间 {moment(o.orderDate).calendar(undefined, { sameElse: 'DD/MM/YYYY' })}</IonText>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeLg="4">
                      <IonText color="medium">Order ID: {o.displayId}</IonText>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeLg="4">
                      <IonRow>
                        <IonText color="medium" className="ion-padding-end">{o.totalQty} 件商品</IonText>
                        <IonText color="medium" className="ion-padding-start">RM {(o.subTotal + o.delivery.fee).toFixed(2)}</IonText>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonButton slot="end" fill="outline" size="small" shape="round">
                  {o.type === "delivery" ?
                    statusForDelivery[o.status].text
                    :
                    statusForSelfPickup[o.status].text
                  }
                </IonButton>
              </IonItem>
            );
          })}
          {orders?.length === 0 &&
            <IonGrid className="hv-center-absolute-item" style={{ width: "100%" }}>
              <IonRow className="ion-justify-content-center">
                <h3>没有交易定单</h3>
              </IonRow>
            </IonGrid>}

          <CartModal
            user={dataConsumer?.consumer?.me}
            shop={shop}
            isOpen={cartModalOpen}
            setShowModal={showCartModal}
            redirectToLogin={() => history.push("/login", { prevLocation: location.pathname, openCart: true })}
          />
        </IonContent>

        <IonLoading isOpen={loadingOrders} message="loading..." />

        <HistoryDetailsModal
          isOpen={showDetail}
          setShowModal={setShowDetail}
          shopUrl={match.params.url}
          order={order}
        />
      </IonPage>
    );
  }
  return <NotFound />;
}

export default History;