export function setNestedKeyValue(dataItem: any, key: string, value: any) {
  const arrKey = key.split(".");
  let field = dataItem;
  // Move down the nested field 
  for (let i = 0; i < arrKey.length - 1; i++) {
    // if hit undefine half way, create the empty object for it
    if (typeof field[arrKey[i]] === "undefined" || field === null)
      field[arrKey[i]] = {};
    field = field[arrKey[i]];
  }
  // Update the target last field
  field[arrKey[arrKey.length - 1]] = value;
}

export function getNestedKeyValue(dataItem: any, key: string) {
  const arrKey = key.split(".");
  let field = dataItem;
  // Move down the nested field 
  for (let i = 0; i < arrKey.length - 1; i++) {
    field = field[arrKey[i]];
    // if hit undefine half way, stop
    if (typeof field === "undefined" || field === null)
      return undefined;
  }
  // Return the target last field value
  return field[arrKey[arrKey.length - 1]];
}

export function updateNestedObj(data: any, path: string, overwriteObj: any) {
  const arrKey = path.split(".");
  let field = data;
  // Move down the nested field 
  for (let i = 0; i < arrKey.length - 1; i++) {
    // if hit undefine half way, create the empty object for it
    if (typeof field[arrKey[i]] === "undefined" || field === null)
      field[arrKey[i]] = {};
    field = field[arrKey[i]];
  }
  // Update the target last field by overwrite
  field[arrKey[arrKey.length - 1]] = {
    ...field[arrKey[arrKey.length - 1]],
    ...overwriteObj
  }
}

export function isPropsFulfilled(obj: any) {
  for (var key in obj) {
      if (obj[key] === null || obj[key] === "")
          return false;
  }
  return true;
}

// null, [], {} and "" will return true
// console.log("empty string:" + isEmpty(""));
// console.log("[]:" + isEmpty([]));
// console.log("{}:" + isEmpty({}));
// console.log("null:" + isEmpty(null));
// console.log("string:" + isEmpty("test"));
// console.log("{test: 0}:" + isEmpty({test: 0}));
// console.log("[0]:" + isEmpty([0]));
// console.log("new Date:" + isEmpty(new Date()));
export function isEmpty(data: any) {
  if (data === null)
    return true;
  switch (typeof data) {
    case "string":
      if (data.length === 0)
        return true;
      break;
    case "object":
      // if we did not check .constructr the "new Date()" object will detect as empty
      if (Object.keys(data).length === 0 && (data.constructor === Object || data.constructor === Array))
        return true;
      break;
    default:
      break;
  }
  return false;
}