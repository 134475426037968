import axios from 'axios';

axios.defaults.baseURL = "https://osrm.deepfuture.com.my";

export async function calculateDeliveryFee(from: any, to: any) {
  console.log(from, to)
  if (to.city === "Kajang") {
    //-use in house delivery
    let baseFee = 5, perKMFee = 0.6;
    const distance = await axios.get(`/route/v1/driving/${from.location.coordinates[0]},${from.location.coordinates[1]};${to.location.coordinates[0]},${to.location.coordinates[1]}?overview=simplified`).then(res => {
      console.log("distance ", res.data)
      return res.data.routes[0].distance;
    }).catch(err => {
      console.log(err)
      return 0;
    });

    if (distance > 5000) {
      //base fee + fee for extra distance after 5km
      baseFee = baseFee + (Math.ceil((distance - 5000) / 1000) * perKMFee)
    }

    return { distanceInKm: distance / 1000, fee: distance === 0 ? 0 : baseFee }
  } else {
    return { distanceInKm: null, fee: 8 }
    //- use other courier service provider
    //- can consider easyparcel integration https://easyparcel.com/my/en/apilanding/
  }
}

//Needed information to get the fee
// from address
// to address
// dimension
// weight
// quantity