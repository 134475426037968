import React from "react";
import { Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { IonPage, IonRouterOutlet, IonSplitPane } from "@ionic/react";

import Menu from '../components/shopGen01/Menu';
import Listing from '../components/shopGen01/Listing';
import ProductDetails from "../components/shopGen01/ProductDetails";

interface Props extends RouteComponentProps<{ url: string; }> {
}

const ShopGen01: React.FC<Props> = ({ match }) => {

  // Cannot get to implement split plan with nested router
  // return (
  //   <IonPage>
  //     <IonSplitPane contentId="shopContent">
  //       <Menu url={match.url} />

  //       <IonRouterOutlet id="shopContent">
  //         <Route path={`/ShopGen01/:url/product-details/:_id`} render={(props) => <ProductDetails {...props} />} />
  //         <Route exact path={`/ShopGen01/:url`} render={(props) => <Listing {...props} />} />
  //       </IonRouterOutlet>
  //     </IonSplitPane>
  //   </IonPage>
  // )

  return (
    <IonRouterOutlet id="shopContent" ionPage>
      <Route path={`/ShopGen01/:url/product-details/:_id`} render={(props) => <ProductDetails {...props} />} />
      <Route exact path={`/ShopGen01/:url`} render={(props) => <Listing {...props} />} />
    </IonRouterOutlet>
  )

  // The below code is same as above code
  // return (
  //   <IonPage>
  //     <IonRouterOutlet>
  //       <Route path={`/ShopGen01/:url/product-details/:_id`} render={(props) => <ProductDetails {...props} />} />
  //       <Route exact path={`/ShopGen01/:url`} render={(props) => <Listing {...props} />} />
  //     </IonRouterOutlet>
  //   </IonPage>
  // )

}

export default ShopGen01;