import React from 'react'
import { IonPage, IonContent, IonButton, IonRow, IonGrid } from '@ionic/react'

interface Props {
  refresh: any
}

const ErrorPage: React.FC<Props> = ({ refresh }) => {
  return (
    <IonPage>
      <IonContent >
        <IonGrid style={{ height: "100%" }}>
          <IonRow style={{ height: "100%", flexDirection: "column" }} className="ion-justify-content-center ion-align-items-center">
            <h3 style={{ textAlign: "center" }}>Something went wrong. Please check your connection and try again.</h3>
            <IonButton onClick={refresh}>TRY AGAIN</IonButton>
          </IonRow>
        </IonGrid>
      </IonContent >
    </IonPage>
  )
}

export default ErrorPage;
