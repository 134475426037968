import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonRouterOutlet, IonToast, isPlatform } from '@ionic/react';
import TagManager from 'react-gtm-module';

import {
  IonPage, IonTabs, IonTabBar, IonTabButton, IonLabel, IonIcon, IonAlert,
} from '@ionic/react';
import { home, cube, settings, cloudDownload, reloadCircle } from 'ionicons/icons';

import {
  useSystemVersionQuery, useGetCacheVersionQuery, useGetSettingQuery,
  useClearCacheMutation,
  EnumSystemVersionType,
} from '../generated/graphql';

import { cacheVersion } from '../states/appApolloClient';
import { useServiceWorker } from '../hooks/useServiceWorker';

import Home from './Home';
import Setting from './Setting';
import MyOrder from './MyOrder';
import Delivery from './Delivery';
// import BlankPage from './BlankPage';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';

import ShopGen01 from './ShopGen01';
import ShopGen02 from './ShopGen02';

import './global.css';

// code version format: mm.nn.vv
// mm different: will cause force update
// nn different: show UpdateNow button to download new ServiceWorker immediately
// vv different: depend on browser behaviour, new ServiceWorker will be download when
//                  1) ServiceWorker cache expired after 24hr 
//                  2) User refresh browser page
//                  3) User visit page on new browser tab
//                  4) User kill and open pwa or when app exec window.location.reload()
const codeVersion = "1.0.0";

let installPWAPrompt: any;

interface PrivateRouteProps {
  component: any,
  path: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, path }) => (
  <Route exact path={path} render={(props) => {
    const isAuth = localStorage.getItem("isAuthenticated")
    return isAuth === "true" ?
      <Component {...props} />
      :
      <Redirect to={{ pathname: '/login', state: { prevLocation: path } }} />
  }}
  />
)

const AppTab: React.FC = (prop) => {
  const { data: dataSystemVersion } = useSystemVersionQuery({
    pollInterval: 300000,
    variables: { filter: { type: EnumSystemVersionType.Consumer } },
    fetchPolicy: "cache-and-network"
  });
  const { data: dataCacheVersion } = useGetCacheVersionQuery({ fetchPolicy: "cache-first" })
  const { data: dataSetting } = useGetSettingQuery({ fetchPolicy: "cache-first" });

  const [clearCache] = useClearCacheMutation();

  const { isUpdateAvailable, updateAssets, updateServiceWorker } = useServiceWorker();
  const [showForceUpdate, setShowForceUpdate] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showInstall, setShowInstall] = useState(false);
  const [toast, setToast] = useState({ show: false, duration: 1500, text: "" });
  const [showReset, setShowReset] = useState(false);

  const darkMode = dataSetting?.getSetting?.darkMode ? true : false;

  const handleInstallPWA = (e: any) => {
    // e.preventDefault();
    console.log("Install Prompt fired");
    installPWAPrompt = e;
    // See if the app is already installed, in that case, do nothing
    if ((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches)) {
      return false;
    }
    setShowInstall(true)
  }

  const installPwa = async () => {
    installPWAPrompt.prompt()
    let outcome = await installPWAPrompt.userChoice;
    if (outcome.outcome === 'accepted') {
      setToast({ show: true, duration: 1500, text: "Installing app." });
      setShowInstall(false);
    }
    else {
      setToast({ show: true, duration: 3000, text: "Install app with provide you with better user experience." });
    }
  }

  const handleLoad = (e: any) => {
    // if not loaded from PWA and PWA already install
    // load is always fire before beforeinstallprompt, so we could not rely on 
    // beforeinstallprompt to determine whether the user have app install
    /*    
        if (!(matchMedia('(display-mode: standalone)').matches) && !showInstall) {
          setToast({ show: true, duration: 3000, text: "Run from installed app to have a better user experience." });
        }
    */
  }

  const handleConnection = () => {
    if (navigator.onLine) {
      setToast({ show: true, duration: 1500, text: "You are back online" });
    } else {
      setToast({ show: true, duration: 3000, text: "You are currently offline. New input data might be lost." });
    }
  }

  useEffect(() => {
    //@ts-ignore
    // window.dataLayer.push({
    //   event: 'pageview'
    // });
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview'
      }
    });
  }, [])
  
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleInstallPWA)
    window.addEventListener('load', handleLoad);
    window.addEventListener('online', handleConnection);
    window.addEventListener('offline', handleConnection);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstallPWA)
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('online', handleConnection);
      window.removeEventListener('offline', handleConnection);
    }
  });

  useEffect(() => {
    if (dataCacheVersion) {
      const curVersion = dataCacheVersion.getCacheVersion?.cacheVersion?.split(".");
      const tarVersion = cacheVersion.split(".");
      // console.log("current cache version: ", curVersion);
      // console.log("target cache version: ", tarVersion);
      if (curVersion?.[0] !== tarVersion[0] || curVersion?.[1] !== tarVersion[1]) {
        console.log("delete cache");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        clearCache();
        setShowReset(true);
      }
    }
  }, [dataCacheVersion, clearCache])

  useEffect(() => {
    const strVersion = dataSystemVersion?.public?.systemVersion?.[0]?.codeVersion;
    if (strVersion) {
      const latestVersion = strVersion.split(".");
      const currentVersion = codeVersion.split(".");
      if (latestVersion[0] !== currentVersion[0]) {
        console.log("major version different. Current:" + codeVersion + " Latest:" + strVersion);
        if (!showForceUpdate) {
          updateServiceWorker();
        }
        setShowForceUpdate(true);
      } else if (latestVersion[1] !== currentVersion[1]) {
        console.log("minor version different. Current:" + codeVersion + " Latest:" + strVersion);
        setShowForceUpdate(false);
        setShowUpdate(true);
      } else if (latestVersion[2] !== currentVersion[2]) {
        console.log("variant version different. Current:" + codeVersion + " Latest:" + strVersion);
        setShowForceUpdate(false);
        setShowUpdate(false);
      } else {
        // console.log("same version");
        setShowForceUpdate(false);
        setShowUpdate(false);
      }
    }
  }, [dataSystemVersion, showForceUpdate, updateServiceWorker])

  useEffect(() => {
    if (darkMode !== undefined) {
      document.body.classList.toggle('dark', darkMode);
    }
  }, [darkMode]);

  // There is a bug in current version of IonTabButton which the onClick will not fire, so
  // use onIonTabsWillChange event instead
  const onTabChange = (e: any) => {
    switch (e.detail.tab) {
      case "install":
        installPwa();
        break ;
      case "updateNow":
        updateServiceWorker();
        break ;
      case "update":
        updateAssets();
        break ;
    }
  }

  if (showReset) {
    return (
      <IonPage>
        <IonAlert
          isOpen={true}
          backdropDismiss={false}
          header="Reset data"
          message="Reset data due to major upgrade."
          buttons={[
            {
              text: "OK",
              handler: () => window.location.reload(),
            }
          ]}
        />
      </IonPage>
    );
  } else {
    const tabLayout = isPlatform("mobile") ? "icon-top" : "icon-start";
    return (
      <>
        <IonTabs onIonTabsWillChange={onTabChange}>
          <IonRouterOutlet>
            <Route path={`/ShopGen01/:url`} render={(props) => <ShopGen01 {...props} />} />
            <Route path={`/ShopGen02/:url`} render={(props) => <ShopGen02 {...props} />} />

            <Route exact path="/home" component={Home} />
            <Route exact path="/delivery" component={Delivery} />
            <PrivateRoute path="/my_orders" component={MyOrder} />
            <PrivateRoute path="/setting" component={Setting} />

            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/forgot-password" component={ForgotPassword} />

            <Route exact path="/" render={() => <Redirect to="/home" />} />
          </IonRouterOutlet>

          <IonTabBar slot={isPlatform("mobile") ? "bottom" : "top"}>
            <IonTabButton tab="home" href="/home" layout={tabLayout}>
              <IonLabel>Home</IonLabel>
              <IonIcon icon={home} />
            </IonTabButton>
            {/* <IonTabButton tab="shop" href={`/shop/${state.last_merchant_id}`}> */}
            {/* <IonTabButton tab="shop" href="/shop" layout={tabLayout}>
              <IonLabel>Shop</IonLabel>
              <IonIcon icon={cart} />
            </IonTabButton> */}
            {/* <IonTabButton tab="delivery" href="/delivery" layout={tabLayout}>
              <IonLabel>Send</IonLabel>
              <IonIcon icon={send} />
            </IonTabButton> */}
            <IonTabButton tab="my_order" href="/my_orders" layout={tabLayout}>
              <IonLabel>My Orders</IonLabel>
              <IonIcon icon={cube} />
            </IonTabButton>
            <IonTabButton tab="setting" href="/setting" layout={tabLayout}>
              <IonLabel>Setting</IonLabel>
              <IonIcon icon={settings} />
            </IonTabButton>
            {showInstall ?
              <IonTabButton tab="install" layout={tabLayout}>
                <IonLabel>Install</IonLabel>
                <IonIcon icon={cloudDownload} />
              </IonTabButton>
              : null}
            {(!isUpdateAvailable && showUpdate) ?
              <IonTabButton tab="updateNow" layout={tabLayout}>
                <IonLabel>UpdateNow</IonLabel>
                <IonIcon icon={reloadCircle} />
              </IonTabButton>
              : null}
            {isUpdateAvailable ?
              <IonTabButton tab="update" layout={tabLayout}>
                <IonLabel>Update</IonLabel>
                <IonIcon icon={reloadCircle} />
              </IonTabButton>
              : null}
          </IonTabBar>
        </IonTabs>

        <IonToast
          isOpen={toast.show}
          onDidDismiss={() => setToast({ show: false, duration: 1500, text: "" })}
          message={toast.text}
          duration={toast.duration}
        />

        <IonAlert
          isOpen={isUpdateAvailable}
          backdropDismiss={false}
          header={'Update Available'}
          message={'A new update is available!'}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Update',
              handler: () => { updateAssets() }
            }
          ]}
        />

        <IonAlert
          isOpen={showForceUpdate}
          backdropDismiss={false}
          header={'Update needed before continue'}
          message={'Please kill app and all browser tab. Restart to receive update!'}
          buttons={isUpdateAvailable ?
            [{
              text: 'Update',
              handler: () => { updateAssets() }
            }]
            :
            []
          }
        />

      </>
    );
  }
};

export default AppTab;
