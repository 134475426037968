import React, { useState, useEffect } from "react";
import moment from "moment";
import { cloneDeep } from "lodash";

import {
  IonModal, IonContent, IonHeader, IonFooter, IonToolbar,
  IonList, IonItem,
  IonGrid, IonRow, IonCol,
  IonButton, IonIcon, IonLabel, IonTitle, IonText, IonAlert, IonDatetime, IonToggle,
} from "@ionic/react";
import { add, remove, chevronBack, trash, timeOutline } from 'ionicons/icons';

import {
  useAddToCartMutation, useRemoveFromCartMutation, useClearCartsMutation,
  useSetDeliveryAddressMutation, useSetDeliveryOptionFeeMutation, useSetDeliveryTimeMutation,
  useCreateOrderMutation, useOrderUpdateMutation,
  ShopOneDocument
} from "../../generated/graphql";

import { stripTypenames } from '../../util/arrayUtil';
import { isPropsFulfilled } from "../../util/objectUtil";

import AddressModal from "../AddressModal";
import { calculateDeliveryFee } from "../../util/deliveryFeeCalculator";
import PaymentModal from "../PaymentModal";

import "./CartModal.css";

interface Props {
  user: any,
  shop: any,
  isOpen: boolean,
  setShowModal: (isOpen: boolean) => void,
  redirectToLogin: any
}

const CartModal: React.FC<Props> = ({ user, shop, isOpen, setShowModal, redirectToLogin }) => {

  const [addToCartMutation] = useAddToCartMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const [clearCarts] = useClearCartsMutation();
  const [setDeliveryAddress] = useSetDeliveryAddressMutation();
  const [setDeliveryOptionFee] = useSetDeliveryOptionFeeMutation();
  const [setDeliveryTime] = useSetDeliveryTimeMutation();
  const [createOrder] = useCreateOrderMutation({
    update(cache, { data }) {
      //@ts-ignore
      let { shop: shopOne } = cache.readQuery({
        query: ShopOneDocument,
        variables: { filter: { url: shop.url } }
      }).public
      let shopCopy = cloneDeep(shopOne);
      shopCopy.orderLocal._id = data?.consumer?.orderCreate?.record?._id;
      cache.writeQuery({
        query: ShopOneDocument,
        data: { public: { shop: shopCopy, __typename: "PublicQuery" } },
      });
    }
  });
  const [orderUpdate] = useOrderUpdateMutation();

  const orderLocal = shop ? shop.orderLocal : null;
  const { type, carts, subTotal, delivery: { fee, option, deliverTo, timeFrom, timeTo } } = orderLocal;

  const [showClearAlert, setShowClearAlert] = useState(false);
  const [addressModalOpen, showAddressModal] = useState(false);
  const [paymentModalOpen, showPaymentModal] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [selfPickup, setSelfPickup] = useState(type === "selfpickup" ? true : false);
  const [beginDate, setBeginDate] = useState<any>(timeFrom);
  const [endDate] = useState<any>(timeTo);
  const [deliveryDetailsError, setDeliveryDetailsError] = useState<string | null | undefined>(null);

  useEffect(() => {
    if (isOpen && !deliverTo.detailed_address) {
      if (user && user.addresses.length > 0 && user.addresses[0].detailed_address) {
        console.log("update address")
        setDeliveryAddress({ variables: { shopUrl: shop.url, address: user.addresses[0] } })
        calculateDeliveryFee(shop.address, user.addresses[0]).then (res => {
          setDeliveryOptionFee({
            variables: {
              shopUrl: shop.url,
              deliveryOption: user.addresses[0].city === "Kajang" ? "ownfleet" : "courier",
              deliveryFee: res.fee,
              deliveryDistance: res.distanceInKm
            }
          })        
        })
      }
    }
  }, [isOpen, deliverTo.detailed_address, shop.url, shop.address, user, setDeliveryOptionFee, setDeliveryAddress]);

  async function addressModalResult(result: string, data?: any) {
    if (result === "save") {
      setDeliveryAddress({ variables: { shopUrl: shop.url, address: data } })
      let res = await calculateDeliveryFee(shop.address, data)
      setDeliveryOptionFee({
        variables: {
          shopUrl: shop.url,
          deliveryOption: data.city === "Kajang" ? "ownfleet" : "courier",
          deliveryFee: res.fee,
          deliveryDistance: res.distanceInKm
        }
      })
    }
    showAddressModal(false);
  }

  function clearCart() {
    clearCarts({ variables: { shopUrl: shop.url } })
    setShowModal(false);
  }

  function validateDeliveryInfo() {
    if (type === "delivery") {
      if (!isPropsFulfilled(deliverTo)) {
        setDeliveryDetailsError("Please key in your delivery address")
        return false
      } else {
        if (option === "ownfleet") {
          if (fee === 0) {
            setDeliveryDetailsError("Delivery fee are not calculated yet. Please make sure you are online & save your address again.")
            return false
          } else if (!timeFrom || !timeTo) {
            setDeliveryDetailsError("Please set your delivery time")
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }

  function onPaymentClick() {
    if (localStorage.getItem("isAuthenticated") === "true") {
      if (validateDeliveryInfo()) {
        if (orderLocal._id === "") {
          let newOrder = cloneDeep(stripTypenames(orderLocal))
          newOrder.consumerId = user._id;
          newOrder.shopId = shop._id;
          newOrder.delivery.deliverFrom = cloneDeep(stripTypenames(shop.address));
          newOrder.delivery.deliverFrom.name = shop.name;
          newOrder.delivery.deliverFrom.phone = shop.phone;
          delete newOrder._id;
          newOrder.carts?.forEach((c:any) => delete c.cartProduct);
          createOrder({
            variables: {
              record: newOrder
            }
          })
            .then(res => {
              if (res.data?.consumer?.orderCreate) {
                console.log("order created: ", res.data.consumer.orderCreate.record)
                showPaymentModal(true)
              }
            })
            .catch(err => console.log("create order in server ", err));
        } else {
          let record = cloneDeep(stripTypenames(orderLocal))
          delete record._id
          record.carts?.forEach((c:any) => delete c.cartProduct);
          orderUpdate({
            variables: {
              id: orderLocal._id,
              record
            }
          })
            .then(res => {
              if (res.data?.consumer?.orderUpdate) {
                console.log("order updated: ", res.data.consumer.orderUpdate.record)
                showPaymentModal(true)
              }
            })
            .catch(err => console.log("update order in server error: ", err));
        }
      }
    } else {
      setShowModal(false)
      redirectToLogin();
    }
  }

  async function onDeliveryOptionChange(e: any) {
    setSelfPickup(e.detail.checked)
    let res = await calculateDeliveryFee(shop.address, deliverTo);
    setDeliveryOptionFee({
      variables: {
        shopUrl: shop.url,
        deliveryOption: e.detail.checked ? "Self pick up" : deliverTo.city === "Kajang" ? "ownfleet" : "courier",
        deliveryFee: e.detail.checked ? 0 : res.fee,
        deliveryDistance: e.detail.checked ? null : res.distanceInKm,
      }
    })
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setShowModal(false)}>

      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => setShowModal(false)}>
            <IonIcon icon={chevronBack}></IonIcon>
          </IonButton>
          <IonTitle>Your cart</IonTitle>
          <IonButton fill="clear" slot="end" onClick={() => setShowClearAlert(true)}>
            <IonIcon icon={trash}></IonIcon>
          </IonButton>
        </IonToolbar>
      </IonHeader>

      {carts.length === 0 ?
        <IonContent>
          <div className="cart-empty">
            <IonText className="cart-empty ion-text-center ion-padding">
              You haven’t added anything to your cart yet!
            </IonText>
          </div>
        </IonContent>
        :
        <>
          <IonContent className="shop01-cart">
            <IonList lines="none">
              {carts.map((p: any) => {
                return (
                  <IonItem key={p.productId} className="cart-product" lines="inset">
                    <img slot="start" src={p.cartProduct.imageUrl} alt="product" />
                    <IonGrid>
                      <IonRow><IonCol>
                        <IonLabel>
                          <h2>{p.name}</h2>
                          <p>RM {p.price.toFixed(2)}</p>
                        </IonLabel>
                        <IonButton fill="clear" className="notes" onClick={() => setShowNotes(true)} >
                          Add notes (optional)
                        </IonButton>
                      </IonCol></IonRow>
                      <IonRow><IonCol>
                        <IonGrid><IonRow className="ion-align-items-center">
                          <IonCol size="7" className="cart-product-qty">
                            <IonButton fill="clear"
                              onClick={() => removeFromCartMutation({
                                variables: {
                                  shopUrl: shop.url,
                                  cart: {
                                    productId: p.productId,
                                    price: p.price
                                  }
                                }
                              })}>
                              <IonIcon icon={remove}></IonIcon>
                            </IonButton>
                            <IonText>&nbsp;{p.qty}&nbsp;</IonText>
                            <IonButton fill="clear"
                              onClick={() => addToCartMutation({
                                variables: {
                                  shopUrl: shop.url,
                                  cart: {
                                    productId: p.productId,
                                    price: p.price,
                                    // product already exist in the shop.orderLocal, so do not need to supply all data
                                    // name: p.name,
                                    // cartProduct: {
                                    //   name: p.name,
                                    //   imageUrl: p.cartProduct.imageUrl
                                    // }
                                  }
                                }
                              })}>
                              <IonIcon icon={add}></IonIcon>
                            </IonButton>
                          </IonCol>
                          <IonCol size="5" className="cart-product-price">
                            <IonText>RM {(p.qty * p.price).toFixed(2)}</IonText>
                          </IonCol>
                        </IonRow></IonGrid>

                      </IonCol></IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })}
            </IonList>
          </IonContent>

          <IonFooter>
            <IonItem lines="none">
              <IonButton fill="outline" size="small"
                color={selfPickup ? "medium" : "primary"}
                disabled={selfPickup}
                onClick={() => showAddressModal(true)}
              >
                Deliver To ...
              </IonButton>
              <IonToggle checked={selfPickup} onIonChange={onDeliveryOptionChange} />
              <IonLabel color={selfPickup ? "primary" : "medium"}>
                Self Pick Up
              </IonLabel>
            </IonItem>
            {type === "selfpickup" ? null :
              <>
                {deliverTo.detailed_address && <IonItem lines="none">
                  <IonText>
                    <p>{orderLocal && deliverTo ? `${deliverTo.detailed_address}, ${deliverTo.zipcode} ${deliverTo.city}, ${deliverTo.state}` : ""}</p>
                  </IonText>
                </IonItem>}
                {option === "ownfleet" ?
                  <IonItem lines="none">
                    <IonIcon color="primary" icon={timeOutline} />
                    <IonDatetime className="date-time" placeholder="deliver after"
                      displayFormat="DD MMM HH:mm" pickerFormat="DD MMM HH:mm"
                      min={moment().add(30, 'minutes').format()}
                      max={moment().add(5, 'days').format()}
                      value={beginDate}
                      onIonChange={e => {
                        setBeginDate(e.detail.value);
                        setDeliveryTime({
                          variables: {
                            shopUrl: shop.url,
                            deliveryTimeFrom: e.detail.value,
                            deliveryTimeTo: endDate
                          }
                        })
                      }}
                    />
                    {/* <IonText className="ion-padding-horizontal">To</IonText>
                    <IonDatetime placeholder="deliver before"
                      displayFormat="DD MMM HH:mm" pickerFormat="DD MMM HH:mm"
                      min={moment().add(1, 'hours').format()}
                      max={moment().add(5, 'days').format()}
                      value={endDate}
                      onIonChange={e => {
                        setEndDate(e.detail.value);
                        setDeliveryTime({
                          variables: {
                            shopUrl: shop.url,
                            deliveryTimeFrom: beginDate,
                            deliveryTimeTo: e.detail.value
                          }
                        })
                      }}
                    /> */}
                  </IonItem>
                  : option === "courier" ?
                    <IonItem>
                      <IonLabel>
                        Fulfilled by our courier service partner
                        <p>Est. Arrival: 11-22 May</p>
                      </IonLabel>
                    </IonItem> : null
                }
                <IonItem>
                  <IonText>Delivery Fee</IonText>
                  <IonText slot="end">RM {fee.toFixed(2)}</IonText>
                </IonItem>
              </>
            }
            <IonItem detail color="primary" onClick={onPaymentClick}>
              <IonText>Payment</IonText>
              <IonText slot="end">RM {(subTotal + fee).toFixed(2)}</IonText>
            </IonItem>
          </IonFooter>
        </>
      }

      <AddressModal
        title="Deliver Address"
        isOpen={addressModalOpen}
        user={user}
        shopUrl={shop.url}
        deliveryAddress={deliverTo}
        enableDelete={false}
        modalResult={addressModalResult}
      />

      <PaymentModal
        isOpen={paymentModalOpen}
        setShowModal={showPaymentModal}
        orderId={orderLocal._id}
      />

      <IonAlert
        isOpen={showClearAlert}
        header="Confirm"
        message="Clear all items in the cart?"
        onDidDismiss={() => setShowClearAlert(false)}
        buttons={[
          {
            text: "NO",
            handler: () => setShowClearAlert(false)
          },
          {
            text: "YES",
            handler: clearCart
          }
        ]}
      />

      <IonAlert
        isOpen={typeof (deliveryDetailsError) === "string"}
        message={deliveryDetailsError ? deliveryDetailsError : ""}
        onDidDismiss={() => setDeliveryDetailsError(null)}
        buttons={[
          {
            text: "OK",
            handler: () => setDeliveryDetailsError(null)
          }
        ]}
      />

      <IonAlert
        isOpen={showNotes}
        backdropDismiss={false}   // if allow backdrop dismiss will call buttons role cancel handle
        animated={true}
        onDidDismiss={() => setShowNotes(false)}
        header="Notes (optional)"
        // subHeader={`Edit ${showEdit.fieldName}`}
        // message={`Edit ${showEdit.fieldName}`}
        inputs={[
          {
            name: "notes",
            type: "textarea",
            placeholder: "e.g size m, blue color",
            //value: showEdit.value,
          }
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: (e) => setShowNotes(false)
          },
          {
            text: "OK",
            handler: (e) => {

            }
          }
        ]}
      />

    </IonModal>
  )
}

export default CartModal;