/*
  simple language translate without using library

  i18n library choise for React https://phrase.com/blog/posts/react-i18n-best-libraries/
  LinguiJS
  React-intl
  React-intl-universal
  i18next
*/

// {t['setting.mobile'][l]}

const t: { [key: string]: { [key: string]: any }} = {
  "apptab.tab.job": { en: "Job", bm: "Kerja" },
  "apptab.tab.payment": { en: "Payment", bm: "Bayar" },
  "apptab.tab.me": { en: "Me", bm: "Saya" },

  "setting.darkmode": { en: "Dark Mode", bm: "Mod Gelap" },
  "setting.language": { en: "Language", bm: "Bahasa" },
  "setting.language.english": { en: "English", bm: "Bahasa Inggeris" },
  "setting.language.malay": { en: "Malay", bm: "Bahasa Melayu" },
}

export default t ;
