import React, { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"

import {
  IonModal, IonContent, IonHeader, IonToolbar,
  IonButton, IonIcon, IonTitle, IonInput, IonItem, IonLoading, IonAlert, IonText
} from "@ionic/react"
import { chevronBack } from "ionicons/icons";
import { useSendSmsOtpMutation, useVerifySmsOtpMutation, OtpChannelEnum, useConsumerUpdateMutation, usePhoneExistQuery } from '../generated/graphql';
import "./PhoneOtpModal.css"


interface Props {
  isOpen: boolean,
  setShowModal: (isOpen: boolean) => void,
  userId: string,
}

let initialValues = {
  new_number: "",
  verification_code: "",
};

let intervalId: NodeJS.Timeout;
let timeoutId: NodeJS.Timeout;

const PhoneOTPModal: React.FC<Props> = ({ isOpen, setShowModal, userId }) => {
  const { refetch: checkPhone } = usePhoneExistQuery({
    variables: { phone: "" },
    fetchPolicy: "network-only",
  })

  const [sendOtp, { loading: sendingOtp }] = useSendSmsOtpMutation();
  const [verifyOtp, { loading: verifyingOtp }] = useVerifySmsOtpMutation();
  const [consumerUpdate, { loading: consumerUpdating }] = useConsumerUpdateMutation();

  // initialize timeLeft with the seconds prop
  const [codeTimeoutActivated, activateCodeTimeout] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isError, setIsError] = useState(false)

  const { control, handleSubmit, formState, getValues, errors, setError } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange"
  });

  /**
   *
   * @param _fieldName
   */
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="error-message">
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  /**
   *
   * @param data
   */
  const onSubmit = (data: any) => {
    console.log(data)
    //call api to verify the phone number with given code
    verifyOtp({
      variables: { record: { phone: `+60${data.new_number}`, code: data.verification_code } }
    }).then(res => {
      if (res.data?.public?.otp?.sms?.verify?.response.valid) {
        console.log("valid code")
        //change phone number
        clearInterval(intervalId);
        consumerUpdate({
          variables: {
            id: userId,
            record: {
              phone: getValues("new_number"),
              phoneVerified: true
            }
          }
        }).then(res => {
          if (res.data?.consumer?.meUpdate) {
            activateCodeTimeout(false);
            setShowModal(false)
          }
        }).catch(error => {
          console.log("err change phone numer ", error)
          setIsError(true)
        })
      } else if (!res.data?.public?.otp?.sms?.verify?.response.valid) {
        throw new Error("Invalid code");
      }
    }).catch(error => {
      console.log("err invalid code ", error)
      setError("verification_code", "Invalid code", "Invalid code")
    })
  };

  useEffect(() => {

    if (isOpen && codeTimeoutActivated) {
      // exit early when we reach 0
      if (!timeLeft) {
        activateCodeTimeout(false)
        return;
      }

      // save intervalId to clear the interval when the
      // component re-renders
      intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }

  }, [isOpen, timeLeft, codeTimeoutActivated]);

  const sendCode = () => {
    //call api to ask server send code
    sendOtp({
      variables: { record: { phone: `+60${getValues("new_number")}`, channel: OtpChannelEnum.Sms } }
    }).then(res => {
      console.log("code sent", res)
      if (res.data?.public?.otp?.sms?.send) {
        activateCodeTimeout(true);
      }
    }).catch(error => {
      console.log("err send code ", error)
      setError("new_number", "Error", `Unable to send code. ${error.message}.`)
    })
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setShowModal(false)}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => setShowModal(false)}>
            <IonIcon icon={chevronBack} size="100px"></IonIcon>
          </IonButton>
          <IonTitle>Change Phone Number</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
          <IonItem>
            <IonText className="ion-margin-end">+60</IonText>
            <Controller
              as={IonInput}
              disabled={codeTimeoutActivated}
              placeholder="Enter new phone number"
              inputMode="tel"
              maxlength={10}
              control={control}
              onChangeName="onIonChange"
              onChange={([selected]) => {
                console.log("new_number", selected.detail.value);
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                  checkPhone({ phone: `+60${selected.detail.value}` }).then(res => {
                    if (res.data.public?.isExist?.phone) {
                      setError("new_number", "unique", "This phone number already associated to an account")
                    }
                  }).catch(err => console.log("error phone checking ", err))
                }, 2000)
                return selected.detail.value;
              }}
              name="new_number"
              rules={{
                required: true,
                minLength: { value: 9, message: "Invalid phone number" },
                pattern: { value: /^[1-9][0-9]*$/, message: "Invalid phone number" }
              }}
            />
            <IonButton disabled={codeTimeoutActivated || !getValues("new_number") ? true : false} onClick={() => sendCode()}>
              {codeTimeoutActivated ? `${timeLeft} s` : "Send Code"}
            </IonButton>
          </IonItem>
          {showError("new_number")}

          <IonItem>
            <Controller
              as={IonInput}
              placeholder="Enter verification code"
              disabled={!codeTimeoutActivated}
              inputMode="numeric"
              maxlength={6}
              control={control}
              onChangeName="onIonChange"
              onChange={([selected]) => {
                console.log("verification_code", selected.detail.value);
                return selected.detail.value;
              }}
              name="verification_code"
              rules={{
                required: true,
              }}
            />
          </IonItem>
          {showError("verification_code")}

          <IonButton className="submit-button" type="submit" disabled={formState.isValid === false}>Change</IonButton>
        </form>
        <IonLoading
          isOpen={sendingOtp || verifyingOtp || consumerUpdating}
          message={'Please wait...'}
        />
        <IonAlert
          isOpen={isError}
          message="Unable to change phone number"
          onDidDismiss={() => setIsError(false)}
          buttons={[
            {
              text: "OK",
              handler: () => setIsError(false)
            }
          ]}
        />
      </IonContent>
    </IonModal>
  )
}

export default PhoneOTPModal;