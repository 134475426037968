import React, { useState } from 'react';
import { RefresherEventDetail } from '@ionic/core';
import moment from "moment";

import {
  IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonRow, IonIcon,
  IonGrid, IonCol, IonButton, IonRefresher, IonRefresherContent, IonText, IonLoading
} from '@ionic/react';
import { refresh } from 'ionicons/icons';

import { useOrderManyQuery, Order } from '../generated/graphql';

import OrderDetailsModal from '../components/OrderDetailsModal';

import './MyOrder.css';

const statusForDelivery: any = {
  "open": { text: "Processing" },
  "confirmed": { text: "Delivering" },
  "completed": { text: "Received" },
  "cancelled": { text: "Cancelled" },
}

const statusForSelfPickup: any = {
  "open": { text: "Processing" },
  "confirmed": { text: "Self pickup" },
  "completed": { text: "Received" },
  "cancelled": { text: "Cancelled" },
}

const MyOrder: React.FC = () => {
  const { data, loading, refetch } = useOrderManyQuery({ fetchPolicy: "cache-and-network" })
  const [order, setOrder] = useState<Order>();
  const [showDetail, setShowDetail] = useState(false);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    refetch();
    // if (!loading) event.detail.complete()
    // setTimeout(() => event.detail.complete(), 500);
    event.detail.complete();
  }

  return (
    <IonPage className="my-order">
      <IonHeader>
        <IonToolbar>
          <IonTitle>My Orders</IonTitle>
          <IonButton slot="end" fill="clear" onClick={() => refetch()}>
            <IonIcon icon={refresh} />
          </IonButton>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {data?.consumer?.orders?.map((o: any, i: number) => {
          return (
            <IonItem detail key={i} lines="full" onClick={() => {
              setOrder(o);
              setShowDetail(true);
            }}>
              <IonGrid>
                <IonRow>
                  <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeLg="4">
                    <IonText>Placed on {moment(o.orderDate).calendar(undefined, { sameElse: 'DD/MM/YYYY'})}</IonText>
                  </IonCol>
                  <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeLg="4">
                    <IonText color="medium">Order ID: {o.displayId}</IonText>
                  </IonCol>
                  <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeLg="4">
                    <IonRow>
                      <IonText color="medium" className="ion-padding-end">{o.totalQty} {o.totalQty > 1 ? " items" : " item"}</IonText>
                      <IonText color="medium" className="ion-padding-start">RM {(o.subTotal + o.delivery.fee).toFixed(2)}</IonText>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonButton slot="end" fill="outline" size="small" shape="round">
                {o.type === "delivery" ?
                  statusForDelivery[o.status].text
                  :
                  statusForSelfPickup[o.status].text
                }
              </IonButton>
            </IonItem>
          );
        })}
        {data?.consumer?.orders?.length === 0 &&
          <IonGrid className="hv-center-absolute-item" style={{width: "100%"}}>
            <IonRow className="ion-justify-content-center">
              <h3>You don't have any order yet</h3>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonButton href="/home">Continue Shopping</IonButton>
            </IonRow>
          </IonGrid>}
      </IonContent>

      <IonLoading isOpen={loading} message="loading..." />

      <OrderDetailsModal
        isOpen={showDetail}
        setShowModal={setShowDetail}
        order={order}
      />
    </IonPage>
  )
}

export default MyOrder;