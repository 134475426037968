import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButton, IonIcon, IonContent, IonRow, IonItem, IonInput, isPlatform, IonCard, IonCardContent, IonCardTitle } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router';

let initialValues = {
  phone: "",
};

const ForgotPassword: React.FC = () => {
  const { control, handleSubmit, formState, errors } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange"
  });
  const history = useHistory()

  /**
   *
   * @param _fieldName
   */
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="error-message">
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  /**
   *
   * @param data
   */
  const onSubmit = (data: any) => {
    console.log(data)
  };

  const renderContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonItem>
          <Controller
            as={IonInput}
            placeholder="Phone number"
            inputMode="numeric"
            control={control}
            onChangeName="onIonChange"
            onChange={([selected]) => {
              console.log("phone", selected.detail.value);
              return selected.detail.value;
            }}
            name="phone"
            rules={{
              required: true,
            }}
          />
        </IonItem>
        {showError("phone")}

        <IonButton className="ion-margin-top" expand="block" type="submit" disabled={formState.isValid === false}>Submit</IonButton>
      </form>
    )
  }

  return isPlatform("mobile") ?
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => history.goBack()}>
            <IonIcon icon={chevronBack}></IonIcon>
          </IonButton>
          <IonTitle>Forgot Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="login ion-padding">
        <IonRow className="ion-justify-content-center ion-padding-vertical">
          <p className="ion-text-center">Enter your phone number and we will send a secure link to reset your password</p>
        </IonRow>
        {renderContent()}
      </IonContent>
    </IonPage>
    :
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonItem slot="start" lines="none" href="/home">
            <img src='/assets/icon/icon.png' alt="logo" width="50px" height="50px"/>
            <IonTitle>Transformer</IonTitle>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard className="card">
          <IonCardContent>
            <IonCardTitle>Enter your phone number and we will send a secure link to reset your password</IonCardTitle>
            {renderContent()}
            <IonButton fill="clear" onClick={() => history.goBack()}>Go back</IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
}

export default ForgotPassword;