import React from 'react'
import { IonPage, IonContent } from '@ionic/react'

export const NotFound = () => {
  return (
    <IonPage>
      <IonContent>
        <h1 style={{ textAlign: "center" }}>Not Found</h1>
      </IonContent >
    </IonPage>
  )
}
