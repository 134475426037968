import React, { useState } from 'react';
import moment from 'moment';

import {
  IonModal, IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonContent, IonLabel,
  IonItem, IonCol, IonRow, IonImg,
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

import {
  useAddToCartMutation, F_OrderFragment,
} from '../../generated/graphql';

import { useToast } from '../../hooks/useToast';
import DeliveryTrackingModal from '../DeliveryTrackingModal';

import './HistoryDetailsModal.css'

const statusForDelivery: any = {
  "open": { text: "处理中" },
  "confirmed": { text: "送货中" },
  "completed": { text: "已签收" },
  "cancelled": { text: "取消" },
}

const statusForSelfPickup: any = {
  "open": { text: "处理中" },
  "confirmed": { text: "自行领取" },
  "completed": { text: "已签收" },
  "cancelled": { text: "取消" },
}

const paymentMethod = {
  "cash": "Cash",
  "card": "Credit/Debit Card",
  "fpx": "FPX Online Banking"
}

interface Props {
  isOpen: boolean
  setShowModal: (isOpen: boolean) => void
  shopUrl: string
  // order: Order | undefined
  order: F_OrderFragment | undefined    // more tighten type by using F_OrderFragment
}

const HistoryDetailsModal: React.FC<Props> = ({ isOpen, setShowModal, shopUrl, order }) => {
  const [addToCartMutation] = useAddToCartMutation();

  const Toast = useToast();
  const [trackingOpen, showTracking] = useState(false);

  function onCartAdd(e: any, p: any) {
    Toast.success("商品成功加入购物车中");
    e.stopPropagation();
    e.preventDefault();
    addToCartMutation({
      variables: {
        shopUrl: shopUrl,
        cart: {
          productId: p.productId,
          name: p.name,
          price: p.price,
          qty: p.qty,
          cartProduct: {
            name: p.name,
            imageUrl: p.imageUrl
          }
      }
      }
    })
  }

  function onCartAddAll(e: any, c: any) {
    Toast.success("商品成功加入购物车中");
    e.stopPropagation();
    e.preventDefault();
    c.forEach((p: any) => {
      addToCartMutation({
        variables: {
          shopUrl: shopUrl,
          cart: {
            productId: p.productId,
            name: p.name,
            price: p.price,
            qty: p.qty,
            cartProduct: {
              name: p.name,
              imageUrl: p.imageUrl
            }
          }
        }
      })
    })
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setShowModal(false)}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => setShowModal(false)}>
            <IonIcon icon={chevronBack}></IonIcon>
          </IonButton>
          <IonTitle>定单细项</IonTitle>
        </IonToolbar>
      </IonHeader>

      {order &&
        <IonContent className="shop02-history-details">
          <IonItem lines="full">
            <IonCol>
              <IonRow className="ion-justify-content-between ion-align-items-center">
                <IonLabel><h2>{order.type === "delivery" ? "送货服务" : "自行领取"}</h2></IonLabel>
                {order?.status &&
                  <IonButton fill="outline" size="small" shape="round">
                    {order.type === "delivery" ? statusForDelivery[order.status].text : statusForSelfPickup[order.status].text}
                  </IonButton>}
              </IonRow>
              <IonLabel>
                <p>定单编号: {order._id}</p>
              </IonLabel>
              <IonRow className="ion-justify-content-between ion-align-items-center">
                <IonLabel>
                  <p>下单时间 {moment(order.orderDate).calendar(undefined, { sameElse: 'DD/MM/YYYY' })}</p>
                </IonLabel>
                <IonButton fill="clear" onClick={(e) => onCartAddAll(e, order.carts)}>
                  重购全部
                </IonButton>
              </IonRow>
            </IonCol>
          </IonItem>

          {order.type === "delivery" &&
            <IonItem lines="full">
              <IonCol>
                <IonRow className="ion-justify-content-between ion-align-items-center">
                  <IonLabel><h2>送货地址</h2></IonLabel>
                  <IonButton onClick={() => showTracking(true)}>Track Delivery</IonButton>
                </IonRow>
                <IonLabel>
                  <p>{order.delivery?.deliverTo?.name}</p>
                  <p>{order.delivery?.deliverTo?.phone}</p>
                  <p>{order.delivery?.deliverTo?.detailed_address}, {order.delivery?.deliverTo?.zipcode} {order.delivery?.deliverTo?.city}, {order.delivery?.deliverTo?.state}</p>
                </IonLabel>
              </IonCol>
            </IonItem>}

          <IonItem lines="full" className="ion-item-full-width">
            <IonCol>
              {order.carts?.map((p: any) => {
                return (
                  <IonItem key={p.productId} lines="none" routerLink={`/ShopGen02/${shopUrl}/product-details/${p.productId}`}>
                    <IonImg slot="start" src={p.cartProduct.imageUrl} alt="product" />
                    <IonCol >
                      <IonRow className="ion-justify-content-between ion-align-items-center">
                        <IonLabel><h2>{p.name}</h2></IonLabel>
                        <IonButton fill="clear" onClick={(e) => onCartAdd(e, p)}>
                          重购
                        </IonButton>
                      </IonRow>
                      <IonRow className="ion-justify-content-between ion-align-items-center">
                        <IonLabel><p>RM {p.price.toFixed(2)} x {p.qty}</p></IonLabel>
                        <IonLabel><h3>RM {(p.qty * p.price).toFixed(2)}</h3></IonLabel>
                      </IonRow>
                    </IonCol>
                  </IonItem>
                );
              })}
            </IonCol>
          </IonItem>

          <IonItem lines="full">
            <IonCol>
              <IonRow className="ion-justify-content-between">
                <IonLabel><h3>Subtotal</h3></IonLabel>
                <IonLabel><h3>RM {order.subTotal?.toFixed(2)}</h3></IonLabel>
              </IonRow>
              {order?.type === "delivery" &&
                <IonRow className="ion-justify-content-between">
                  <IonLabel><h3>Delivery Fee</h3></IonLabel>
                  <IonLabel><h3>RM {order.delivery?.fee?.toFixed(2)}</h3></IonLabel>
                </IonRow>}
              {order.subTotal && <IonRow className="ion-justify-content-between">
                <IonLabel><h3>Total</h3></IonLabel>
                <IonLabel><h3>RM {(order.subTotal + (order.delivery?.fee ? order.delivery.fee : 0)).toFixed(2)}</h3></IonLabel>
              </IonRow>}
            </IonCol>
          </IonItem>

          <IonItem lines="full">
            <IonCol>
              <IonRow className="ion-justify-content-between ion-align-items-center">
                <IonLabel><h2>付款方式</h2></IonLabel>
                <IonButton fill="outline" size="small" shape="round">
                  {order.paymentStatus === "success" ? "已付" : order.paymentStatus === "pending" ? "待付款" : "未付"}
                </IonButton>
              </IonRow>
              {order.paymentType && <IonLabel><p>{paymentMethod[order.paymentType]}</p></IonLabel>}
            </IonCol>
          </IonItem>

          <DeliveryTrackingModal
            isOpen={trackingOpen}
            setShowModal={showTracking}
            status={order.deliveryOrder?.status}
          />
        </IonContent>}
    </IonModal >
  )
}

export default HistoryDetailsModal;