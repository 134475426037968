import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any;
  /** The string representation of JavaScript regexp. You may provide it with flags "/^abc.*\/i" or without flags like "^abc.*". More info about RegExp characters and flags: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions */
  RegExpAsString: any;
};


export type AwsUploadInput = {
  fileType: Scalars['String'];
};

export type AwsUploadPayload = {
  __typename?: 'AWSUploadPayload';
  signedRequest: Scalars['String'];
  url: Scalars['String'];
  fileName: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  detailed_address: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  state: Scalars['String'];
  country: EnumAddressCountry;
  city: Scalars['String'];
  zipcode: Scalars['String'];
  location?: Maybe<AddressLocation>;
};

export type AddressInput = {
  detailed_address: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  state: Scalars['String'];
  country: EnumAddressCountry;
  city: Scalars['String'];
  zipcode: Scalars['String'];
  location?: Maybe<AddressLocationInput>;
};

export type AddressLocation = {
  __typename?: 'AddressLocation';
  type: EnumAddressLocationType;
  coordinates: Array<Maybe<Scalars['Float']>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type AddressLocationInput = {
  type: EnumAddressLocationType;
  coordinates: Array<Maybe<Scalars['Float']>>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** Login with type and returns the access token */
export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Blog = {
  __typename?: 'Blog';
  merchantId: Scalars['MongoID'];
  cardTitle?: Maybe<Scalars['String']>;
  cardText?: Maybe<Scalars['String']>;
  cardImageUrl: Scalars['String'];
  contentHeader: Scalars['String'];
  shopId: Scalars['MongoID'];
  contentSections?: Maybe<Array<Maybe<BlogContentSections>>>;
  status: EnumBlogStatus;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  blogProducts: Array<Product>;
};


export type BlogBlogProductsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyProductInput>;
};

export type BlogContentSections = {
  __typename?: 'BlogContentSections';
  type: EnumBlogContentSectionsType;
  data: Array<Maybe<Scalars['JSON']>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type BlogContentSectionsInput = {
  type: EnumBlogContentSectionsType;
  data: Array<Maybe<Scalars['JSON']>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export enum Created_At {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodeversionAsc = 'CODEVERSION_ASC',
  CodeversionDesc = 'CODEVERSION_DESC',
  CodeversionCacheversionAsc = 'CODEVERSION__CACHEVERSION_ASC',
  CodeversionCacheversionDesc = 'CODEVERSION__CACHEVERSION_DESC',
  CodeversionCacheversionTypeAsc = 'CODEVERSION__CACHEVERSION__TYPE_ASC',
  CodeversionCacheversionTypeDesc = 'CODEVERSION__CACHEVERSION__TYPE_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

/** Calculate order on server for cash payment */
export type CashPaymentPayload = {
  __typename?: 'CashPaymentPayload';
  totalAmount: Scalars['Float'];
};

export type Category = {
  __typename?: 'Category';
  shopId: Scalars['MongoID'];
  name: Scalars['String'];
  description: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  shop?: Maybe<Shop>;
  products: Array<Product>;
  productConnection?: Maybe<ProductConnection>;
};


export type CategoryShopArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneShopInput>;
};


export type CategoryProductsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyProductInput>;
};


export type CategoryProductConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  sort?: Maybe<SortConnectionProductEnum>;
};

export type ChangePasswordInput = {
  current: Scalars['String'];
  updated: Scalars['String'];
};

export type Consumer = {
  __typename?: 'Consumer';
  username: Scalars['String'];
  type?: Maybe<EnumConsumerType>;
  name: Scalars['String'];
  /** Only employee can see this */
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  preferedPayment?: Maybe<EnumConsumerPreferedPayment>;
  addresses?: Maybe<Array<Maybe<DeliveryAddress>>>;
  tokenVersion?: Maybe<Scalars['Float']>;
  penefitIntegration?: Maybe<ConsumerPenefitIntegration>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  orders: Array<Order>;
};


export type ConsumerOrdersArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyOrderInput>;
};

export type ConsumerDoStatusUpdateInputTc = {
  status: ConsumerDoUpdateEnum;
  description: Scalars['String'];
};

export enum ConsumerDoUpdateEnum {
  Delivered = 'delivered'
}

export type ConsumerMutation = {
  __typename?: 'ConsumerMutation';
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  meUpdate?: Maybe<UpdateByIdConsumerPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  orderCreate?: Maybe<CreateOneOrderPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  orderUpdate?: Maybe<UpdateByIdOrderPayload>;
  deliveryOrderStatusUpdate?: Maybe<StandardDeliveryOrderPayload>;
  stripePayment?: Maybe<StripePaymentPayload>;
  cashPayment?: Maybe<CashPaymentPayload>;
  changePassword?: Maybe<StandardPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  like?: Maybe<CreateOneLikePayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  unliked?: Maybe<RemoveOneLikePayload>;
};


export type ConsumerMutationMeUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdConsumerInput;
};


export type ConsumerMutationOrderCreateArgs = {
  record: CreateOneOrderInput;
};


export type ConsumerMutationOrderUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdOrderInput;
};


export type ConsumerMutationDeliveryOrderStatusUpdateArgs = {
  _id: Scalars['MongoID'];
  record?: Maybe<ConsumerDoStatusUpdateInputTc>;
};


export type ConsumerMutationStripePaymentArgs = {
  record?: Maybe<PaymentInput>;
};


export type ConsumerMutationCashPaymentArgs = {
  record?: Maybe<PaymentInput>;
};


export type ConsumerMutationChangePasswordArgs = {
  record?: Maybe<ChangePasswordInput>;
};


export type ConsumerMutationLikeArgs = {
  record: CreateOneLikeInput;
};


export type ConsumerMutationUnlikedArgs = {
  filter: FilterRemoveOneLikeInput;
  sort?: Maybe<SortRemoveOneLikeInput>;
};

export type ConsumerPayment = {
  __typename?: 'ConsumerPayment';
  paymentPlatformId: Scalars['String'];
  paymentPlatform: EnumConsumerPaymentPaymentPlatform;
  orderId?: Maybe<Scalars['MongoID']>;
  consumerId?: Maybe<Scalars['MongoID']>;
  amountReceived: Scalars['Float'];
  status: EnumConsumerPaymentStatus;
  apiResponse?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type ConsumerPenefitIntegration = {
  __typename?: 'ConsumerPenefitIntegration';
  enabled?: Maybe<Scalars['Boolean']>;
  posId: Scalars['String'];
  penefitId: Scalars['String'];
};

export type ConsumerPenefitIntegrationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  posId: Scalars['String'];
  penefitId: Scalars['String'];
};

export type ConsumerQuery = {
  __typename?: 'ConsumerQuery';
  me?: Maybe<Consumer>;
  orders: Array<Order>;
  order?: Maybe<Order>;
  shopsByLocation?: Maybe<Array<Maybe<ConsumerShopsByLocation>>>;
  likes: Array<Like>;
};


export type ConsumerQueryMeArgs = {
  filter?: Maybe<FilterFindOneConsumerInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneConsumerInput>;
};


export type ConsumerQueryOrdersArgs = {
  filter?: Maybe<FilterFindManyOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyOrderInput>;
};


export type ConsumerQueryOrderArgs = {
  filter?: Maybe<FilterFindOneOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneOrderInput>;
};


export type ConsumerQueryShopsByLocationArgs = {
  record?: Maybe<ShopsByLocationInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type ConsumerQueryLikesArgs = {
  filter?: Maybe<FilterFindManyLikeInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyLikeInput>;
};

export type ConsumerShopsByLocation = {
  __typename?: 'ConsumerShopsByLocation';
  merchantId: Scalars['MongoID'];
  type: EnumShopType;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  logo: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  whatsapp_number: Scalars['String'];
  coverImage: Scalars['String'];
  optOutDelivery: Scalars['Boolean'];
  description: Scalars['String'];
  status: EnumShopStatus;
  address?: Maybe<Address>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  distanceInMeters?: Maybe<Scalars['Float']>;
  distanceInKm?: Maybe<Scalars['Float']>;
};

export type CreateManyProductInput = {
  name: Scalars['String'];
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId: Scalars['MongoID'];
  sku?: Maybe<Scalars['String']>;
  shortDesc: Scalars['String'];
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<ProductImagesInput>>>;
  descriptionImages?: Maybe<Array<Maybe<ProductDescriptionImagesInput>>>;
  active: Scalars['Boolean'];
  status: EnumProductStatus;
  stock?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<ProductPricingInput>;
  shipping?: Maybe<ProductShippingInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateManyProductPayload = {
  __typename?: 'CreateManyProductPayload';
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']>;
  /** Created documents */
  records?: Maybe<Array<Product>>;
  /** Number of created documents */
  createdCount: Scalars['Int'];
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneBlogInput = {
  merchantId: Scalars['MongoID'];
  cardTitle?: Maybe<Scalars['String']>;
  cardText?: Maybe<Scalars['String']>;
  cardImageUrl: Scalars['String'];
  contentHeader: Scalars['String'];
  shopId: Scalars['MongoID'];
  contentSections?: Maybe<Array<Maybe<BlogContentSectionsInput>>>;
  status: EnumBlogStatus;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneBlogPayload = {
  __typename?: 'CreateOneBlogPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Blog>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneCategoryInput = {
  shopId: Scalars['MongoID'];
  name: Scalars['String'];
  description: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneCategoryPayload = {
  __typename?: 'CreateOneCategoryPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Category>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneConsumerInput = {
  username: Scalars['String'];
  type?: Maybe<EnumConsumerType>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  preferedPayment?: Maybe<EnumConsumerPreferedPayment>;
  addresses?: Maybe<Array<Maybe<DeliveryAddressInput>>>;
  tokenVersion?: Maybe<Scalars['Float']>;
  penefitIntegration?: Maybe<ConsumerPenefitIntegrationInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneConsumerPayload = {
  __typename?: 'CreateOneConsumerPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Consumer>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneDeliveryOrderInput = {
  consumerId: Scalars['MongoID'];
  orderId: Scalars['MongoID'];
  shopId: Scalars['MongoID'];
  riderId?: Maybe<Scalars['MongoID']>;
  deliverTo?: Maybe<DeliveryAddressInput>;
  deliverFrom?: Maybe<DeliveryAddressInput>;
  deliverDate: Scalars['Date'];
  type?: Maybe<EnumDeliveryOrderType>;
  date: Scalars['Date'];
  status?: Maybe<Array<Maybe<DeliveryOrderStatusInput>>>;
  distanceInKm: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneDeliveryOrderPayload = {
  __typename?: 'CreateOneDeliveryOrderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<DeliveryOrder>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneEmployeeInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  type?: Maybe<EnumEmployeeType>;
  tokenVersion?: Maybe<Scalars['Float']>;
  roles?: Maybe<Array<Maybe<EnumEmployeeRoles>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneEmployeePayload = {
  __typename?: 'CreateOneEmployeePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Employee>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneLikeInput = {
  consumerId: Scalars['MongoID'];
  shopId: Scalars['MongoID'];
  type: EnumLikeType;
  typeId: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneLikePayload = {
  __typename?: 'CreateOneLikePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Like>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneMerchantInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  type?: Maybe<EnumMerchantType>;
  status: EnumMerchantStatus;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  tokenVersion?: Maybe<Scalars['Float']>;
  salesRepId: Scalars['MongoID'];
  penefitIntegration?: Maybe<MerchantPenefitIntegrationInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneMerchantPayload = {
  __typename?: 'CreateOneMerchantPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Merchant>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneOrderInput = {
  displayId?: Maybe<Scalars['String']>;
  consumerId: Scalars['MongoID'];
  shopId: Scalars['MongoID'];
  paymentId?: Maybe<Scalars['MongoID']>;
  deliveryOrderId?: Maybe<Scalars['MongoID']>;
  type: EnumOrderType;
  status?: Maybe<EnumOrderStatus>;
  paymentStatus?: Maybe<EnumOrderPaymentStatus>;
  paymentType?: Maybe<EnumOrderPaymentType>;
  orderDate?: Maybe<Scalars['Date']>;
  tax?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  totalQty?: Maybe<Scalars['Float']>;
  delivery?: Maybe<OrderDeliveryInput>;
  carts: Array<Maybe<OrderCartsInput>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneOrderPayload = {
  __typename?: 'CreateOneOrderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Order>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneProductInput = {
  name: Scalars['String'];
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId: Scalars['MongoID'];
  sku?: Maybe<Scalars['String']>;
  shortDesc: Scalars['String'];
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<ProductImagesInput>>>;
  descriptionImages?: Maybe<Array<Maybe<ProductDescriptionImagesInput>>>;
  active: Scalars['Boolean'];
  status: EnumProductStatus;
  stock?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<ProductPricingInput>;
  shipping?: Maybe<ProductShippingInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneProductPayload = {
  __typename?: 'CreateOneProductPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Product>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneRiderInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  type?: Maybe<EnumRiderType>;
  name: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  ic?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  lastLocation?: Maybe<Array<Maybe<RiderLastLocationInput>>>;
  phoneOtp: Scalars['String'];
  phoneVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
  tokenVersion?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneRiderPayload = {
  __typename?: 'CreateOneRiderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Rider>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneShopInput = {
  merchantId: Scalars['MongoID'];
  type: EnumShopType;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  logo: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  whatsapp_number: Scalars['String'];
  coverImage: Scalars['String'];
  optOutDelivery: Scalars['Boolean'];
  description: Scalars['String'];
  status: EnumShopStatus;
  address?: Maybe<AddressInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneShopPayload = {
  __typename?: 'CreateOneShopPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Shop>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneSystemVersionInput = {
  codeVersion: Scalars['String'];
  cacheVersion: Scalars['String'];
  type: EnumSystemVersionType;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneSystemVersionPayload = {
  __typename?: 'CreateOneSystemVersionPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<SystemVersion>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneTestSubscriptionInput = {
  name: Scalars['String'];
  type?: Maybe<EnumTestSubscriptionType>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOneTestSubscriptionPayload = {
  __typename?: 'CreateOneTestSubscriptionPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<TestSubscription>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};


export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  name: Scalars['String'];
  phone: Scalars['String'];
  detailed_address: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  state: Scalars['String'];
  country: EnumDeliveryAddressCountry;
  city: Scalars['String'];
  zipcode: Scalars['String'];
  location?: Maybe<AddressLocation>;
};

export type DeliveryAddressInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
  detailed_address: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  state: Scalars['String'];
  country: EnumDeliveryAddressCountry;
  city: Scalars['String'];
  zipcode: Scalars['String'];
  location?: Maybe<AddressLocationInput>;
};

export type DeliveryOrder = {
  __typename?: 'DeliveryOrder';
  consumerId: Scalars['MongoID'];
  orderId: Scalars['MongoID'];
  shopId: Scalars['MongoID'];
  riderId?: Maybe<Scalars['MongoID']>;
  deliverTo?: Maybe<DeliveryAddress>;
  deliverFrom?: Maybe<DeliveryAddress>;
  deliverDate: Scalars['Date'];
  type?: Maybe<EnumDeliveryOrderType>;
  date: Scalars['Date'];
  status?: Maybe<Array<Maybe<DeliveryOrderStatus>>>;
  distanceInKm: Scalars['Float'];
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  order?: Maybe<Order>;
  rider?: Maybe<Rider>;
};


export type DeliveryOrderOrderArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneOrderInput>;
};


export type DeliveryOrderRiderArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRiderInput>;
};

export type DeliveryOrderStatus = {
  __typename?: 'DeliveryOrderStatus';
  name?: Maybe<EnumDeliveryOrderStatusName>;
  description?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  _id?: Maybe<Scalars['MongoID']>;
};

export type DeliveryOrderStatusInput = {
  name?: Maybe<EnumDeliveryOrderStatusName>;
  description?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  _id?: Maybe<Scalars['MongoID']>;
};

export type Email = {
  __typename?: 'Email';
  send?: Maybe<OtpPayload>;
  verify?: Maybe<OtpPayload>;
};


export type EmailSendArgs = {
  record?: Maybe<SendEmailOtpInput>;
};


export type EmailVerifyArgs = {
  record?: Maybe<VerifyEmailOtpInput>;
};

export type Employee = {
  __typename?: 'Employee';
  username: Scalars['String'];
  /** Must have admin role to view */
  password: Scalars['String'];
  type?: Maybe<EnumEmployeeType>;
  tokenVersion?: Maybe<Scalars['Float']>;
  roles?: Maybe<Array<Maybe<EnumEmployeeRoles>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  merchants: Array<Merchant>;
};


export type EmployeeMerchantsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyMerchantInput>;
};

export type EmployeeMutation = {
  __typename?: 'EmployeeMutation';
  /** Create one document with mongoose defaults, setters, hooks and validation */
  employeeCreate?: Maybe<CreateOneEmployeePayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  employeeUpdate?: Maybe<UpdateByIdEmployeePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  productCreate?: Maybe<CreateOneProductPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  productCreateMany?: Maybe<CreateManyProductPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  productUpdate?: Maybe<UpdateByIdProductPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  productRemove?: Maybe<RemoveOneProductPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  categoryCreate?: Maybe<CreateOneCategoryPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  categoryUpdate?: Maybe<UpdateByIdCategoryPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  categoryRemove?: Maybe<RemoveOneCategoryPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  merchantCreate?: Maybe<CreateOneMerchantPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  merchantUpdate?: Maybe<UpdateByIdMerchantPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  merchantRemove?: Maybe<RemoveOneMerchantPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  shopCreate?: Maybe<CreateOneShopPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  shopUpdate?: Maybe<UpdateByIdShopPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  shopRemove?: Maybe<RemoveOneShopPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  orderCreate?: Maybe<CreateOneOrderPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  orderUpdate?: Maybe<UpdateByIdOrderPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  orderRemove?: Maybe<RemoveOneOrderPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  deliveryOrderCreate?: Maybe<CreateOneDeliveryOrderPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  deliveryOrderUpdate?: Maybe<UpdateByIdDeliveryOrderPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  deliveryOrderRemove?: Maybe<RemoveOneDeliveryOrderPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  consumerCreate?: Maybe<CreateOneConsumerPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  consumerUpdate?: Maybe<UpdateByIdConsumerPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  consumerRemove?: Maybe<RemoveOneConsumerPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  riderCreate?: Maybe<CreateOneRiderPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  riderUpdate?: Maybe<UpdateByIdRiderPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  riderRemove?: Maybe<RemoveOneRiderPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  systemVersionCreate?: Maybe<CreateOneSystemVersionPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  systemVersionUpdate?: Maybe<UpdateByIdSystemVersionPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  systemVersionRemove?: Maybe<RemoveOneSystemVersionPayload>;
};


export type EmployeeMutationEmployeeCreateArgs = {
  record: CreateOneEmployeeInput;
};


export type EmployeeMutationEmployeeUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdEmployeeInput;
};


export type EmployeeMutationProductCreateArgs = {
  record: CreateOneProductInput;
};


export type EmployeeMutationProductCreateManyArgs = {
  records: Array<CreateManyProductInput>;
};


export type EmployeeMutationProductUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdProductInput;
};


export type EmployeeMutationProductRemoveArgs = {
  filter?: Maybe<FilterRemoveOneProductInput>;
  sort?: Maybe<SortRemoveOneProductInput>;
};


export type EmployeeMutationCategoryCreateArgs = {
  record: CreateOneCategoryInput;
};


export type EmployeeMutationCategoryUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdCategoryInput;
};


export type EmployeeMutationCategoryRemoveArgs = {
  filter?: Maybe<FilterRemoveOneCategoryInput>;
  sort?: Maybe<SortRemoveOneCategoryInput>;
};


export type EmployeeMutationMerchantCreateArgs = {
  record: CreateOneMerchantInput;
};


export type EmployeeMutationMerchantUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdMerchantInput;
};


export type EmployeeMutationMerchantRemoveArgs = {
  filter?: Maybe<FilterRemoveOneMerchantInput>;
  sort?: Maybe<SortRemoveOneMerchantInput>;
};


export type EmployeeMutationShopCreateArgs = {
  record: CreateOneShopInput;
};


export type EmployeeMutationShopUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdShopInput;
};


export type EmployeeMutationShopRemoveArgs = {
  filter?: Maybe<FilterRemoveOneShopInput>;
  sort?: Maybe<SortRemoveOneShopInput>;
};


export type EmployeeMutationOrderCreateArgs = {
  record: CreateOneOrderInput;
};


export type EmployeeMutationOrderUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdOrderInput;
};


export type EmployeeMutationOrderRemoveArgs = {
  filter?: Maybe<FilterRemoveOneOrderInput>;
  sort?: Maybe<SortRemoveOneOrderInput>;
};


export type EmployeeMutationDeliveryOrderCreateArgs = {
  record: CreateOneDeliveryOrderInput;
};


export type EmployeeMutationDeliveryOrderUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdDeliveryOrderInput;
};


export type EmployeeMutationDeliveryOrderRemoveArgs = {
  filter?: Maybe<FilterRemoveOneDeliveryOrderInput>;
  sort?: Maybe<SortRemoveOneDeliveryOrderInput>;
};


export type EmployeeMutationConsumerCreateArgs = {
  record: CreateOneConsumerInput;
};


export type EmployeeMutationConsumerUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdConsumerInput;
};


export type EmployeeMutationConsumerRemoveArgs = {
  filter?: Maybe<FilterRemoveOneConsumerInput>;
  sort?: Maybe<SortRemoveOneConsumerInput>;
};


export type EmployeeMutationRiderCreateArgs = {
  record: CreateOneRiderInput;
};


export type EmployeeMutationRiderUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdRiderInput;
};


export type EmployeeMutationRiderRemoveArgs = {
  filter?: Maybe<FilterRemoveOneRiderInput>;
  sort?: Maybe<SortRemoveOneRiderInput>;
};


export type EmployeeMutationSystemVersionCreateArgs = {
  record: CreateOneSystemVersionInput;
};


export type EmployeeMutationSystemVersionUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdSystemVersionInput;
};


export type EmployeeMutationSystemVersionRemoveArgs = {
  filter?: Maybe<FilterRemoveOneSystemVersionInput>;
  sort?: Maybe<SortRemoveOneSystemVersionInput>;
};

export type EmployeeQuery = {
  __typename?: 'EmployeeQuery';
  employeeMany: Array<Employee>;
  employeeOne?: Maybe<Employee>;
  productMany: Array<Product>;
  productOne?: Maybe<Product>;
  categoryMany: Array<Category>;
  categoryOne?: Maybe<Category>;
  merchantMany: Array<Merchant>;
  merchantOne?: Maybe<Merchant>;
  shopMany: Array<Shop>;
  shopOne?: Maybe<Shop>;
  orderMany: Array<Order>;
  orderOne?: Maybe<Order>;
  deliverOrderMany: Array<DeliveryOrder>;
  deliverOrderOne?: Maybe<DeliveryOrder>;
  consumerPaymentsMany: Array<ConsumerPayment>;
  consumerPaymentsOne?: Maybe<ConsumerPayment>;
  consumerMany: Array<Consumer>;
  consumerOne?: Maybe<Consumer>;
  riderMany: Array<Rider>;
  riderOne?: Maybe<Rider>;
  systemVersionMany: Array<SystemVersion>;
};


export type EmployeeQueryEmployeeManyArgs = {
  filter?: Maybe<FilterFindManyEmployeeInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyEmployeeInput>;
};


export type EmployeeQueryEmployeeOneArgs = {
  filter?: Maybe<FilterFindOneEmployeeInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEmployeeInput>;
};


export type EmployeeQueryProductManyArgs = {
  filter?: Maybe<FilterFindManyProductInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyProductInput>;
};


export type EmployeeQueryProductOneArgs = {
  filter?: Maybe<FilterFindOneProductInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneProductInput>;
};


export type EmployeeQueryCategoryManyArgs = {
  filter?: Maybe<FilterFindManyCategoryInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyCategoryInput>;
};


export type EmployeeQueryCategoryOneArgs = {
  filter?: Maybe<FilterFindOneCategoryInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneCategoryInput>;
};


export type EmployeeQueryMerchantManyArgs = {
  filter?: Maybe<FilterFindManyMerchantInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyMerchantInput>;
};


export type EmployeeQueryMerchantOneArgs = {
  filter?: Maybe<FilterFindOneMerchantInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneMerchantInput>;
};


export type EmployeeQueryShopManyArgs = {
  filter?: Maybe<FilterFindManyShopInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyShopInput>;
};


export type EmployeeQueryShopOneArgs = {
  filter?: Maybe<FilterFindOneShopInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneShopInput>;
};


export type EmployeeQueryOrderManyArgs = {
  filter?: Maybe<FilterFindManyOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyOrderInput>;
};


export type EmployeeQueryOrderOneArgs = {
  filter?: Maybe<FilterFindOneOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneOrderInput>;
};


export type EmployeeQueryDeliverOrderManyArgs = {
  filter?: Maybe<FilterFindManyDeliveryOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyDeliveryOrderInput>;
};


export type EmployeeQueryDeliverOrderOneArgs = {
  filter?: Maybe<FilterFindOneDeliveryOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneDeliveryOrderInput>;
};


export type EmployeeQueryConsumerPaymentsManyArgs = {
  filter?: Maybe<FilterFindManyConsumerPaymentInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyConsumerPaymentInput>;
};


export type EmployeeQueryConsumerPaymentsOneArgs = {
  filter?: Maybe<FilterFindOneConsumerPaymentInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneConsumerPaymentInput>;
};


export type EmployeeQueryConsumerManyArgs = {
  filter?: Maybe<FilterFindManyConsumerInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyConsumerInput>;
};


export type EmployeeQueryConsumerOneArgs = {
  filter?: Maybe<FilterFindOneConsumerInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneConsumerInput>;
};


export type EmployeeQueryRiderManyArgs = {
  filter?: Maybe<FilterFindManyRiderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyRiderInput>;
};


export type EmployeeQueryRiderOneArgs = {
  filter?: Maybe<FilterFindOneRiderInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRiderInput>;
};


export type EmployeeQuerySystemVersionManyArgs = {
  filter?: Maybe<FilterFindManySystemVersionInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Created_At>;
};

export enum EnumAddressCountry {
  Malaysia = 'Malaysia',
  Singapore = 'Singapore',
  Thailand = 'Thailand'
}

export enum EnumAddressLocationType {
  Point = 'Point'
}

export enum EnumBlogContentSectionsType {
  Videos = 'videos',
  Images = 'images',
  Products = 'products',
  Texts = 'texts'
}

export enum EnumBlogStatus {
  Pending = 'pending',
  Draft = 'draft',
  Published = 'published'
}

export enum EnumConsumerPaymentPaymentPlatform {
  Stripe = 'stripe'
}

export enum EnumConsumerPaymentStatus {
  Success = 'success',
  Failed = 'failed'
}

export enum EnumConsumerPreferedPayment {
  Cash = 'cash',
  Creditcard = 'creditcard'
}

export enum EnumConsumerType {
  Consumer = 'consumer'
}

export enum EnumDeliveryAddressCountry {
  Malaysia = 'Malaysia',
  Singapore = 'Singapore',
  Thailand = 'Thailand'
}

export enum EnumDeliveryOrderStatusName {
  Open = 'open',
  Scheduled = 'scheduled',
  Collecting = 'collecting',
  Transit = 'transit',
  Delivered = 'delivered'
}

export enum EnumDeliveryOrderType {
  Express = 'express',
  Standard = 'standard'
}

export enum EnumEmployeeRoles {
  Sales = 'sales',
  Cs = 'cs',
  Admin = 'admin',
  Accounts = 'accounts'
}

export enum EnumEmployeeType {
  Employee = 'employee'
}

export enum EnumLikeType {
  Product = 'Product',
  Blog = 'Blog',
  Shop = 'Shop'
}

export enum EnumMerchantStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Published = 'Published'
}

export enum EnumMerchantType {
  Merchant = 'merchant'
}

export enum EnumOrderDeliveryOption {
  Ownfleet = 'ownfleet',
  Courier = 'courier'
}

export enum EnumOrderPaymentStatus {
  Pending = 'pending',
  Failed = 'failed',
  Success = 'success'
}

export enum EnumOrderPaymentType {
  Cash = 'cash',
  Card = 'card',
  Fpx = 'fpx'
}

export enum EnumOrderStatus {
  Incart = 'incart',
  Open = 'open',
  Confirmed = 'confirmed',
  Completed = 'completed',
  Cancelled = 'cancelled'
}

export enum EnumOrderType {
  Selfpickup = 'selfpickup',
  Delivery = 'delivery'
}

export enum EnumProductStatus {
  Pending = 'pending',
  Draft = 'draft',
  Published = 'published'
}

export enum EnumRiderType {
  Rider = 'rider'
}

export enum EnumShopStatus {
  Pending = 'pending',
  Draft = 'draft',
  Published = 'published'
}

export enum EnumShopType {
  ShopGen01 = 'ShopGen01',
  ShopGen02 = 'ShopGen02'
}

export enum EnumSystemVersionType {
  Consumer = 'consumer',
  Merchant = 'merchant',
  Rider = 'rider'
}

export enum EnumTestSubscriptionType {
  Express = 'express',
  Standard = 'standard'
}

export type ErrorInterface = {
  /** Generic error message */
  message?: Maybe<Scalars['String']>;
};

export type Exist = {
  __typename?: 'Exist';
  username: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  shopUrl: Scalars['Boolean'];
};


export type ExistUsernameArgs = {
  username: Scalars['String'];
};


export type ExistPhoneArgs = {
  phone: Scalars['String'];
};


export type ExistShopUrlArgs = {
  url: Scalars['String'];
};

export type FilterFindManyAddressInput = {
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<FilterFindManyAddressLocationInput>;
};

export type FilterFindManyAddressInputCustomFindInput = {
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<FilterFindManyAddressLocationInputCustomFindInput>;
};

export type FilterFindManyAddressLocationInput = {
  type?: Maybe<EnumAddressLocationType>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyAddressLocationInputCustomFindInput = {
  type?: Maybe<EnumAddressLocationType>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyAddressLocationInputCustomFindPenefitInput = {
  type?: Maybe<EnumAddressLocationType>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyBlogContentSectionsInputCustomFindInput = {
  type?: Maybe<EnumBlogContentSectionsType>;
  data?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyBlogInputCustomFindInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  cardTitle?: Maybe<Scalars['String']>;
  cardText?: Maybe<Scalars['String']>;
  cardImageUrl?: Maybe<Scalars['String']>;
  contentHeader?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['MongoID']>;
  contentSections?: Maybe<Array<Maybe<FilterFindManyBlogContentSectionsInputCustomFindInput>>>;
  status?: Maybe<EnumBlogStatus>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type FilterFindManyCategoryInput = {
  shopId?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyCategoryOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyCategoryInput>>;
  AND?: Maybe<Array<FilterFindManyCategoryInput>>;
};

export type FilterFindManyCategoryNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyCategoryOperatorsInput = {
  shopId?: Maybe<FilterFindManyCategoryShopIdOperatorsInput>;
  name?: Maybe<FilterFindManyCategoryNameOperatorsInput>;
  _id?: Maybe<FilterFindManyCategory_IdOperatorsInput>;
};

export type FilterFindManyCategoryShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCategory_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyConsumerEmailOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyConsumerInput = {
  username?: Maybe<Scalars['String']>;
  type?: Maybe<EnumConsumerType>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  preferedPayment?: Maybe<EnumConsumerPreferedPayment>;
  addresses?: Maybe<Array<Maybe<FilterFindManyDeliveryAddressInput>>>;
  tokenVersion?: Maybe<Scalars['Float']>;
  penefitIntegration?: Maybe<FilterFindManyConsumerPenefitIntegrationInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyConsumerOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyConsumerInput>>;
  AND?: Maybe<Array<FilterFindManyConsumerInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyConsumerOperatorsInput = {
  username?: Maybe<FilterFindManyConsumerUsernameOperatorsInput>;
  phone?: Maybe<FilterFindManyConsumerPhoneOperatorsInput>;
  email?: Maybe<FilterFindManyConsumerEmailOperatorsInput>;
  _id?: Maybe<FilterFindManyConsumer_IdOperatorsInput>;
};

export type FilterFindManyConsumerPaymentInput = {
  paymentPlatformId?: Maybe<Scalars['String']>;
  paymentPlatform?: Maybe<EnumConsumerPaymentPaymentPlatform>;
  orderId?: Maybe<Scalars['MongoID']>;
  consumerId?: Maybe<Scalars['MongoID']>;
  amountReceived?: Maybe<Scalars['Float']>;
  status?: Maybe<EnumConsumerPaymentStatus>;
  apiResponse?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyConsumerPaymentOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyConsumerPaymentInput>>;
  AND?: Maybe<Array<FilterFindManyConsumerPaymentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyConsumerPaymentOperatorsInput = {
  _id?: Maybe<FilterFindManyConsumerPayment_IdOperatorsInput>;
};

export type FilterFindManyConsumerPayment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyConsumerPenefitIntegrationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  posId?: Maybe<Scalars['String']>;
  penefitId?: Maybe<Scalars['String']>;
};

export type FilterFindManyConsumerPenefitIntegrationInputCustomFindPenefitInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  posId?: Maybe<Scalars['String']>;
  penefitId?: Maybe<Scalars['String']>;
};

export type FilterFindManyConsumerPhoneOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyConsumerUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyConsumer_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryAddressInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
  detailed_address: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  state: Scalars['String'];
  country: EnumDeliveryAddressCountry;
  city: Scalars['String'];
  zipcode: Scalars['String'];
  location?: Maybe<AddressLocationInput>;
};

export type FilterFindManyDeliveryAddressInputCustomFindPenefitInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumDeliveryAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<FilterFindManyAddressLocationInputCustomFindPenefitInput>;
};

export type FilterFindManyDeliveryOrderConsumerIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromCityOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromCountryOperatorsInput = {
  gt?: Maybe<EnumDeliveryAddressCountry>;
  gte?: Maybe<EnumDeliveryAddressCountry>;
  lt?: Maybe<EnumDeliveryAddressCountry>;
  lte?: Maybe<EnumDeliveryAddressCountry>;
  ne?: Maybe<EnumDeliveryAddressCountry>;
  in?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromDetailed_AddressOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromLocationCoordinatesOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromLocationOperatorsInput = {
  type?: Maybe<FilterFindManyDeliveryOrderDeliverFromLocationTypeOperatorsInput>;
  coordinates?: Maybe<FilterFindManyDeliveryOrderDeliverFromLocationCoordinatesOperatorsInput>;
  _id?: Maybe<FilterFindManyDeliveryOrderDeliverFromLocation_IdOperatorsInput>;
};

export type FilterFindManyDeliveryOrderDeliverFromLocationTypeOperatorsInput = {
  gt?: Maybe<EnumAddressLocationType>;
  gte?: Maybe<EnumAddressLocationType>;
  lt?: Maybe<EnumAddressLocationType>;
  lte?: Maybe<EnumAddressLocationType>;
  ne?: Maybe<EnumAddressLocationType>;
  in?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  nin?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromLocation_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromOperatorsInput = {
  name?: Maybe<FilterFindManyDeliveryOrderDeliverFromNameOperatorsInput>;
  phone?: Maybe<FilterFindManyDeliveryOrderDeliverFromPhoneOperatorsInput>;
  detailed_address?: Maybe<FilterFindManyDeliveryOrderDeliverFromDetailed_AddressOperatorsInput>;
  primary?: Maybe<FilterFindManyDeliveryOrderDeliverFromPrimaryOperatorsInput>;
  state?: Maybe<FilterFindManyDeliveryOrderDeliverFromStateOperatorsInput>;
  country?: Maybe<FilterFindManyDeliveryOrderDeliverFromCountryOperatorsInput>;
  city?: Maybe<FilterFindManyDeliveryOrderDeliverFromCityOperatorsInput>;
  zipcode?: Maybe<FilterFindManyDeliveryOrderDeliverFromZipcodeOperatorsInput>;
  location?: Maybe<FilterFindManyDeliveryOrderDeliverFromLocationOperatorsInput>;
};

export type FilterFindManyDeliveryOrderDeliverFromPhoneOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromPrimaryOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromStateOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverFromZipcodeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToCityOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToCountryOperatorsInput = {
  gt?: Maybe<EnumDeliveryAddressCountry>;
  gte?: Maybe<EnumDeliveryAddressCountry>;
  lt?: Maybe<EnumDeliveryAddressCountry>;
  lte?: Maybe<EnumDeliveryAddressCountry>;
  ne?: Maybe<EnumDeliveryAddressCountry>;
  in?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToDetailed_AddressOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToLocationCoordinatesOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToLocationOperatorsInput = {
  type?: Maybe<FilterFindManyDeliveryOrderDeliverToLocationTypeOperatorsInput>;
  coordinates?: Maybe<FilterFindManyDeliveryOrderDeliverToLocationCoordinatesOperatorsInput>;
  _id?: Maybe<FilterFindManyDeliveryOrderDeliverToLocation_IdOperatorsInput>;
};

export type FilterFindManyDeliveryOrderDeliverToLocationTypeOperatorsInput = {
  gt?: Maybe<EnumAddressLocationType>;
  gte?: Maybe<EnumAddressLocationType>;
  lt?: Maybe<EnumAddressLocationType>;
  lte?: Maybe<EnumAddressLocationType>;
  ne?: Maybe<EnumAddressLocationType>;
  in?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  nin?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToLocation_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToOperatorsInput = {
  name?: Maybe<FilterFindManyDeliveryOrderDeliverToNameOperatorsInput>;
  phone?: Maybe<FilterFindManyDeliveryOrderDeliverToPhoneOperatorsInput>;
  detailed_address?: Maybe<FilterFindManyDeliveryOrderDeliverToDetailed_AddressOperatorsInput>;
  primary?: Maybe<FilterFindManyDeliveryOrderDeliverToPrimaryOperatorsInput>;
  state?: Maybe<FilterFindManyDeliveryOrderDeliverToStateOperatorsInput>;
  country?: Maybe<FilterFindManyDeliveryOrderDeliverToCountryOperatorsInput>;
  city?: Maybe<FilterFindManyDeliveryOrderDeliverToCityOperatorsInput>;
  zipcode?: Maybe<FilterFindManyDeliveryOrderDeliverToZipcodeOperatorsInput>;
  location?: Maybe<FilterFindManyDeliveryOrderDeliverToLocationOperatorsInput>;
};

export type FilterFindManyDeliveryOrderDeliverToPhoneOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToPrimaryOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToStateOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDeliverToZipcodeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderDistanceInKmOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderInput = {
  consumerId?: Maybe<Scalars['MongoID']>;
  orderId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  riderId?: Maybe<Scalars['MongoID']>;
  deliverTo?: Maybe<FilterFindManyDeliveryAddressInput>;
  deliverFrom?: Maybe<FilterFindManyDeliveryAddressInput>;
  deliverDate?: Maybe<Scalars['Date']>;
  type?: Maybe<EnumDeliveryOrderType>;
  date?: Maybe<Scalars['Date']>;
  status?: Maybe<Array<Maybe<FilterFindManyDeliveryOrderStatusInput>>>;
  distanceInKm?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyDeliveryOrderOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyDeliveryOrderInput>>;
  AND?: Maybe<Array<FilterFindManyDeliveryOrderInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyDeliveryOrderOperatorsInput = {
  consumerId?: Maybe<FilterFindManyDeliveryOrderConsumerIdOperatorsInput>;
  orderId?: Maybe<FilterFindManyDeliveryOrderOrderIdOperatorsInput>;
  shopId?: Maybe<FilterFindManyDeliveryOrderShopIdOperatorsInput>;
  riderId?: Maybe<FilterFindManyDeliveryOrderRiderIdOperatorsInput>;
  deliverTo?: Maybe<FilterFindManyDeliveryOrderDeliverToOperatorsInput>;
  deliverFrom?: Maybe<FilterFindManyDeliveryOrderDeliverFromOperatorsInput>;
  deliverDate?: Maybe<FilterFindManyDeliveryOrderDeliverDateOperatorsInput>;
  type?: Maybe<FilterFindManyDeliveryOrderTypeOperatorsInput>;
  date?: Maybe<FilterFindManyDeliveryOrderDateOperatorsInput>;
  status?: Maybe<FilterFindManyDeliveryOrderStatusOperatorsInput>;
  distanceInKm?: Maybe<FilterFindManyDeliveryOrderDistanceInKmOperatorsInput>;
  _id?: Maybe<FilterFindManyDeliveryOrder_IdOperatorsInput>;
  updatedAt?: Maybe<FilterFindManyDeliveryOrderUpdatedAtOperatorsInput>;
  createdAt?: Maybe<FilterFindManyDeliveryOrderCreatedAtOperatorsInput>;
};

export type FilterFindManyDeliveryOrderOrderIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderRiderIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderStatusDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderStatusDescriptionOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderStatusInput = {
  name?: Maybe<EnumDeliveryOrderStatusName>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyDeliveryOrderStatusNameOperatorsInput = {
  gt?: Maybe<EnumDeliveryOrderStatusName>;
  gte?: Maybe<EnumDeliveryOrderStatusName>;
  lt?: Maybe<EnumDeliveryOrderStatusName>;
  lte?: Maybe<EnumDeliveryOrderStatusName>;
  ne?: Maybe<EnumDeliveryOrderStatusName>;
  in?: Maybe<Array<Maybe<EnumDeliveryOrderStatusName>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryOrderStatusName>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderStatusOperatorsInput = {
  name?: Maybe<FilterFindManyDeliveryOrderStatusNameOperatorsInput>;
  description?: Maybe<FilterFindManyDeliveryOrderStatusDescriptionOperatorsInput>;
  date?: Maybe<FilterFindManyDeliveryOrderStatusDateOperatorsInput>;
  _id?: Maybe<FilterFindManyDeliveryOrderStatus_IdOperatorsInput>;
};

export type FilterFindManyDeliveryOrderStatus_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderTypeOperatorsInput = {
  gt?: Maybe<EnumDeliveryOrderType>;
  gte?: Maybe<EnumDeliveryOrderType>;
  lt?: Maybe<EnumDeliveryOrderType>;
  lte?: Maybe<EnumDeliveryOrderType>;
  ne?: Maybe<EnumDeliveryOrderType>;
  in?: Maybe<Array<Maybe<EnumDeliveryOrderType>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryOrderType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderUpdatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrder_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyEmployeeInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumEmployeeType>;
  tokenVersion?: Maybe<Scalars['Float']>;
  roles?: Maybe<Array<Maybe<EnumEmployeeRoles>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyEmployeeOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyEmployeeInput>>;
  AND?: Maybe<Array<FilterFindManyEmployeeInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyEmployeeOperatorsInput = {
  username?: Maybe<FilterFindManyEmployeeUsernameOperatorsInput>;
  _id?: Maybe<FilterFindManyEmployee_IdOperatorsInput>;
};

export type FilterFindManyEmployeeUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyEmployee_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyLikeConsumerIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyLikeInput = {
  consumerId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumLikeType>;
  typeId?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyLikeOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyLikeInput>>;
  AND?: Maybe<Array<FilterFindManyLikeInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyLikeOperatorsInput = {
  consumerId?: Maybe<FilterFindManyLikeConsumerIdOperatorsInput>;
  shopId?: Maybe<FilterFindManyLikeShopIdOperatorsInput>;
  type?: Maybe<FilterFindManyLikeTypeOperatorsInput>;
  typeId?: Maybe<FilterFindManyLikeTypeIdOperatorsInput>;
  _id?: Maybe<FilterFindManyLike_IdOperatorsInput>;
};

export type FilterFindManyLikeShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyLikeTypeIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyLikeTypeOperatorsInput = {
  gt?: Maybe<EnumLikeType>;
  gte?: Maybe<EnumLikeType>;
  lt?: Maybe<EnumLikeType>;
  lte?: Maybe<EnumLikeType>;
  ne?: Maybe<EnumLikeType>;
  in?: Maybe<Array<Maybe<EnumLikeType>>>;
  nin?: Maybe<Array<Maybe<EnumLikeType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyLike_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyMerchantInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumMerchantType>;
  status?: Maybe<EnumMerchantStatus>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tokenVersion?: Maybe<Scalars['Float']>;
  salesRepId?: Maybe<Scalars['MongoID']>;
  penefitIntegration?: Maybe<FilterFindManyMerchantPenefitIntegrationInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyMerchantOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyMerchantInput>>;
  AND?: Maybe<Array<FilterFindManyMerchantInput>>;
};

export type FilterFindManyMerchantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyMerchantOperatorsInput = {
  username?: Maybe<FilterFindManyMerchantUsernameOperatorsInput>;
  name?: Maybe<FilterFindManyMerchantNameOperatorsInput>;
  _id?: Maybe<FilterFindManyMerchant_IdOperatorsInput>;
};

export type FilterFindManyMerchantPenefitConsumerCustomFindPenefitInput = {
  username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  preferedPayment?: Maybe<EnumConsumerPreferedPayment>;
  addresses?: Maybe<Array<Maybe<FilterFindManyDeliveryAddressInputCustomFindPenefitInput>>>;
  penefitIntegration?: Maybe<FilterFindManyConsumerPenefitIntegrationInputCustomFindPenefitInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  myPenefitRewards?: Maybe<FilterFindManyPenefitRewardCardInputCustomFindPenefitInput>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyMerchantPenefitConsumerOperatorsCustomFindPenefitInput>;
  OR?: Maybe<Array<FilterFindManyMerchantPenefitConsumerCustomFindPenefitInput>>;
  AND?: Maybe<Array<FilterFindManyMerchantPenefitConsumerCustomFindPenefitInput>>;
};

export type FilterFindManyMerchantPenefitConsumerEmailOperatorsCustomFindPenefitInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyMerchantPenefitConsumerOperatorsCustomFindPenefitInput = {
  username?: Maybe<FilterFindManyMerchantPenefitConsumerUsernameOperatorsCustomFindPenefitInput>;
  phone?: Maybe<FilterFindManyMerchantPenefitConsumerPhoneOperatorsCustomFindPenefitInput>;
  email?: Maybe<FilterFindManyMerchantPenefitConsumerEmailOperatorsCustomFindPenefitInput>;
  _id?: Maybe<FilterFindManyMerchantPenefitConsumer_IdOperatorsCustomFindPenefitInput>;
};

export type FilterFindManyMerchantPenefitConsumerPhoneOperatorsCustomFindPenefitInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyMerchantPenefitConsumerUsernameOperatorsCustomFindPenefitInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyMerchantPenefitConsumer_IdOperatorsCustomFindPenefitInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyMerchantPenefitIntegrationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  xAppKey?: Maybe<Scalars['String']>;
  xAcctId?: Maybe<Scalars['String']>;
  xUsername?: Maybe<Scalars['String']>;
  xOutletKey?: Maybe<Scalars['String']>;
  xAuthToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyMerchantUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyMerchant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyOrderCartsInput = {
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['MongoID']>;
  price?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyOrderDeliveryInput = {
  deliverFrom?: Maybe<FilterFindManyDeliveryAddressInput>;
  deliverTo?: Maybe<FilterFindManyDeliveryAddressInput>;
  option?: Maybe<EnumOrderDeliveryOption>;
  fee?: Maybe<Scalars['Float']>;
  timeFrom?: Maybe<Scalars['Date']>;
  timeTo?: Maybe<Scalars['Date']>;
  distanceInKm?: Maybe<Scalars['Float']>;
};

export type FilterFindManyOrderDisplayIdOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyOrderInput = {
  displayId?: Maybe<Scalars['String']>;
  consumerId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  paymentId?: Maybe<Scalars['MongoID']>;
  deliveryOrderId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumOrderType>;
  status?: Maybe<EnumOrderStatus>;
  paymentStatus?: Maybe<EnumOrderPaymentStatus>;
  paymentType?: Maybe<EnumOrderPaymentType>;
  orderDate?: Maybe<Scalars['Date']>;
  tax?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  totalQty?: Maybe<Scalars['Float']>;
  delivery?: Maybe<FilterFindManyOrderDeliveryInput>;
  carts?: Maybe<Array<Maybe<FilterFindManyOrderCartsInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyOrderOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyOrderInput>>;
  AND?: Maybe<Array<FilterFindManyOrderInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyOrderOperatorsInput = {
  displayId?: Maybe<FilterFindManyOrderDisplayIdOperatorsInput>;
  shopId?: Maybe<FilterFindManyOrderShopIdOperatorsInput>;
  orderDate?: Maybe<FilterFindManyOrderOrderDateOperatorsInput>;
  _id?: Maybe<FilterFindManyOrder_IdOperatorsInput>;
};

export type FilterFindManyOrderOrderDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyOrderShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyOrder_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPenefitRewardCardInputCustomFindInput = {
  reward_cards?: Maybe<Array<Maybe<FilterFindManyPenefitRewardCard_Reward_CardsInputCustomFindInput>>>;
  total?: Maybe<Scalars['Float']>;
  currency?: Maybe<FilterFindManyPenefitRewardCard_CurrencyInputCustomFindInput>;
  consumerId?: Maybe<Scalars['MongoID']>;
  merchantId?: Maybe<Scalars['MongoID']>;
  penefitResponse?: Maybe<Scalars['JSON']>;
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  rewardTransactions?: Maybe<FilterFindManyPenefitRewardTransactionsInputCustomFindInput>;
};

export type FilterFindManyPenefitRewardCardInputCustomFindPenefitInput = {
  reward_cards?: Maybe<Array<Maybe<FilterFindManyPenefitRewardCard_Reward_CardsInputCustomFindPenefitInput>>>;
  total?: Maybe<Scalars['Float']>;
  currency?: Maybe<FilterFindManyPenefitRewardCard_CurrencyInputCustomFindPenefitInput>;
  consumerId?: Maybe<Scalars['MongoID']>;
  merchantId?: Maybe<Scalars['MongoID']>;
  penefitResponse?: Maybe<Scalars['JSON']>;
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  rewardTransactions?: Maybe<FilterFindManyPenefitRewardTransactionsInputCustomFindPenefitInput>;
};

export type FilterFindManyPenefitRewardCard_CurrencyInputCustomFindInput = {
  currency_label?: Maybe<Scalars['String']>;
  thousand_separator?: Maybe<Scalars['String']>;
  decimal_separator?: Maybe<Scalars['String']>;
  currency_display?: Maybe<Scalars['String']>;
  key_name?: Maybe<Scalars['String']>;
  floating_point?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPenefitRewardCard_CurrencyInputCustomFindPenefitInput = {
  currency_label?: Maybe<Scalars['String']>;
  thousand_separator?: Maybe<Scalars['String']>;
  decimal_separator?: Maybe<Scalars['String']>;
  currency_display?: Maybe<Scalars['String']>;
  key_name?: Maybe<Scalars['String']>;
  floating_point?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPenefitRewardCard_Reward_CardsInputCustomFindInput = {
  card_dimension?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Card_DimensionInputCustomFindInput>;
  reward_balance?: Maybe<Scalars['Float']>;
  reward_balance_to_be_expired_within_30_days?: Maybe<Scalars['Float']>;
  card_image_url?: Maybe<Scalars['String']>;
  reward_type?: Maybe<Scalars['String']>;
  program_key?: Maybe<Scalars['String']>;
  card_label?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Card_LabelInputCustomFindInput>;
  card_logo?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Card_LogoInputCustomFindInput>;
  member_reference_code?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Member_Reference_CodeInputCustomFindInput>;
  expiry_date?: Maybe<Scalars['String']>;
};

export type FilterFindManyPenefitRewardCard_Reward_CardsInputCustomFindPenefitInput = {
  card_dimension?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Card_DimensionInputCustomFindPenefitInput>;
  reward_balance?: Maybe<Scalars['Float']>;
  reward_balance_to_be_expired_within_30_days?: Maybe<Scalars['Float']>;
  card_image_url?: Maybe<Scalars['String']>;
  reward_type?: Maybe<Scalars['String']>;
  program_key?: Maybe<Scalars['String']>;
  card_label?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Card_LabelInputCustomFindPenefitInput>;
  card_logo?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Card_LogoInputCustomFindPenefitInput>;
  member_reference_code?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Member_Reference_CodeInputCustomFindPenefitInput>;
  expiry_date?: Maybe<Scalars['String']>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Card_DimensionInputCustomFindInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Card_DimensionInputCustomFindPenefitInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Card_LabelInputCustomFindInput = {
  color?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Card_LabelInputCustomFindPenefitInput = {
  color?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Card_LogoInputCustomFindInput = {
  logo_url?: Maybe<Scalars['String']>;
  dimension?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Card_Logo_DimensionInputCustomFindInput>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Card_LogoInputCustomFindPenefitInput = {
  logo_url?: Maybe<Scalars['String']>;
  dimension?: Maybe<FilterFindManyPenefitRewardCard_Reward_Cards_Card_Logo_DimensionInputCustomFindPenefitInput>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Card_Logo_DimensionInputCustomFindInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Card_Logo_DimensionInputCustomFindPenefitInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Member_Reference_CodeInputCustomFindInput = {
  color?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type FilterFindManyPenefitRewardCard_Reward_Cards_Member_Reference_CodeInputCustomFindPenefitInput = {
  color?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type FilterFindManyPenefitRewardTransactionsInputCustomFindInput = {
  currency?: Maybe<FilterFindManyPenefitRewardTransactions_CurrencyInputCustomFindInput>;
  total?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<FilterFindManyPenefitRewardTransactions_TransactionsInputCustomFindInput>>>;
};

export type FilterFindManyPenefitRewardTransactionsInputCustomFindPenefitInput = {
  currency?: Maybe<FilterFindManyPenefitRewardTransactions_CurrencyInputCustomFindPenefitInput>;
  total?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<FilterFindManyPenefitRewardTransactions_TransactionsInputCustomFindPenefitInput>>>;
};

export type FilterFindManyPenefitRewardTransactions_CurrencyInputCustomFindInput = {
  currency_label?: Maybe<Scalars['String']>;
  thousand_separator?: Maybe<Scalars['String']>;
  decimal_separator?: Maybe<Scalars['String']>;
  currency_display?: Maybe<Scalars['String']>;
  key_name?: Maybe<Scalars['String']>;
  floating_point?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPenefitRewardTransactions_CurrencyInputCustomFindPenefitInput = {
  currency_label?: Maybe<Scalars['String']>;
  thousand_separator?: Maybe<Scalars['String']>;
  decimal_separator?: Maybe<Scalars['String']>;
  currency_display?: Maybe<Scalars['String']>;
  key_name?: Maybe<Scalars['String']>;
  floating_point?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPenefitRewardTransactions_TransactionsInputCustomFindInput = {
  reward_amount?: Maybe<Scalars['Int']>;
  reward_type?: Maybe<Scalars['String']>;
  transact_outlet_name?: Maybe<Scalars['String']>;
  transact_datetime?: Maybe<Scalars['String']>;
  rewarded_by_name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  invoice_no?: Maybe<Scalars['String']>;
  purchase_currency_amount?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['String']>;
};

export type FilterFindManyPenefitRewardTransactions_TransactionsInputCustomFindPenefitInput = {
  reward_amount?: Maybe<Scalars['Int']>;
  reward_type?: Maybe<Scalars['String']>;
  transact_outlet_name?: Maybe<Scalars['String']>;
  transact_datetime?: Maybe<Scalars['String']>;
  rewarded_by_name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  invoice_no?: Maybe<Scalars['String']>;
  purchase_currency_amount?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['String']>;
};

export type FilterFindManyProductDescriptionImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterFindManyProductDescriptionImagesInputCustomFindInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterFindManyProductImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterFindManyProductImagesInputCustomFindInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterFindManyProductInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FilterFindManyProductImagesInput>>>;
  descriptionImages?: Maybe<Array<Maybe<FilterFindManyProductDescriptionImagesInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<FilterFindManyProductPricingInput>;
  shipping?: Maybe<FilterFindManyProductShippingInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyProductOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyProductInput>>;
  AND?: Maybe<Array<FilterFindManyProductInput>>;
};

export type FilterFindManyProductNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyProductOperatorsInput = {
  name?: Maybe<FilterFindManyProductNameOperatorsInput>;
  shopId?: Maybe<FilterFindManyProductShopIdOperatorsInput>;
  _id?: Maybe<FilterFindManyProduct_IdOperatorsInput>;
};

export type FilterFindManyProductPricingInput = {
  retail?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

export type FilterFindManyProductPricingInputCustomFindInput = {
  retail: Scalars['Float'];
  discount: Scalars['Float'];
  tax: Scalars['Float'];
};

export type FilterFindManyProductShippingDimensionInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

export type FilterFindManyProductShippingInput = {
  weight?: Maybe<Scalars['Float']>;
  dimension?: Maybe<FilterFindManyProductShippingDimensionInput>;
};

export type FilterFindManyProductShippingInputCustomFindInput = {
  weight: Scalars['Float'];
  dimension?: Maybe<ProductShippingDimensionInput>;
};

export type FilterFindManyProductShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyProduct_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPublicCategoryInputCustomFindInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FilterFindManyProductImagesInputCustomFindInput>>>;
  descriptionImages?: Maybe<Array<Maybe<FilterFindManyProductDescriptionImagesInputCustomFindInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<FilterFindManyProductPricingInputCustomFindInput>;
  shipping?: Maybe<FilterFindManyProductShippingInputCustomFindInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type FilterFindManyPublicProductCustomFindInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FilterFindManyProductImagesInputCustomFindInput>>>;
  descriptionImages?: Maybe<Array<Maybe<FilterFindManyProductDescriptionImagesInputCustomFindInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<FilterFindManyProductPricingInputCustomFindInput>;
  shipping?: Maybe<FilterFindManyProductShippingInputCustomFindInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyPublicProductOperatorsCustomFindInput>;
  OR?: Maybe<Array<FilterFindManyPublicProductCustomFindInput>>;
  AND?: Maybe<Array<FilterFindManyPublicProductCustomFindInput>>;
};

export type FilterFindManyPublicProductInputCustomFindInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FilterFindManyProductImagesInputCustomFindInput>>>;
  descriptionImages?: Maybe<Array<Maybe<FilterFindManyProductDescriptionImagesInputCustomFindInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<FilterFindManyProductPricingInputCustomFindInput>;
  shipping?: Maybe<FilterFindManyProductShippingInputCustomFindInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type FilterFindManyPublicProductNameOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyPublicProductOperatorsCustomFindInput = {
  name?: Maybe<FilterFindManyPublicProductNameOperatorsCustomFindInput>;
  shopId?: Maybe<FilterFindManyPublicProductShopIdOperatorsCustomFindInput>;
  _id?: Maybe<FilterFindManyPublicProduct_IdOperatorsCustomFindInput>;
};

export type FilterFindManyPublicProductShopIdOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPublicProduct_IdOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPublicShopCustomFindInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumShopType>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  whatsapp_number?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  optOutDelivery?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumShopStatus>;
  address?: Maybe<FilterFindManyAddressInputCustomFindInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  myPenefitRewards?: Maybe<FilterFindManyPenefitRewardCardInputCustomFindInput>;
  categories?: Maybe<Array<FilterFindManyPublicCategoryInputCustomFindInput>>;
  products?: Maybe<Array<FilterFindManyPublicProductInputCustomFindInput>>;
  blogs?: Maybe<Array<FilterFindManyBlogInputCustomFindInput>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyPublicShopOperatorsCustomFindInput>;
  OR?: Maybe<Array<FilterFindManyPublicShopCustomFindInput>>;
  AND?: Maybe<Array<FilterFindManyPublicShopCustomFindInput>>;
};

export type FilterFindManyPublicShopMerchantIdOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPublicShopNameOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyPublicShopOperatorsCustomFindInput = {
  merchantId?: Maybe<FilterFindManyPublicShopMerchantIdOperatorsCustomFindInput>;
  name?: Maybe<FilterFindManyPublicShopNameOperatorsCustomFindInput>;
  url?: Maybe<FilterFindManyPublicShopUrlOperatorsCustomFindInput>;
  _id?: Maybe<FilterFindManyPublicShop_IdOperatorsCustomFindInput>;
};

export type FilterFindManyPublicShopUrlOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPublicShop_IdOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyRiderInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumRiderType>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ic?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  lastLocation?: Maybe<Array<Maybe<FilterFindManyRiderLastLocationInput>>>;
  phoneOtp?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<FilterFindManyAddressInput>;
  tokenVersion?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyRiderOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyRiderInput>>;
  AND?: Maybe<Array<FilterFindManyRiderInput>>;
};

export type FilterFindManyRiderLastLocationInput = {
  dateTime?: Maybe<Scalars['Date']>;
  location?: Maybe<FilterFindManyAddressLocationInput>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyRiderOperatorsInput = {
  username?: Maybe<FilterFindManyRiderUsernameOperatorsInput>;
  _id?: Maybe<FilterFindManyRider_IdOperatorsInput>;
};

export type FilterFindManyRiderUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyRider_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyShopInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumShopType>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  whatsapp_number?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  optOutDelivery?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumShopStatus>;
  address?: Maybe<FilterFindManyAddressInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyShopOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyShopInput>>;
  AND?: Maybe<Array<FilterFindManyShopInput>>;
};

export type FilterFindManyShopMerchantIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyShopNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyShopOperatorsInput = {
  merchantId?: Maybe<FilterFindManyShopMerchantIdOperatorsInput>;
  name?: Maybe<FilterFindManyShopNameOperatorsInput>;
  url?: Maybe<FilterFindManyShopUrlOperatorsInput>;
  _id?: Maybe<FilterFindManyShop_IdOperatorsInput>;
};

export type FilterFindManyShopUrlOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyShop_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManySystemVersionCodeVersionOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManySystemVersionCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManySystemVersionInput = {
  codeVersion?: Maybe<Scalars['String']>;
  cacheVersion?: Maybe<Scalars['String']>;
  type: EnumSystemVersionType;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManySystemVersionOperatorsInput>;
  OR?: Maybe<Array<FilterFindManySystemVersionInput>>;
  AND?: Maybe<Array<FilterFindManySystemVersionInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManySystemVersionOperatorsInput = {
  codeVersion?: Maybe<FilterFindManySystemVersionCodeVersionOperatorsInput>;
  _id?: Maybe<FilterFindManySystemVersion_IdOperatorsInput>;
  createdAt?: Maybe<FilterFindManySystemVersionCreatedAtOperatorsInput>;
};

export type FilterFindManySystemVersion_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyTestSubscriptionInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EnumTestSubscriptionType>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyTestSubscriptionOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyTestSubscriptionInput>>;
  AND?: Maybe<Array<FilterFindManyTestSubscriptionInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyTestSubscriptionOperatorsInput = {
  _id?: Maybe<FilterFindManyTestSubscription_IdOperatorsInput>;
};

export type FilterFindManyTestSubscription_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneAddressInput = {
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<FilterFindOneAddressLocationInput>;
};

export type FilterFindOneAddressInputCustomFindInput = {
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<FilterFindOneAddressLocationInputCustomFindInput>;
};

export type FilterFindOneAddressLocationInput = {
  type?: Maybe<EnumAddressLocationType>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindOneAddressLocationInputCustomFindInput = {
  type?: Maybe<EnumAddressLocationType>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindOneBlogContentSectionsInputCustomFindInput = {
  type?: Maybe<EnumBlogContentSectionsType>;
  data?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindOneBlogInputCustomFindInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  cardTitle?: Maybe<Scalars['String']>;
  cardText?: Maybe<Scalars['String']>;
  cardImageUrl?: Maybe<Scalars['String']>;
  contentHeader?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['MongoID']>;
  contentSections?: Maybe<Array<Maybe<FilterFindOneBlogContentSectionsInputCustomFindInput>>>;
  status?: Maybe<EnumBlogStatus>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type FilterFindOneCategoryInput = {
  shopId?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneCategoryOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneCategoryInput>>;
  AND?: Maybe<Array<FilterFindOneCategoryInput>>;
};

export type FilterFindOneCategoryNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneCategoryOperatorsInput = {
  shopId?: Maybe<FilterFindOneCategoryShopIdOperatorsInput>;
  name?: Maybe<FilterFindOneCategoryNameOperatorsInput>;
  _id?: Maybe<FilterFindOneCategory_IdOperatorsInput>;
};

export type FilterFindOneCategoryShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneCategory_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneConsumerEmailOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneConsumerInput = {
  username?: Maybe<Scalars['String']>;
  type?: Maybe<EnumConsumerType>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  preferedPayment?: Maybe<EnumConsumerPreferedPayment>;
  addresses?: Maybe<Array<Maybe<FilterFindOneDeliveryAddressInput>>>;
  tokenVersion?: Maybe<Scalars['Float']>;
  penefitIntegration?: Maybe<FilterFindOneConsumerPenefitIntegrationInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneConsumerOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneConsumerInput>>;
  AND?: Maybe<Array<FilterFindOneConsumerInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneConsumerOperatorsInput = {
  username?: Maybe<FilterFindOneConsumerUsernameOperatorsInput>;
  phone?: Maybe<FilterFindOneConsumerPhoneOperatorsInput>;
  email?: Maybe<FilterFindOneConsumerEmailOperatorsInput>;
  _id?: Maybe<FilterFindOneConsumer_IdOperatorsInput>;
};

export type FilterFindOneConsumerPaymentInput = {
  paymentPlatformId?: Maybe<Scalars['String']>;
  paymentPlatform?: Maybe<EnumConsumerPaymentPaymentPlatform>;
  orderId?: Maybe<Scalars['MongoID']>;
  consumerId?: Maybe<Scalars['MongoID']>;
  amountReceived?: Maybe<Scalars['Float']>;
  status?: Maybe<EnumConsumerPaymentStatus>;
  apiResponse?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneConsumerPaymentOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneConsumerPaymentInput>>;
  AND?: Maybe<Array<FilterFindOneConsumerPaymentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneConsumerPaymentOperatorsInput = {
  _id?: Maybe<FilterFindOneConsumerPayment_IdOperatorsInput>;
};

export type FilterFindOneConsumerPayment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneConsumerPenefitIntegrationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  posId?: Maybe<Scalars['String']>;
  penefitId?: Maybe<Scalars['String']>;
};

export type FilterFindOneConsumerPhoneOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneConsumerUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneConsumer_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryAddressInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumDeliveryAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<FilterFindOneAddressLocationInput>;
};

export type FilterFindOneDeliveryOrderConsumerIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromCityOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromCountryOperatorsInput = {
  gt?: Maybe<EnumDeliveryAddressCountry>;
  gte?: Maybe<EnumDeliveryAddressCountry>;
  lt?: Maybe<EnumDeliveryAddressCountry>;
  lte?: Maybe<EnumDeliveryAddressCountry>;
  ne?: Maybe<EnumDeliveryAddressCountry>;
  in?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromDetailed_AddressOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromLocationCoordinatesOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromLocationOperatorsInput = {
  type?: Maybe<FilterFindOneDeliveryOrderDeliverFromLocationTypeOperatorsInput>;
  coordinates?: Maybe<FilterFindOneDeliveryOrderDeliverFromLocationCoordinatesOperatorsInput>;
  _id?: Maybe<FilterFindOneDeliveryOrderDeliverFromLocation_IdOperatorsInput>;
};

export type FilterFindOneDeliveryOrderDeliverFromLocationTypeOperatorsInput = {
  gt?: Maybe<EnumAddressLocationType>;
  gte?: Maybe<EnumAddressLocationType>;
  lt?: Maybe<EnumAddressLocationType>;
  lte?: Maybe<EnumAddressLocationType>;
  ne?: Maybe<EnumAddressLocationType>;
  in?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  nin?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromLocation_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromOperatorsInput = {
  name?: Maybe<FilterFindOneDeliveryOrderDeliverFromNameOperatorsInput>;
  phone?: Maybe<FilterFindOneDeliveryOrderDeliverFromPhoneOperatorsInput>;
  detailed_address?: Maybe<FilterFindOneDeliveryOrderDeliverFromDetailed_AddressOperatorsInput>;
  primary?: Maybe<FilterFindOneDeliveryOrderDeliverFromPrimaryOperatorsInput>;
  state?: Maybe<FilterFindOneDeliveryOrderDeliverFromStateOperatorsInput>;
  country?: Maybe<FilterFindOneDeliveryOrderDeliverFromCountryOperatorsInput>;
  city?: Maybe<FilterFindOneDeliveryOrderDeliverFromCityOperatorsInput>;
  zipcode?: Maybe<FilterFindOneDeliveryOrderDeliverFromZipcodeOperatorsInput>;
  location?: Maybe<FilterFindOneDeliveryOrderDeliverFromLocationOperatorsInput>;
};

export type FilterFindOneDeliveryOrderDeliverFromPhoneOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromPrimaryOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromStateOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverFromZipcodeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToCityOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToCountryOperatorsInput = {
  gt?: Maybe<EnumDeliveryAddressCountry>;
  gte?: Maybe<EnumDeliveryAddressCountry>;
  lt?: Maybe<EnumDeliveryAddressCountry>;
  lte?: Maybe<EnumDeliveryAddressCountry>;
  ne?: Maybe<EnumDeliveryAddressCountry>;
  in?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToDetailed_AddressOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToLocationCoordinatesOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToLocationOperatorsInput = {
  type?: Maybe<FilterFindOneDeliveryOrderDeliverToLocationTypeOperatorsInput>;
  coordinates?: Maybe<FilterFindOneDeliveryOrderDeliverToLocationCoordinatesOperatorsInput>;
  _id?: Maybe<FilterFindOneDeliveryOrderDeliverToLocation_IdOperatorsInput>;
};

export type FilterFindOneDeliveryOrderDeliverToLocationTypeOperatorsInput = {
  gt?: Maybe<EnumAddressLocationType>;
  gte?: Maybe<EnumAddressLocationType>;
  lt?: Maybe<EnumAddressLocationType>;
  lte?: Maybe<EnumAddressLocationType>;
  ne?: Maybe<EnumAddressLocationType>;
  in?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  nin?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToLocation_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToOperatorsInput = {
  name?: Maybe<FilterFindOneDeliveryOrderDeliverToNameOperatorsInput>;
  phone?: Maybe<FilterFindOneDeliveryOrderDeliverToPhoneOperatorsInput>;
  detailed_address?: Maybe<FilterFindOneDeliveryOrderDeliverToDetailed_AddressOperatorsInput>;
  primary?: Maybe<FilterFindOneDeliveryOrderDeliverToPrimaryOperatorsInput>;
  state?: Maybe<FilterFindOneDeliveryOrderDeliverToStateOperatorsInput>;
  country?: Maybe<FilterFindOneDeliveryOrderDeliverToCountryOperatorsInput>;
  city?: Maybe<FilterFindOneDeliveryOrderDeliverToCityOperatorsInput>;
  zipcode?: Maybe<FilterFindOneDeliveryOrderDeliverToZipcodeOperatorsInput>;
  location?: Maybe<FilterFindOneDeliveryOrderDeliverToLocationOperatorsInput>;
};

export type FilterFindOneDeliveryOrderDeliverToPhoneOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToPrimaryOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToStateOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDeliverToZipcodeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderDistanceInKmOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderInput = {
  consumerId?: Maybe<Scalars['MongoID']>;
  orderId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  riderId?: Maybe<Scalars['MongoID']>;
  deliverTo?: Maybe<FilterFindOneDeliveryAddressInput>;
  deliverFrom?: Maybe<FilterFindOneDeliveryAddressInput>;
  deliverDate?: Maybe<Scalars['Date']>;
  type?: Maybe<EnumDeliveryOrderType>;
  date?: Maybe<Scalars['Date']>;
  status?: Maybe<Array<Maybe<FilterFindOneDeliveryOrderStatusInput>>>;
  distanceInKm?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneDeliveryOrderOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneDeliveryOrderInput>>;
  AND?: Maybe<Array<FilterFindOneDeliveryOrderInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneDeliveryOrderOperatorsInput = {
  consumerId?: Maybe<FilterFindOneDeliveryOrderConsumerIdOperatorsInput>;
  orderId?: Maybe<FilterFindOneDeliveryOrderOrderIdOperatorsInput>;
  shopId?: Maybe<FilterFindOneDeliveryOrderShopIdOperatorsInput>;
  riderId?: Maybe<FilterFindOneDeliveryOrderRiderIdOperatorsInput>;
  deliverTo?: Maybe<FilterFindOneDeliveryOrderDeliverToOperatorsInput>;
  deliverFrom?: Maybe<FilterFindOneDeliveryOrderDeliverFromOperatorsInput>;
  deliverDate?: Maybe<FilterFindOneDeliveryOrderDeliverDateOperatorsInput>;
  type?: Maybe<FilterFindOneDeliveryOrderTypeOperatorsInput>;
  date?: Maybe<FilterFindOneDeliveryOrderDateOperatorsInput>;
  status?: Maybe<FilterFindOneDeliveryOrderStatusOperatorsInput>;
  distanceInKm?: Maybe<FilterFindOneDeliveryOrderDistanceInKmOperatorsInput>;
  _id?: Maybe<FilterFindOneDeliveryOrder_IdOperatorsInput>;
  updatedAt?: Maybe<FilterFindOneDeliveryOrderUpdatedAtOperatorsInput>;
  createdAt?: Maybe<FilterFindOneDeliveryOrderCreatedAtOperatorsInput>;
};

export type FilterFindOneDeliveryOrderOrderIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderRiderIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderStatusDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderStatusDescriptionOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderStatusInput = {
  name?: Maybe<EnumDeliveryOrderStatusName>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindOneDeliveryOrderStatusNameOperatorsInput = {
  gt?: Maybe<EnumDeliveryOrderStatusName>;
  gte?: Maybe<EnumDeliveryOrderStatusName>;
  lt?: Maybe<EnumDeliveryOrderStatusName>;
  lte?: Maybe<EnumDeliveryOrderStatusName>;
  ne?: Maybe<EnumDeliveryOrderStatusName>;
  in?: Maybe<Array<Maybe<EnumDeliveryOrderStatusName>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryOrderStatusName>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderStatusOperatorsInput = {
  name?: Maybe<FilterFindOneDeliveryOrderStatusNameOperatorsInput>;
  description?: Maybe<FilterFindOneDeliveryOrderStatusDescriptionOperatorsInput>;
  date?: Maybe<FilterFindOneDeliveryOrderStatusDateOperatorsInput>;
  _id?: Maybe<FilterFindOneDeliveryOrderStatus_IdOperatorsInput>;
};

export type FilterFindOneDeliveryOrderStatus_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderTypeOperatorsInput = {
  gt?: Maybe<EnumDeliveryOrderType>;
  gte?: Maybe<EnumDeliveryOrderType>;
  lt?: Maybe<EnumDeliveryOrderType>;
  lte?: Maybe<EnumDeliveryOrderType>;
  ne?: Maybe<EnumDeliveryOrderType>;
  in?: Maybe<Array<Maybe<EnumDeliveryOrderType>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryOrderType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrderUpdatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeliveryOrder_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneEmployeeInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumEmployeeType>;
  tokenVersion?: Maybe<Scalars['Float']>;
  roles?: Maybe<Array<Maybe<EnumEmployeeRoles>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneEmployeeOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneEmployeeInput>>;
  AND?: Maybe<Array<FilterFindOneEmployeeInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneEmployeeOperatorsInput = {
  username?: Maybe<FilterFindOneEmployeeUsernameOperatorsInput>;
  _id?: Maybe<FilterFindOneEmployee_IdOperatorsInput>;
};

export type FilterFindOneEmployeeUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneEmployee_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneMerchantInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumMerchantType>;
  status?: Maybe<EnumMerchantStatus>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tokenVersion?: Maybe<Scalars['Float']>;
  salesRepId?: Maybe<Scalars['MongoID']>;
  penefitIntegration?: Maybe<FilterFindOneMerchantPenefitIntegrationInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneMerchantOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneMerchantInput>>;
  AND?: Maybe<Array<FilterFindOneMerchantInput>>;
};

export type FilterFindOneMerchantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneMerchantOperatorsInput = {
  username?: Maybe<FilterFindOneMerchantUsernameOperatorsInput>;
  name?: Maybe<FilterFindOneMerchantNameOperatorsInput>;
  _id?: Maybe<FilterFindOneMerchant_IdOperatorsInput>;
};

export type FilterFindOneMerchantPenefitIntegrationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  xAppKey?: Maybe<Scalars['String']>;
  xAcctId?: Maybe<Scalars['String']>;
  xUsername?: Maybe<Scalars['String']>;
  xOutletKey?: Maybe<Scalars['String']>;
  xAuthToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindOneMerchantUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneMerchant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneOrderCartsInput = {
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['MongoID']>;
  price?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindOneOrderDeliveryInput = {
  deliverFrom?: Maybe<FilterFindOneDeliveryAddressInput>;
  deliverTo?: Maybe<FilterFindOneDeliveryAddressInput>;
  option?: Maybe<EnumOrderDeliveryOption>;
  fee?: Maybe<Scalars['Float']>;
  timeFrom?: Maybe<Scalars['Date']>;
  timeTo?: Maybe<Scalars['Date']>;
  distanceInKm?: Maybe<Scalars['Float']>;
};

export type FilterFindOneOrderDisplayIdOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneOrderInput = {
  displayId?: Maybe<Scalars['String']>;
  consumerId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  paymentId?: Maybe<Scalars['MongoID']>;
  deliveryOrderId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumOrderType>;
  status?: Maybe<EnumOrderStatus>;
  paymentStatus?: Maybe<EnumOrderPaymentStatus>;
  paymentType?: Maybe<EnumOrderPaymentType>;
  orderDate?: Maybe<Scalars['Date']>;
  tax?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  totalQty?: Maybe<Scalars['Float']>;
  delivery?: Maybe<FilterFindOneOrderDeliveryInput>;
  carts?: Maybe<Array<Maybe<FilterFindOneOrderCartsInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneOrderOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneOrderInput>>;
  AND?: Maybe<Array<FilterFindOneOrderInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneOrderOperatorsInput = {
  displayId?: Maybe<FilterFindOneOrderDisplayIdOperatorsInput>;
  shopId?: Maybe<FilterFindOneOrderShopIdOperatorsInput>;
  orderDate?: Maybe<FilterFindOneOrderOrderDateOperatorsInput>;
  _id?: Maybe<FilterFindOneOrder_IdOperatorsInput>;
};

export type FilterFindOneOrderOrderDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneOrderShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneOrder_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOnePenefitRewardCardInputCustomFindInput = {
  reward_cards?: Maybe<Array<Maybe<FilterFindOnePenefitRewardCard_Reward_CardsInputCustomFindInput>>>;
  total?: Maybe<Scalars['Float']>;
  currency?: Maybe<FilterFindOnePenefitRewardCard_CurrencyInputCustomFindInput>;
  consumerId?: Maybe<Scalars['MongoID']>;
  merchantId?: Maybe<Scalars['MongoID']>;
  penefitResponse?: Maybe<Scalars['JSON']>;
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  rewardTransactions?: Maybe<FilterFindOnePenefitRewardTransactionsInputCustomFindInput>;
};

export type FilterFindOnePenefitRewardCard_CurrencyInputCustomFindInput = {
  currency_label?: Maybe<Scalars['String']>;
  thousand_separator?: Maybe<Scalars['String']>;
  decimal_separator?: Maybe<Scalars['String']>;
  currency_display?: Maybe<Scalars['String']>;
  key_name?: Maybe<Scalars['String']>;
  floating_point?: Maybe<Scalars['Float']>;
};

export type FilterFindOnePenefitRewardCard_Reward_CardsInputCustomFindInput = {
  card_dimension?: Maybe<FilterFindOnePenefitRewardCard_Reward_Cards_Card_DimensionInputCustomFindInput>;
  reward_balance?: Maybe<Scalars['Float']>;
  reward_balance_to_be_expired_within_30_days?: Maybe<Scalars['Float']>;
  card_image_url?: Maybe<Scalars['String']>;
  reward_type?: Maybe<Scalars['String']>;
  program_key?: Maybe<Scalars['String']>;
  card_label?: Maybe<FilterFindOnePenefitRewardCard_Reward_Cards_Card_LabelInputCustomFindInput>;
  card_logo?: Maybe<FilterFindOnePenefitRewardCard_Reward_Cards_Card_LogoInputCustomFindInput>;
  member_reference_code?: Maybe<FilterFindOnePenefitRewardCard_Reward_Cards_Member_Reference_CodeInputCustomFindInput>;
  expiry_date?: Maybe<Scalars['String']>;
};

export type FilterFindOnePenefitRewardCard_Reward_Cards_Card_DimensionInputCustomFindInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type FilterFindOnePenefitRewardCard_Reward_Cards_Card_LabelInputCustomFindInput = {
  color?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type FilterFindOnePenefitRewardCard_Reward_Cards_Card_LogoInputCustomFindInput = {
  logo_url?: Maybe<Scalars['String']>;
  dimension?: Maybe<FilterFindOnePenefitRewardCard_Reward_Cards_Card_Logo_DimensionInputCustomFindInput>;
};

export type FilterFindOnePenefitRewardCard_Reward_Cards_Card_Logo_DimensionInputCustomFindInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type FilterFindOnePenefitRewardCard_Reward_Cards_Member_Reference_CodeInputCustomFindInput = {
  color?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type FilterFindOnePenefitRewardTransactionsInputCustomFindInput = {
  currency?: Maybe<FilterFindOnePenefitRewardTransactions_CurrencyInputCustomFindInput>;
  total?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<FilterFindOnePenefitRewardTransactions_TransactionsInputCustomFindInput>>>;
};

export type FilterFindOnePenefitRewardTransactions_CurrencyInputCustomFindInput = {
  currency_label?: Maybe<Scalars['String']>;
  thousand_separator?: Maybe<Scalars['String']>;
  decimal_separator?: Maybe<Scalars['String']>;
  currency_display?: Maybe<Scalars['String']>;
  key_name?: Maybe<Scalars['String']>;
  floating_point?: Maybe<Scalars['Float']>;
};

export type FilterFindOnePenefitRewardTransactions_TransactionsInputCustomFindInput = {
  reward_amount?: Maybe<Scalars['Int']>;
  reward_type?: Maybe<Scalars['String']>;
  transact_outlet_name?: Maybe<Scalars['String']>;
  transact_datetime?: Maybe<Scalars['String']>;
  rewarded_by_name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  invoice_no?: Maybe<Scalars['String']>;
  purchase_currency_amount?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['String']>;
};

export type FilterFindOneProductDescriptionImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterFindOneProductDescriptionImagesInputCustomFindInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterFindOneProductImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterFindOneProductImagesInputCustomFindInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterFindOneProductInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FilterFindOneProductImagesInput>>>;
  descriptionImages?: Maybe<Array<Maybe<FilterFindOneProductDescriptionImagesInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<FilterFindOneProductPricingInput>;
  shipping?: Maybe<FilterFindOneProductShippingInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneProductOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneProductInput>>;
  AND?: Maybe<Array<FilterFindOneProductInput>>;
};

export type FilterFindOneProductNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneProductOperatorsInput = {
  name?: Maybe<FilterFindOneProductNameOperatorsInput>;
  shopId?: Maybe<FilterFindOneProductShopIdOperatorsInput>;
  _id?: Maybe<FilterFindOneProduct_IdOperatorsInput>;
};

export type FilterFindOneProductPricingInput = {
  retail?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

export type FilterFindOneProductPricingInputCustomFindInput = {
  retail: Scalars['Float'];
  discount: Scalars['Float'];
  tax: Scalars['Float'];
};

export type FilterFindOneProductShippingDimensionInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

export type FilterFindOneProductShippingInput = {
  weight?: Maybe<Scalars['Float']>;
  dimension?: Maybe<FilterFindOneProductShippingDimensionInput>;
};

export type FilterFindOneProductShippingInputCustomFindInput = {
  weight: Scalars['Float'];
  dimension?: Maybe<ProductShippingDimensionInput>;
};

export type FilterFindOneProductShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneProduct_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOnePublicCategoryInputCustomFindInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FilterFindOneProductImagesInputCustomFindInput>>>;
  descriptionImages?: Maybe<Array<Maybe<FilterFindOneProductDescriptionImagesInputCustomFindInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<FilterFindOneProductPricingInputCustomFindInput>;
  shipping?: Maybe<FilterFindOneProductShippingInputCustomFindInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type FilterFindOnePublicProductCustomFindInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FilterFindOneProductImagesInputCustomFindInput>>>;
  descriptionImages?: Maybe<Array<Maybe<FilterFindOneProductDescriptionImagesInputCustomFindInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<FilterFindOneProductPricingInputCustomFindInput>;
  shipping?: Maybe<FilterFindOneProductShippingInputCustomFindInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOnePublicProductOperatorsCustomFindInput>;
  OR?: Maybe<Array<FilterFindOnePublicProductCustomFindInput>>;
  AND?: Maybe<Array<FilterFindOnePublicProductCustomFindInput>>;
};

export type FilterFindOnePublicProductInputCustomFindInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FilterFindOneProductImagesInputCustomFindInput>>>;
  descriptionImages?: Maybe<Array<Maybe<FilterFindOneProductDescriptionImagesInputCustomFindInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<FilterFindOneProductPricingInputCustomFindInput>;
  shipping?: Maybe<FilterFindOneProductShippingInputCustomFindInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type FilterFindOnePublicProductNameOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOnePublicProductOperatorsCustomFindInput = {
  name?: Maybe<FilterFindOnePublicProductNameOperatorsCustomFindInput>;
  shopId?: Maybe<FilterFindOnePublicProductShopIdOperatorsCustomFindInput>;
  _id?: Maybe<FilterFindOnePublicProduct_IdOperatorsCustomFindInput>;
};

export type FilterFindOnePublicProductShopIdOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOnePublicProduct_IdOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOnePublicShopCustomFindInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumShopType>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  whatsapp_number?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  optOutDelivery?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumShopStatus>;
  address?: Maybe<FilterFindOneAddressInputCustomFindInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  myPenefitRewards?: Maybe<FilterFindOnePenefitRewardCardInputCustomFindInput>;
  categories?: Maybe<Array<FilterFindOnePublicCategoryInputCustomFindInput>>;
  products?: Maybe<Array<FilterFindOnePublicProductInputCustomFindInput>>;
  blogs?: Maybe<Array<FilterFindOneBlogInputCustomFindInput>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOnePublicShopOperatorsCustomFindInput>;
  OR?: Maybe<Array<FilterFindOnePublicShopCustomFindInput>>;
  AND?: Maybe<Array<FilterFindOnePublicShopCustomFindInput>>;
};

export type FilterFindOnePublicShopMerchantIdOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOnePublicShopNameOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOnePublicShopOperatorsCustomFindInput = {
  merchantId?: Maybe<FilterFindOnePublicShopMerchantIdOperatorsCustomFindInput>;
  name?: Maybe<FilterFindOnePublicShopNameOperatorsCustomFindInput>;
  url?: Maybe<FilterFindOnePublicShopUrlOperatorsCustomFindInput>;
  _id?: Maybe<FilterFindOnePublicShop_IdOperatorsCustomFindInput>;
};

export type FilterFindOnePublicShopUrlOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOnePublicShop_IdOperatorsCustomFindInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneRiderInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumRiderType>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ic?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  lastLocation?: Maybe<Array<Maybe<FilterFindOneRiderLastLocationInput>>>;
  phoneOtp?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<FilterFindOneAddressInput>;
  tokenVersion?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneRiderOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneRiderInput>>;
  AND?: Maybe<Array<FilterFindOneRiderInput>>;
};

export type FilterFindOneRiderLastLocationInput = {
  dateTime?: Maybe<Scalars['Date']>;
  location?: Maybe<FilterFindOneAddressLocationInput>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneRiderOperatorsInput = {
  username?: Maybe<FilterFindOneRiderUsernameOperatorsInput>;
  _id?: Maybe<FilterFindOneRider_IdOperatorsInput>;
};

export type FilterFindOneRiderUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneRider_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneShopInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumShopType>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  whatsapp_number?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  optOutDelivery?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumShopStatus>;
  address?: Maybe<FilterFindOneAddressInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneShopOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneShopInput>>;
  AND?: Maybe<Array<FilterFindOneShopInput>>;
};

export type FilterFindOneShopMerchantIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneShopNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneShopOperatorsInput = {
  merchantId?: Maybe<FilterFindOneShopMerchantIdOperatorsInput>;
  name?: Maybe<FilterFindOneShopNameOperatorsInput>;
  url?: Maybe<FilterFindOneShopUrlOperatorsInput>;
  _id?: Maybe<FilterFindOneShop_IdOperatorsInput>;
};

export type FilterFindOneShopUrlOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneShop_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneAddressInput = {
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<FilterRemoveOneAddressLocationInput>;
};

export type FilterRemoveOneAddressLocationInput = {
  type?: Maybe<EnumAddressLocationType>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterRemoveOneBlogContentSectionsInput = {
  type?: Maybe<EnumBlogContentSectionsType>;
  data?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterRemoveOneBlogInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  cardTitle?: Maybe<Scalars['String']>;
  cardText?: Maybe<Scalars['String']>;
  cardImageUrl?: Maybe<Scalars['String']>;
  contentHeader?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['MongoID']>;
  contentSections?: Maybe<Array<Maybe<FilterRemoveOneBlogContentSectionsInput>>>;
  status?: Maybe<EnumBlogStatus>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneBlogOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneBlogInput>>;
  AND?: Maybe<Array<FilterRemoveOneBlogInput>>;
};

export type FilterRemoveOneBlogMerchantIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneBlogOperatorsInput = {
  merchantId?: Maybe<FilterRemoveOneBlogMerchantIdOperatorsInput>;
  shopId?: Maybe<FilterRemoveOneBlogShopIdOperatorsInput>;
  _id?: Maybe<FilterRemoveOneBlog_IdOperatorsInput>;
};

export type FilterRemoveOneBlogShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneBlog_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneCategoryInput = {
  shopId?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneCategoryOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneCategoryInput>>;
  AND?: Maybe<Array<FilterRemoveOneCategoryInput>>;
};

export type FilterRemoveOneCategoryNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneCategoryOperatorsInput = {
  shopId?: Maybe<FilterRemoveOneCategoryShopIdOperatorsInput>;
  name?: Maybe<FilterRemoveOneCategoryNameOperatorsInput>;
  _id?: Maybe<FilterRemoveOneCategory_IdOperatorsInput>;
};

export type FilterRemoveOneCategoryShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneCategory_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneConsumerEmailOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneConsumerInput = {
  username?: Maybe<Scalars['String']>;
  type?: Maybe<EnumConsumerType>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  preferedPayment?: Maybe<EnumConsumerPreferedPayment>;
  addresses?: Maybe<Array<Maybe<FilterRemoveOneDeliveryAddressInput>>>;
  tokenVersion?: Maybe<Scalars['Float']>;
  penefitIntegration?: Maybe<FilterRemoveOneConsumerPenefitIntegrationInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneConsumerOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneConsumerInput>>;
  AND?: Maybe<Array<FilterRemoveOneConsumerInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneConsumerOperatorsInput = {
  username?: Maybe<FilterRemoveOneConsumerUsernameOperatorsInput>;
  phone?: Maybe<FilterRemoveOneConsumerPhoneOperatorsInput>;
  email?: Maybe<FilterRemoveOneConsumerEmailOperatorsInput>;
  _id?: Maybe<FilterRemoveOneConsumer_IdOperatorsInput>;
};

export type FilterRemoveOneConsumerPenefitIntegrationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  posId?: Maybe<Scalars['String']>;
  penefitId?: Maybe<Scalars['String']>;
};

export type FilterRemoveOneConsumerPhoneOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneConsumerUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneConsumer_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryAddressInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumDeliveryAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<FilterRemoveOneAddressLocationInput>;
};

export type FilterRemoveOneDeliveryOrderConsumerIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromCityOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromCountryOperatorsInput = {
  gt?: Maybe<EnumDeliveryAddressCountry>;
  gte?: Maybe<EnumDeliveryAddressCountry>;
  lt?: Maybe<EnumDeliveryAddressCountry>;
  lte?: Maybe<EnumDeliveryAddressCountry>;
  ne?: Maybe<EnumDeliveryAddressCountry>;
  in?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromDetailed_AddressOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromLocationCoordinatesOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromLocationOperatorsInput = {
  type?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromLocationTypeOperatorsInput>;
  coordinates?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromLocationCoordinatesOperatorsInput>;
  _id?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromLocation_IdOperatorsInput>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromLocationTypeOperatorsInput = {
  gt?: Maybe<EnumAddressLocationType>;
  gte?: Maybe<EnumAddressLocationType>;
  lt?: Maybe<EnumAddressLocationType>;
  lte?: Maybe<EnumAddressLocationType>;
  ne?: Maybe<EnumAddressLocationType>;
  in?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  nin?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromLocation_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromOperatorsInput = {
  name?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromNameOperatorsInput>;
  phone?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromPhoneOperatorsInput>;
  detailed_address?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromDetailed_AddressOperatorsInput>;
  primary?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromPrimaryOperatorsInput>;
  state?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromStateOperatorsInput>;
  country?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromCountryOperatorsInput>;
  city?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromCityOperatorsInput>;
  zipcode?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromZipcodeOperatorsInput>;
  location?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromLocationOperatorsInput>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromPhoneOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromPrimaryOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromStateOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverFromZipcodeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToCityOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToCountryOperatorsInput = {
  gt?: Maybe<EnumDeliveryAddressCountry>;
  gte?: Maybe<EnumDeliveryAddressCountry>;
  lt?: Maybe<EnumDeliveryAddressCountry>;
  lte?: Maybe<EnumDeliveryAddressCountry>;
  ne?: Maybe<EnumDeliveryAddressCountry>;
  in?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryAddressCountry>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToDetailed_AddressOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToLocationCoordinatesOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToLocationOperatorsInput = {
  type?: Maybe<FilterRemoveOneDeliveryOrderDeliverToLocationTypeOperatorsInput>;
  coordinates?: Maybe<FilterRemoveOneDeliveryOrderDeliverToLocationCoordinatesOperatorsInput>;
  _id?: Maybe<FilterRemoveOneDeliveryOrderDeliverToLocation_IdOperatorsInput>;
};

export type FilterRemoveOneDeliveryOrderDeliverToLocationTypeOperatorsInput = {
  gt?: Maybe<EnumAddressLocationType>;
  gte?: Maybe<EnumAddressLocationType>;
  lt?: Maybe<EnumAddressLocationType>;
  lte?: Maybe<EnumAddressLocationType>;
  ne?: Maybe<EnumAddressLocationType>;
  in?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  nin?: Maybe<Array<Maybe<EnumAddressLocationType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToLocation_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToOperatorsInput = {
  name?: Maybe<FilterRemoveOneDeliveryOrderDeliverToNameOperatorsInput>;
  phone?: Maybe<FilterRemoveOneDeliveryOrderDeliverToPhoneOperatorsInput>;
  detailed_address?: Maybe<FilterRemoveOneDeliveryOrderDeliverToDetailed_AddressOperatorsInput>;
  primary?: Maybe<FilterRemoveOneDeliveryOrderDeliverToPrimaryOperatorsInput>;
  state?: Maybe<FilterRemoveOneDeliveryOrderDeliverToStateOperatorsInput>;
  country?: Maybe<FilterRemoveOneDeliveryOrderDeliverToCountryOperatorsInput>;
  city?: Maybe<FilterRemoveOneDeliveryOrderDeliverToCityOperatorsInput>;
  zipcode?: Maybe<FilterRemoveOneDeliveryOrderDeliverToZipcodeOperatorsInput>;
  location?: Maybe<FilterRemoveOneDeliveryOrderDeliverToLocationOperatorsInput>;
};

export type FilterRemoveOneDeliveryOrderDeliverToPhoneOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToPrimaryOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToStateOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDeliverToZipcodeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderDistanceInKmOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderInput = {
  consumerId?: Maybe<Scalars['MongoID']>;
  orderId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  riderId?: Maybe<Scalars['MongoID']>;
  deliverTo?: Maybe<FilterRemoveOneDeliveryAddressInput>;
  deliverFrom?: Maybe<FilterRemoveOneDeliveryAddressInput>;
  deliverDate?: Maybe<Scalars['Date']>;
  type?: Maybe<EnumDeliveryOrderType>;
  date?: Maybe<Scalars['Date']>;
  status?: Maybe<Array<Maybe<FilterRemoveOneDeliveryOrderStatusInput>>>;
  distanceInKm?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneDeliveryOrderOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneDeliveryOrderInput>>;
  AND?: Maybe<Array<FilterRemoveOneDeliveryOrderInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneDeliveryOrderOperatorsInput = {
  consumerId?: Maybe<FilterRemoveOneDeliveryOrderConsumerIdOperatorsInput>;
  orderId?: Maybe<FilterRemoveOneDeliveryOrderOrderIdOperatorsInput>;
  shopId?: Maybe<FilterRemoveOneDeliveryOrderShopIdOperatorsInput>;
  riderId?: Maybe<FilterRemoveOneDeliveryOrderRiderIdOperatorsInput>;
  deliverTo?: Maybe<FilterRemoveOneDeliveryOrderDeliverToOperatorsInput>;
  deliverFrom?: Maybe<FilterRemoveOneDeliveryOrderDeliverFromOperatorsInput>;
  deliverDate?: Maybe<FilterRemoveOneDeliveryOrderDeliverDateOperatorsInput>;
  type?: Maybe<FilterRemoveOneDeliveryOrderTypeOperatorsInput>;
  date?: Maybe<FilterRemoveOneDeliveryOrderDateOperatorsInput>;
  status?: Maybe<FilterRemoveOneDeliveryOrderStatusOperatorsInput>;
  distanceInKm?: Maybe<FilterRemoveOneDeliveryOrderDistanceInKmOperatorsInput>;
  _id?: Maybe<FilterRemoveOneDeliveryOrder_IdOperatorsInput>;
  updatedAt?: Maybe<FilterRemoveOneDeliveryOrderUpdatedAtOperatorsInput>;
  createdAt?: Maybe<FilterRemoveOneDeliveryOrderCreatedAtOperatorsInput>;
};

export type FilterRemoveOneDeliveryOrderOrderIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderRiderIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderStatusDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderStatusDescriptionOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderStatusInput = {
  name?: Maybe<EnumDeliveryOrderStatusName>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterRemoveOneDeliveryOrderStatusNameOperatorsInput = {
  gt?: Maybe<EnumDeliveryOrderStatusName>;
  gte?: Maybe<EnumDeliveryOrderStatusName>;
  lt?: Maybe<EnumDeliveryOrderStatusName>;
  lte?: Maybe<EnumDeliveryOrderStatusName>;
  ne?: Maybe<EnumDeliveryOrderStatusName>;
  in?: Maybe<Array<Maybe<EnumDeliveryOrderStatusName>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryOrderStatusName>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderStatusOperatorsInput = {
  name?: Maybe<FilterRemoveOneDeliveryOrderStatusNameOperatorsInput>;
  description?: Maybe<FilterRemoveOneDeliveryOrderStatusDescriptionOperatorsInput>;
  date?: Maybe<FilterRemoveOneDeliveryOrderStatusDateOperatorsInput>;
  _id?: Maybe<FilterRemoveOneDeliveryOrderStatus_IdOperatorsInput>;
};

export type FilterRemoveOneDeliveryOrderStatus_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderTypeOperatorsInput = {
  gt?: Maybe<EnumDeliveryOrderType>;
  gte?: Maybe<EnumDeliveryOrderType>;
  lt?: Maybe<EnumDeliveryOrderType>;
  lte?: Maybe<EnumDeliveryOrderType>;
  ne?: Maybe<EnumDeliveryOrderType>;
  in?: Maybe<Array<Maybe<EnumDeliveryOrderType>>>;
  nin?: Maybe<Array<Maybe<EnumDeliveryOrderType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrderUpdatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneDeliveryOrder_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneLikeConsumerIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneLikeInput = {
  consumerId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumLikeType>;
  typeId?: Maybe<Scalars['MongoID']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneLikeOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneLikeInput>>;
  AND?: Maybe<Array<FilterRemoveOneLikeInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneLikeOperatorsInput = {
  consumerId?: Maybe<FilterRemoveOneLikeConsumerIdOperatorsInput>;
  shopId?: Maybe<FilterRemoveOneLikeShopIdOperatorsInput>;
  type?: Maybe<FilterRemoveOneLikeTypeOperatorsInput>;
  typeId?: Maybe<FilterRemoveOneLikeTypeIdOperatorsInput>;
  _id?: Maybe<FilterRemoveOneLike_IdOperatorsInput>;
};

export type FilterRemoveOneLikeShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneLikeTypeIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneLikeTypeOperatorsInput = {
  gt?: Maybe<EnumLikeType>;
  gte?: Maybe<EnumLikeType>;
  lt?: Maybe<EnumLikeType>;
  lte?: Maybe<EnumLikeType>;
  ne?: Maybe<EnumLikeType>;
  in?: Maybe<Array<Maybe<EnumLikeType>>>;
  nin?: Maybe<Array<Maybe<EnumLikeType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneLike_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneMerchantInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumMerchantType>;
  status?: Maybe<EnumMerchantStatus>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tokenVersion?: Maybe<Scalars['Float']>;
  salesRepId?: Maybe<Scalars['MongoID']>;
  penefitIntegration?: Maybe<FilterRemoveOneMerchantPenefitIntegrationInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneMerchantOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneMerchantInput>>;
  AND?: Maybe<Array<FilterRemoveOneMerchantInput>>;
};

export type FilterRemoveOneMerchantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneMerchantOperatorsInput = {
  username?: Maybe<FilterRemoveOneMerchantUsernameOperatorsInput>;
  name?: Maybe<FilterRemoveOneMerchantNameOperatorsInput>;
  _id?: Maybe<FilterRemoveOneMerchant_IdOperatorsInput>;
};

export type FilterRemoveOneMerchantPenefitIntegrationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  xAppKey?: Maybe<Scalars['String']>;
  xAcctId?: Maybe<Scalars['String']>;
  xUsername?: Maybe<Scalars['String']>;
  xOutletKey?: Maybe<Scalars['String']>;
  xAuthToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterRemoveOneMerchantUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneMerchant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneOrderCartsInput = {
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['MongoID']>;
  price?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterRemoveOneOrderDeliveryInput = {
  deliverFrom?: Maybe<FilterRemoveOneDeliveryAddressInput>;
  deliverTo?: Maybe<FilterRemoveOneDeliveryAddressInput>;
  option?: Maybe<EnumOrderDeliveryOption>;
  fee?: Maybe<Scalars['Float']>;
  timeFrom?: Maybe<Scalars['Date']>;
  timeTo?: Maybe<Scalars['Date']>;
  distanceInKm?: Maybe<Scalars['Float']>;
};

export type FilterRemoveOneOrderDisplayIdOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneOrderInput = {
  displayId?: Maybe<Scalars['String']>;
  consumerId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  paymentId?: Maybe<Scalars['MongoID']>;
  deliveryOrderId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumOrderType>;
  status?: Maybe<EnumOrderStatus>;
  paymentStatus?: Maybe<EnumOrderPaymentStatus>;
  paymentType?: Maybe<EnumOrderPaymentType>;
  orderDate?: Maybe<Scalars['Date']>;
  tax?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  totalQty?: Maybe<Scalars['Float']>;
  delivery?: Maybe<FilterRemoveOneOrderDeliveryInput>;
  carts?: Maybe<Array<Maybe<FilterRemoveOneOrderCartsInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneOrderOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneOrderInput>>;
  AND?: Maybe<Array<FilterRemoveOneOrderInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneOrderOperatorsInput = {
  displayId?: Maybe<FilterRemoveOneOrderDisplayIdOperatorsInput>;
  shopId?: Maybe<FilterRemoveOneOrderShopIdOperatorsInput>;
  orderDate?: Maybe<FilterRemoveOneOrderOrderDateOperatorsInput>;
  _id?: Maybe<FilterRemoveOneOrder_IdOperatorsInput>;
};

export type FilterRemoveOneOrderOrderDateOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneOrderShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneOrder_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneProductDescriptionImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterRemoveOneProductImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FilterRemoveOneProductInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<FilterRemoveOneProductImagesInput>>>;
  descriptionImages?: Maybe<Array<Maybe<FilterRemoveOneProductDescriptionImagesInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<FilterRemoveOneProductPricingInput>;
  shipping?: Maybe<FilterRemoveOneProductShippingInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneProductOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneProductInput>>;
  AND?: Maybe<Array<FilterRemoveOneProductInput>>;
};

export type FilterRemoveOneProductNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneProductOperatorsInput = {
  name?: Maybe<FilterRemoveOneProductNameOperatorsInput>;
  shopId?: Maybe<FilterRemoveOneProductShopIdOperatorsInput>;
  _id?: Maybe<FilterRemoveOneProduct_IdOperatorsInput>;
};

export type FilterRemoveOneProductPricingInput = {
  retail?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

export type FilterRemoveOneProductShippingDimensionInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

export type FilterRemoveOneProductShippingInput = {
  weight?: Maybe<Scalars['Float']>;
  dimension?: Maybe<FilterRemoveOneProductShippingDimensionInput>;
};

export type FilterRemoveOneProductShopIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneProduct_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneRiderInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumRiderType>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ic?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  lastLocation?: Maybe<Array<Maybe<FilterRemoveOneRiderLastLocationInput>>>;
  phoneOtp?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<FilterRemoveOneAddressInput>;
  tokenVersion?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneRiderOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneRiderInput>>;
  AND?: Maybe<Array<FilterRemoveOneRiderInput>>;
};

export type FilterRemoveOneRiderLastLocationInput = {
  dateTime?: Maybe<Scalars['Date']>;
  location?: Maybe<FilterRemoveOneAddressLocationInput>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneRiderOperatorsInput = {
  username?: Maybe<FilterRemoveOneRiderUsernameOperatorsInput>;
  _id?: Maybe<FilterRemoveOneRider_IdOperatorsInput>;
};

export type FilterRemoveOneRiderUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneRider_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneShopInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumShopType>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  whatsapp_number?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  optOutDelivery?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumShopStatus>;
  address?: Maybe<FilterRemoveOneAddressInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneShopOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneShopInput>>;
  AND?: Maybe<Array<FilterRemoveOneShopInput>>;
};

export type FilterRemoveOneShopMerchantIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneShopNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneShopOperatorsInput = {
  merchantId?: Maybe<FilterRemoveOneShopMerchantIdOperatorsInput>;
  name?: Maybe<FilterRemoveOneShopNameOperatorsInput>;
  url?: Maybe<FilterRemoveOneShopUrlOperatorsInput>;
  _id?: Maybe<FilterRemoveOneShop_IdOperatorsInput>;
};

export type FilterRemoveOneShopUrlOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneShop_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneSystemVersionCodeVersionOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneSystemVersionCreatedAtOperatorsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneSystemVersionInput = {
  codeVersion?: Maybe<Scalars['String']>;
  cacheVersion?: Maybe<Scalars['String']>;
  type?: Maybe<EnumSystemVersionType>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneSystemVersionOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneSystemVersionInput>>;
  AND?: Maybe<Array<FilterRemoveOneSystemVersionInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneSystemVersionOperatorsInput = {
  codeVersion?: Maybe<FilterRemoveOneSystemVersionCodeVersionOperatorsInput>;
  _id?: Maybe<FilterRemoveOneSystemVersion_IdOperatorsInput>;
  createdAt?: Maybe<FilterRemoveOneSystemVersionCreatedAtOperatorsInput>;
};

export type FilterRemoveOneSystemVersion_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneTestSubscriptionInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EnumTestSubscriptionType>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneTestSubscriptionOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneTestSubscriptionInput>>;
  AND?: Maybe<Array<FilterRemoveOneTestSubscriptionInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneTestSubscriptionOperatorsInput = {
  _id?: Maybe<FilterRemoveOneTestSubscription_IdOperatorsInput>;
};

export type FilterRemoveOneTestSubscription_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};


export type Like = {
  __typename?: 'Like';
  consumerId: Scalars['MongoID'];
  shopId: Scalars['MongoID'];
  type: EnumLikeType;
  typeId: Scalars['MongoID'];
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  likedProduct?: Maybe<Product>;
  likedBlog?: Maybe<Blog>;
  likedShop?: Maybe<Shop>;
};


export type LikeLikedProductArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneProductInput>;
};


export type LikeLikedBlogArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneBlogInput>;
};


export type LikeLikedShopArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneShopInput>;
};

export type LinkPenefitAccountInput = {
  consumerId: Scalars['MongoID'];
  penefitId: Scalars['String'];
  posId: Scalars['String'];
};

export type Login = {
  __typename?: 'Login';
  /** Login with using password */
  usingPassword?: Maybe<UsingPassword>;
  /** Login with using one time password */
  usingOTP?: Maybe<UsingOtp>;
};

export enum LoginEnum {
  Employee = 'employee',
  Consumer = 'consumer',
  Merchant = 'merchant',
  Rider = 'rider'
}

export type LoginOtpEmailInput = {
  email: Scalars['String'];
  code: Scalars['String'];
};

export type LoginOtpSmsInput = {
  phone: Scalars['String'];
  code: Scalars['String'];
};

export enum LoginPassEmailEnum {
  Consumer = 'consumer'
}

export enum LoginPassPhoneEnum {
  Consumer = 'consumer'
}

export type LoginPasswordEmailInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  type: LoginPassEmailEnum;
};

export type LoginPasswordPhoneInput = {
  phone: Scalars['String'];
  password: Scalars['String'];
  type: LoginPassPhoneEnum;
};

export type LoginPasswordUsernameInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  type: LoginEnum;
};

export type Merchant = {
  __typename?: 'Merchant';
  username: Scalars['String'];
  password: Scalars['String'];
  type?: Maybe<EnumMerchantType>;
  status: EnumMerchantStatus;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  tokenVersion?: Maybe<Scalars['Float']>;
  salesRepId: Scalars['MongoID'];
  penefitIntegration?: Maybe<MerchantPenefitIntegration>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  salesRep?: Maybe<Employee>;
  shops: Array<Shop>;
};


export type MerchantSalesRepArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEmployeeInput>;
};


export type MerchantShopsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyShopInput>;
};

export type MerchantDoStatusUpdateInputTc = {
  status: MerchantDoUpdateEnum;
  description: Scalars['String'];
};

export enum MerchantDoUpdateEnum {
  Transit = 'transit'
}

export type MerchantMutation = {
  __typename?: 'MerchantMutation';
  penefit?: Maybe<MerchantPenefitMutation>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  meUpdate?: Maybe<UpdateByIdMerchantPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  productCreate?: Maybe<CreateOneProductPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  productUpdate?: Maybe<UpdateByIdProductPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  productRemove?: Maybe<RemoveOneProductPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  categoryCreate?: Maybe<CreateOneCategoryPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  categoryUpdate?: Maybe<UpdateByIdCategoryPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  categoryRemove?: Maybe<RemoveOneCategoryPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  shopCreate?: Maybe<CreateOneShopPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  shopUpdate?: Maybe<UpdateByIdShopPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  shopRemove?: Maybe<RemoveOneShopPayload>;
  orderAccept?: Maybe<Order>;
  orderStatusUpdate?: Maybe<StandardOrderPayload>;
  deliveryOrderStatusUpdate?: Maybe<StandardDeliveryOrderPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  blogCreate?: Maybe<CreateOneBlogPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  blogUpdate?: Maybe<UpdateByIdBlogPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  blogRemove?: Maybe<RemoveOneBlogPayload>;
  changePassword?: Maybe<StandardPayload>;
};


export type MerchantMutationMeUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdMerchantInput;
};


export type MerchantMutationProductCreateArgs = {
  record: CreateOneProductInput;
};


export type MerchantMutationProductUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdProductInput;
};


export type MerchantMutationProductRemoveArgs = {
  filter?: Maybe<FilterRemoveOneProductInput>;
  sort?: Maybe<SortRemoveOneProductInput>;
};


export type MerchantMutationCategoryCreateArgs = {
  record: CreateOneCategoryInput;
};


export type MerchantMutationCategoryUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdCategoryInput;
};


export type MerchantMutationCategoryRemoveArgs = {
  filter?: Maybe<FilterRemoveOneCategoryInput>;
  sort?: Maybe<SortRemoveOneCategoryInput>;
};


export type MerchantMutationShopCreateArgs = {
  record: CreateOneShopInput;
};


export type MerchantMutationShopUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdShopInput;
};


export type MerchantMutationShopRemoveArgs = {
  filter?: Maybe<FilterRemoveOneShopInput>;
  sort?: Maybe<SortRemoveOneShopInput>;
};


export type MerchantMutationOrderAcceptArgs = {
  _id: Scalars['MongoID'];
};


export type MerchantMutationOrderStatusUpdateArgs = {
  _id: Scalars['MongoID'];
  record?: Maybe<MerchantOrderStatusUpdateInputTc>;
};


export type MerchantMutationDeliveryOrderStatusUpdateArgs = {
  _id: Scalars['MongoID'];
  record?: Maybe<MerchantDoStatusUpdateInputTc>;
};


export type MerchantMutationBlogCreateArgs = {
  record: CreateOneBlogInput;
};


export type MerchantMutationBlogUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdBlogInput;
};


export type MerchantMutationBlogRemoveArgs = {
  filter?: Maybe<FilterRemoveOneBlogInput>;
  sort?: Maybe<SortRemoveOneBlogInput>;
};


export type MerchantMutationChangePasswordArgs = {
  record?: Maybe<ChangePasswordInput>;
};

export type MerchantOrderStatusUpdateInputTc = {
  status?: Maybe<MerchantOrderUpdateStatusEnum>;
};

export enum MerchantOrderUpdateStatusEnum {
  Confirmed = 'confirmed',
  Completed = 'completed'
}

export type MerchantPenefitConsumer = {
  __typename?: 'MerchantPenefitConsumer';
  username: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  preferedPayment?: Maybe<EnumConsumerPreferedPayment>;
  addresses?: Maybe<Array<Maybe<DeliveryAddress>>>;
  penefitIntegration?: Maybe<ConsumerPenefitIntegration>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  myPenefitRewards?: Maybe<PenefitRewardCard>;
};

export type MerchantPenefitIntegration = {
  __typename?: 'MerchantPenefitIntegration';
  enabled: Scalars['Boolean'];
  xAppKey: Scalars['String'];
  xAcctId: Scalars['String'];
  xUsername: Scalars['String'];
  xOutletKey: Scalars['String'];
  xAuthToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MerchantPenefitIntegrationInput = {
  enabled: Scalars['Boolean'];
  xAppKey: Scalars['String'];
  xAcctId: Scalars['String'];
  xUsername: Scalars['String'];
  xOutletKey: Scalars['String'];
  xAuthToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MerchantPenefitMutation = {
  __typename?: 'MerchantPenefitMutation';
  testAuthenticatePenefit?: Maybe<PenefitStandardPayload>;
  linkConsumer?: Maybe<PenefitStandardPayload>;
};


export type MerchantPenefitMutationLinkConsumerArgs = {
  record: LinkPenefitAccountInput;
};

export type MerchantPenefitQuery = {
  __typename?: 'MerchantPenefitQuery';
  listUsers: Array<MerchantPenefitConsumer>;
};


export type MerchantPenefitQueryListUsersArgs = {
  filter?: Maybe<FilterFindManyMerchantPenefitConsumerCustomFindPenefitInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyMerchantPenefitConsumerCustomFindPenefitInput>;
};

export type MerchantQuery = {
  __typename?: 'MerchantQuery';
  penefit?: Maybe<MerchantPenefitQuery>;
  me?: Maybe<Merchant>;
  products: Array<Product>;
  categories: Array<Category>;
  shops: Array<Shop>;
  shop?: Maybe<Shop>;
  orders: Array<Order>;
  deliveryOrders: Array<DeliveryOrder>;
};


export type MerchantQueryMeArgs = {
  filter?: Maybe<FilterFindOneMerchantInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneMerchantInput>;
};


export type MerchantQueryProductsArgs = {
  filter?: Maybe<FilterFindManyProductInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyProductInput>;
};


export type MerchantQueryCategoriesArgs = {
  filter?: Maybe<FilterFindManyCategoryInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyCategoryInput>;
};


export type MerchantQueryShopsArgs = {
  filter?: Maybe<FilterFindManyShopInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyShopInput>;
};


export type MerchantQueryShopArgs = {
  filter?: Maybe<FilterFindOneShopInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneShopInput>;
};


export type MerchantQueryOrdersArgs = {
  filter?: Maybe<FilterFindManyOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyOrderInput>;
};


export type MerchantQueryDeliveryOrdersArgs = {
  filter?: Maybe<FilterFindManyDeliveryOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyDeliveryOrderInput>;
};

export type MongoError = ErrorInterface & {
  __typename?: 'MongoError';
  /** MongoDB error message */
  message?: Maybe<Scalars['String']>;
  /** MongoDB error code */
  code?: Maybe<Scalars['Int']>;
};


export type Mutation = {
  __typename?: 'Mutation';
  addProduct?: Maybe<Scalars['Boolean']>;
  clearCache?: Maybe<Scalars['Boolean']>;
  clearCarts?: Maybe<Scalars['Boolean']>;
  consumer?: Maybe<ConsumerMutation>;
  employee?: Maybe<EmployeeMutation>;
  merchant?: Maybe<MerchantMutation>;
  public?: Maybe<PublicMutation>;
  removeProduct?: Maybe<Scalars['Boolean']>;
  rider?: Maybe<RiderMutation>;
  setDeliveryAddress?: Maybe<Scalars['Boolean']>;
  setDeliveryOptionFee?: Maybe<Scalars['Boolean']>;
  setDeliveryTime?: Maybe<Scalars['Boolean']>;
  setProductsFilter?: Maybe<Scalars['String']>;
  setSetting?: Maybe<Scalars['String']>;
  setShopsFilter?: Maybe<Scalars['String']>;
};


export type MutationAddProductArgs = {
  shopUrl: Scalars['String'];
  cart?: Maybe<OrderLocalCartsInput>;
};


export type MutationClearCacheArgs = {
  record?: Maybe<Scalars['Boolean']>;
};


export type MutationClearCartsArgs = {
  shopUrl: Scalars['String'];
};


export type MutationRemoveProductArgs = {
  shopUrl: Scalars['String'];
  cart?: Maybe<OrderLocalCartsInput>;
};


export type MutationSetDeliveryAddressArgs = {
  shopUrl?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};


export type MutationSetDeliveryOptionFeeArgs = {
  shopUrl: Scalars['String'];
  deliveryOption?: Maybe<Scalars['String']>;
  deliveryFee?: Maybe<Scalars['Float']>;
  deliveryDistance?: Maybe<Scalars['Float']>;
};


export type MutationSetDeliveryTimeArgs = {
  shopUrl: Scalars['String'];
  deliveryTimeFrom?: Maybe<Scalars['Date']>;
  deliveryTimeTo?: Maybe<Scalars['Date']>;
};


export type MutationSetProductsFilterArgs = {
  filter?: Maybe<T_ProductsFilter>;
};


export type MutationSetSettingArgs = {
  record?: Maybe<T_SettingInput>;
};


export type MutationSetShopsFilterArgs = {
  filter?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  displayId?: Maybe<Scalars['String']>;
  consumerId: Scalars['MongoID'];
  shopId: Scalars['MongoID'];
  paymentId?: Maybe<Scalars['MongoID']>;
  deliveryOrderId?: Maybe<Scalars['MongoID']>;
  type: EnumOrderType;
  status?: Maybe<EnumOrderStatus>;
  paymentStatus?: Maybe<EnumOrderPaymentStatus>;
  paymentType?: Maybe<EnumOrderPaymentType>;
  orderDate?: Maybe<Scalars['Date']>;
  tax?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  totalQty?: Maybe<Scalars['Float']>;
  delivery?: Maybe<OrderDelivery>;
  carts: Array<Maybe<OrderCarts>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  shop?: Maybe<Shop>;
  consumer?: Maybe<Consumer>;
  deliveryOrder?: Maybe<DeliveryOrder>;
};


export type OrderShopArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneShopInput>;
};


export type OrderConsumerArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneConsumerInput>;
};


export type OrderDeliveryOrderArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneDeliveryOrderInput>;
};

export type OrderCarts = {
  __typename?: 'OrderCarts';
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  qty: Scalars['Float'];
  productId: Scalars['MongoID'];
  price?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  cartProduct?: Maybe<Product>;
};


export type OrderCartsCartProductArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneProductInput>;
};

export type OrderCartsInput = {
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  qty: Scalars['Float'];
  productId: Scalars['MongoID'];
  price?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type OrderDelivery = {
  __typename?: 'OrderDelivery';
  deliverFrom?: Maybe<DeliveryAddress>;
  deliverTo?: Maybe<DeliveryAddress>;
  option?: Maybe<EnumOrderDeliveryOption>;
  fee?: Maybe<Scalars['Float']>;
  timeFrom?: Maybe<Scalars['Date']>;
  timeTo?: Maybe<Scalars['Date']>;
  distanceInKm?: Maybe<Scalars['Float']>;
};

export type OrderDeliveryInput = {
  deliverFrom?: Maybe<DeliveryAddressInput>;
  deliverTo?: Maybe<DeliveryAddressInput>;
  option?: Maybe<EnumOrderDeliveryOption>;
  fee?: Maybe<Scalars['Float']>;
  timeFrom?: Maybe<Scalars['Date']>;
  timeTo?: Maybe<Scalars['Date']>;
  distanceInKm?: Maybe<Scalars['Float']>;
};

export type OrderLocalCartsInput = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['MongoID']>;
  price?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  cartProduct?: Maybe<OrderLocalCartsProductInput>;
};

export type OrderLocalCartsProductInput = {
  name?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type Otp = {
  __typename?: 'Otp';
  /** OTP features using sms */
  sms?: Maybe<Sms>;
  /** OTP features using email */
  email?: Maybe<Email>;
};

export enum OtpChannelEnum {
  Sms = 'sms',
  Call = 'call'
}

/** OTP Verification Service */
export type OtpPayload = {
  __typename?: 'OtpPayload';
  response: Scalars['JSON'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  orderId: Scalars['MongoID'];
};

export type PenefitRewardCard = {
  __typename?: 'PenefitRewardCard';
  reward_cards?: Maybe<Array<Maybe<PenefitRewardCard_Reward_Cards>>>;
  total?: Maybe<Scalars['Float']>;
  currency?: Maybe<PenefitRewardCard_Currency>;
  consumerId?: Maybe<Scalars['MongoID']>;
  merchantId?: Maybe<Scalars['MongoID']>;
  penefitResponse?: Maybe<Scalars['JSON']>;
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  rewardTransactions?: Maybe<PenefitRewardTransactions>;
};

export type PenefitRewardCard_Currency = {
  __typename?: 'PenefitRewardCard_Currency';
  currency_label?: Maybe<Scalars['String']>;
  thousand_separator?: Maybe<Scalars['String']>;
  decimal_separator?: Maybe<Scalars['String']>;
  currency_display?: Maybe<Scalars['String']>;
  key_name?: Maybe<Scalars['String']>;
  floating_point?: Maybe<Scalars['Float']>;
};

export type PenefitRewardCard_Reward_Cards = {
  __typename?: 'PenefitRewardCard_Reward_cards';
  card_dimension?: Maybe<PenefitRewardCard_Reward_Cards_Card_Dimension>;
  reward_balance?: Maybe<Scalars['Float']>;
  reward_balance_to_be_expired_within_30_days?: Maybe<Scalars['Float']>;
  card_image_url?: Maybe<Scalars['String']>;
  reward_type?: Maybe<Scalars['String']>;
  program_key?: Maybe<Scalars['String']>;
  card_label?: Maybe<PenefitRewardCard_Reward_Cards_Card_Label>;
  card_logo?: Maybe<PenefitRewardCard_Reward_Cards_Card_Logo>;
  member_reference_code?: Maybe<PenefitRewardCard_Reward_Cards_Member_Reference_Code>;
  expiry_date?: Maybe<Scalars['String']>;
};

export type PenefitRewardCard_Reward_Cards_Card_Dimension = {
  __typename?: 'PenefitRewardCard_Reward_cards_Card_dimension';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type PenefitRewardCard_Reward_Cards_Card_Label = {
  __typename?: 'PenefitRewardCard_Reward_cards_Card_label';
  color?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type PenefitRewardCard_Reward_Cards_Card_Logo = {
  __typename?: 'PenefitRewardCard_Reward_cards_Card_logo';
  logo_url?: Maybe<Scalars['String']>;
  dimension?: Maybe<PenefitRewardCard_Reward_Cards_Card_Logo_Dimension>;
};

export type PenefitRewardCard_Reward_Cards_Card_Logo_Dimension = {
  __typename?: 'PenefitRewardCard_Reward_cards_Card_logo_Dimension';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type PenefitRewardCard_Reward_Cards_Member_Reference_Code = {
  __typename?: 'PenefitRewardCard_Reward_cards_Member_reference_code';
  color?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type PenefitRewardTransactions = {
  __typename?: 'PenefitRewardTransactions';
  currency?: Maybe<PenefitRewardTransactions_Currency>;
  total: Scalars['Int'];
  transactions?: Maybe<Array<Maybe<PenefitRewardTransactions_Transactions>>>;
};

export type PenefitRewardTransactions_Currency = {
  __typename?: 'PenefitRewardTransactions_Currency';
  currency_label?: Maybe<Scalars['String']>;
  thousand_separator?: Maybe<Scalars['String']>;
  decimal_separator?: Maybe<Scalars['String']>;
  currency_display?: Maybe<Scalars['String']>;
  key_name?: Maybe<Scalars['String']>;
  floating_point?: Maybe<Scalars['Float']>;
};

export type PenefitRewardTransactions_Transactions = {
  __typename?: 'PenefitRewardTransactions_Transactions';
  reward_amount: Scalars['Int'];
  reward_type?: Maybe<Scalars['String']>;
  transact_outlet_name?: Maybe<Scalars['String']>;
  transact_datetime?: Maybe<Scalars['String']>;
  rewarded_by_name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  invoice_no?: Maybe<Scalars['String']>;
  purchase_currency_amount?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['String']>;
};

export type PenefitStandardPayload = {
  __typename?: 'PenefitStandardPayload';
  status: Scalars['Boolean'];
  penefitResponse?: Maybe<Scalars['JSON']>;
  message: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  name: Scalars['String'];
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId: Scalars['MongoID'];
  sku?: Maybe<Scalars['String']>;
  shortDesc: Scalars['String'];
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<ProductImages>>>;
  descriptionImages?: Maybe<Array<Maybe<ProductDescriptionImages>>>;
  active: Scalars['Boolean'];
  status: EnumProductStatus;
  stock?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<ProductPricing>;
  shipping?: Maybe<ProductShipping>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  shop?: Maybe<Shop>;
  categories: Array<Category>;
};


export type ProductShopArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneShopInput>;
};


export type ProductCategoriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyCategoryInput>;
};

/** A connection to a list of items. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Information to aid in pagination. */
  edges: Array<ProductEdge>;
};

export type ProductDescriptionImages = {
  __typename?: 'ProductDescriptionImages';
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductDescriptionImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** The item at the end of the edge */
  node: Product;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProductImages = {
  __typename?: 'ProductImages';
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductPricing = {
  __typename?: 'ProductPricing';
  retail: Scalars['Float'];
  discount: Scalars['Float'];
  tax: Scalars['Float'];
};

export type ProductPricingInput = {
  retail: Scalars['Float'];
  discount: Scalars['Float'];
  tax: Scalars['Float'];
};

export type ProductShipping = {
  __typename?: 'ProductShipping';
  weight: Scalars['Float'];
  dimension?: Maybe<ProductShippingDimension>;
};

export type ProductShippingDimension = {
  __typename?: 'ProductShippingDimension';
  width: Scalars['Float'];
  height: Scalars['Float'];
  depth: Scalars['Float'];
};

export type ProductShippingDimensionInput = {
  width: Scalars['Float'];
  height: Scalars['Float'];
  depth: Scalars['Float'];
};

export type ProductShippingInput = {
  weight: Scalars['Float'];
  dimension?: Maybe<ProductShippingDimensionInput>;
};

export type PublicCategory = {
  __typename?: 'PublicCategory';
  name: Scalars['String'];
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId: Scalars['MongoID'];
  sku?: Maybe<Scalars['String']>;
  shortDesc: Scalars['String'];
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<ProductImages>>>;
  descriptionImages?: Maybe<Array<Maybe<ProductDescriptionImages>>>;
  active: Scalars['Boolean'];
  status: EnumProductStatus;
  stock?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<ProductPricing>;
  shipping?: Maybe<ProductShipping>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  products: Array<PublicProduct>;
};


export type PublicCategoryProductsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyPublicProductCustomFindInput>;
};

export type PublicMutation = {
  __typename?: 'PublicMutation';
  /** Create one document with mongoose defaults, setters, hooks and validation */
  consumerCreate?: Maybe<CreateOneConsumerPayload>;
  logout: Scalars['Boolean'];
  awsUpload?: Maybe<AwsUploadPayload>;
  /** Send OTP using different methods */
  otp?: Maybe<Otp>;
  /** Login with different methods */
  login?: Maybe<Login>;
};


export type PublicMutationConsumerCreateArgs = {
  record: CreateOneConsumerInput;
};


export type PublicMutationAwsUploadArgs = {
  record?: Maybe<AwsUploadInput>;
};

export type PublicProduct = {
  __typename?: 'PublicProduct';
  _id: Scalars['MongoID'];
  active: Scalars['Boolean'];
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  descriptionImages?: Maybe<Array<Maybe<ProductDescriptionImages>>>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<ProductImages>>>;
  longDesc?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pricing?: Maybe<ProductPricing>;
  shipping?: Maybe<ProductShipping>;
  shopId: Scalars['MongoID'];
  shortDesc: Scalars['String'];
  show?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  status: EnumProductStatus;
  stock?: Maybe<Scalars['Float']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  url: Scalars['String'];
};

export type PublicQuery = {
  __typename?: 'PublicQuery';
  products: Array<PublicProduct>;
  product?: Maybe<PublicProduct>;
  shops: Array<PublicShop>;
  shop?: Maybe<PublicShop>;
  systemVersion: Array<SystemVersion>;
  /** Check existance of certain field in db */
  isExist?: Maybe<Exist>;
};


export type PublicQueryProductsArgs = {
  filter?: Maybe<FilterFindManyPublicProductCustomFindInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyPublicProductCustomFindInput>;
};


export type PublicQueryProductArgs = {
  filter?: Maybe<FilterFindOnePublicProductCustomFindInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOnePublicProductCustomFindInput>;
};


export type PublicQueryShopsArgs = {
  filter?: Maybe<FilterFindManyPublicShopCustomFindInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyPublicShopCustomFindInput>;
};


export type PublicQueryShopArgs = {
  filter?: Maybe<FilterFindOnePublicShopCustomFindInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOnePublicShopCustomFindInput>;
};


export type PublicQuerySystemVersionArgs = {
  filter?: Maybe<FilterFindManySystemVersionInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManySystemVersionInput>;
};

export type PublicShop = {
  __typename?: 'PublicShop';
  _id: Scalars['MongoID'];
  active: Scalars['Boolean'];
  address?: Maybe<Address>;
  blogs: Array<Blog>;
  categories: Array<PublicCategory>;
  coverImage: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  fax?: Maybe<Scalars['String']>;
  logo: Scalars['String'];
  merchantId: Scalars['MongoID'];
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  myPenefitRewards?: Maybe<PenefitRewardCard>;
  name: Scalars['String'];
  optOutDelivery: Scalars['Boolean'];
  orderLocal?: Maybe<T_OrderLocal>;
  phone?: Maybe<Scalars['String']>;
  products: Array<PublicProduct>;
  show?: Maybe<Scalars['Boolean']>;
  status: EnumShopStatus;
  type: EnumShopType;
  updatedAt?: Maybe<Scalars['Date']>;
  url: Scalars['String'];
  whatsapp_number: Scalars['String'];
};


export type PublicShopBlogsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyBlogInput>;
};


export type PublicShopCategoriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyPublicCategoryCustomFindInput>;
};


export type PublicShopProductsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyPublicProductCustomFindInput>;
};

export type Query = {
  __typename?: 'Query';
  consumer?: Maybe<ConsumerQuery>;
  employee?: Maybe<EmployeeQuery>;
  getCacheVersion?: Maybe<T_CacheVersion>;
  getSetting?: Maybe<T_Setting>;
  lastShopId?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantQuery>;
  public?: Maybe<PublicQuery>;
  rider?: Maybe<RiderQuery>;
};


export type RemoveOneBlogPayload = {
  __typename?: 'RemoveOneBlogPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Blog>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneCategoryPayload = {
  __typename?: 'RemoveOneCategoryPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Category>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneConsumerPayload = {
  __typename?: 'RemoveOneConsumerPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Consumer>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneDeliveryOrderPayload = {
  __typename?: 'RemoveOneDeliveryOrderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<DeliveryOrder>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneLikePayload = {
  __typename?: 'RemoveOneLikePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Like>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneMerchantPayload = {
  __typename?: 'RemoveOneMerchantPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Merchant>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneOrderPayload = {
  __typename?: 'RemoveOneOrderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Order>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneProductPayload = {
  __typename?: 'RemoveOneProductPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Product>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneRiderPayload = {
  __typename?: 'RemoveOneRiderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Rider>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneShopPayload = {
  __typename?: 'RemoveOneShopPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Shop>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneSystemVersionPayload = {
  __typename?: 'RemoveOneSystemVersionPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<SystemVersion>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneTestSubscriptionPayload = {
  __typename?: 'RemoveOneTestSubscriptionPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<TestSubscription>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type Rider = {
  __typename?: 'Rider';
  username: Scalars['String'];
  password: Scalars['String'];
  type?: Maybe<EnumRiderType>;
  name: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  ic?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  lastLocation?: Maybe<Array<Maybe<RiderLastLocation>>>;
  phoneOtp: Scalars['String'];
  phoneVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  tokenVersion?: Maybe<Scalars['Float']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  activeDeliveryOrders?: Maybe<Array<Maybe<DeliveryOrder>>>;
};


export type RiderActiveDeliveryOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type RiderDoStatusUpdateInput = {
  status: EnumDeliveryOrderStatusName;
  description: Scalars['String'];
};

export type RiderLastLocation = {
  __typename?: 'RiderLastLocation';
  dateTime?: Maybe<Scalars['Date']>;
  location?: Maybe<AddressLocation>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RiderLastLocationInput = {
  dateTime?: Maybe<Scalars['Date']>;
  location?: Maybe<AddressLocationInput>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RiderMutation = {
  __typename?: 'RiderMutation';
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  meUpdate?: Maybe<UpdateByIdRiderPayload>;
  deliveryOrderAccept?: Maybe<StandardDeliveryOrderPayload>;
  deliveryOrderStatusUpdate?: Maybe<StandardDeliveryOrderPayload>;
  changePassword?: Maybe<StandardPayload>;
  updateLocation?: Maybe<StandardRiderPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  createTestSub?: Maybe<CreateOneTestSubscriptionPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  updateTestSub?: Maybe<UpdateByIdTestSubscriptionPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  removeTestSub?: Maybe<RemoveOneTestSubscriptionPayload>;
};


export type RiderMutationMeUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdRiderInput;
};


export type RiderMutationDeliveryOrderAcceptArgs = {
  _id: Scalars['MongoID'];
};


export type RiderMutationDeliveryOrderStatusUpdateArgs = {
  _id: Scalars['MongoID'];
  record?: Maybe<RiderDoStatusUpdateInput>;
};


export type RiderMutationChangePasswordArgs = {
  record?: Maybe<ChangePasswordInput>;
};


export type RiderMutationUpdateLocationArgs = {
  record?: Maybe<Array<RiderUpdateLocationInput>>;
};


export type RiderMutationCreateTestSubArgs = {
  record: CreateOneTestSubscriptionInput;
};


export type RiderMutationUpdateTestSubArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdTestSubscriptionInput;
};


export type RiderMutationRemoveTestSubArgs = {
  filter?: Maybe<FilterRemoveOneTestSubscriptionInput>;
  sort?: Maybe<SortRemoveOneTestSubscriptionInput>;
};

export type RiderQuery = {
  __typename?: 'RiderQuery';
  me?: Maybe<Rider>;
  getOpenDeliveryOrders?: Maybe<Array<Maybe<DeliveryOrder>>>;
  myDeliveryOrders: Array<DeliveryOrder>;
  getTestSubscription: Array<TestSubscription>;
};


export type RiderQueryMeArgs = {
  filter?: Maybe<FilterFindOneRiderInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRiderInput>;
};


export type RiderQueryMyDeliveryOrdersArgs = {
  filter?: Maybe<FilterFindManyDeliveryOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyDeliveryOrderInput>;
};


export type RiderQueryGetTestSubscriptionArgs = {
  filter?: Maybe<FilterFindManyTestSubscriptionInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyTestSubscriptionInput>;
};

export type RiderUpdateLocationInput = {
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  dateTime?: Maybe<Scalars['Date']>;
};

export type RuntimeError = ErrorInterface & {
  __typename?: 'RuntimeError';
  /** Runtime error message */
  message?: Maybe<Scalars['String']>;
};

export type SendEmailOtpInput = {
  email: Scalars['String'];
};

export type SendSmsOtpInput = {
  phone: Scalars['String'];
  channel: OtpChannelEnum;
};

export type Shop = {
  __typename?: 'Shop';
  merchantId: Scalars['MongoID'];
  type: EnumShopType;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  logo: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  whatsapp_number: Scalars['String'];
  coverImage: Scalars['String'];
  optOutDelivery: Scalars['Boolean'];
  description: Scalars['String'];
  status: EnumShopStatus;
  address?: Maybe<Address>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** If type consumer will targetId = merchantId , merchant type will targetId = consumerId */
  myPenefitRewards?: Maybe<PenefitRewardCard>;
  /** Only employee able to query this */
  merchant?: Maybe<Merchant>;
  categories: Array<Category>;
  products: Array<Product>;
  blogs: Array<Blog>;
  /** Only employee/merchant/consumer able to query this. If merchant query then will provide the default filters for only seeing their own orders. */
  orders: Array<Order>;
};


export type ShopMerchantArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneMerchantInput>;
};


export type ShopCategoriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyCategoryInput>;
};


export type ShopProductsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyProductInput>;
};


export type ShopBlogsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyBlogInput>;
};


export type ShopOrdersArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyOrderInput>;
};

export type ShopsByLocationInput = {
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  maxDistanceInKm?: Maybe<Scalars['Float']>;
};

export type Sms = {
  __typename?: 'Sms';
  /** Send sms for verification to a phone number with country code. */
  send?: Maybe<OtpPayload>;
  verify?: Maybe<OtpPayload>;
};


export type SmsSendArgs = {
  record?: Maybe<SendSmsOtpInput>;
};


export type SmsVerifyArgs = {
  record?: Maybe<VerifySmsOtpInput>;
};

export enum SortConnectionProductEnum {
  IdDesc = '_ID_DESC',
  IdAsc = '_ID_ASC'
}

export enum SortFindManyBlogInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  MerchantidAsc = 'MERCHANTID_ASC',
  MerchantidDesc = 'MERCHANTID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC'
}

export enum SortFindManyCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManyConsumerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC'
}

export enum SortFindManyConsumerPaymentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyDeliveryOrderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OrderidAsc = 'ORDERID_ASC',
  OrderidDesc = 'ORDERID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  DeliverdateAsc = 'DELIVERDATE_ASC',
  DeliverdateDesc = 'DELIVERDATE_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  Status_1Asc = 'STATUS__1_ASC',
  Status_1Desc = 'STATUS__1_DESC'
}

export enum SortFindManyEmployeeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export enum SortFindManyLikeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ConsumeridAsc = 'CONSUMERID_ASC',
  ConsumeridDesc = 'CONSUMERID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  TypeidAsc = 'TYPEID_ASC',
  TypeidDesc = 'TYPEID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  ShopidTypeidAsc = 'SHOPID__TYPEID_ASC',
  ShopidTypeidDesc = 'SHOPID__TYPEID_DESC'
}

export enum SortFindManyMerchantInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManyMerchantPenefitConsumerCustomFindPenefitInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC'
}

export enum SortFindManyOrderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  DisplayidAsc = 'DISPLAYID_ASC',
  DisplayidDesc = 'DISPLAYID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  OrderdateAsc = 'ORDERDATE_ASC',
  OrderdateDesc = 'ORDERDATE_DESC'
}

export enum SortFindManyProductInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC'
}

export enum SortFindManyPublicCategoryCustomFindInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManyPublicProductCustomFindInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC'
}

export enum SortFindManyPublicShopCustomFindInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  MerchantidAsc = 'MERCHANTID_ASC',
  MerchantidDesc = 'MERCHANTID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

export enum SortFindManyRiderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export enum SortFindManyShopInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  MerchantidAsc = 'MERCHANTID_ASC',
  MerchantidDesc = 'MERCHANTID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

export enum SortFindManySystemVersionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodeversionAsc = 'CODEVERSION_ASC',
  CodeversionDesc = 'CODEVERSION_DESC',
  CodeversionCacheversionAsc = 'CODEVERSION__CACHEVERSION_ASC',
  CodeversionCacheversionDesc = 'CODEVERSION__CACHEVERSION_DESC',
  CodeversionCacheversionTypeAsc = 'CODEVERSION__CACHEVERSION__TYPE_ASC',
  CodeversionCacheversionTypeDesc = 'CODEVERSION__CACHEVERSION__TYPE_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyTestSubscriptionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneBlogInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  MerchantidAsc = 'MERCHANTID_ASC',
  MerchantidDesc = 'MERCHANTID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC'
}

export enum SortFindOneCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneConsumerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC'
}

export enum SortFindOneConsumerPaymentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneDeliveryOrderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OrderidAsc = 'ORDERID_ASC',
  OrderidDesc = 'ORDERID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  DeliverdateAsc = 'DELIVERDATE_ASC',
  DeliverdateDesc = 'DELIVERDATE_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  Status_1Asc = 'STATUS__1_ASC',
  Status_1Desc = 'STATUS__1_DESC'
}

export enum SortFindOneEmployeeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export enum SortFindOneMerchantInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneOrderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  DisplayidAsc = 'DISPLAYID_ASC',
  DisplayidDesc = 'DISPLAYID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  OrderdateAsc = 'ORDERDATE_ASC',
  OrderdateDesc = 'ORDERDATE_DESC'
}

export enum SortFindOneProductInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC'
}

export enum SortFindOnePublicProductCustomFindInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC'
}

export enum SortFindOnePublicShopCustomFindInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  MerchantidAsc = 'MERCHANTID_ASC',
  MerchantidDesc = 'MERCHANTID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

export enum SortFindOneRiderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export enum SortFindOneShopInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  MerchantidAsc = 'MERCHANTID_ASC',
  MerchantidDesc = 'MERCHANTID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

export enum SortRemoveOneBlogInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  MerchantidAsc = 'MERCHANTID_ASC',
  MerchantidDesc = 'MERCHANTID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC'
}

export enum SortRemoveOneCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortRemoveOneConsumerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC'
}

export enum SortRemoveOneDeliveryOrderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OrderidAsc = 'ORDERID_ASC',
  OrderidDesc = 'ORDERID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  DeliverdateAsc = 'DELIVERDATE_ASC',
  DeliverdateDesc = 'DELIVERDATE_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  Status_1Asc = 'STATUS__1_ASC',
  Status_1Desc = 'STATUS__1_DESC'
}

export enum SortRemoveOneLikeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ConsumeridAsc = 'CONSUMERID_ASC',
  ConsumeridDesc = 'CONSUMERID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  TypeidAsc = 'TYPEID_ASC',
  TypeidDesc = 'TYPEID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  ShopidTypeidAsc = 'SHOPID__TYPEID_ASC',
  ShopidTypeidDesc = 'SHOPID__TYPEID_DESC'
}

export enum SortRemoveOneMerchantInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortRemoveOneOrderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  DisplayidAsc = 'DISPLAYID_ASC',
  DisplayidDesc = 'DISPLAYID_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC',
  OrderdateAsc = 'ORDERDATE_ASC',
  OrderdateDesc = 'ORDERDATE_DESC'
}

export enum SortRemoveOneProductInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ShopidAsc = 'SHOPID_ASC',
  ShopidDesc = 'SHOPID_DESC'
}

export enum SortRemoveOneRiderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export enum SortRemoveOneShopInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  MerchantidAsc = 'MERCHANTID_ASC',
  MerchantidDesc = 'MERCHANTID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

export enum SortRemoveOneSystemVersionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodeversionAsc = 'CODEVERSION_ASC',
  CodeversionDesc = 'CODEVERSION_DESC',
  CodeversionCacheversionAsc = 'CODEVERSION__CACHEVERSION_ASC',
  CodeversionCacheversionDesc = 'CODEVERSION__CACHEVERSION_DESC',
  CodeversionCacheversionTypeAsc = 'CODEVERSION__CACHEVERSION__TYPE_ASC',
  CodeversionCacheversionTypeDesc = 'CODEVERSION__CACHEVERSION__TYPE_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortRemoveOneTestSubscriptionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

/** Standard Payload for delivery order */
export type StandardDeliveryOrderPayload = {
  __typename?: 'StandardDeliveryOrderPayload';
  recordId?: Maybe<Scalars['MongoID']>;
  record?: Maybe<DeliveryOrder>;
};

/** Standard Payload for order */
export type StandardOrderPayload = {
  __typename?: 'StandardOrderPayload';
  recordId?: Maybe<Scalars['MongoID']>;
  record?: Maybe<Order>;
};

/** Standard Payload */
export type StandardPayload = {
  __typename?: 'StandardPayload';
  status: Scalars['Boolean'];
  message: Scalars['String'];
};

/** Standard Payload for delivery order */
export type StandardRiderPayload = {
  __typename?: 'StandardRiderPayload';
  recordId?: Maybe<Scalars['MongoID']>;
  record?: Maybe<Rider>;
};

/** Calculate order on server and send secret key to client for payment */
export type StripePaymentPayload = {
  __typename?: 'StripePaymentPayload';
  clientSecret: Scalars['String'];
  publicKey: Scalars['String'];
  totalAmount: Scalars['Float'];
};

export type Subscription = {
  __typename?: 'Subscription';
  deliveryOrderOpen?: Maybe<DeliveryOrder>;
  deliveryOrderUpdated?: Maybe<DeliveryOrder>;
  orderOpen?: Maybe<Order>;
  testSubscriptionCreated?: Maybe<TestSubscription>;
  testSubscriptionUpdated?: Maybe<TestSubscription>;
};


export type SubscriptionDeliveryOrderUpdatedArgs = {
  deliveryOrderId: Scalars['MongoID'];
};


export type SubscriptionOrderOpenArgs = {
  shopId: Scalars['MongoID'];
};

export type SystemVersion = {
  __typename?: 'SystemVersion';
  codeVersion: Scalars['String'];
  cacheVersion: Scalars['String'];
  type: EnumSystemVersionType;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type T_CacheVersion = {
  __typename?: 'T_CacheVersion';
  cacheVersion?: Maybe<Scalars['String']>;
};

export type T_OrderLocal = {
  __typename?: 'T_OrderLocal';
  _id: Scalars['MongoID'];
  type?: Maybe<EnumOrderType>;
  carts?: Maybe<Array<Maybe<OrderCarts>>>;
  totalQty?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  delivery?: Maybe<OrderDelivery>;
};

export type T_ProductsFilter = {
  shopId?: Maybe<Scalars['MongoID']>;
  categoryId?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
};

export type T_Setting = {
  __typename?: 'T_Setting';
  darkMode?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};

export type T_SettingInput = {
  darkMode?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};

export type TestSubscription = {
  __typename?: 'TestSubscription';
  name: Scalars['String'];
  type?: Maybe<EnumTestSubscriptionType>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdAddressInput = {
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<UpdateByIdAddressLocationInput>;
};

export type UpdateByIdAddressLocationInput = {
  type?: Maybe<EnumAddressLocationType>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdBlogContentSectionsInput = {
  type?: Maybe<EnumBlogContentSectionsType>;
  data?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdBlogInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  cardTitle?: Maybe<Scalars['String']>;
  cardText?: Maybe<Scalars['String']>;
  cardImageUrl?: Maybe<Scalars['String']>;
  contentHeader?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['MongoID']>;
  contentSections?: Maybe<Array<Maybe<UpdateByIdBlogContentSectionsInput>>>;
  status?: Maybe<EnumBlogStatus>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdBlogPayload = {
  __typename?: 'UpdateByIdBlogPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Blog>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdCategoryInput = {
  shopId?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdCategoryPayload = {
  __typename?: 'UpdateByIdCategoryPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Category>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdConsumerInput = {
  username?: Maybe<Scalars['String']>;
  type?: Maybe<EnumConsumerType>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  preferedPayment?: Maybe<EnumConsumerPreferedPayment>;
  addresses?: Maybe<Array<Maybe<UpdateByIdDeliveryAddressInput>>>;
  tokenVersion?: Maybe<Scalars['Float']>;
  penefitIntegration?: Maybe<UpdateByIdConsumerPenefitIntegrationInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdConsumerPayload = {
  __typename?: 'UpdateByIdConsumerPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Consumer>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdConsumerPenefitIntegrationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  posId?: Maybe<Scalars['String']>;
  penefitId?: Maybe<Scalars['String']>;
};

export type UpdateByIdDeliveryAddressInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  detailed_address?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<EnumDeliveryAddressCountry>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  location?: Maybe<UpdateByIdAddressLocationInput>;
};

export type UpdateByIdDeliveryOrderInput = {
  consumerId?: Maybe<Scalars['MongoID']>;
  orderId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  riderId?: Maybe<Scalars['MongoID']>;
  deliverTo?: Maybe<UpdateByIdDeliveryAddressInput>;
  deliverFrom?: Maybe<UpdateByIdDeliveryAddressInput>;
  deliverDate?: Maybe<Scalars['Date']>;
  type?: Maybe<EnumDeliveryOrderType>;
  date?: Maybe<Scalars['Date']>;
  status?: Maybe<Array<Maybe<UpdateByIdDeliveryOrderStatusInput>>>;
  distanceInKm?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdDeliveryOrderPayload = {
  __typename?: 'UpdateByIdDeliveryOrderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<DeliveryOrder>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdDeliveryOrderStatusInput = {
  name?: Maybe<EnumDeliveryOrderStatusName>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdEmployeeInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumEmployeeType>;
  tokenVersion?: Maybe<Scalars['Float']>;
  roles?: Maybe<Array<Maybe<EnumEmployeeRoles>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdEmployeePayload = {
  __typename?: 'UpdateByIdEmployeePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Employee>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdMerchantInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumMerchantType>;
  status?: Maybe<EnumMerchantStatus>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tokenVersion?: Maybe<Scalars['Float']>;
  salesRepId?: Maybe<Scalars['MongoID']>;
  penefitIntegration?: Maybe<UpdateByIdMerchantPenefitIntegrationInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdMerchantPayload = {
  __typename?: 'UpdateByIdMerchantPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Merchant>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdMerchantPenefitIntegrationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  xAppKey?: Maybe<Scalars['String']>;
  xAcctId?: Maybe<Scalars['String']>;
  xUsername?: Maybe<Scalars['String']>;
  xOutletKey?: Maybe<Scalars['String']>;
  xAuthToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdOrderCartsInput = {
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['MongoID']>;
  price?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdOrderDeliveryInput = {
  deliverFrom?: Maybe<UpdateByIdDeliveryAddressInput>;
  deliverTo?: Maybe<UpdateByIdDeliveryAddressInput>;
  option?: Maybe<EnumOrderDeliveryOption>;
  fee?: Maybe<Scalars['Float']>;
  timeFrom?: Maybe<Scalars['Date']>;
  timeTo?: Maybe<Scalars['Date']>;
  distanceInKm?: Maybe<Scalars['Float']>;
};

export type UpdateByIdOrderInput = {
  displayId?: Maybe<Scalars['String']>;
  consumerId?: Maybe<Scalars['MongoID']>;
  shopId?: Maybe<Scalars['MongoID']>;
  paymentId?: Maybe<Scalars['MongoID']>;
  deliveryOrderId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumOrderType>;
  status?: Maybe<EnumOrderStatus>;
  paymentStatus?: Maybe<EnumOrderPaymentStatus>;
  paymentType?: Maybe<EnumOrderPaymentType>;
  orderDate?: Maybe<Scalars['Date']>;
  tax?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  totalQty?: Maybe<Scalars['Float']>;
  delivery?: Maybe<UpdateByIdOrderDeliveryInput>;
  carts?: Maybe<Array<Maybe<UpdateByIdOrderCartsInput>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdOrderPayload = {
  __typename?: 'UpdateByIdOrderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Order>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdProductDescriptionImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateByIdProductImagesInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateByIdProductInput = {
  name?: Maybe<Scalars['String']>;
  categoriesId?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  shopId?: Maybe<Scalars['MongoID']>;
  sku?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<UpdateByIdProductImagesInput>>>;
  descriptionImages?: Maybe<Array<Maybe<UpdateByIdProductDescriptionImagesInput>>>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumProductStatus>;
  stock?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  unlimitedStock?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<UpdateByIdProductPricingInput>;
  shipping?: Maybe<UpdateByIdProductShippingInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdProductPayload = {
  __typename?: 'UpdateByIdProductPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Product>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdProductPricingInput = {
  retail?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

export type UpdateByIdProductShippingDimensionInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

export type UpdateByIdProductShippingInput = {
  weight?: Maybe<Scalars['Float']>;
  dimension?: Maybe<UpdateByIdProductShippingDimensionInput>;
};

export type UpdateByIdRiderInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<EnumRiderType>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ic?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  lastLocation?: Maybe<Array<Maybe<UpdateByIdRiderLastLocationInput>>>;
  phoneOtp?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<UpdateByIdAddressInput>;
  tokenVersion?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdRiderLastLocationInput = {
  dateTime?: Maybe<Scalars['Date']>;
  location?: Maybe<UpdateByIdAddressLocationInput>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdRiderPayload = {
  __typename?: 'UpdateByIdRiderPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Rider>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdShopInput = {
  merchantId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<EnumShopType>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  whatsapp_number?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  optOutDelivery?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumShopStatus>;
  address?: Maybe<UpdateByIdAddressInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdShopPayload = {
  __typename?: 'UpdateByIdShopPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Shop>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdSystemVersionInput = {
  codeVersion?: Maybe<Scalars['String']>;
  cacheVersion?: Maybe<Scalars['String']>;
  type?: Maybe<EnumSystemVersionType>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdSystemVersionPayload = {
  __typename?: 'UpdateByIdSystemVersionPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<SystemVersion>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdTestSubscriptionInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EnumTestSubscriptionType>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UpdateByIdTestSubscriptionPayload = {
  __typename?: 'UpdateByIdTestSubscriptionPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<TestSubscription>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UsingOtp = {
  __typename?: 'UsingOTP';
  byEmail?: Maybe<AuthPayload>;
  bySms?: Maybe<AuthPayload>;
};


export type UsingOtpByEmailArgs = {
  record?: Maybe<LoginOtpEmailInput>;
};


export type UsingOtpBySmsArgs = {
  record?: Maybe<LoginOtpSmsInput>;
};

export type UsingPassword = {
  __typename?: 'UsingPassword';
  byUsername?: Maybe<AuthPayload>;
  byEmail?: Maybe<AuthPayload>;
  /** Only available for consumer */
  byPhone?: Maybe<AuthPayload>;
};


export type UsingPasswordByUsernameArgs = {
  record?: Maybe<LoginPasswordUsernameInput>;
};


export type UsingPasswordByEmailArgs = {
  record?: Maybe<LoginPasswordEmailInput>;
};


export type UsingPasswordByPhoneArgs = {
  record?: Maybe<LoginPasswordPhoneInput>;
};

export type ValidationError = ErrorInterface & {
  __typename?: 'ValidationError';
  /** Combined error message from all validators */
  message?: Maybe<Scalars['String']>;
  /** List of validator errors */
  errors?: Maybe<Array<ValidatorError>>;
};

export type ValidatorError = {
  __typename?: 'ValidatorError';
  /** Validation error message */
  message?: Maybe<Scalars['String']>;
  /** Source of the validation error from the model path */
  path?: Maybe<Scalars['String']>;
  /** Field value which occurs the validation error */
  value?: Maybe<Scalars['JSON']>;
  /** Input record idx in array which occurs the validation error. This `idx` is useful for createMany operation. For singular operations it always be 0. For *Many operations `idx` represents record index in array received from user. */
  idx: Scalars['Int'];
};

export type VerifyEmailOtpInput = {
  email: Scalars['String'];
  code: Scalars['String'];
};

export type VerifySmsOtpInput = {
  phone: Scalars['String'];
  code: Scalars['String'];
};

export type GetCacheVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCacheVersionQuery = (
  { __typename?: 'Query' }
  & { getCacheVersion?: Maybe<(
    { __typename?: 'T_CacheVersion' }
    & Pick<T_CacheVersion, 'cacheVersion'>
  )> }
);

export type ClearCacheMutationVariables = Exact<{
  record?: Maybe<Scalars['Boolean']>;
}>;


export type ClearCacheMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clearCache'>
);

export type GetSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingQuery = (
  { __typename?: 'Query' }
  & { getSetting?: Maybe<(
    { __typename?: 'T_Setting' }
    & Pick<T_Setting, 'darkMode' | 'language'>
  )> }
);

export type SetSettingMutationVariables = Exact<{
  record?: Maybe<T_SettingInput>;
}>;


export type SetSettingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setSetting'>
);

export type SetShopsFilterMutationVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type SetShopsFilterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setShopsFilter'>
);

export type SetProductsFilterMutationVariables = Exact<{
  filter?: Maybe<T_ProductsFilter>;
}>;


export type SetProductsFilterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setProductsFilter'>
);

export type AddToCartMutationVariables = Exact<{
  shopUrl: Scalars['String'];
  cart?: Maybe<OrderLocalCartsInput>;
}>;


export type AddToCartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addProduct'>
);

export type RemoveFromCartMutationVariables = Exact<{
  shopUrl: Scalars['String'];
  cart?: Maybe<OrderLocalCartsInput>;
}>;


export type RemoveFromCartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeProduct'>
);

export type ClearCartsMutationVariables = Exact<{
  shopUrl: Scalars['String'];
}>;


export type ClearCartsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clearCarts'>
);

export type SetDeliveryAddressMutationVariables = Exact<{
  shopUrl?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
}>;


export type SetDeliveryAddressMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDeliveryAddress'>
);

export type SetDeliveryOptionFeeMutationVariables = Exact<{
  shopUrl: Scalars['String'];
  deliveryOption?: Maybe<Scalars['String']>;
  deliveryFee?: Maybe<Scalars['Float']>;
  deliveryDistance?: Maybe<Scalars['Float']>;
}>;


export type SetDeliveryOptionFeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDeliveryOptionFee'>
);

export type SetDeliveryTimeMutationVariables = Exact<{
  shopUrl: Scalars['String'];
  deliveryTimeFrom?: Maybe<Scalars['Date']>;
  deliveryTimeTo?: Maybe<Scalars['Date']>;
}>;


export type SetDeliveryTimeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDeliveryTime'>
);

export type SendEmailOtpMutationVariables = Exact<{
  record?: Maybe<SendEmailOtpInput>;
}>;


export type SendEmailOtpMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { otp?: Maybe<(
      { __typename?: 'Otp' }
      & { email?: Maybe<(
        { __typename?: 'Email' }
        & { send?: Maybe<(
          { __typename?: 'OtpPayload' }
          & Pick<OtpPayload, 'response'>
        )> }
      )> }
    )> }
  )> }
);

export type VerifyEmailOtpMutationVariables = Exact<{
  record?: Maybe<VerifyEmailOtpInput>;
}>;


export type VerifyEmailOtpMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { otp?: Maybe<(
      { __typename?: 'Otp' }
      & { email?: Maybe<(
        { __typename?: 'Email' }
        & { verify?: Maybe<(
          { __typename?: 'OtpPayload' }
          & Pick<OtpPayload, 'response'>
        )> }
      )> }
    )> }
  )> }
);

export type F_MeFragment = (
  { __typename?: 'Consumer' }
  & Pick<Consumer, '_id' | 'username' | 'name' | 'phone' | 'email' | 'preferedPayment'>
  & { addresses?: Maybe<Array<Maybe<(
    { __typename?: 'DeliveryAddress' }
    & Pick<DeliveryAddress, 'name' | 'phone' | 'detailed_address' | 'state' | 'city' | 'zipcode'>
    & { location?: Maybe<(
      { __typename?: 'AddressLocation' }
      & Pick<AddressLocation, 'type' | 'coordinates'>
    )> }
  )>>> }
);

export type F_ShopFragment = (
  { __typename?: 'PublicShop' }
  & Pick<PublicShop, '_id' | 'url' | 'name' | 'type' | 'description' | 'logo' | 'coverImage' | 'status'>
);

export type F_ProductFragment = (
  { __typename?: 'PublicProduct' }
  & Pick<PublicProduct, '_id' | 'name' | 'shortDesc' | 'longDesc' | 'imageUrl' | 'categoriesId' | 'status' | 'show' | 'stock' | 'unlimitedStock'>
  & { images?: Maybe<Array<Maybe<(
    { __typename?: 'ProductImages' }
    & Pick<ProductImages, 'url' | 'hidden'>
  )>>>, descriptionImages?: Maybe<Array<Maybe<(
    { __typename?: 'ProductDescriptionImages' }
    & Pick<ProductDescriptionImages, 'url' | 'hidden'>
  )>>>, pricing?: Maybe<(
    { __typename?: 'ProductPricing' }
    & Pick<ProductPricing, 'retail'>
  )> }
);

export type F_ProductsFragment = (
  { __typename?: 'PublicShop' }
  & { products: Array<(
    { __typename?: 'PublicProduct' }
    & F_ProductFragment
  )> }
);

export type F_BlogFragment = (
  { __typename?: 'Blog' }
  & Pick<Blog, '_id' | 'merchantId' | 'shopId' | 'cardImageUrl' | 'cardTitle' | 'cardText' | 'contentHeader' | 'status'>
  & { contentSections?: Maybe<Array<Maybe<(
    { __typename?: 'BlogContentSections' }
    & Pick<BlogContentSections, '_id' | 'type' | 'data'>
  )>>> }
);

export type F_BlogsFragment = (
  { __typename?: 'PublicShop' }
  & { blogs: Array<(
    { __typename?: 'Blog' }
    & F_BlogFragment
  )> }
);

export type F_OrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, '_id' | 'displayId' | 'orderDate' | 'type' | 'consumerId' | 'shopId' | 'totalQty' | 'subTotal' | 'tax' | 'paymentId' | 'paymentType' | 'paymentStatus' | 'deliveryOrderId' | 'status'>
  & { shop?: Maybe<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'url'>
  )>, carts: Array<Maybe<(
    { __typename?: 'OrderCarts' }
    & Pick<OrderCarts, 'name' | 'qty' | 'productId' | 'price'>
    & { cartProduct?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, '_id' | 'name' | 'imageUrl'>
    )> }
  )>>, delivery?: Maybe<(
    { __typename?: 'OrderDelivery' }
    & Pick<OrderDelivery, 'fee' | 'option' | 'timeFrom' | 'timeTo' | 'distanceInKm'>
    & { deliverTo?: Maybe<(
      { __typename?: 'DeliveryAddress' }
      & Pick<DeliveryAddress, 'name' | 'phone' | 'detailed_address' | 'state' | 'country' | 'city' | 'zipcode'>
      & { location?: Maybe<(
        { __typename?: 'AddressLocation' }
        & Pick<AddressLocation, 'type' | 'coordinates'>
      )> }
    )> }
  )>, deliveryOrder?: Maybe<(
    { __typename?: 'DeliveryOrder' }
    & { status?: Maybe<Array<Maybe<(
      { __typename?: 'DeliveryOrderStatus' }
      & Pick<DeliveryOrderStatus, 'name' | 'date'>
    )>>> }
  )> }
);

export type F_OrderLocalFragment = (
  { __typename?: 'T_OrderLocal' }
  & Pick<T_OrderLocal, '_id' | 'type' | 'totalQty' | 'subTotal'>
  & { carts?: Maybe<Array<Maybe<(
    { __typename?: 'OrderCarts' }
    & Pick<OrderCarts, 'name' | 'qty' | 'productId' | 'price'>
    & { cartProduct?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'imageUrl'>
    )> }
  )>>>, delivery?: Maybe<(
    { __typename?: 'OrderDelivery' }
    & Pick<OrderDelivery, 'fee' | 'option' | 'timeFrom' | 'timeTo' | 'distanceInKm'>
    & { deliverTo?: Maybe<(
      { __typename?: 'DeliveryAddress' }
      & Pick<DeliveryAddress, 'name' | 'phone' | 'detailed_address' | 'state' | 'country' | 'city' | 'zipcode'>
      & { location?: Maybe<(
        { __typename?: 'AddressLocation' }
        & Pick<AddressLocation, 'type' | 'coordinates'>
      )> }
    )> }
  )> }
);

export type F_LikeFragment = (
  { __typename?: 'Like' }
  & Pick<Like, '_id' | 'shopId' | 'type' | 'typeId'>
  & { likedProduct?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, '_id' | 'name' | 'imageUrl' | 'shortDesc' | 'unlimitedStock' | 'stock' | 'status'>
    & { pricing?: Maybe<(
      { __typename?: 'ProductPricing' }
      & Pick<ProductPricing, 'retail'>
    )> }
  )>, likedBlog?: Maybe<(
    { __typename?: 'Blog' }
    & Pick<Blog, '_id' | 'cardImageUrl' | 'cardTitle' | 'cardText'>
  )> }
);

export type SystemVersionQueryVariables = Exact<{
  filter?: Maybe<FilterFindManySystemVersionInput>;
}>;


export type SystemVersionQuery = (
  { __typename?: 'Query' }
  & { public?: Maybe<(
    { __typename?: 'PublicQuery' }
    & { systemVersion: Array<(
      { __typename?: 'SystemVersion' }
      & Pick<SystemVersion, '_id' | 'type' | 'codeVersion' | 'cacheVersion'>
    )> }
  )> }
);

export type ShopManyQueryVariables = Exact<{ [key: string]: never; }>;


export type ShopManyQuery = (
  { __typename?: 'Query' }
  & { public?: Maybe<(
    { __typename?: 'PublicQuery' }
    & { shops: Array<(
      { __typename?: 'PublicShop' }
      & Pick<PublicShop, 'show'>
      & F_ShopFragment
    )> }
  )> }
);

export type ShopOneQueryVariables = Exact<{
  filter?: Maybe<FilterFindOnePublicShopCustomFindInput>;
}>;


export type ShopOneQuery = (
  { __typename?: 'Query' }
  & { public?: Maybe<(
    { __typename?: 'PublicQuery' }
    & { shop?: Maybe<(
      { __typename?: 'PublicShop' }
      & Pick<PublicShop, 'phone' | 'whatsapp_number'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'detailed_address' | 'city' | 'zipcode' | 'state' | 'country'>
        & { location?: Maybe<(
          { __typename?: 'AddressLocation' }
          & Pick<AddressLocation, 'type' | 'coordinates'>
        )> }
      )>, categories: Array<(
        { __typename?: 'PublicCategory' }
        & Pick<PublicCategory, '_id' | 'name' | 'imageUrl'>
      )>, orderLocal?: Maybe<(
        { __typename?: 'T_OrderLocal' }
        & F_OrderLocalFragment
      )> }
      & F_ShopFragment
      & F_ProductsFragment
      & F_BlogsFragment
    )> }
  )> }
);

export type ConsumerOneQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsumerOneQuery = (
  { __typename?: 'Query' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerQuery' }
    & { me?: Maybe<(
      { __typename?: 'Consumer' }
      & { orders: Array<(
        { __typename?: 'Order' }
        & F_OrderFragment
      )> }
      & F_MeFragment
    )> }
  )> }
);

export type OrderManyQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderManyQuery = (
  { __typename?: 'Query' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerQuery' }
    & { orders: Array<(
      { __typename?: 'Order' }
      & F_OrderFragment
    )> }
  )> }
);

export type OrdersQueryVariables = Exact<{
  filter?: Maybe<FilterFindManyOrderInput>;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerQuery' }
    & { orders: Array<(
      { __typename?: 'Order' }
      & F_OrderFragment
    )> }
  )> }
);

export type OrderPaymentQueryVariables = Exact<{
  filter?: Maybe<FilterFindOneOrderInput>;
}>;


export type OrderPaymentQuery = (
  { __typename?: 'Query' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerQuery' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, '_id' | 'paymentType' | 'paymentStatus'>
      & { shop?: Maybe<(
        { __typename?: 'Shop' }
        & Pick<Shop, 'url'>
      )> }
    )> }
  )> }
);

export type ConsumerLikesQueryVariables = Exact<{
  filter?: Maybe<FilterFindManyLikeInput>;
}>;


export type ConsumerLikesQuery = (
  { __typename?: 'Query' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerQuery' }
    & { likes: Array<(
      { __typename?: 'Like' }
      & F_LikeFragment
    )> }
  )> }
);

export type UsernameExistQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UsernameExistQuery = (
  { __typename?: 'Query' }
  & { public?: Maybe<(
    { __typename?: 'PublicQuery' }
    & { isExist?: Maybe<(
      { __typename?: 'Exist' }
      & Pick<Exist, 'username'>
    )> }
  )> }
);

export type PhoneExistQueryVariables = Exact<{
  phone: Scalars['String'];
}>;


export type PhoneExistQuery = (
  { __typename?: 'Query' }
  & { public?: Maybe<(
    { __typename?: 'PublicQuery' }
    & { isExist?: Maybe<(
      { __typename?: 'Exist' }
      & Pick<Exist, 'phone'>
    )> }
  )> }
);

export type ConsumerUpdateMutationVariables = Exact<{
  id: Scalars['MongoID'];
  record: UpdateByIdConsumerInput;
}>;


export type ConsumerUpdateMutation = (
  { __typename?: 'Mutation' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerMutation' }
    & { meUpdate?: Maybe<(
      { __typename?: 'UpdateByIdConsumerPayload' }
      & { record?: Maybe<(
        { __typename?: 'Consumer' }
        & F_MeFragment
      )> }
    )> }
  )> }
);

export type CreateOrderMutationVariables = Exact<{
  record: CreateOneOrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerMutation' }
    & { orderCreate?: Maybe<(
      { __typename?: 'CreateOneOrderPayload' }
      & { record?: Maybe<(
        { __typename?: 'Order' }
        & Pick<Order, '_id' | 'status'>
      )> }
    )> }
  )> }
);

export type OrderUpdateMutationVariables = Exact<{
  id: Scalars['MongoID'];
  record: UpdateByIdOrderInput;
}>;


export type OrderUpdateMutation = (
  { __typename?: 'Mutation' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerMutation' }
    & { orderUpdate?: Maybe<(
      { __typename?: 'UpdateByIdOrderPayload' }
      & { record?: Maybe<(
        { __typename?: 'Order' }
        & Pick<Order, '_id' | 'status'>
      )> }
    )> }
  )> }
);

export type NewLikeMutationVariables = Exact<{
  record: CreateOneLikeInput;
}>;


export type NewLikeMutation = (
  { __typename?: 'Mutation' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerMutation' }
    & { like?: Maybe<(
      { __typename?: 'CreateOneLikePayload' }
      & Pick<CreateOneLikePayload, 'recordId'>
    )> }
  )> }
);

export type UnLikeMutationVariables = Exact<{
  filter: FilterRemoveOneLikeInput;
}>;


export type UnLikeMutation = (
  { __typename?: 'Mutation' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerMutation' }
    & { unliked?: Maybe<(
      { __typename?: 'RemoveOneLikePayload' }
      & Pick<RemoveOneLikePayload, 'recordId'>
    )> }
  )> }
);

export type LoginUsingPasswordUsernameMutationVariables = Exact<{
  record: LoginPasswordUsernameInput;
}>;


export type LoginUsingPasswordUsernameMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { login?: Maybe<(
      { __typename?: 'Login' }
      & { usingPassword?: Maybe<(
        { __typename?: 'UsingPassword' }
        & { byUsername?: Maybe<(
          { __typename?: 'AuthPayload' }
          & Pick<AuthPayload, 'accessToken' | 'refreshToken'>
        )> }
      )> }
    )> }
  )> }
);

export type LoginUsingPasswordPhoneMutationVariables = Exact<{
  record: LoginPasswordPhoneInput;
}>;


export type LoginUsingPasswordPhoneMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { login?: Maybe<(
      { __typename?: 'Login' }
      & { usingPassword?: Maybe<(
        { __typename?: 'UsingPassword' }
        & { byPhone?: Maybe<(
          { __typename?: 'AuthPayload' }
          & Pick<AuthPayload, 'accessToken' | 'refreshToken'>
        )> }
      )> }
    )> }
  )> }
);

export type LoginUsingPasswordEmailMutationVariables = Exact<{
  record: LoginPasswordEmailInput;
}>;


export type LoginUsingPasswordEmailMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { login?: Maybe<(
      { __typename?: 'Login' }
      & { usingPassword?: Maybe<(
        { __typename?: 'UsingPassword' }
        & { byEmail?: Maybe<(
          { __typename?: 'AuthPayload' }
          & Pick<AuthPayload, 'accessToken' | 'refreshToken'>
        )> }
      )> }
    )> }
  )> }
);

export type LoginUsingOtpPhoneMutationVariables = Exact<{
  record: LoginOtpSmsInput;
}>;


export type LoginUsingOtpPhoneMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { login?: Maybe<(
      { __typename?: 'Login' }
      & { usingOTP?: Maybe<(
        { __typename?: 'UsingOTP' }
        & { bySms?: Maybe<(
          { __typename?: 'AuthPayload' }
          & Pick<AuthPayload, 'accessToken' | 'refreshToken'>
        )> }
      )> }
    )> }
  )> }
);

export type LoginUsingOtpEmailMutationVariables = Exact<{
  record: LoginOtpEmailInput;
}>;


export type LoginUsingOtpEmailMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { login?: Maybe<(
      { __typename?: 'Login' }
      & { usingOTP?: Maybe<(
        { __typename?: 'UsingOTP' }
        & { byEmail?: Maybe<(
          { __typename?: 'AuthPayload' }
          & Pick<AuthPayload, 'accessToken' | 'refreshToken'>
        )> }
      )> }
    )> }
  )> }
);

export type SignUpMutationVariables = Exact<{
  record: CreateOneConsumerInput;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { consumerCreate?: Maybe<(
      { __typename?: 'CreateOneConsumerPayload' }
      & Pick<CreateOneConsumerPayload, 'recordId'>
    )> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  record?: Maybe<ChangePasswordInput>;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerMutation' }
    & { changePassword?: Maybe<(
      { __typename?: 'StandardPayload' }
      & Pick<StandardPayload, 'status' | 'message'>
    )> }
  )> }
);

export type StripePaymentMutationVariables = Exact<{
  record: PaymentInput;
}>;


export type StripePaymentMutation = (
  { __typename?: 'Mutation' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerMutation' }
    & { stripePayment?: Maybe<(
      { __typename?: 'StripePaymentPayload' }
      & Pick<StripePaymentPayload, 'clientSecret' | 'publicKey' | 'totalAmount'>
    )> }
  )> }
);

export type CashPaymentMutationVariables = Exact<{
  record: PaymentInput;
}>;


export type CashPaymentMutation = (
  { __typename?: 'Mutation' }
  & { consumer?: Maybe<(
    { __typename?: 'ConsumerMutation' }
    & { cashPayment?: Maybe<(
      { __typename?: 'CashPaymentPayload' }
      & Pick<CashPaymentPayload, 'totalAmount'>
    )> }
  )> }
);

export type SendSmsOtpMutationVariables = Exact<{
  record?: Maybe<SendSmsOtpInput>;
}>;


export type SendSmsOtpMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { otp?: Maybe<(
      { __typename?: 'Otp' }
      & { sms?: Maybe<(
        { __typename?: 'Sms' }
        & { send?: Maybe<(
          { __typename?: 'OtpPayload' }
          & Pick<OtpPayload, 'response'>
        )> }
      )> }
    )> }
  )> }
);

export type VerifySmsOtpMutationVariables = Exact<{
  record?: Maybe<VerifySmsOtpInput>;
}>;


export type VerifySmsOtpMutation = (
  { __typename?: 'Mutation' }
  & { public?: Maybe<(
    { __typename?: 'PublicMutation' }
    & { otp?: Maybe<(
      { __typename?: 'Otp' }
      & { sms?: Maybe<(
        { __typename?: 'Sms' }
        & { verify?: Maybe<(
          { __typename?: 'OtpPayload' }
          & Pick<OtpPayload, 'response'>
        )> }
      )> }
    )> }
  )> }
);

export const F_MeFragmentDoc = gql`
    fragment F_Me on Consumer {
  _id
  username
  name
  phone
  email
  addresses {
    name
    phone
    detailed_address
    state
    city
    zipcode
    location {
      type
      coordinates
    }
  }
  preferedPayment
}
    `;
export const F_ShopFragmentDoc = gql`
    fragment F_Shop on PublicShop {
  _id
  url
  name
  type
  description
  logo
  coverImage
  status
}
    `;
export const F_ProductFragmentDoc = gql`
    fragment F_Product on PublicProduct {
  _id
  name
  shortDesc
  longDesc
  imageUrl
  images {
    url
    hidden
  }
  descriptionImages {
    url
    hidden
  }
  categoriesId
  status
  show @client
  pricing {
    retail
  }
  stock
  unlimitedStock
}
    `;
export const F_ProductsFragmentDoc = gql`
    fragment F_Products on PublicShop {
  products {
    ...F_Product
  }
}
    ${F_ProductFragmentDoc}`;
export const F_BlogFragmentDoc = gql`
    fragment F_Blog on Blog {
  _id
  merchantId
  shopId
  cardImageUrl
  cardTitle
  cardText
  contentHeader
  contentSections {
    _id
    type
    data
  }
  status
}
    `;
export const F_BlogsFragmentDoc = gql`
    fragment F_Blogs on PublicShop {
  blogs {
    ...F_Blog
  }
}
    ${F_BlogFragmentDoc}`;
export const F_OrderFragmentDoc = gql`
    fragment F_Order on Order {
  _id
  displayId
  orderDate
  type
  consumerId
  shopId
  shop {
    url
  }
  carts {
    name
    qty
    productId
    price
    cartProduct {
      _id
      name
      imageUrl
    }
  }
  totalQty
  subTotal
  tax
  paymentId
  paymentType
  paymentStatus
  delivery {
    fee
    option
    deliverTo {
      name
      phone
      detailed_address
      state
      country
      city
      zipcode
      location {
        type
        coordinates
      }
    }
    timeFrom
    timeTo
    distanceInKm
  }
  deliveryOrderId
  deliveryOrder {
    status {
      name
      date
    }
  }
  status
}
    `;
export const F_OrderLocalFragmentDoc = gql`
    fragment F_OrderLocal on T_OrderLocal {
  _id
  type
  carts {
    name
    qty
    productId
    price
    cartProduct {
      imageUrl
    }
  }
  totalQty
  subTotal
  delivery {
    fee
    option
    deliverTo {
      name
      phone
      detailed_address
      state
      country
      city
      zipcode
      location {
        type
        coordinates
      }
    }
    timeFrom
    timeTo
    distanceInKm
  }
}
    `;
export const F_LikeFragmentDoc = gql`
    fragment F_Like on Like {
  _id
  shopId
  type
  typeId
  likedProduct {
    _id
    name
    imageUrl
    shortDesc
    unlimitedStock
    stock
    status
    pricing {
      retail
    }
  }
  likedBlog {
    _id
    cardImageUrl
    cardTitle
    cardText
  }
}
    `;
export const GetCacheVersionDocument = gql`
    query GetCacheVersion {
  getCacheVersion @client {
    cacheVersion
  }
}
    `;

/**
 * __useGetCacheVersionQuery__
 *
 * To run a query within a React component, call `useGetCacheVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCacheVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCacheVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCacheVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCacheVersionQuery, GetCacheVersionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCacheVersionQuery, GetCacheVersionQueryVariables>(GetCacheVersionDocument, baseOptions);
      }
export function useGetCacheVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCacheVersionQuery, GetCacheVersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCacheVersionQuery, GetCacheVersionQueryVariables>(GetCacheVersionDocument, baseOptions);
        }
export type GetCacheVersionQueryHookResult = ReturnType<typeof useGetCacheVersionQuery>;
export type GetCacheVersionLazyQueryHookResult = ReturnType<typeof useGetCacheVersionLazyQuery>;
export type GetCacheVersionQueryResult = ApolloReactCommon.QueryResult<GetCacheVersionQuery, GetCacheVersionQueryVariables>;
export const ClearCacheDocument = gql`
    mutation ClearCache($record: Boolean) {
  clearCache(record: $record) @client
}
    `;
export type ClearCacheMutationFn = ApolloReactCommon.MutationFunction<ClearCacheMutation, ClearCacheMutationVariables>;

/**
 * __useClearCacheMutation__
 *
 * To run a mutation, you first call `useClearCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCacheMutation, { data, loading, error }] = useClearCacheMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useClearCacheMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearCacheMutation, ClearCacheMutationVariables>) {
        return ApolloReactHooks.useMutation<ClearCacheMutation, ClearCacheMutationVariables>(ClearCacheDocument, baseOptions);
      }
export type ClearCacheMutationHookResult = ReturnType<typeof useClearCacheMutation>;
export type ClearCacheMutationResult = ApolloReactCommon.MutationResult<ClearCacheMutation>;
export type ClearCacheMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearCacheMutation, ClearCacheMutationVariables>;
export const GetSettingDocument = gql`
    query GetSetting {
  getSetting @client {
    darkMode
    language
  }
}
    `;

/**
 * __useGetSettingQuery__
 *
 * To run a query within a React component, call `useGetSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSettingQuery, GetSettingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSettingQuery, GetSettingQueryVariables>(GetSettingDocument, baseOptions);
      }
export function useGetSettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSettingQuery, GetSettingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSettingQuery, GetSettingQueryVariables>(GetSettingDocument, baseOptions);
        }
export type GetSettingQueryHookResult = ReturnType<typeof useGetSettingQuery>;
export type GetSettingLazyQueryHookResult = ReturnType<typeof useGetSettingLazyQuery>;
export type GetSettingQueryResult = ApolloReactCommon.QueryResult<GetSettingQuery, GetSettingQueryVariables>;
export const SetSettingDocument = gql`
    mutation SetSetting($record: T_SettingInput) {
  setSetting(record: $record) @client
}
    `;
export type SetSettingMutationFn = ApolloReactCommon.MutationFunction<SetSettingMutation, SetSettingMutationVariables>;

/**
 * __useSetSettingMutation__
 *
 * To run a mutation, you first call `useSetSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSettingMutation, { data, loading, error }] = useSetSettingMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useSetSettingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetSettingMutation, SetSettingMutationVariables>) {
        return ApolloReactHooks.useMutation<SetSettingMutation, SetSettingMutationVariables>(SetSettingDocument, baseOptions);
      }
export type SetSettingMutationHookResult = ReturnType<typeof useSetSettingMutation>;
export type SetSettingMutationResult = ApolloReactCommon.MutationResult<SetSettingMutation>;
export type SetSettingMutationOptions = ApolloReactCommon.BaseMutationOptions<SetSettingMutation, SetSettingMutationVariables>;
export const SetShopsFilterDocument = gql`
    mutation SetShopsFilter($filter: String) {
  setShopsFilter(filter: $filter) @client
}
    `;
export type SetShopsFilterMutationFn = ApolloReactCommon.MutationFunction<SetShopsFilterMutation, SetShopsFilterMutationVariables>;

/**
 * __useSetShopsFilterMutation__
 *
 * To run a mutation, you first call `useSetShopsFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShopsFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShopsFilterMutation, { data, loading, error }] = useSetShopsFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSetShopsFilterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetShopsFilterMutation, SetShopsFilterMutationVariables>) {
        return ApolloReactHooks.useMutation<SetShopsFilterMutation, SetShopsFilterMutationVariables>(SetShopsFilterDocument, baseOptions);
      }
export type SetShopsFilterMutationHookResult = ReturnType<typeof useSetShopsFilterMutation>;
export type SetShopsFilterMutationResult = ApolloReactCommon.MutationResult<SetShopsFilterMutation>;
export type SetShopsFilterMutationOptions = ApolloReactCommon.BaseMutationOptions<SetShopsFilterMutation, SetShopsFilterMutationVariables>;
export const SetProductsFilterDocument = gql`
    mutation SetProductsFilter($filter: T_ProductsFilter) {
  setProductsFilter(filter: $filter) @client
}
    `;
export type SetProductsFilterMutationFn = ApolloReactCommon.MutationFunction<SetProductsFilterMutation, SetProductsFilterMutationVariables>;

/**
 * __useSetProductsFilterMutation__
 *
 * To run a mutation, you first call `useSetProductsFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProductsFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProductsFilterMutation, { data, loading, error }] = useSetProductsFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSetProductsFilterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetProductsFilterMutation, SetProductsFilterMutationVariables>) {
        return ApolloReactHooks.useMutation<SetProductsFilterMutation, SetProductsFilterMutationVariables>(SetProductsFilterDocument, baseOptions);
      }
export type SetProductsFilterMutationHookResult = ReturnType<typeof useSetProductsFilterMutation>;
export type SetProductsFilterMutationResult = ApolloReactCommon.MutationResult<SetProductsFilterMutation>;
export type SetProductsFilterMutationOptions = ApolloReactCommon.BaseMutationOptions<SetProductsFilterMutation, SetProductsFilterMutationVariables>;
export const AddToCartDocument = gql`
    mutation AddToCart($shopUrl: String!, $cart: OrderLocalCartsInput) {
  addProduct(shopUrl: $shopUrl, cart: $cart) @client
}
    `;
export type AddToCartMutationFn = ApolloReactCommon.MutationFunction<AddToCartMutation, AddToCartMutationVariables>;

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      shopUrl: // value for 'shopUrl'
 *      cart: // value for 'cart'
 *   },
 * });
 */
export function useAddToCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddToCartMutation, AddToCartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddToCartMutation, AddToCartMutationVariables>(AddToCartDocument, baseOptions);
      }
export type AddToCartMutationHookResult = ReturnType<typeof useAddToCartMutation>;
export type AddToCartMutationResult = ApolloReactCommon.MutationResult<AddToCartMutation>;
export type AddToCartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddToCartMutation, AddToCartMutationVariables>;
export const RemoveFromCartDocument = gql`
    mutation RemoveFromCart($shopUrl: String!, $cart: OrderLocalCartsInput) {
  removeProduct(shopUrl: $shopUrl, cart: $cart) @client
}
    `;
export type RemoveFromCartMutationFn = ApolloReactCommon.MutationFunction<RemoveFromCartMutation, RemoveFromCartMutationVariables>;

/**
 * __useRemoveFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCartMutation, { data, loading, error }] = useRemoveFromCartMutation({
 *   variables: {
 *      shopUrl: // value for 'shopUrl'
 *      cart: // value for 'cart'
 *   },
 * });
 */
export function useRemoveFromCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFromCartMutation, RemoveFromCartMutationVariables>(RemoveFromCartDocument, baseOptions);
      }
export type RemoveFromCartMutationHookResult = ReturnType<typeof useRemoveFromCartMutation>;
export type RemoveFromCartMutationResult = ApolloReactCommon.MutationResult<RemoveFromCartMutation>;
export type RemoveFromCartMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>;
export const ClearCartsDocument = gql`
    mutation ClearCarts($shopUrl: String!) {
  clearCarts(shopUrl: $shopUrl) @client
}
    `;
export type ClearCartsMutationFn = ApolloReactCommon.MutationFunction<ClearCartsMutation, ClearCartsMutationVariables>;

/**
 * __useClearCartsMutation__
 *
 * To run a mutation, you first call `useClearCartsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCartsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCartsMutation, { data, loading, error }] = useClearCartsMutation({
 *   variables: {
 *      shopUrl: // value for 'shopUrl'
 *   },
 * });
 */
export function useClearCartsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearCartsMutation, ClearCartsMutationVariables>) {
        return ApolloReactHooks.useMutation<ClearCartsMutation, ClearCartsMutationVariables>(ClearCartsDocument, baseOptions);
      }
export type ClearCartsMutationHookResult = ReturnType<typeof useClearCartsMutation>;
export type ClearCartsMutationResult = ApolloReactCommon.MutationResult<ClearCartsMutation>;
export type ClearCartsMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearCartsMutation, ClearCartsMutationVariables>;
export const SetDeliveryAddressDocument = gql`
    mutation SetDeliveryAddress($shopUrl: String, $address: AddressInput) {
  setDeliveryAddress(shopUrl: $shopUrl, address: $address) @client
}
    `;
export type SetDeliveryAddressMutationFn = ApolloReactCommon.MutationFunction<SetDeliveryAddressMutation, SetDeliveryAddressMutationVariables>;

/**
 * __useSetDeliveryAddressMutation__
 *
 * To run a mutation, you first call `useSetDeliveryAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeliveryAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeliveryAddressMutation, { data, loading, error }] = useSetDeliveryAddressMutation({
 *   variables: {
 *      shopUrl: // value for 'shopUrl'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useSetDeliveryAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDeliveryAddressMutation, SetDeliveryAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDeliveryAddressMutation, SetDeliveryAddressMutationVariables>(SetDeliveryAddressDocument, baseOptions);
      }
export type SetDeliveryAddressMutationHookResult = ReturnType<typeof useSetDeliveryAddressMutation>;
export type SetDeliveryAddressMutationResult = ApolloReactCommon.MutationResult<SetDeliveryAddressMutation>;
export type SetDeliveryAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDeliveryAddressMutation, SetDeliveryAddressMutationVariables>;
export const SetDeliveryOptionFeeDocument = gql`
    mutation SetDeliveryOptionFee($shopUrl: String!, $deliveryOption: String, $deliveryFee: Float, $deliveryDistance: Float) {
  setDeliveryOptionFee(
    shopUrl: $shopUrl
    deliveryOption: $deliveryOption
    deliveryFee: $deliveryFee
    deliveryDistance: $deliveryDistance
  ) @client
}
    `;
export type SetDeliveryOptionFeeMutationFn = ApolloReactCommon.MutationFunction<SetDeliveryOptionFeeMutation, SetDeliveryOptionFeeMutationVariables>;

/**
 * __useSetDeliveryOptionFeeMutation__
 *
 * To run a mutation, you first call `useSetDeliveryOptionFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeliveryOptionFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeliveryOptionFeeMutation, { data, loading, error }] = useSetDeliveryOptionFeeMutation({
 *   variables: {
 *      shopUrl: // value for 'shopUrl'
 *      deliveryOption: // value for 'deliveryOption'
 *      deliveryFee: // value for 'deliveryFee'
 *      deliveryDistance: // value for 'deliveryDistance'
 *   },
 * });
 */
export function useSetDeliveryOptionFeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDeliveryOptionFeeMutation, SetDeliveryOptionFeeMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDeliveryOptionFeeMutation, SetDeliveryOptionFeeMutationVariables>(SetDeliveryOptionFeeDocument, baseOptions);
      }
export type SetDeliveryOptionFeeMutationHookResult = ReturnType<typeof useSetDeliveryOptionFeeMutation>;
export type SetDeliveryOptionFeeMutationResult = ApolloReactCommon.MutationResult<SetDeliveryOptionFeeMutation>;
export type SetDeliveryOptionFeeMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDeliveryOptionFeeMutation, SetDeliveryOptionFeeMutationVariables>;
export const SetDeliveryTimeDocument = gql`
    mutation SetDeliveryTime($shopUrl: String!, $deliveryTimeFrom: Date, $deliveryTimeTo: Date) {
  setDeliveryTime(
    shopUrl: $shopUrl
    deliveryTimeFrom: $deliveryTimeFrom
    deliveryTimeTo: $deliveryTimeTo
  ) @client
}
    `;
export type SetDeliveryTimeMutationFn = ApolloReactCommon.MutationFunction<SetDeliveryTimeMutation, SetDeliveryTimeMutationVariables>;

/**
 * __useSetDeliveryTimeMutation__
 *
 * To run a mutation, you first call `useSetDeliveryTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeliveryTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeliveryTimeMutation, { data, loading, error }] = useSetDeliveryTimeMutation({
 *   variables: {
 *      shopUrl: // value for 'shopUrl'
 *      deliveryTimeFrom: // value for 'deliveryTimeFrom'
 *      deliveryTimeTo: // value for 'deliveryTimeTo'
 *   },
 * });
 */
export function useSetDeliveryTimeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDeliveryTimeMutation, SetDeliveryTimeMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDeliveryTimeMutation, SetDeliveryTimeMutationVariables>(SetDeliveryTimeDocument, baseOptions);
      }
export type SetDeliveryTimeMutationHookResult = ReturnType<typeof useSetDeliveryTimeMutation>;
export type SetDeliveryTimeMutationResult = ApolloReactCommon.MutationResult<SetDeliveryTimeMutation>;
export type SetDeliveryTimeMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDeliveryTimeMutation, SetDeliveryTimeMutationVariables>;
export const SendEmailOtpDocument = gql`
    mutation SendEmailOTP($record: SendEmailOTPInput) {
  public {
    otp {
      email {
        send(record: $record) {
          response
        }
      }
    }
  }
}
    `;
export type SendEmailOtpMutationFn = ApolloReactCommon.MutationFunction<SendEmailOtpMutation, SendEmailOtpMutationVariables>;

/**
 * __useSendEmailOtpMutation__
 *
 * To run a mutation, you first call `useSendEmailOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailOtpMutation, { data, loading, error }] = useSendEmailOtpMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useSendEmailOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailOtpMutation, SendEmailOtpMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailOtpMutation, SendEmailOtpMutationVariables>(SendEmailOtpDocument, baseOptions);
      }
export type SendEmailOtpMutationHookResult = ReturnType<typeof useSendEmailOtpMutation>;
export type SendEmailOtpMutationResult = ApolloReactCommon.MutationResult<SendEmailOtpMutation>;
export type SendEmailOtpMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailOtpMutation, SendEmailOtpMutationVariables>;
export const VerifyEmailOtpDocument = gql`
    mutation VerifyEmailOTP($record: VerifyEmailOTPInput) {
  public {
    otp {
      email {
        verify(record: $record) {
          response
        }
      }
    }
  }
}
    `;
export type VerifyEmailOtpMutationFn = ApolloReactCommon.MutationFunction<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>;

/**
 * __useVerifyEmailOtpMutation__
 *
 * To run a mutation, you first call `useVerifyEmailOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailOtpMutation, { data, loading, error }] = useVerifyEmailOtpMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useVerifyEmailOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>(VerifyEmailOtpDocument, baseOptions);
      }
export type VerifyEmailOtpMutationHookResult = ReturnType<typeof useVerifyEmailOtpMutation>;
export type VerifyEmailOtpMutationResult = ApolloReactCommon.MutationResult<VerifyEmailOtpMutation>;
export type VerifyEmailOtpMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyEmailOtpMutation, VerifyEmailOtpMutationVariables>;
export const SystemVersionDocument = gql`
    query SystemVersion($filter: FilterFindManySystemVersionInput) {
  public {
    systemVersion(filter: $filter, sort: CREATEDAT_DESC, limit: 1) {
      _id
      type
      codeVersion
      cacheVersion
    }
  }
}
    `;

/**
 * __useSystemVersionQuery__
 *
 * To run a query within a React component, call `useSystemVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemVersionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSystemVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SystemVersionQuery, SystemVersionQueryVariables>) {
        return ApolloReactHooks.useQuery<SystemVersionQuery, SystemVersionQueryVariables>(SystemVersionDocument, baseOptions);
      }
export function useSystemVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemVersionQuery, SystemVersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SystemVersionQuery, SystemVersionQueryVariables>(SystemVersionDocument, baseOptions);
        }
export type SystemVersionQueryHookResult = ReturnType<typeof useSystemVersionQuery>;
export type SystemVersionLazyQueryHookResult = ReturnType<typeof useSystemVersionLazyQuery>;
export type SystemVersionQueryResult = ApolloReactCommon.QueryResult<SystemVersionQuery, SystemVersionQueryVariables>;
export const ShopManyDocument = gql`
    query ShopMany {
  public {
    shops {
      ...F_Shop
      show @client
    }
  }
}
    ${F_ShopFragmentDoc}`;

/**
 * __useShopManyQuery__
 *
 * To run a query within a React component, call `useShopManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopManyQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShopManyQuery, ShopManyQueryVariables>) {
        return ApolloReactHooks.useQuery<ShopManyQuery, ShopManyQueryVariables>(ShopManyDocument, baseOptions);
      }
export function useShopManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShopManyQuery, ShopManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShopManyQuery, ShopManyQueryVariables>(ShopManyDocument, baseOptions);
        }
export type ShopManyQueryHookResult = ReturnType<typeof useShopManyQuery>;
export type ShopManyLazyQueryHookResult = ReturnType<typeof useShopManyLazyQuery>;
export type ShopManyQueryResult = ApolloReactCommon.QueryResult<ShopManyQuery, ShopManyQueryVariables>;
export const ShopOneDocument = gql`
    query ShopOne($filter: FilterFindOnePublicShopCustomFindInput) {
  public {
    shop(filter: $filter) {
      ...F_Shop
      phone
      whatsapp_number
      address {
        detailed_address
        city
        zipcode
        state
        country
        location {
          type
          coordinates
        }
      }
      categories {
        _id
        name
        imageUrl
      }
      ...F_Products
      ...F_Blogs
      orderLocal @client {
        ...F_OrderLocal
      }
    }
  }
}
    ${F_ShopFragmentDoc}
${F_ProductsFragmentDoc}
${F_BlogsFragmentDoc}
${F_OrderLocalFragmentDoc}`;

/**
 * __useShopOneQuery__
 *
 * To run a query within a React component, call `useShopOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopOneQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useShopOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShopOneQuery, ShopOneQueryVariables>) {
        return ApolloReactHooks.useQuery<ShopOneQuery, ShopOneQueryVariables>(ShopOneDocument, baseOptions);
      }
export function useShopOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShopOneQuery, ShopOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShopOneQuery, ShopOneQueryVariables>(ShopOneDocument, baseOptions);
        }
export type ShopOneQueryHookResult = ReturnType<typeof useShopOneQuery>;
export type ShopOneLazyQueryHookResult = ReturnType<typeof useShopOneLazyQuery>;
export type ShopOneQueryResult = ApolloReactCommon.QueryResult<ShopOneQuery, ShopOneQueryVariables>;
export const ConsumerOneDocument = gql`
    query ConsumerOne {
  consumer {
    me {
      ...F_Me
      orders(sort: _ID_DESC) {
        ...F_Order
      }
    }
  }
}
    ${F_MeFragmentDoc}
${F_OrderFragmentDoc}`;

/**
 * __useConsumerOneQuery__
 *
 * To run a query within a React component, call `useConsumerOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerOneQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsumerOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConsumerOneQuery, ConsumerOneQueryVariables>) {
        return ApolloReactHooks.useQuery<ConsumerOneQuery, ConsumerOneQueryVariables>(ConsumerOneDocument, baseOptions);
      }
export function useConsumerOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConsumerOneQuery, ConsumerOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConsumerOneQuery, ConsumerOneQueryVariables>(ConsumerOneDocument, baseOptions);
        }
export type ConsumerOneQueryHookResult = ReturnType<typeof useConsumerOneQuery>;
export type ConsumerOneLazyQueryHookResult = ReturnType<typeof useConsumerOneLazyQuery>;
export type ConsumerOneQueryResult = ApolloReactCommon.QueryResult<ConsumerOneQuery, ConsumerOneQueryVariables>;
export const OrderManyDocument = gql`
    query OrderMany {
  consumer {
    orders(
      filter: {OR: [{status: open}, {status: confirmed}, {status: completed}, {status: cancelled}]}
      sort: ORDERDATE_DESC
    ) {
      ...F_Order
    }
  }
}
    ${F_OrderFragmentDoc}`;

/**
 * __useOrderManyQuery__
 *
 * To run a query within a React component, call `useOrderManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderManyQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderManyQuery, OrderManyQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderManyQuery, OrderManyQueryVariables>(OrderManyDocument, baseOptions);
      }
export function useOrderManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderManyQuery, OrderManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderManyQuery, OrderManyQueryVariables>(OrderManyDocument, baseOptions);
        }
export type OrderManyQueryHookResult = ReturnType<typeof useOrderManyQuery>;
export type OrderManyLazyQueryHookResult = ReturnType<typeof useOrderManyLazyQuery>;
export type OrderManyQueryResult = ApolloReactCommon.QueryResult<OrderManyQuery, OrderManyQueryVariables>;
export const OrdersDocument = gql`
    query Orders($filter: FilterFindManyOrderInput) {
  consumer {
    orders(filter: $filter, sort: ORDERDATE_DESC) {
      ...F_Order
    }
  }
}
    ${F_OrderFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = ApolloReactCommon.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderPaymentDocument = gql`
    query OrderPayment($filter: FilterFindOneOrderInput) {
  consumer {
    order(filter: $filter) {
      _id
      paymentType
      paymentStatus
      shop {
        url
      }
    }
  }
}
    `;

/**
 * __useOrderPaymentQuery__
 *
 * To run a query within a React component, call `useOrderPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderPaymentQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrderPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderPaymentQuery, OrderPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderPaymentQuery, OrderPaymentQueryVariables>(OrderPaymentDocument, baseOptions);
      }
export function useOrderPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderPaymentQuery, OrderPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderPaymentQuery, OrderPaymentQueryVariables>(OrderPaymentDocument, baseOptions);
        }
export type OrderPaymentQueryHookResult = ReturnType<typeof useOrderPaymentQuery>;
export type OrderPaymentLazyQueryHookResult = ReturnType<typeof useOrderPaymentLazyQuery>;
export type OrderPaymentQueryResult = ApolloReactCommon.QueryResult<OrderPaymentQuery, OrderPaymentQueryVariables>;
export const ConsumerLikesDocument = gql`
    query ConsumerLikes($filter: FilterFindManyLikeInput) {
  consumer {
    likes(filter: $filter) {
      ...F_Like
    }
  }
}
    ${F_LikeFragmentDoc}`;

/**
 * __useConsumerLikesQuery__
 *
 * To run a query within a React component, call `useConsumerLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerLikesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useConsumerLikesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConsumerLikesQuery, ConsumerLikesQueryVariables>) {
        return ApolloReactHooks.useQuery<ConsumerLikesQuery, ConsumerLikesQueryVariables>(ConsumerLikesDocument, baseOptions);
      }
export function useConsumerLikesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConsumerLikesQuery, ConsumerLikesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConsumerLikesQuery, ConsumerLikesQueryVariables>(ConsumerLikesDocument, baseOptions);
        }
export type ConsumerLikesQueryHookResult = ReturnType<typeof useConsumerLikesQuery>;
export type ConsumerLikesLazyQueryHookResult = ReturnType<typeof useConsumerLikesLazyQuery>;
export type ConsumerLikesQueryResult = ApolloReactCommon.QueryResult<ConsumerLikesQuery, ConsumerLikesQueryVariables>;
export const UsernameExistDocument = gql`
    query UsernameExist($username: String!) {
  public {
    isExist {
      username(username: $username)
    }
  }
}
    `;

/**
 * __useUsernameExistQuery__
 *
 * To run a query within a React component, call `useUsernameExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameExistQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsernameExistQuery, UsernameExistQueryVariables>) {
        return ApolloReactHooks.useQuery<UsernameExistQuery, UsernameExistQueryVariables>(UsernameExistDocument, baseOptions);
      }
export function useUsernameExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsernameExistQuery, UsernameExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsernameExistQuery, UsernameExistQueryVariables>(UsernameExistDocument, baseOptions);
        }
export type UsernameExistQueryHookResult = ReturnType<typeof useUsernameExistQuery>;
export type UsernameExistLazyQueryHookResult = ReturnType<typeof useUsernameExistLazyQuery>;
export type UsernameExistQueryResult = ApolloReactCommon.QueryResult<UsernameExistQuery, UsernameExistQueryVariables>;
export const PhoneExistDocument = gql`
    query PhoneExist($phone: String!) {
  public {
    isExist {
      phone(phone: $phone)
    }
  }
}
    `;

/**
 * __usePhoneExistQuery__
 *
 * To run a query within a React component, call `usePhoneExistQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneExistQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function usePhoneExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PhoneExistQuery, PhoneExistQueryVariables>) {
        return ApolloReactHooks.useQuery<PhoneExistQuery, PhoneExistQueryVariables>(PhoneExistDocument, baseOptions);
      }
export function usePhoneExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PhoneExistQuery, PhoneExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PhoneExistQuery, PhoneExistQueryVariables>(PhoneExistDocument, baseOptions);
        }
export type PhoneExistQueryHookResult = ReturnType<typeof usePhoneExistQuery>;
export type PhoneExistLazyQueryHookResult = ReturnType<typeof usePhoneExistLazyQuery>;
export type PhoneExistQueryResult = ApolloReactCommon.QueryResult<PhoneExistQuery, PhoneExistQueryVariables>;
export const ConsumerUpdateDocument = gql`
    mutation ConsumerUpdate($id: MongoID!, $record: UpdateByIdConsumerInput!) {
  consumer {
    meUpdate(_id: $id, record: $record) {
      record {
        ...F_Me
      }
    }
  }
}
    ${F_MeFragmentDoc}`;
export type ConsumerUpdateMutationFn = ApolloReactCommon.MutationFunction<ConsumerUpdateMutation, ConsumerUpdateMutationVariables>;

/**
 * __useConsumerUpdateMutation__
 *
 * To run a mutation, you first call `useConsumerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumerUpdateMutation, { data, loading, error }] = useConsumerUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      record: // value for 'record'
 *   },
 * });
 */
export function useConsumerUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConsumerUpdateMutation, ConsumerUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ConsumerUpdateMutation, ConsumerUpdateMutationVariables>(ConsumerUpdateDocument, baseOptions);
      }
export type ConsumerUpdateMutationHookResult = ReturnType<typeof useConsumerUpdateMutation>;
export type ConsumerUpdateMutationResult = ApolloReactCommon.MutationResult<ConsumerUpdateMutation>;
export type ConsumerUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ConsumerUpdateMutation, ConsumerUpdateMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($record: CreateOneOrderInput!) {
  consumer {
    orderCreate(record: $record) {
      record {
        _id
        status
      }
    }
  }
}
    `;
export type CreateOrderMutationFn = ApolloReactCommon.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = ApolloReactCommon.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const OrderUpdateDocument = gql`
    mutation OrderUpdate($id: MongoID!, $record: UpdateByIdOrderInput!) {
  consumer {
    orderUpdate(_id: $id, record: $record) {
      record {
        _id
        status
      }
    }
  }
}
    `;
export type OrderUpdateMutationFn = ApolloReactCommon.MutationFunction<OrderUpdateMutation, OrderUpdateMutationVariables>;

/**
 * __useOrderUpdateMutation__
 *
 * To run a mutation, you first call `useOrderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderUpdateMutation, { data, loading, error }] = useOrderUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      record: // value for 'record'
 *   },
 * });
 */
export function useOrderUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrderUpdateMutation, OrderUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OrderUpdateMutation, OrderUpdateMutationVariables>(OrderUpdateDocument, baseOptions);
      }
export type OrderUpdateMutationHookResult = ReturnType<typeof useOrderUpdateMutation>;
export type OrderUpdateMutationResult = ApolloReactCommon.MutationResult<OrderUpdateMutation>;
export type OrderUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OrderUpdateMutation, OrderUpdateMutationVariables>;
export const NewLikeDocument = gql`
    mutation NewLike($record: CreateOneLikeInput!) {
  consumer {
    like(record: $record) {
      recordId
    }
  }
}
    `;
export type NewLikeMutationFn = ApolloReactCommon.MutationFunction<NewLikeMutation, NewLikeMutationVariables>;

/**
 * __useNewLikeMutation__
 *
 * To run a mutation, you first call `useNewLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newLikeMutation, { data, loading, error }] = useNewLikeMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useNewLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewLikeMutation, NewLikeMutationVariables>) {
        return ApolloReactHooks.useMutation<NewLikeMutation, NewLikeMutationVariables>(NewLikeDocument, baseOptions);
      }
export type NewLikeMutationHookResult = ReturnType<typeof useNewLikeMutation>;
export type NewLikeMutationResult = ApolloReactCommon.MutationResult<NewLikeMutation>;
export type NewLikeMutationOptions = ApolloReactCommon.BaseMutationOptions<NewLikeMutation, NewLikeMutationVariables>;
export const UnLikeDocument = gql`
    mutation UnLike($filter: FilterRemoveOneLikeInput!) {
  consumer {
    unliked(filter: $filter) {
      recordId
    }
  }
}
    `;
export type UnLikeMutationFn = ApolloReactCommon.MutationFunction<UnLikeMutation, UnLikeMutationVariables>;

/**
 * __useUnLikeMutation__
 *
 * To run a mutation, you first call `useUnLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unLikeMutation, { data, loading, error }] = useUnLikeMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUnLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnLikeMutation, UnLikeMutationVariables>) {
        return ApolloReactHooks.useMutation<UnLikeMutation, UnLikeMutationVariables>(UnLikeDocument, baseOptions);
      }
export type UnLikeMutationHookResult = ReturnType<typeof useUnLikeMutation>;
export type UnLikeMutationResult = ApolloReactCommon.MutationResult<UnLikeMutation>;
export type UnLikeMutationOptions = ApolloReactCommon.BaseMutationOptions<UnLikeMutation, UnLikeMutationVariables>;
export const LoginUsingPasswordUsernameDocument = gql`
    mutation LoginUsingPasswordUsername($record: LoginPasswordUsernameInput!) {
  public {
    login {
      usingPassword {
        byUsername(record: $record) {
          accessToken
          refreshToken
        }
      }
    }
  }
}
    `;
export type LoginUsingPasswordUsernameMutationFn = ApolloReactCommon.MutationFunction<LoginUsingPasswordUsernameMutation, LoginUsingPasswordUsernameMutationVariables>;

/**
 * __useLoginUsingPasswordUsernameMutation__
 *
 * To run a mutation, you first call `useLoginUsingPasswordUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUsingPasswordUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUsingPasswordUsernameMutation, { data, loading, error }] = useLoginUsingPasswordUsernameMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useLoginUsingPasswordUsernameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUsingPasswordUsernameMutation, LoginUsingPasswordUsernameMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginUsingPasswordUsernameMutation, LoginUsingPasswordUsernameMutationVariables>(LoginUsingPasswordUsernameDocument, baseOptions);
      }
export type LoginUsingPasswordUsernameMutationHookResult = ReturnType<typeof useLoginUsingPasswordUsernameMutation>;
export type LoginUsingPasswordUsernameMutationResult = ApolloReactCommon.MutationResult<LoginUsingPasswordUsernameMutation>;
export type LoginUsingPasswordUsernameMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginUsingPasswordUsernameMutation, LoginUsingPasswordUsernameMutationVariables>;
export const LoginUsingPasswordPhoneDocument = gql`
    mutation LoginUsingPasswordPhone($record: LoginPasswordPhoneInput!) {
  public {
    login {
      usingPassword {
        byPhone(record: $record) {
          accessToken
          refreshToken
        }
      }
    }
  }
}
    `;
export type LoginUsingPasswordPhoneMutationFn = ApolloReactCommon.MutationFunction<LoginUsingPasswordPhoneMutation, LoginUsingPasswordPhoneMutationVariables>;

/**
 * __useLoginUsingPasswordPhoneMutation__
 *
 * To run a mutation, you first call `useLoginUsingPasswordPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUsingPasswordPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUsingPasswordPhoneMutation, { data, loading, error }] = useLoginUsingPasswordPhoneMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useLoginUsingPasswordPhoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUsingPasswordPhoneMutation, LoginUsingPasswordPhoneMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginUsingPasswordPhoneMutation, LoginUsingPasswordPhoneMutationVariables>(LoginUsingPasswordPhoneDocument, baseOptions);
      }
export type LoginUsingPasswordPhoneMutationHookResult = ReturnType<typeof useLoginUsingPasswordPhoneMutation>;
export type LoginUsingPasswordPhoneMutationResult = ApolloReactCommon.MutationResult<LoginUsingPasswordPhoneMutation>;
export type LoginUsingPasswordPhoneMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginUsingPasswordPhoneMutation, LoginUsingPasswordPhoneMutationVariables>;
export const LoginUsingPasswordEmailDocument = gql`
    mutation LoginUsingPasswordEmail($record: LoginPasswordEmailInput!) {
  public {
    login {
      usingPassword {
        byEmail(record: $record) {
          accessToken
          refreshToken
        }
      }
    }
  }
}
    `;
export type LoginUsingPasswordEmailMutationFn = ApolloReactCommon.MutationFunction<LoginUsingPasswordEmailMutation, LoginUsingPasswordEmailMutationVariables>;

/**
 * __useLoginUsingPasswordEmailMutation__
 *
 * To run a mutation, you first call `useLoginUsingPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUsingPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUsingPasswordEmailMutation, { data, loading, error }] = useLoginUsingPasswordEmailMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useLoginUsingPasswordEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUsingPasswordEmailMutation, LoginUsingPasswordEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginUsingPasswordEmailMutation, LoginUsingPasswordEmailMutationVariables>(LoginUsingPasswordEmailDocument, baseOptions);
      }
export type LoginUsingPasswordEmailMutationHookResult = ReturnType<typeof useLoginUsingPasswordEmailMutation>;
export type LoginUsingPasswordEmailMutationResult = ApolloReactCommon.MutationResult<LoginUsingPasswordEmailMutation>;
export type LoginUsingPasswordEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginUsingPasswordEmailMutation, LoginUsingPasswordEmailMutationVariables>;
export const LoginUsingOtpPhoneDocument = gql`
    mutation LoginUsingOtpPhone($record: LoginOtpSmsInput!) {
  public {
    login {
      usingOTP {
        bySms(record: $record) {
          accessToken
          refreshToken
        }
      }
    }
  }
}
    `;
export type LoginUsingOtpPhoneMutationFn = ApolloReactCommon.MutationFunction<LoginUsingOtpPhoneMutation, LoginUsingOtpPhoneMutationVariables>;

/**
 * __useLoginUsingOtpPhoneMutation__
 *
 * To run a mutation, you first call `useLoginUsingOtpPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUsingOtpPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUsingOtpPhoneMutation, { data, loading, error }] = useLoginUsingOtpPhoneMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useLoginUsingOtpPhoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUsingOtpPhoneMutation, LoginUsingOtpPhoneMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginUsingOtpPhoneMutation, LoginUsingOtpPhoneMutationVariables>(LoginUsingOtpPhoneDocument, baseOptions);
      }
export type LoginUsingOtpPhoneMutationHookResult = ReturnType<typeof useLoginUsingOtpPhoneMutation>;
export type LoginUsingOtpPhoneMutationResult = ApolloReactCommon.MutationResult<LoginUsingOtpPhoneMutation>;
export type LoginUsingOtpPhoneMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginUsingOtpPhoneMutation, LoginUsingOtpPhoneMutationVariables>;
export const LoginUsingOtpEmailDocument = gql`
    mutation LoginUsingOtpEmail($record: LoginOtpEmailInput!) {
  public {
    login {
      usingOTP {
        byEmail(record: $record) {
          accessToken
          refreshToken
        }
      }
    }
  }
}
    `;
export type LoginUsingOtpEmailMutationFn = ApolloReactCommon.MutationFunction<LoginUsingOtpEmailMutation, LoginUsingOtpEmailMutationVariables>;

/**
 * __useLoginUsingOtpEmailMutation__
 *
 * To run a mutation, you first call `useLoginUsingOtpEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUsingOtpEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUsingOtpEmailMutation, { data, loading, error }] = useLoginUsingOtpEmailMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useLoginUsingOtpEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUsingOtpEmailMutation, LoginUsingOtpEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginUsingOtpEmailMutation, LoginUsingOtpEmailMutationVariables>(LoginUsingOtpEmailDocument, baseOptions);
      }
export type LoginUsingOtpEmailMutationHookResult = ReturnType<typeof useLoginUsingOtpEmailMutation>;
export type LoginUsingOtpEmailMutationResult = ApolloReactCommon.MutationResult<LoginUsingOtpEmailMutation>;
export type LoginUsingOtpEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginUsingOtpEmailMutation, LoginUsingOtpEmailMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($record: CreateOneConsumerInput!) {
  public {
    consumerCreate(record: $record) {
      recordId
    }
  }
}
    `;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($record: ChangePasswordInput) {
  consumer {
    changePassword(record: $record) {
      status
      message
    }
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const StripePaymentDocument = gql`
    mutation StripePayment($record: PaymentInput!) {
  consumer {
    stripePayment(record: $record) {
      clientSecret
      publicKey
      totalAmount
    }
  }
}
    `;
export type StripePaymentMutationFn = ApolloReactCommon.MutationFunction<StripePaymentMutation, StripePaymentMutationVariables>;

/**
 * __useStripePaymentMutation__
 *
 * To run a mutation, you first call `useStripePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMutation, { data, loading, error }] = useStripePaymentMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useStripePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StripePaymentMutation, StripePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<StripePaymentMutation, StripePaymentMutationVariables>(StripePaymentDocument, baseOptions);
      }
export type StripePaymentMutationHookResult = ReturnType<typeof useStripePaymentMutation>;
export type StripePaymentMutationResult = ApolloReactCommon.MutationResult<StripePaymentMutation>;
export type StripePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<StripePaymentMutation, StripePaymentMutationVariables>;
export const CashPaymentDocument = gql`
    mutation CashPayment($record: PaymentInput!) {
  consumer {
    cashPayment(record: $record) {
      totalAmount
    }
  }
}
    `;
export type CashPaymentMutationFn = ApolloReactCommon.MutationFunction<CashPaymentMutation, CashPaymentMutationVariables>;

/**
 * __useCashPaymentMutation__
 *
 * To run a mutation, you first call `useCashPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCashPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cashPaymentMutation, { data, loading, error }] = useCashPaymentMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useCashPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CashPaymentMutation, CashPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CashPaymentMutation, CashPaymentMutationVariables>(CashPaymentDocument, baseOptions);
      }
export type CashPaymentMutationHookResult = ReturnType<typeof useCashPaymentMutation>;
export type CashPaymentMutationResult = ApolloReactCommon.MutationResult<CashPaymentMutation>;
export type CashPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CashPaymentMutation, CashPaymentMutationVariables>;
export const SendSmsOtpDocument = gql`
    mutation SendSmsOTP($record: SendSmsOTPInput) {
  public {
    otp {
      sms {
        send(record: $record) {
          response
        }
      }
    }
  }
}
    `;
export type SendSmsOtpMutationFn = ApolloReactCommon.MutationFunction<SendSmsOtpMutation, SendSmsOtpMutationVariables>;

/**
 * __useSendSmsOtpMutation__
 *
 * To run a mutation, you first call `useSendSmsOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsOtpMutation, { data, loading, error }] = useSendSmsOtpMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useSendSmsOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendSmsOtpMutation, SendSmsOtpMutationVariables>) {
        return ApolloReactHooks.useMutation<SendSmsOtpMutation, SendSmsOtpMutationVariables>(SendSmsOtpDocument, baseOptions);
      }
export type SendSmsOtpMutationHookResult = ReturnType<typeof useSendSmsOtpMutation>;
export type SendSmsOtpMutationResult = ApolloReactCommon.MutationResult<SendSmsOtpMutation>;
export type SendSmsOtpMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSmsOtpMutation, SendSmsOtpMutationVariables>;
export const VerifySmsOtpDocument = gql`
    mutation VerifySmsOTP($record: VerifySmsOTPInput) {
  public {
    otp {
      sms {
        verify(record: $record) {
          response
        }
      }
    }
  }
}
    `;
export type VerifySmsOtpMutationFn = ApolloReactCommon.MutationFunction<VerifySmsOtpMutation, VerifySmsOtpMutationVariables>;

/**
 * __useVerifySmsOtpMutation__
 *
 * To run a mutation, you first call `useVerifySmsOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifySmsOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifySmsOtpMutation, { data, loading, error }] = useVerifySmsOtpMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useVerifySmsOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifySmsOtpMutation, VerifySmsOtpMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifySmsOtpMutation, VerifySmsOtpMutationVariables>(VerifySmsOtpDocument, baseOptions);
      }
export type VerifySmsOtpMutationHookResult = ReturnType<typeof useVerifySmsOtpMutation>;
export type VerifySmsOtpMutationResult = ApolloReactCommon.MutationResult<VerifySmsOtpMutation>;
export type VerifySmsOtpMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifySmsOtpMutation, VerifySmsOtpMutationVariables>;