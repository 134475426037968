import React, { useState } from 'react';
import moment from 'moment';

import {
  IonModal, IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonContent, IonLabel,
  IonItem, IonCol, IonRow, IonImg, 
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

import { F_OrderFragment } from '../generated/graphql';

import DeliveryTrackingModal from './DeliveryTrackingModal';

import './OrderDetailsModal.css'

interface Props {
  isOpen: boolean
  setShowModal: (isOpen: boolean) => void
  // order: Order | undefined
  order: F_OrderFragment | undefined    // more tighten type by using F_OrderFragment
}

const statusForDelivery: any = {
  "open": { text: "Processing" },
  "confirmed": { text: "Delivering" },
  "completed": { text: "Received" },
  "cancelled": { text: "Cancelled" },
}

const statusForSelfPickup: any = {
  "open": { text: "Processing" },
  "confirmed": { text: "Ready to pickup" },
  "completed": { text: "Received" },
  "cancelled": { text: "Cancelled" },
}

const paymentMethod = {
  "cash": "Cash",
  "card": "Credit/Debit Card",
  "fpx": "FPX Online Banking"
}

const OrderDetailsModal: React.FC<Props> = ({ isOpen, setShowModal, order }) => {
  const [trackingOpen, showTracking] = useState(false);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setShowModal(false)}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => setShowModal(false)}>
            <IonIcon icon={chevronBack}></IonIcon>
          </IonButton>
          <IonTitle>Order Details</IonTitle>
        </IonToolbar>
      </IonHeader>

      {order &&
        <IonContent className="order-details">
          <IonItem lines="full">
            <IonCol>
              <IonRow className="ion-justify-content-between ion-align-items-center">
                <IonLabel><h2>{order.type === "delivery" ? "Deliver Service" : "Self Pickup"}</h2></IonLabel>
                {order?.status &&
                  <IonButton fill="outline" size="small" shape="round">
                    {order.type === "delivery" ? statusForDelivery[order.status].text : statusForSelfPickup[order.status].text}
                  </IonButton>}
              </IonRow>
              <IonLabel>
                <p>Order ID: {order._id}</p>
                <p>Placed on {moment(order.orderDate).calendar(undefined, { sameElse: 'DD/MM/YYYY' })}</p>
              </IonLabel>
            </IonCol>
          </IonItem>

          {order.type === "delivery" &&
            <IonItem lines="full">
              <IonCol>
                <IonRow className="ion-justify-content-between ion-align-items-center">
                  <IonLabel><h2>Delivery Address</h2></IonLabel>
                  <IonButton onClick={() => showTracking(true)}>Track Delivery</IonButton>
                </IonRow>
                <IonLabel>
                  <p>{order.delivery?.deliverTo?.name}</p>
                  <p>{order.delivery?.deliverTo?.phone}</p>
                  <p>{order.delivery?.deliverTo?.detailed_address}, {order.delivery?.deliverTo?.zipcode} {order.delivery?.deliverTo?.city}, {order.delivery?.deliverTo?.state}</p>
                </IonLabel>
              </IonCol>
            </IonItem>}

          <IonItem lines="full">
            <IonCol>
              {order.carts?.map((p: any) => {
                return (
                  <IonItem key={p.productId} lines="none">
                    <IonImg slot="start" src={p.cartProduct.imageUrl} alt="product" />
                    <IonCol >
                      <IonRow>
                        <IonLabel><h2>{p.name}</h2></IonLabel>
                      </IonRow>
                      <IonRow className="ion-justify-content-between ion-align-items-center">
                        <IonLabel><p>RM {p.price.toFixed(2)} x {p.qty}</p></IonLabel>
                        <IonLabel><h3>RM {(p.qty * p.price).toFixed(2)}</h3></IonLabel>
                      </IonRow>
                    </IonCol>
                  </IonItem>
                );
              })}
            </IonCol>
          </IonItem>

          <IonItem lines="full">
            <IonCol>
              <IonRow className="ion-justify-content-between">
                <IonLabel><h3>Subtotal</h3></IonLabel>
                <IonLabel><h3>RM {order.subTotal?.toFixed(2)}</h3></IonLabel>
              </IonRow>
              {order?.type === "delivery" &&
                <IonRow className="ion-justify-content-between">
                  <IonLabel><h3>Delivery Fee</h3></IonLabel>
                  <IonLabel><h3>RM {order.delivery?.fee?.toFixed(2)}</h3></IonLabel>
                </IonRow>}
              {order.subTotal && <IonRow className="ion-justify-content-between">
                <IonLabel><h3>Total</h3></IonLabel>
                <IonLabel><h3>RM {(order.subTotal + (order.delivery?.fee ? order.delivery.fee : 0)).toFixed(2)}</h3></IonLabel>
              </IonRow>}
            </IonCol>
          </IonItem>

          <IonItem lines="full">
            <IonCol>
              <IonRow className="ion-justify-content-between ion-align-items-center">
                <IonLabel><h2>Payment Information</h2></IonLabel>
                <IonButton fill="outline" size="small" shape="round">
                  {order.paymentStatus === "success" ? "Paid" : order.paymentStatus === "pending" ? "To be paid" : "Unpaid"}
                </IonButton>
              </IonRow>
              {order.paymentType && <IonLabel><p>{paymentMethod[order.paymentType]}</p></IonLabel>}
            </IonCol>
          </IonItem>

          <DeliveryTrackingModal
            isOpen={trackingOpen}
            setShowModal={showTracking}
            status={order.deliveryOrder?.status}
          />
        </IonContent>}
    </IonModal >
  )
}

export default OrderDetailsModal;