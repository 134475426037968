import React, { useState } from "react"
import { CardElement, useElements, useStripe, FpxBankElement } from "@stripe/react-stripe-js";

import { IonModal, IonHeader, IonToolbar, IonTitle, IonButton, IonIcon, IonContent, IonRadioGroup, IonList, IonItem, IonLabel, IonRadio, IonFooter, IonLoading, IonAlert } from "@ionic/react";
import { chevronBack } from "ionicons/icons";

import { useStripePaymentMutation, useCashPaymentMutation, useGetSettingQuery } from "../generated/graphql";

import './PaymentModal.css';

interface Props {
  isOpen: boolean
  setShowModal: (isOpen: boolean) => void
  orderId: string
}

const PaymentModal: React.FC<Props> = ({ isOpen, setShowModal, orderId }) => {
  const { data: dataSetting } = useGetSettingQuery({ fetchPolicy: "cache-first" });
  // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  const [stripePayment] = useStripePaymentMutation()
  const [cashPayment] = useCashPaymentMutation()

  const [selectedOption, setSelectedOption] = useState("card");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null | undefined>(null);
  // const [disabled, setDisabled] = useState(true);

  const stripe = useStripe();
  const elements = useElements();

  const handleCardChange = async (event: any) => {
    // setDisabled(event.empty)
    setError(event.error ? event.error.message : null)
  }

  // For test please see https://stripe.com/docs/testing
  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    // setDisabled(true)
    setLoading(true);

    if (selectedOption === "cod") {
      console.log("cod")
      cashPayment({ variables: { record: { orderId } } }).then(res => {
        if (res.data?.consumer?.cashPayment?.totalAmount) {
          setLoading(false);
          window.location.replace(`/payment-status?orderId=${orderId}`)
        }
      }).catch(err => {
        console.log("err in cash payment ", err)
        setLoading(false);
        setError(err.message)
        return err;
      })
    } else {
      stripePayment({
        variables: {
          record: {
            orderId
          }
        }
      }).then(async (res) => {
        if (res.data?.consumer?.stripePayment?.clientSecret) {
          const { clientSecret } = res.data.consumer.stripePayment;
          if (selectedOption === "card") {
            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            console.log('card')
            const cardElement = elements.getElement(CardElement);
            console.log(cardElement)
            if (cardElement) {
              //need to get client secret from paymentIntent created by server side
              // See https://stripe.com/docs/js/payment_intents/confirm_card_payment for more info
              const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                  card: cardElement,
                  // Add other details for the payment
                  // billing_details: {
                  //   name: 'Jenny Rosen',
                  // },
                }
              });

              console.log("card result ", result)
              if (result.error) {
                // redirect to payment 
                setLoading(false);
                setError(result.error.message);
                // setDisabled(false)
              } else {
                // The payment has been processed!
                // See https://stripe.com/docs/api/payment_intents for more info
                // and https://stripe.com/docs/payments/intents#intent-statuses
                if (result.paymentIntent?.status === 'succeeded') {
                  //redirect to payment status
                  setLoading(false);
                  window.location.replace(`/payment-status?orderId=${orderId}`)
                }
              }
            }
          } else if (selectedOption === "online_banking") {
            const fpxBankElement = elements.getElement(FpxBankElement);
            console.log(fpxBankElement)
            if (fpxBankElement) {
              const result = await stripe.confirmFpxPayment(clientSecret, {
                payment_method: {
                  fpx: fpxBankElement,
                },
                // Return URL where the customer should be redirected after the authorization
                return_url: `${window.location.origin}/payment-status?orderId=${orderId}`,
              });

              console.log("fpx result ", result.paymentIntent)
              setLoading(false)
              if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                setError(result.error.message);
              }
            }
          }
        }
      }).catch(err => {
        console.log("err in stripe payment ", err)
        setLoading(false);
        setError(err.message)
        return err;
      })
    }

  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setShowModal(false)}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => setShowModal(false)}>
            <IonIcon icon={chevronBack}></IonIcon>
          </IonButton>
          <IonTitle>Payment</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonRadioGroup value={selectedOption} onIonChange={e => { setSelectedOption(e.detail.value); }}>
            <IonItem>
              <IonLabel>Credit/Debit Card</IonLabel>
              <IonRadio slot="start" value="card" />
            </IonItem>
            {selectedOption === "card" ?
              <div className="FormRow">
                <CardElement
                  onChange={handleCardChange}
                  id="card-element"
                  options={{
                    iconStyle: 'solid',
                    style: {
                      base: {
                        iconColor: '#c4f0ff',
                        color: dataSetting?.getSetting?.darkMode ? "#fff" : "#000",
                        backgroundColor: dataSetting?.getSetting?.darkMode ? "#000" : "#fff",
                        fontWeight: "500px",
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSize: '16px',
                        fontSmoothing: 'antialiased',
                        ':-webkit-autofill': { color: '#fce883' },
                        '::placeholder': { color: dataSetting?.getSetting?.darkMode ? "lightgray" : "gray" },
                      },
                      invalid: {
                        iconColor: '#ffc7ee',
                        color: '#ffc7ee',
                      },
                    },
                  }}
                />
              </div> : null}
            <IonItem>
              <IonLabel>Online Banking</IonLabel>
              <IonRadio slot="start" value="online_banking" />
              <img className="fpx-img" slot="end" src='/assets/img/fpx-logo.jpg' alt="fpx" />
            </IonItem>
            {selectedOption === "online_banking" ?
              <div className="FormRow">
                <FpxBankElement options={
                  {
                    accountHolderType: 'individual',
                    classes: {
                      base: 'StripeElementFpx',
                      focus: 'StripeElementFpx--focus',
                    },
                    style: {
                      base: {
                        fontSize: '16px',
                        color: dataSetting?.getSetting?.darkMode ? "#fff" : "#000",
                        '::placeholder': {
                          color: dataSetting?.getSetting?.darkMode ? "lightgray" : "gray",
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }
                } />
                <p>By clicking on the Proceed button, you agree to FPX’s <a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.</p>
              </div> : null}
            <IonItem>
              <IonLabel>Cash</IonLabel>
              <IonRadio slot="start" value="cod" />
            </IonItem>
          </IonRadioGroup>
        </IonList>
        <IonAlert
          isOpen={typeof (error) === "string"}
          header="Payment failed"
          message={error ? error : ""}
          onDidDismiss={() => setError(null)}
          buttons={[
            {
              text: "OK",
              handler: () => setError(null)
            }
          ]}
        />
        <IonLoading isOpen={loading} />
      </IonContent>
      <IonFooter>
        <IonButton disabled={!stripe || !elements} expand="block" onClick={() => handleSubmit()}>
          Proceed
        </IonButton>
      </IonFooter>
    </IonModal>
  )
}

export default PaymentModal;