import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { persistCache } from 'apollo-cache-persist';
import moment from 'moment';
import { cloneDeep } from 'lodash';

import { 
  GetSettingDocument, ShopOneDocument, ShopManyDocument, F_ProductsFragmentDoc, 
  ConsumerOneDocument, EnumOrderType, EnumAddressLocationType
} from '../generated/graphql'
import { findObjInArray, findIndexInArray } from '../util/arrayUtil';
import { isPropsFulfilled } from '../util/objectUtil';

// cache version format: mm.nn.pp
// mm different: will cause cache clear. Breaking change
// nn different: will cause cache clear. Breaking change
// pp different: cache will not clear
export const cacheVersion = "0.0.1" ;

const appApolloClient: any = async () => {
  const cache = new InMemoryCache();

  await persistCache({
    cache,
    storage: window.localStorage as any,
  });

  const client = new ApolloClient({
    uri: 'https://dev.deepfuture.com.my/graphql',
    cache,
    fetchOptions: {
      credentials: "include"
    },
    // fetch: () => {
    //   return fetch('http://localhost:5000/refresh_token', { credentials: "include", method: "post" }) // refresh token is not working properly yet, implement later
    // },
    request: operation => {
      const token = localStorage.getItem('accessToken')
      operation.setContext({
        headers: {
          public_token: "Q2YS8TicfakADRRGwbMrMDZMJjxQxlEt",
          authorization: token ? `Bearer ${token}` : ''
        }
      })
    },
    resolvers: {
      Query: {
        getCacheVersion: (root) => {
          // console.log("resolve getCacheVersion, root:", root);
          return ({
            "cacheVersion": cacheVersion,
            __typename: "GetCacheVersion"
          });
        },
        getSetting: (root) => {
          // console.log("resolve getSetting, root:", root);
          return ({
            darkMode: false,
            language: "en",
            __typename: "GetSetting"
          });
        },
        lastShopId: (root) => {
          console.log("resolve lastShopId, root:", root);
          return "";
        }
      },
      PublicShop: {
        show: (shop) => {
          //console.log("resolve show ", shop);
          return true;
        },
        orderLocal: (shop, _args, { cache }) => {
          try {
            var { me: consumerOne } = cache.readQuery({
              query: ConsumerOneDocument
            }).consumer
          } catch (e) {
            //console.log("consumer ",e);
          }
          try {
            var { shop: shopOne } = cache.readQuery({
              query: ShopOneDocument,
              variables: { filter: { url: shop.url } }
            }).public
          } catch (e) {
            //console.log("shopOne ", e);
          }
          // shopOne?.orderLocal && console.log("Local order: ", shopOne.orderLocal)
          if (consumerOne) {
            // console.log(`[${consumerOne.name}] orders: `, consumerOne.orders)
            let order = findObjInArray(consumerOne.orders, "shopId", shop._id)
            // console.log(`[${consumerOne.name}, ${shop.name}] latest server order: `, order)
            if (order) {
              if (order.status === "incart") {
                if (shopOne?.orderLocal?.totalQty > 0) {
                  //will override the order inside server with the latest carts
                  let alteredOrder = cloneDeep(shopOne.orderLocal);
                  alteredOrder._id = order._id;
                  if (order.delivery.type === "delivery" && alteredOrder.delivery.type === "delivery" && !isPropsFulfilled(alteredOrder.delivery.deliverTo)) {
                    alteredOrder.delivery = order.delivery;
                  }
                  if (alteredOrder.delivery.timeFrom < moment().toISOString()) {
                    alteredOrder.delivery.timeFrom = moment().add(30, 'minutes').toISOString();
                    alteredOrder.delivery.timeTo = moment().add(1, 'hours').toISOString();
                  }
                  return alteredOrder;
                } else {
                  let alteredOrder = cloneDeep(order);
                  if (order.delivery.timeFrom < moment().toISOString()) {
                    alteredOrder.delivery.timeFrom = moment().add(30, 'minutes').toISOString();
                    alteredOrder.delivery.timeTo = moment().add(1, 'hours').toISOString();
                  }
                  alteredOrder.__typename = "T_OrderLocal";
                  return alteredOrder;
                }
              } else {
                //re-initialize order value
                let newOrder = cloneDeep(order);
                // console.log("order: ", order)
                newOrder._id = "";
                newOrder.__typename = "T_OrderLocal";
                newOrder.carts = (shopOne?.order?.carts && shopOne.orderLocal.carts) || [];
                newOrder.totalQty = (shopOne?.order?.totalQty && shopOne.orderLocal.totalQty) || 0;
                newOrder.subTotal = (shopOne?.order?.subTotal && shopOne.orderLocal.subTotal) || 0;
                // never working line as the order.delivery do not have type property
                if (order.type === "delivery" && shopOne?.orderLocal?.type === "delivery" && isPropsFulfilled(shopOne?.orderLocal?.delivery.deliverTo)) {
                  newOrder.delivery = shopOne.orderLocal.delivery;
                }
                newOrder.delivery.timeFrom = moment().add(30, 'minutes').toISOString();
                newOrder.delivery.timeTo = moment().add(1, 'hours').toISOString();
                return newOrder;
              }
            }
          }
          if (shopOne?.orderLocal?.totalQty > 0) return shopOne.orderLocal;
          return {
            _id: "",
            type: EnumOrderType.Delivery,
            carts: [],
            totalQty: 0,
            subTotal: 0,
            delivery: {
              fee: 0,
              option: null,
              deliverTo: {
                name: "",
                phone: "",
                detailed_address: "",
                state: "",
                country: "Malaysia",
                city: "",
                zipcode: "",
                location: {
                  type: EnumAddressLocationType.Point,
                  coordinates: null,
                  __typename: "AddressLocation"
                },
                __typename: "Address"
              },
              timeFrom: moment().add(30, 'minutes').toISOString(),
              timeTo: moment().add(1, 'hours').toISOString(),
              distanceInKm: null,
              __typename: "Delivery"
            },
            __typename: "T_OrderLocal"
          };
        }
      },
      PublicProduct: {
        show: (product) => {
          // console.log("resolve product show ", product);
          return true;
        }
      },
      Mutation: {
        clearCache: (_, args) => {
          console.log("resetStore called");
          client.resetStore();
        },
        setSetting: (_, args, { cache }) => {
          const query = GetSettingDocument;
          const prev = cache.readQuery({ query });
          const data = { getSetting: { ...prev.getSetting, ...args.record } };
          cache.writeQuery({ query, data });
        },
        setShopsFilter: (_, args, { cache, getCacheKey }) => {
          let { shops: shopMany } = cache.readQuery({
            query: ShopManyDocument
          }).public;
          let strSearch = args.filter.toLowerCase();
          let shopsCopy = cloneDeep(shopMany);
          shopsCopy.forEach((s: any) => {
            let match = (strSearch === ""); // if strSearch is blank show all
            if (!match) match = s.name.toLowerCase().indexOf(strSearch) > -1;
            if (!match) match = s.description.toLowerCase().indexOf(strSearch) > -1;
            if (match !== s.show) {
              console.log("filter shop ", match, s.show)
              s.show = match;
              const id = getCacheKey({ __typename: 'PublicShop', id: s._id });
              cache.writeData({ id, data: s });
            }
          });
          return null;
        },
        setProductsFilter: (_, args, { cache, getCacheKey }) => {
          const shopId = getCacheKey({ __typename: 'PublicShop', id: args.filter.shopId });
          const { products } = cache.readFragment({ id: shopId, fragment: F_ProductsFragmentDoc, fragmentName: "F_Products" });
          let productsCopy = cloneDeep(products);
          productsCopy.forEach((p: any) => {
            let catMatch: boolean;
            if (args.filter.categoryId && args.filter.categoryId !== "All") {
              catMatch = false;
              p.categoriesId.forEach((catId: any) => {
                if (catId === args.filter.categoryId) catMatch = true;
              })
            } else
              catMatch = true;

            let searchMatch: boolean;
            if (args.filter.searchText && args.filter.searchText !== "") {
              const strSearch = args.filter.searchText.toLowerCase();
              searchMatch = p.name.toLowerCase().indexOf(strSearch) > -1;
              if (!searchMatch) searchMatch = p.shortDesc.toLowerCase().indexOf(strSearch) > -1;
              //if (!searchMatch) searchMatch = p.longDesc?.toLowerCase().indexOf(strSearch) > -1;
            } else
              searchMatch = true;

            const show = catMatch && searchMatch;
            if (p.show !== show) {
              p.show = show;
              const id = getCacheKey({ __typename: 'PublicProduct', id: p._id });
              cache.writeData({ id, data: p });
            }
          });
          return null;
        },
        addProduct: (_, args, { client, cache, getCacheKey }) => {
          let { shop: shopOne } = cache.readQuery({
            query: ShopOneDocument,
            variables: { filter: { url: args.shopUrl } }
          }).public;
          let shopCopy = cloneDeep(shopOne);
          let cart = findObjInArray(shopCopy.orderLocal.carts, "productId", args.cart.productId);
          let qty = args.cart.qty ? args.cart.qty : 1 ;
          if (cart) {
            cart.qty += qty;
          } else {
            cart = {
              qty: qty,
              ...args.cart,
              __typename: "T_OrderLocalCart"    // any name will do
            };
            if (cart.cartProduct)
              cart.cartProduct.__typename = "T_OrderLocalCartProduct"   // any name will do
            shopCopy.orderLocal.carts.push(cart);
          }
          shopCopy.orderLocal.totalQty += qty;
          shopCopy.orderLocal.subTotal += args.cart.price * qty;
          //truncate the extra decimal
          shopCopy.orderLocal.subTotal = +(shopCopy.orderLocal.subTotal).toFixed(2);
          const id = getCacheKey({ __typename: 'PublicShop', id: shopOne._id })
          client.writeData({ id, data: shopCopy })
          return null;
        },
        removeProduct: (_, args, { client, cache, getCacheKey }) => {
          let { shop: shopOne } = cache.readQuery({
            query: ShopOneDocument,
            variables: { filter: { url: args.shopUrl } }
          }).public
          let shopCopy = cloneDeep(shopOne);
          let cart = findObjInArray(shopCopy.orderLocal.carts, "productId", args.cart.productId);
          if (cart) {
            cart.qty -= 1;
            shopCopy.orderLocal.totalQty -= 1;
            shopCopy.orderLocal.subTotal -= args.cart.price;
            //truncate the extra decimal
            shopCopy.orderLocal.subTotal = +(shopCopy.orderLocal.subTotal).toFixed(2);
            if (cart.qty === 0) {
              shopCopy.orderLocal.carts.splice(findIndexInArray(shopCopy.orderLocal.carts, "productId", args.cart.productId), 1)
            }
          }
          const id = getCacheKey({ __typename: 'PublicShop', id: shopOne._id })
          client.writeData({ id, data: shopCopy })
          return null;
        },
        clearCarts: (_, args, { client, cache, getCacheKey }) => {
          let { shop: shopOne } = cache.readQuery({
            query: ShopOneDocument,
            variables: { filter: { url: args.shopUrl } }
          }).public
          let shopCopy = cloneDeep(shopOne);
          shopCopy.orderLocal.carts = [];
          shopCopy.orderLocal.totalQty = 0;
          shopCopy.orderLocal.subTotal = 0;
          const id = getCacheKey({ __typename: 'PublicShop', id: shopOne._id })
          client.writeData({ id, data: shopCopy })
          return null;
        },
        setDeliveryAddress: (_, args, { client, cache, getCacheKey }) => {
          let { shop: shopOne } = cache.readQuery({
            query: ShopOneDocument,
            variables: { filter: { url: args.shopUrl } }
          }).public
          let shopCopy = cloneDeep(shopOne);
          shopCopy.orderLocal.delivery.deliverTo = args.address;
          const id = getCacheKey({ __typename: 'PublicShop', id: shopOne._id })
          client.writeData({ id, data: shopCopy })
          return null;
        },
        setDeliveryOptionFee: (_, args, { client, cache, getCacheKey }) => {
          let { shop: shopOne } = cache.readQuery({
            query: ShopOneDocument,
            variables: { filter: { url: args.shopUrl } }
          }).public
          let shopCopy = cloneDeep(shopOne);
          if (args.deliveryOption === "Self pick up") {
            shopCopy.orderLocal.type = EnumOrderType.Selfpickup;
          } else {
            shopCopy.orderLocal.type = EnumOrderType.Delivery;
            shopCopy.orderLocal.delivery.option = args.deliveryOption;
          }
          shopCopy.orderLocal.delivery.fee = args.deliveryFee;
          shopCopy.orderLocal.delivery.distanceInKm = args.deliveryDistance;
          const id = getCacheKey({ __typename: 'PublicShop', id: shopOne._id })
          client.writeData({ id, data: shopCopy })
          return null;
        },
        setDeliveryTime: (_, args, { client, cache, getCacheKey }) => {
          let { shop: shopOne } = cache.readQuery({
            query: ShopOneDocument,
            variables: { filter: { url: args.shopUrl } }
          }).public
          let shopCopy = cloneDeep(shopOne);
          shopCopy.orderLocal.delivery.timeFrom = args.deliveryTimeFrom;
          shopCopy.orderLocal.delivery.timeTo = args.deliveryTimeTo;
          const id = getCacheKey({ __typename: 'PublicShop', id: shopOne._id })
          client.writeData({ id, data: shopCopy })
          return null;
        },
        setLastShopId: (_, args, { client }) => {
          //client.writeData({ query: GQL_GET_LAST_SHOP_ID, data: { lastShopId: args.shopId } });
          return null;
        }
      }
    },
    onError({ graphQLErrors, networkError }) {
      console.log(graphQLErrors)
      console.log(`GQL Network Error :${networkError}`)
    }
  });

  return client;
}

export default appApolloClient;
