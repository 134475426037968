import React, { useEffect, useState } from 'react';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { ServiceWorkerProvider } from './hooks/useServiceWorker';
import { ToastProvider } from './hooks/useToast';
import appApolloClient from './states/appApolloClient';

import AppTab from './pages/AppTab';
import PaymentStatus from './pages/PaymentStatus';
// import Login from './pages/Login';
// import Signup from './pages/Signup';
// import ForgotPassword from './pages/ForgotPassword';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51Gq9QGGHfWDhrZqcrd0puUjAxI7DZ257DDMf3PgqxOUVevpVv81VfBv46WMIsLtzi13isu2ZaubN5oqIJHM9aQXc00zG9SN6fI');

const App: React.FC = () => {
  const [client, setClient] = useState<any>(undefined);

  useEffect(() => {
    appApolloClient().then((retClient: any) => {
      setClient(retClient);
    });
  }, []);

  return (
    <ServiceWorkerProvider>
      <IonApp className="app-aspect-lock-container">
        {client ?
          <ApolloProvider client={client}>
            <Elements stripe={stripePromise}>
              <ToastProvider>
                <IonReactRouter>
                  <IonRouterOutlet>
                    <Route path="/" component={AppTab} />
                    {/* <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/forgot-password" component={ForgotPassword} /> */}
                    <Route exact path="/payment-status" component={PaymentStatus} />
                  </IonRouterOutlet>
                </IonReactRouter>
              </ToastProvider>
            </Elements>
          </ApolloProvider>
          :
          <div>loading from cache...</div>
        }
      </IonApp>
    </ServiceWorkerProvider>
  );
}
export default App;
