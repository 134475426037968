import React, { useState, useRef } from 'react';
import { IonModal, IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonContent, IonItem, IonInput, IonLoading, IonAlert } from '@ionic/react';
import { useChangePasswordMutation } from '../generated/graphql';
import { useForm, Controller } from 'react-hook-form';
import { chevronBack } from 'ionicons/icons';
import './PhoneOtpModal.css'

interface Props {
  isOpen: boolean,
  setShowModal: (isOpen: boolean) => void,
}

const PasswordModal: React.FC<Props> = ({ isOpen, setShowModal }) => {
  const [changePassword, { loading }] = useChangePasswordMutation()
  const [showAlert, setShowAlert] = useState<any>(undefined);
  const { control, handleSubmit, formState, errors, watch } = useForm({
    defaultValues: {
      current: "",
      new: "",
      confirm: ""
    },
    mode: "onChange"
  });

  const newPassword = useRef({});
  newPassword.current = watch("new", "")

  /**
   *
   * @param _fieldName
   */
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="error-message">
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  /**
   *
   * @param data
   */
  const onSubmit = (data: any) => {
    changePassword({
      variables: {
        record: {
          current: data.current,
          updated: data.new
        }
      }
    }).then((res: any) => {
      const { status, message } = res.data?.consumer?.changePassword;
      if (status) {
        setShowAlert({
          header: "Successful",
          message: message,
          action: () => setShowModal(false)
        })
      } else {
        setShowAlert({
          header: "Error",
          message: message,
        })
      }
    }).catch(error => {
      console.log("err change password ", error)
      setShowAlert({
        header: "Error",
        message: error.message,
      })
    })
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setShowModal(false)}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => setShowModal(false)}>
            <IonIcon icon={chevronBack} size="100px"></IonIcon>
          </IonButton>
          <IonTitle>Change Password</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
          <IonItem>
            <Controller
              as={IonInput}
              placeholder="Enter current password"
              control={control}
              type="password"
              onChangeName="onIonChange"
              onChange={([selected]) => {
                return selected.detail.value;
              }}
              name="current"
              rules={{
                required: true,
                minLength: { value: 6, message: "Password must at least 6 characters" },
                pattern: { value: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, message: "Password must contain alphabetic and numeric characters" }
              }}
            />
          </IonItem>
          {showError("current")}

          <IonItem>
            <Controller
              as={IonInput}
              placeholder="Enter new password"
              control={control}
              type="password"
              onChangeName="onIonChange"
              onChange={([selected]) => {
                return selected.detail.value;
              }}
              name="new"
              rules={{
                required: true,
                minLength: { value: 6, message: "Password must at least 6 characters" },
                pattern: { value: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, message: "Password must contain alphabetic and numeric characters" }
              }}
            />
          </IonItem>
          {showError("new")}

          <IonItem>
            <Controller
              as={IonInput}
              placeholder="Re-enter new password"
              control={control}
              type="password"
              onChangeName="onIonChange"
              onChange={([selected]) => {
                // if (getValues("new") !== selected.detail.value) {
                //   setError("confirm", "mismatch", "New password not match");
                // }
                return selected.detail.value;
              }}
              name="confirm"
              rules={{
                required: true,
                validate: value => {
                  console.log(value, newPassword.current)
                  return value === newPassword.current || "New password not match"
                }
              }}
            />
          </IonItem>
          {showError("confirm")}

          <IonButton className="submit-button" type="submit" disabled={formState.isValid === false}>Change</IonButton>
        </form>
        <IonLoading
          isOpen={loading}
          message={'Please wait...'}
        />
        <IonAlert
          isOpen={showAlert !== undefined}
          onDidDismiss={() => setShowAlert(undefined)}
          header={showAlert?.header}
          message={showAlert?.message}
          buttons={[
            {
              text: "OK",
              handler: showAlert?.action
            }
          ]}
        />
      </IonContent>
    </IonModal>
  )
}

export default PasswordModal;