import React, { useEffect, useState } from 'react';
import { Geolocation } from '@ionic-native/geolocation';

import {
  IonModal, IonHeader, IonToolbar, IonTitle, IonContent,
  IonAlert,
  IonList, IonItem,
  IonGrid, IonRow, IonCol,
  IonSelect, IonSelectOption,
  IonLabel, IonButton, IonIcon, IonInput, IonToast, IonFooter, isPlatform,
} from '@ionic/react';
import { chevronBack, location } from 'ionicons/icons';

import MapModal from './MapModal';
import { findIndexInArray, stripTypenames } from '../util/arrayUtil';
import {
  useSetDeliveryAddressMutation, useConsumerUpdateMutation, EnumAddressCountry, EnumAddressLocationType
} from "../generated/graphql";
import './AddressModal.css';
import { cloneDeep } from 'lodash';


interface Props {
  title: string,
  user: any,
  shopUrl?: string,
  deliveryAddress: any,
  isOpen: boolean,
  enableDelete: boolean,
  modalResult: (result: string, data?: any) => void,
}

const states = require("../data/states.json").states;

const AddressModal: React.FC<Props> = ({ title, user, shopUrl, deliveryAddress, isOpen, enableDelete, modalResult }) => {
  const [setDeliveryAddress] = useSetDeliveryAddressMutation();
  const [consumerUpdate] = useConsumerUpdateMutation();

  const [prevOpen, setPrevOpen] = useState(false);
  const [saveChange, setSaveChange] = useState(false);
  const [mapOpen, showMap] = useState(false);
  const [clearAlertOpen, showClearAlert] = useState(false);
  const [delAlertOpen, showDelAlert] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const prevAddrs = (user && user.addresses) || [];
  const { name, phone, detailed_address, state, city, zipcode, location: { coordinates } } = deliveryAddress;
  const [address, setAddress] = useState({
    name: name ? name : (user && user.name) || "",
    phone: phone ? phone : (user && user.phone) || "",
    detailed_address,
    state,
    city,
    zipcode,
    country: EnumAddressCountry.Malaysia,
    location: {
      type: EnumAddressLocationType.Point,
      coordinates,
      __typename: "AddressLocation"
    },
    __typename: "DeliveryAddress"
  });

  function setAddressField(newField: any) {
    setAddress(address => ({
      ...address,
      ...newField
    }));
    setSaveChange(true);
  }

  function modalOpen() {
    setAddress({
      name: name ? name : (user && user.name) || "",
      phone: phone ? phone : (user && user.phone.slice(2)) || "",
      detailed_address,
      state,
      city,
      zipcode,
      country: EnumAddressCountry.Malaysia,
      location: {
        type: EnumAddressLocationType.Point,
        coordinates,
        __typename: "AddressLocation"
      },
      __typename: "DeliveryAddress"
    });
    setSaveChange(false);
    if (!coordinates) {
      Geolocation.getCurrentPosition().then(resp => {
        setAddressField({
          "location": {
            "type": EnumAddressLocationType.Point,
            "coordinates": [resp.coords.longitude, resp.coords.latitude],
            "__typename": "AddressLocation"
          }
        });
      })
    }
    setPrevOpen(true);
  }

  function modalClose() {
    setPrevOpen(false);
  }

  useEffect(() => {
    if (isOpen && !prevOpen)
      modalOpen();
    if (!isOpen && prevOpen)
      modalClose();
  })

  function onSave() {
    if (!address.name || !address.phone || !address.detailed_address || !address.state || !address.city || !address.location.coordinates) {
      setShowToast(true);
    } else {
      if (saveChange) {
        if (shopUrl) {
          //update delivery address in shop locally
          setDeliveryAddress({
            variables: {
              shopUrl: shopUrl,
              address: address
            }
          })
        }
        if (user) {
          // compare address using JSON stringify it, if not found add to addresses list
          let newAddrJSON = JSON.stringify(address);
          let found = false;
          prevAddrs.forEach((a: any) => {
            let addsJSON = JSON.stringify(a);
            if (addsJSON === newAddrJSON)
              found = true;
          });
          if (!found) {
            let addressesCopy = cloneDeep(prevAddrs);
            addressesCopy.unshift(address);
            //update addresses inside database
            consumerUpdate({
              variables: {
                id: user._id,
                record: {
                  addresses: stripTypenames(addressesCopy)
                }
              }
            })
          }
        }
      }
      modalResult("save", address);
    }
  }

  function onDelete() {
    // compare address using JSON stringify it, if found delete from addresses list
    let delAddrJSON = JSON.stringify(address);
    let found: number = -1;
    prevAddrs.forEach((a: any, i: any) => {
      let addsJSON = JSON.stringify(a);
      if (addsJSON === delAddrJSON)
        found = i;
    });
    if (found > -1) {
      let addressesCopy = cloneDeep(prevAddrs);
      addressesCopy.splice(found, 1);
      //update addresses inside database
      consumerUpdate({
        variables: {
          id: user._id,
          record: {
            addresses: stripTypenames(addressesCopy)
          }
        }
      })
    }
    modalResult("close");
  }

  const stateIndex = findIndexInArray(states, "name", address.state);
  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => modalResult("close")}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButton fill="clear" slot="start"
            onClick={() => {
              if (saveChange)
                showClearAlert(true);
              else
                modalResult("close");
            }}>
            <IonIcon icon={chevronBack}></IonIcon>
          </IonButton>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form >
          <IonItem>
            <IonLabel>Select previous address</IonLabel>
            <IonSelect interface={isPlatform("mobile") ? "action-sheet" : "alert"}
              onIonChange={e => {
                setAddress(prevAddrs[e.detail.value]);
              }}
            >
              {prevAddrs.map((a: any, i: number) => {
                return <IonSelectOption key={i} value={i}>{a.detailed_address}</IonSelectOption>
              })}
            </IonSelect>
          </IonItem>
          <IonList lines="full" class="ion-no-margin ion-no-padding">
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput value={address.name}
                onIonChange={e => setAddressField({ "name": e.detail.value })}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Mobile Number</IonLabel>
              <IonInput value={address.phone} inputMode="tel" maxlength={12}
                onIonChange={e => setAddressField({ "phone": e.detail.value })}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Detailed Address</IonLabel>
              <IonInput value={address.detailed_address}
                onIonChange={e => setAddressField({ "detailed_address": e.detail.value })}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">State</IonLabel>
              <IonSelect interface="popover" value={address.state} onIonChange={e => {
                if (e.detail.value !== address.state)
                  setAddressField({ "state": e.detail.value, "city": "" });
                else
                  setAddressField({ "state": e.detail.value });
              }}>
                {states.map((state: any) => {
                  return <IonSelectOption key={state.name} value={state.name}>{state.name}</IonSelectOption>
                })}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">City</IonLabel>
              <IonSelect interface="popover" value={address.city}
                disabled={stateIndex !== null ? false : true}
                onIonChange={e => { if (e.detail.value !== "") setAddressField({ "city": e.detail.value }) }}
              >
                {states[stateIndex !== null ? stateIndex : 0].cities.map((city: string) => {
                  return <IonSelectOption key={city} value={city}>{city}</IonSelectOption>
                })}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Postcode</IonLabel>
              <IonInput value={address.zipcode} inputMode="numeric" maxlength={5}
                onIonChange={e => setAddressField({ "zipcode": e.detail.value })}
              />
            </IonItem>
            <IonItem className="ion-margin-top" lines="none" onClick={() => showMap(true)}>
              <IonIcon icon={location} color="primary" slot="start"></IonIcon>
              <IonLabel>
                <h2>Set location on map</h2>
                <p>
                  {address.location.coordinates ?
                    `[ ${address.location.coordinates[0].toFixed(4)}, ${address.location.coordinates[1].toFixed(4)} ]`
                    : "unset"
                  }
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        </form>
      </IonContent>

      <IonFooter>
        <IonGrid><IonRow>
          {enableDelete ?
            <IonCol>
              <IonButton expand="block" onClick={() => showDelAlert(true)}>
                DELETE
              </IonButton>
            </IonCol>
            : null}
          <IonCol>
            <IonButton expand="block" onClick={onSave}>
              SAVE
            </IonButton>
          </IonCol>
        </IonRow></IonGrid>
      </IonFooter>

      <MapModal isOpen={mapOpen} setShowModal={showMap} location={address.location}
        updateLocation={(updated: any) => setAddressField({
          "location": {
            "type": EnumAddressLocationType.Point,
            "coordinates": [updated.lng, updated.lat],
            "__typename": "AddressLocation"
          }
        })}
      />

      <IonAlert
        isOpen={clearAlertOpen}
        message="You have unsaved changes, are you sure you want to discard it?"
        onDidDismiss={() => showClearAlert(false)}
        buttons={[
          {
            text: "Cancel",
            handler: () => showClearAlert(false)
          },
          {
            text: "Discard",
            handler: () => modalResult("close")
          }
        ]}
      />

      <IonAlert
        isOpen={delAlertOpen}
        message="Remove current address, are you sure?"
        onDidDismiss={() => showDelAlert(false)}
        buttons={[
          {
            text: "Cancel",
            handler: () => showDelAlert(false)
          },
          {
            text: "Yes",
            handler: () => onDelete()
          }
        ]}
      />

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Please fill in all the required information"
        duration={1000}
        position="middle"
      />
    </IonModal>
  )
}

export default AddressModal;