import React, { useState, useEffect } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { Share } from '@capacitor/share';
import YouTube from "react-youtube";
import TagManager from 'react-gtm-module';
import Helmet from "react-helmet";

import {
  IonPage, IonHeader, IonFooter, IonToolbar, IonContent,
  IonItem, IonButtons, IonButton, IonTitle, IonIcon, IonLabel,
  IonGrid, IonRow, IonCol, isPlatform, IonImg, IonLoading
} from "@ionic/react";
import {
  chevronBack, shareSocial, add, remove, cart, cartOutline, heartOutline, heart
} from 'ionicons/icons';

import {
  useShopOneQuery, useConsumerOneQuery,
  useAddToCartMutation, useRemoveFromCartMutation,
  useConsumerLikesLazyQuery, useNewLikeMutation, useUnLikeMutation,
  EnumBlogContentSectionsType, EnumLikeType,
} from "../../generated/graphql";

import { findIndexInArray } from '../../util/arrayUtil';

import CartModal from "./CartModal";
import { useToast } from '../../hooks/useToast';
import { NotFound } from "../../pages/NotFound";
import ErrorPage from "../../pages/ErrorPage";

import './Blog.css'

interface Props extends RouteComponentProps<{ url: string, _id: string }> {
}

const Blog: React.FC<Props> = ({ match, history, location }) => {
  const Toast = useToast();
  const [cartModalOpen, showCartModal] = useState(new URLSearchParams(useLocation().search).get("openCart") === "true" ? true : false)

  const { data: dataConsumer } = useConsumerOneQuery({ fetchPolicy: "cache-first" })
  const { data: dataShop, loading: loadingShop, refetch: refetchShop, error: errorShop } = useShopOneQuery({
    variables: { filter: { url: match.params.url } },
    fetchPolicy: "cache-first",
  })
  const [getLikes, { data: dataLikes }] = useConsumerLikesLazyQuery({
    fetchPolicy: "cache-and-network"
  })

  const [addToCartMutation] = useAddToCartMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const [newLike] = useNewLikeMutation();
  const [unLike] = useUnLikeMutation();

  const shop = dataShop?.public?.shop;
  const blog = shop?.blogs.filter(f => f._id === match.params._id)?.[0];
  const likes = dataLikes?.consumer?.likes
  const liked = (likes && blog) ? likes.filter(f => (f.type === EnumLikeType.Blog && f.typeId === blog._id))[0] : false;

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview'
      }
    });
  }, [])

  useEffect(() => {
    if (shop)
      getLikes({ variables: { filter: { shopId: shop._id } } })
  }, [shop, getLikes])

  function onCartRemove(e: any, p: any) {
    //prevent from going to product details page
    e.stopPropagation(); //stop parent event
    e.preventDefault(); //stop itself default event
    removeFromCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: p._id,
          price: p.pricing.retail
        }
      }
    })
  }

  function onCartAdd(e: any, p: any) {
    e.stopPropagation();
    e.preventDefault();
    addToCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: p._id,
          name: p.name,
          price: p.pricing.retail,
          cartProduct: {
            name: p.name,
            imageUrl: p.imageUrl
          }
        }
      }
    })
  }

  async function onShareClick() {
    try {
      let shareRet = await Share.share({
        title: blog?.contentHeader,
        url: match.url,
      });
      console.log("share successful: ", shareRet)
    } catch (err) {
      console.log("share error: ", err)
      Toast.error(err);
    }
  }

  function onLikeClick(id: string) {
    if (liked) {
      Toast.success("Remove from liked list");
      unLike({
        variables: {
          filter: {
            _id: liked._id
          }
        }
      }).then(() => getLikes({ variables: { filter: { shopId: shop?._id } } }))
    } else {
      Toast.success("Added to liked list");
      newLike({
        variables: {
          record: {
            consumerId: dataConsumer?.consumer?.me?._id,
            shopId: shop?._id,
            type: EnumLikeType.Blog,
            typeId: id
          }
        }
      }).then(() => getLikes({ variables: { filter: { shopId: shop?._id } } }))
    }
  }

  function onVideoStateChange(e: any) {
    const duration = e.target.getDuration();
    const currentTime = e.target.getCurrentTime();
    console.log("duration: ", duration);
    console.log("currentTime: ", currentTime);
    console.log("view percent: %d%", currentTime / duration * 100);
  }

  if (!shop && loadingShop) {
    return <IonPage><IonLoading isOpen={loadingShop} /></IonPage>
  }

  if (!shop && errorShop) {
    console.log('ShopHome error', errorShop)
    return <ErrorPage refresh={() => refetchShop()} />
  }

  if (shop && blog) {
    const iconSize = isPlatform("desktop") ? "large" : "default";
    return (
      <>
        <Helmet>
          <title>{blog.cardTitle ? blog.cardTitle : blog.contentHeader}</title>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={blog.cardTitle ? blog.cardTitle : blog.contentHeader} />
          <meta property="og:description" content={blog.cardText ? blog.cardText : ""} />
          <meta property="og:image" content={blog.cardImageUrl} />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="300" />
        </Helmet>
        <IonPage className="shop02-blog">
          <IonHeader>
            <IonToolbar>
              <IonButton slot="start" fill="clear" onClick={() => history.goBack()}>
                <IonIcon icon={chevronBack} slot="icon-only"></IonIcon>
              </IonButton>
              <IonTitle >{blog.contentHeader}</IonTitle>

              <IonButtons slot="end">
                <IonButton fill="clear" onClick={() => onLikeClick(blog._id)}>
                  <IonIcon icon={liked ? heart : heartOutline} color={liked ? "primary" : "medium"} size={iconSize} />
                </IonButton>
                <IonButton fill="clear" onClick={onShareClick}>
                  <IonIcon icon={shareSocial} color="primary" size={iconSize} />
                </IonButton>
                {isPlatform("desktop") && shop.orderLocal?.totalQty ?
                  <IonButton fill="clear" onClick={() => showCartModal(true)} >
                    <IonIcon icon={cartOutline} color="primary" size={iconSize} />
                    <IonLabel>{shop.orderLocal?.totalQty}</IonLabel>
                  </IonButton>
                  : null}
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {blog.contentSections?.map((s, i) => {
              switch (s?.type) {
                case EnumBlogContentSectionsType.Videos:
                  return (
                    s.data.map((d: any, j: number) =>
                      <IonItem key={`${i}-${j}`} lines="none" className="ion-item-full-width">
                        {/* <iframe key={`if-${i}-${j}`} className="shop02-video"
                        title="Youtube"
                        // src={`https://www.youtube.com/embed/${blog.video}?playlist=${blog.video}&modestbranding=1&autoplay=1&loop=1&rel=0`}
                        src={`https://www.youtube.com/embed/${d}?playlist=${d}&modestbranding=1&loop=1&rel=0`}
                        frameBorder="0" allowFullScreen
                      /> */}
                        <YouTube
                          containerClassName="shop02-video"
                          videoId={d}
                          opts={{ playerVars: { playlist: d, modestbranding: 1, loop: 1, rel: 0 } }}
                          onStateChange={onVideoStateChange}
                        />
                      </IonItem>
                    )
                  );
                case EnumBlogContentSectionsType.Images:
                  return (
                    <IonItem key={i} lines="none" className="ion-item-full-width">
                      <div style={{ width: '100%' }}>
                        {s.data.map((d: any, j: number) => <IonImg key={`${i}-${j}`} src={d} />)}
                      </div>
                    </IonItem>
                  );
                case EnumBlogContentSectionsType.Texts:
                  return (
                    <IonItem key={i} lines="none">
                      <div style={{ width: '100%' }} className="shop02-description">
                        {s.data.map((d: any, j: number) => <div dangerouslySetInnerHTML={{ __html: d }} />)}
                      </div>
                    </IonItem>
                  );
                case EnumBlogContentSectionsType.Products:
                  return (
                    <IonItem key={i} lines="none" className="ion-item-full-width">
                      <IonGrid className="shop02-product">
                        <IonRow>
                          {s.data.map((d: any, j: number) => {
                            let p_index = findIndexInArray(shop.products, "_id", d);
                            if (p_index != null) {
                              let p = shop.products[p_index];
                              let cartIndex = shop.orderLocal?.carts ? findIndexInArray(shop.orderLocal.carts, "productId", p._id) : null;
                              return (
                                <IonCol key={p._id} sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" hidden={p.status !== "published"} >
                                  <IonItem routerLink={`/ShopGen02/${match.params.url}/product-details/${p._id}`}>
                                    <IonImg slot="start" src={p.imageUrl!} alt="product" />
                                    <IonCol>
                                      <IonRow><IonCol>
                                        <IonLabel>
                                          <h2>{p.name}</h2>
                                          <p>{p.shortDesc}</p>
                                          {(!p.unlimitedStock && p.stock! < 1) && <p className="out-of-stock">Out of stock</p>}
                                        </IonLabel>
                                      </IonCol></IonRow>
                                      <IonRow className="ion-align-items-center">
                                        <IonCol  >
                                          <IonLabel className="ion-text-nowrap">RM {p.pricing!.retail.toFixed(2)}</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                          <IonRow className="ion-nowrap ion-justify-content-end ion-align-items-center">
                                            {cartIndex !== null ?
                                              <IonButton size="small" color="medium" onClick={(e) => onCartRemove(e, p)}>
                                                <IonIcon icon={remove} />
                                              </IonButton>
                                              : null}
                                            {shop.orderLocal?.carts && cartIndex !== null ?
                                              <IonLabel>&nbsp;{shop.orderLocal.carts[cartIndex]?.qty}&nbsp;</IonLabel>
                                              : null}
                                            <IonButton size="small" color="primary" onClick={(e) => onCartAdd(e, p)}
                                              disabled={(!p.unlimitedStock && p.stock! < 1)} >
                                              <IonIcon icon={add} />
                                            </IonButton>
                                          </IonRow>
                                        </IonCol>
                                      </IonRow>
                                    </IonCol>
                                  </IonItem>
                                </IonCol>
                              )
                            } else
                              return null;
                          })}
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                default:
                  return null;
              }
            })}

            {(isPlatform("mobile") && shop.orderLocal?.totalQty) ?
              <IonFooter>
                <IonItem detail color="primary" onClick={() => { showCartModal(true) }}>
                  <IonIcon slot="start" icon={cart} />
                  <IonLabel>{shop.orderLocal?.totalQty} items</IonLabel>
                  <IonLabel>RM {shop.orderLocal?.subTotal?.toFixed(2)}</IonLabel>
                </IonItem>
              </IonFooter>
              : null}

            <CartModal
              user={dataConsumer?.consumer?.me}
              shop={shop}
              isOpen={cartModalOpen}
              setShowModal={showCartModal}
              redirectToLogin={() => history.push("/login", { prevLocation: location.pathname, openCart: true })}
            />

          </IonContent>
        </IonPage >
      </>
    );
  }
  return <NotFound />;
}

export default Blog;