import React, { useState, createContext } from "react";
import * as serviceWorker from '../serviceWorkerRegistration';

const ServiceWorkerContext = createContext<any>({});

export const ServiceWorkerProvider = ({ children }: any) => {
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | null>(null);
  const [waitingServiceWorker, setWaitingServiceWorker] = useState<ServiceWorker | null>(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  // console.log("Render ServiceWorkerProvider");

  React.useEffect(() => {
    // console.log("ServiceWorkerProvider->useEffect->serviceWorker.register");
    serviceWorker.register({
      onUpdate: (reg: ServiceWorkerRegistration) => {
        console.log('serviceWorker -> onUpdate')
        setWaitingServiceWorker(reg.waiting);
        setUpdateAvailable(true);
      },
      onRegistered: (reg: ServiceWorkerRegistration) => {
        if (reg.waiting) {
          console.log('serviceWorker -> onWaiting')
          setWaitingServiceWorker(reg.waiting);
          setUpdateAvailable(true);
        } else {
          console.log('serviceWorker -> onRegistered')
          setRegistration(reg);
        }
      }
    });
  }, []);

  React.useEffect(() => {
    // console.log("ServiceWorkerProvider->useEffect->waitingServiceWorker: ", waitingServiceWorker);
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an incosistent state,
    // two statechange event will be firing: 1. activating 2. activated
    waitingServiceWorker?.addEventListener('statechange', (event: any) => {
      // console.log('waitingServiceWorker -> statechange :', event.target.state);
      if (event.target.state === 'activated') {
        console.log('reload now');
        window.location.reload();
      }
    });
  }, [waitingServiceWorker]);

  const value = React.useMemo(() => ({
    isUpdateAvailable,
    updateAssets: () => {
      if (waitingServiceWorker) {
        // Send SKIP_WAITING message to tell the Service Worker
        // to update its cache and flush the old one
        // refer build file service-worker.js after building production build
        console.log('send skip waiting')
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    },
    updateServiceWorker: () => {
      console.log("udate ServiceWorker:", registration);
      registration?.update();
    }
  }), [isUpdateAvailable, waitingServiceWorker, registration]);

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
}

// creating custom react hook so able to access `isUpdateAvailable` and `updateAssets`
export const useServiceWorker = () => {
  return React.useContext(ServiceWorkerContext);
}