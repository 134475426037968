import React, { useState, useEffect } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { RefresherEventDetail } from '@ionic/core';
import { cloneDeep } from "lodash";

import {
  IonPage, IonHeader, IonFooter,
  IonToolbar, IonContent, IonSearchbar,
  IonRefresher, IonRefresherContent, IonSegment, IonSegmentButton,
  IonItem, IonButtons, IonButton, IonTitle, IonIcon, IonLabel,
  IonGrid, IonRow, IonCol, IonLoading, isPlatform, IonImg,
} from "@ionic/react";
import { add, remove, cart, logoWhatsapp, cartOutline } from 'ionicons/icons';

import {
  useShopOneQuery, useConsumerOneQuery, useSetProductsFilterMutation, useAddToCartMutation,
  useRemoveFromCartMutation, useCreateOrderMutation, useOrderUpdateMutation, ShopOneDocument,
  ConsumerOneDocument
} from "../../generated/graphql";

import { findIndexInArray, findObjInArray, stripTypenames } from '../../util/arrayUtil';
import { NotFound } from "../../pages/NotFound";
import ErrorPage from "../../pages/ErrorPage";
import CartModal from "./CartModal";

import "./Listing.css";

let timeoutId: NodeJS.Timeout;

interface Props extends RouteComponentProps<{ url: string; }> {
}

const Listing: React.FC<Props> = ({ match, location }) => {
  const history = useHistory();

  const [cartModalOpen, showCartModal] = useState(new URLSearchParams(useLocation().search).get("openCart") === "true" ? true : false)
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("All");
  //const [products, setProducts] = useState<any>([]);

  const { data: dataConsumer } = useConsumerOneQuery({ fetchPolicy: "cache-and-network" })
  const { data, loading, refetch, error } = useShopOneQuery({
    variables: { filter: { url: match.params.url } },
    // variables: { filter: { _id: "5eb249bcc56c325ee4361c41" } },
    fetchPolicy: "cache-and-network",
  })

  const [setFilter] = useSetProductsFilterMutation();
  const [addToCartMutation] = useAddToCartMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const [createOrder] = useCreateOrderMutation({
    update(cache, { data }) {
      //@ts-ignore
      let { shop: shopOne } = cache.readQuery({
        query: ShopOneDocument,
        variables: { filter: { url: match.params.url } }
      }).public
      let shopCopy = cloneDeep(shopOne);
      shopCopy.orderLocal._id = data?.consumer?.orderCreate?.record?._id;
      cache.writeQuery({
        query: ShopOneDocument,
        data: { public: { shop: shopCopy, __typename: "PublicQuery" } },
      });
    },
    refetchQueries: [{
      query: ConsumerOneDocument
    }]
  });
  const [orderUpdate] = useOrderUpdateMutation();

  const me = dataConsumer?.consumer?.me ;
  const orders = me?.orders ;
  const shop = data?.public?.shop ;

  useEffect(() => {
    if (shop && orders) {
      if (orders.length > 0) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          if (shop) {
            let order = findObjInArray(orders, "shopId", shop._id);
            if (order && order.status === "incart") {
              let localOrder = cloneDeep(stripTypenames(shop.orderLocal))
              // need to delete the unmatch data from localOrder before pass to graphql
              delete localOrder._id ;
              localOrder.carts?.forEach((c:any) => delete c.cartProduct);
              // console.log("updateOrder ", localOrder)
              orderUpdate({
                variables: {
                  id: order._id,
                  record: localOrder
                }
              })
                .then(res => res.data?.consumer?.orderUpdate && console.log("order updated: ", res.data.consumer.orderUpdate.record))
                .catch(err => console.log("update order in server error: ", err));
            } else {
              let newOrder = cloneDeep(stripTypenames(shop.orderLocal))
              newOrder.consumerId = me?._id;
              newOrder.shopId = shop._id;
              newOrder.delivery.deliverFrom = cloneDeep(stripTypenames(shop.address));
              newOrder.delivery.deliverFrom.name = shop.name;
              newOrder.delivery.deliverFrom.phone = shop.phone;
              // need to delete the unmatch data from localOrder before pass to graphql
              delete newOrder._id;
              newOrder.carts?.forEach((c:any) => delete c.cartProduct);
              // console.log("newOrder ", newOrder)
              createOrder({
                variables: {
                  record: newOrder
                }
              })
                .then(res => res.data?.consumer?.orderCreate && console.log("order created: ", res.data.consumer.orderCreate.record))
                .catch(err => console.log("create order in server error: ", err));
            }
          }
        }, 2000);
      }
    }
  }, [shop, me, orders, createOrder, orderUpdate])

  function onFilter(category: any, searchText: any) {
    if (shop) {
      setFilter({
        variables: {
          filter: {
            shopId: shop._id,
            categoryId: category,
            searchText: searchText
          }
        }
      });
    }
  }

  function onCategoryChange(e: any) {
    if (e.detail.value !== undefined) {
      setCategory(e.detail.value);
      onFilter(e.detail.value, searchText);
    }
  }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setCategory("All");
    setSearchText("");
    refetch();
    setTimeout(() => event.detail.complete(), 500);
  }

  function onCartRemove(e: any, p: any) {
    //prevent from going to product details page
    e.stopPropagation(); //stop parent event
    e.preventDefault(); //stop itself default event
    removeFromCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: p._id,
          price: p.pricing.retail
        }
      }
    })

  }

  function onCartAdd(e: any, p: any) {
    e.stopPropagation();
    e.preventDefault();
    addToCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: p._id,
          name: p.name,
          price: p.pricing.retail,
          cartProduct: {
            name: p.name,
            imageUrl: p.imageUrl
          }
        }
      }
    })
  }

  if (!shop && loading) {
    // console.log('loading', loading)
    return <IonPage>
      <IonLoading
        isOpen={loading}
      />
    </IonPage>
  }

  if (!shop && error) {
    console.log('Listing error', error)
    return <ErrorPage refresh={() => refetch()} />
  }

  if (shop) {
    return (
      <IonPage className="shop01-listing">
        <IonHeader >
          <IonToolbar>
            {/* <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons> */}
            {isPlatform("desktop") ?
              <IonItem slot="start" lines="none">
                <img src={shop.logo ? shop.logo : ""} alt="logo" width="50px" height="50px"/>
                <IonTitle>{shop.name}</IonTitle>
              </IonItem>
              :
              <IonTitle slot="start">{shop.name}</IonTitle>}
            <IonButtons slot="end">
              <IonButton href={`https://api.whatsApp.com/send?phone=${shop.whatsapp_number}`}>
                <IonIcon icon={logoWhatsapp} size={isPlatform("mobile") ? "default" : "large"} color="primary" />
              </IonButton>
              {isPlatform("desktop") && <IonButton onClick={() => {
                showCartModal(true)
              }}>
                <IonIcon icon={cartOutline} size="large" />
                <IonLabel>{shop.orderLocal?.totalQty}</IonLabel>
              </IonButton>}
            </IonButtons>
          </IonToolbar>
          {shop.categories && shop.categories.length > 0 ?
            <IonToolbar>
              <IonSegment scrollable value={category} onIonChange={onCategoryChange}>
                <IonSegmentButton value="All">
                  <IonLabel>All</IonLabel>
                </IonSegmentButton>
                {shop.categories?.map((cat: any, index: number) => {
                  return (
                    <IonSegmentButton key={index} value={cat._id}>
                      <IonLabel>{cat.name}</IonLabel>
                    </IonSegmentButton>
                  );
                })}
              </IonSegment>
            </IonToolbar> : null}
        </IonHeader>

        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          <IonGrid>
            <IonSearchbar value={searchText} onIonChange={e => {
              if (e.detail.value !== undefined) {
                setSearchText(e.detail.value);
                onFilter(category, e.detail.value);
              }
            }} />
            <IonRow>
              {shop.products?.map((p: any) => {
                let cartIndex = shop.orderLocal?.carts ? findIndexInArray(shop.orderLocal.carts, "productId", p._id) : null;
                return (
                  <IonCol key={p._id} sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" hidden={p.status !== "published" || !p.show}>
                    <IonItem routerLink={`${match.url}/product-details/${p._id}`}>
                      <IonImg slot="start" src={p.imageUrl} alt="product" />
                      <IonCol>
                        <IonRow><IonCol>
                          <IonLabel>
                            <h2>{p.name}</h2>
                            <p>{p.shortDesc}</p>
                            {(!p.unlimitedStock && p.stock < 1) && <p className="out-of-stock">Out of stock</p>}
                          </IonLabel>
                        </IonCol></IonRow>
                        <IonRow className="ion-align-items-center">
                          <IonCol  >
                            <IonLabel className="ion-text-nowrap">RM {p.pricing.retail.toFixed(2)}</IonLabel>
                          </IonCol>
                          <IonCol>
                            <IonRow className="ion-nowrap ion-justify-content-end ion-align-items-center">
                              {cartIndex !== null ?
                                <IonButton size="small" color="medium" onClick={(e) => onCartRemove(e, p)}>
                                  <IonIcon icon={remove} />
                                </IonButton>
                                : null}
                              {shop.orderLocal?.carts && cartIndex !== null ?
                                <IonLabel>&nbsp;{shop.orderLocal.carts[cartIndex]?.qty}&nbsp;</IonLabel>
                                : null}
                              <IonButton size="small" color="primary" onClick={(e) => onCartAdd(e, p)}
                                disabled={(!p.unlimitedStock && p.stock < 1)} >
                                <IonIcon icon={add} />
                              </IonButton>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonItem>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>

          <CartModal
            user={me}
            shop={shop}
            isOpen={cartModalOpen}
            setShowModal={showCartModal}
            redirectToLogin={() => history.push("/login", { prevLocation: location.pathname, openCart: true })}
          />
        </IonContent>
        {isPlatform("mobile") && <IonFooter>
          {/* <IonItem detail routerLink={`${match.url}/cart`}> */}
          <IonItem detail color="primary" onClick={() => {
            showCartModal(true)
          }}>
            <IonIcon slot="start" icon={cart} />
            <IonLabel>{shop.orderLocal?.totalQty} items</IonLabel>
            <IonLabel>RM {shop.orderLocal?.subTotal?.toFixed(2)}</IonLabel>
          </IonItem>
        </IonFooter>}
      </IonPage>
    );
  }
  return <NotFound />;
}

export default Listing;