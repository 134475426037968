import React, { useState, useEffect } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { RefresherEventDetail } from '@ionic/core';

import {
  IonPage, IonHeader, IonToolbar, IonContent,
  IonRefresher, IonRefresherContent, IonSegment, IonSegmentButton,
  IonItem, IonButtons, IonButton, IonTitle, IonIcon, IonLabel,
  IonGrid, IonRow, IonCol, IonLoading, isPlatform, IonImg,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
} from "@ionic/react";
import {
  add, remove, cartOutline, chevronBack,
  timerOutline, newspaperOutline, basketOutline
} from 'ionicons/icons';

import {
  useShopOneQuery, useConsumerOneQuery, useConsumerLikesLazyQuery,
  useAddToCartMutation, useRemoveFromCartMutation, EnumLikeType,
} from "../../generated/graphql";

import { findObjInArray } from '../../util/arrayUtil';
import { NotFound } from "../../pages/NotFound";
import ErrorPage from "../../pages/ErrorPage";
import CartModal from "./CartModal";

import "./Favourite.css";

interface Props extends RouteComponentProps<{ url: string; }> {
}

const Favourite: React.FC<Props> = ({ match, location, history }) => {
  const [cartModalOpen, showCartModal] = useState(new URLSearchParams(useLocation().search).get("openCart") === "true" ? true : false)
  const [category, setCategory] = useState("Product");

  const { data: dataConsumer } = useConsumerOneQuery({ fetchPolicy: "cache-and-network" })
  const { data: dataShop, loading: loadingShop, refetch: refetchShop, error: errorShop } = useShopOneQuery({
    variables: { filter: { url: match.params.url } },
    fetchPolicy: "cache-and-network",
  })
  const [getLikes, { data: dataLikes }] = useConsumerLikesLazyQuery({
    fetchPolicy: "cache-and-network"
  })

  const [addToCartMutation] = useAddToCartMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();

  const shop = dataShop?.public?.shop
  const likes = dataLikes?.consumer?.likes;

  useEffect(() => {
    if (shop)
      getLikes({ variables: { filter: { shopId: shop._id } } })
  }, [shop, getLikes])

  function onCategoryChange(e: any) {
    if (e.detail.value !== undefined) {
      setCategory(e.detail.value);
      //!!!! implement necessary filter
      // onFilter(e.detail.value, searchText);
    }
  }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setCategory("Product");
    refetchShop();
    // if (!loading) event.detail.complete();
    setTimeout(() => event.detail.complete(), 500);
  }

  function onCartRemove(e: any, p: any) {
    //prevent from going to product details page
    e.stopPropagation(); //stop parent event
    e.preventDefault(); //stop itself default event
    removeFromCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: p._id,
          price: p.pricing.retail
        }
      }
    })

  }

  function onCartAdd(e: any, p: any) {
    e.stopPropagation();
    e.preventDefault();
    addToCartMutation({
      variables: {
        shopUrl: match.params.url,
        cart: {
          productId: p._id,
          name: p.name,
          price: p.pricing.retail,
          cartProduct: {
            name: p.name,
            imageUrl: p.imageUrl
          }
        }
      }
    })
  }

  if (!shop && loadingShop) {
    return <IonPage><IonLoading isOpen={loadingShop} /></IonPage>
  }

  if (!shop && errorShop) {
    console.log('Favourite error', errorShop)
    return <ErrorPage refresh={() => refetchShop()} />
  }

  if (shop && likes) {
    const iconSize = isPlatform("desktop") ? "large" : "default";
    return (
      <IonPage className="shop02-favourite">
        <IonHeader >
          <IonToolbar>
            {isPlatform("desktop") ?
              <IonItem slot="start" lines="none" routerLink={`/ShopGen02/${match.params.url}`} routerDirection="back">
                <IonButton fill="clear">
                  <IonIcon icon={chevronBack} color="primary" />
                </IonButton>
                <img src={shop.logo ? shop.logo : ""} alt="logo" width="50px" height="50px" />
                <IonTitle>我喜欢的</IonTitle>
              </IonItem>
              :
              <IonItem slot="start" lines="none">
                <IonButton fill="clear" onClick={() => history.goBack()}>
                  <IonIcon icon={chevronBack} slot="icon-only"></IonIcon>
                </IonButton>
                <IonTitle>我喜欢的</IonTitle>
              </IonItem>
            }
            {shop.orderLocal?.totalQty ?
              <IonButtons slot="end">
                <IonButton fill="clear" onClick={() => { showCartModal(true) }}>
                  <IonIcon icon={cartOutline} color="primary" size={iconSize} />
                  <IonLabel slot="end">{shop.orderLocal?.totalQty}</IonLabel>
                </IonButton>
              </IonButtons>
              : null}
          </IonToolbar>

          <IonToolbar>
            <IonSegment scrollable value={category} onIonChange={onCategoryChange}>
              <IonSegmentButton value="Product" layout="icon-start">
                <IonIcon icon={basketOutline} />
                <IonLabel>产品</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="Blog" layout="icon-start">
                <IonIcon icon={newspaperOutline} />
                <IonLabel>文章</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="Recent" layout="icon-start">
                <IonIcon icon={timerOutline} />
                <IonLabel>最近</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          <IonGrid>
            {category === "Product" ?
              <IonRow>
                {likes.filter(f => f.type === EnumLikeType.Product).map(l => {
                  let cartProduct = shop.orderLocal?.carts ? findObjInArray(shop.orderLocal.carts, "productId", l.typeId) : undefined;
                  let p = l.likedProduct;
                  if (p) {
                    return (
                      <IonCol key={l.typeId} sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" hidden={l.likedProduct?.status !== "published"}>
                        <IonItem routerLink={`/ShopGen02/${match.params.url}/product-details/${l.typeId}`}>
                          <IonImg slot="start" src={p.imageUrl!} alt="product" />
                          <IonCol>
                            <IonRow><IonCol>
                              <IonLabel>
                                <h2>{p.name}</h2>
                                <p>{p.shortDesc}</p>
                                {(!p.unlimitedStock && p.stock! < 1) && <p className="out-of-stock">Out of stock</p>}
                              </IonLabel>
                            </IonCol></IonRow>
                            <IonRow className="ion-align-items-center">
                              <IonCol  >
                                <IonLabel className="ion-text-nowrap">RM {p.pricing!.retail.toFixed(2)}</IonLabel>
                              </IonCol>
                              <IonCol>
                                <IonRow className="ion-nowrap ion-justify-content-end ion-align-items-center">
                                  {cartProduct ?
                                    <IonButton size="small" color="medium" onClick={(e) => onCartRemove(e, p)}>
                                      <IonIcon icon={remove} />
                                    </IonButton>
                                    : null}
                                  {cartProduct ?
                                    <IonLabel>&nbsp;{cartProduct.qty}&nbsp;</IonLabel>
                                    : null}
                                  <IonButton size="small" color="primary" onClick={(e) => onCartAdd(e, p)}
                                    disabled={(!p.unlimitedStock && p.stock! < 1)} >
                                    <IonIcon icon={add} />
                                  </IonButton>
                                </IonRow>
                              </IonCol>
                            </IonRow>
                          </IonCol>
                        </IonItem>
                      </IonCol>
                    );
                  } else
                    return null;
                })}
              </IonRow>
              : null}
            {category === "Blog" ?
              <IonRow>
                {likes.filter(f => f.type === EnumLikeType.Blog).map(l => {
                  let b = l.likedBlog;
                  if (b)
                    return (
                      <IonCol key={b._id} sizeMd="4" sizeXs="12" >
                        <IonCard button routerLink={`/ShopGen02/${match.params.url}/blog/${b._id}`}>
                          <img src={b.cardImageUrl} alt="blog" />
                          <IonCardHeader>
                            <IonCardTitle>{b.cardTitle}</IonCardTitle>
                            <IonCardSubtitle>{b.cardText}</IonCardSubtitle>
                          </IonCardHeader>
                        </IonCard>
                      </IonCol>
                    );
                  else
                    return null;
                })}
              </IonRow>
              : null}
          </IonGrid>

          <CartModal
            user={dataConsumer?.consumer?.me}
            shop={shop}
            isOpen={cartModalOpen}
            setShowModal={showCartModal}
            redirectToLogin={() => history.push("/login", { prevLocation: location.pathname, openCart: true })}
          />
        </IonContent>

      </IonPage>
    );
  }
  return <NotFound />;
}

export default Favourite;