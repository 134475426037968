import React, { useState } from "react";
import {
  IonPage, IonHeader, IonToolbar, IonContent, IonItem, IonTitle, IonIcon, IonLabel, IonCol, 
  IonSelect, IonSelectOption, IonTextarea,
} from "@ionic/react";
import "./Delivery.css";
import { RouteComponentProps } from "react-router";
import { location as locationIcon, time } from "ionicons/icons";

interface Props extends RouteComponentProps { }

const categories = ["Document", "Food", "Medical", "Groceries", "Others"];
const weights = ["Less than 1kg", "Between 1kg to 5kg", "More than 5kg"];

const Delivery: React.FC<Props> = () => {

  const [category, setCategory] = useState("Document");
  const [weight, setWeight] = useState("Less than 1kg");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Delivery</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonItem className="ion-no-padding">
          <IonCol>
            <IonItem lines="none" button className="ion-align-items-center">
              <IonIcon icon={locationIcon} color="primary" />
              <IonLabel className="ion-padding-start">
                <p> Pick up point</p>
                <h4>12, Taman 2, 43000 Kajang</h4>
              </IonLabel>
            </IonItem>
            <IonItem lines="none" button className="ion-align-items-center">
              <IonIcon icon={locationIcon} color="danger" />
              <IonLabel className="ion-padding-start">
                <p> Drop off point</p>
                <h4>12, Taman 2, 43000 Kajang</h4>
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonItem>
        <IonItem className="ion-no-padding">
          <IonCol>
            <IonLabel className="ion-padding-start"><h2>Delivery Time</h2></IonLabel>
            <IonItem lines="none" button onClick={() => { }}>
              <IonIcon icon={time} />
              <IonLabel className="ion-padding-start">ASAP</IonLabel>
            </IonItem>
          </IonCol>
        </IonItem>
        <IonItem className="ion-no-padding">
          <IonCol>
            <IonLabel className="ion-padding-start"><h2>Item Details</h2></IonLabel>
            <IonItem lines="none">
              <IonLabel>Category</IonLabel>
              <IonSelect value={category} interface="popover" onIonChange={e => setCategory(e.detail.value)}>
                {categories.map(category => <IonSelectOption value={category}>{category}</IonSelectOption>)}
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>Weight</IonLabel>
              <IonSelect value={weight} interface="popover" onIonChange={e => setWeight(e.detail.value)}>
                {weights.map(weight => <IonSelectOption value={weight}>{weight}</IonSelectOption>)}
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonItem>
        <IonItem className="ion-no-padding">
          <IonCol>
            <IonLabel className="ion-padding-start"><h2>Notes/Instructions</h2></IonLabel>
            <IonItem lines="none">
            <IonTextarea placeholder="e.g Give item to receptionist" onIonChange={e => {}}></IonTextarea>
            </IonItem>
          </IonCol>
        </IonItem>
      </IonContent>
    </IonPage>
  );
}

export default Delivery;