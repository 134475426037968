import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';

import { 
  IonPage, IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonContent, IonRow, 
  IonLoading, isPlatform, IonItem 
} from '@ionic/react';
import { chevronBack, checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { useOrderPaymentQuery, useClearCartsMutation } from '../generated/graphql';

import "./PaymentStatus.css";

let timeoutId: NodeJS.Timeout;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const PaymentStatus: React.FC = () => {
  const client_secret = useQuery().get("payment_intent_client_secret");
  const orderId = useQuery().get("orderId");
  const stripe = useStripe();
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();
  const [loading, setLoading] = useState(true);
  const [isCleared, setIsCleared] = useState(false);

  const { data: dataOrder, refetch: refetchOrder } = useOrderPaymentQuery({
    variables: { filter: { _id: orderId } },
    fetchPolicy: "network-only",
  })
  const [clearCarts] = useClearCartsMutation();

  const order = dataOrder?.consumer?.order;

  useEffect(() => {
    async function retrievePaymentInfo() {
      if (stripe && client_secret) {
        const result = await stripe.retrievePaymentIntent(client_secret);
        console.log("Payment intent ", result.paymentIntent)
        if (result.error) {
          console.log("get payment intent err ", result.error)
        } else if (result.paymentIntent) {
          //todo: update payment status in order type & straight away trigger the merchant/rider

          //update data to display ui accordingly
          setPaymentIntent(result.paymentIntent)
        }
      }
    }

    if (!paymentIntent) retrievePaymentInfo();

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      refetchOrder().then(res => res.data.consumer?.order && setLoading(false));
    }, 3000)

    if (!isCleared && !loading && order) {
      if (order.paymentStatus === "success" || (order.paymentType === "cash" && order.paymentStatus === "pending"))
        clearCarts({ variables: { shopUrl: order.shop?.url || "" } }).then(() => {
          console.log("cleared :", order.shop?.url)
          setIsCleared(true)
        })
    }

  }, [paymentIntent, stripe, client_secret, order, refetchOrder, isCleared, loading, clearCarts]);

  if (loading)
    return (
      <IonPage >
        <IonContent>
          <IonLoading isOpen={loading} />
        </IonContent>
      </IonPage >
    )

  if (dataOrder?.consumer?.order?.paymentType === "cash")
    return (
      <IonPage>
        {isPlatform("mobile") ?
          <IonHeader>
            <IonToolbar>
              <IonButton fill="clear" slot="start" onClick={() => window.location.replace("/")}>
                <IonIcon icon={chevronBack} />
              </IonButton>
              <IonTitle>Order Received</IonTitle>
            </IonToolbar>
          </IonHeader>
          :
          <IonHeader>
            <IonToolbar>
              <IonItem slot="start" lines="none" href="/home">
                <img src='/assets/icon/icon.png' alt="logo" width="50px" height="50px"/>
                <IonTitle>Transformer</IonTitle>
              </IonItem>
            </IonToolbar>
          </IonHeader>}
        <IonContent className="payment-status ion-padding">
          <IonRow className="ion-justify-content-center ion-padding-vertical">
            <IonIcon icon={checkmarkCircleOutline} />
          </IonRow>
          <h2 className="ion-text-center">We have received your order and being processed.</h2>
          <p className="ion-text-center">Order Number: {orderId}</p>
          <p className="ion-text-center">Payment method: Cash</p>
          <IonRow className="ion-justify-content-center">
            <IonButton onClick={() => window.location.replace("/")}>Back to home page</IonButton>
          </IonRow>
        </IonContent>
      </IonPage>
    )

  if (order) {
    return (
      <IonPage>
        {isPlatform("mobile") ? <IonHeader>
          <IonToolbar>
            <IonButton fill="clear" slot="start" onClick={() => window.location.replace("/")}>
              <IonIcon icon={chevronBack} />
            </IonButton>
            <IonTitle>{order.paymentStatus === "success" ? "Order Received" : "Payment Fail"}</IonTitle>
          </IonToolbar>
        </IonHeader>
          :
          <IonHeader>
            <IonToolbar>
              <IonItem slot="start" lines="none" href="/home">
                <img src='/assets/icon/icon.png' alt="logo" width="50px" height="50px"/>
                <IonTitle>Transformer</IonTitle>
              </IonItem>
            </IonToolbar>
          </IonHeader>}
        <IonContent className="payment-status ion-padding">
          <IonRow className="ion-justify-content-center ion-padding-vertical">
            <IonIcon icon={order.paymentStatus === "success" ? checkmarkCircleOutline : closeCircleOutline} />
          </IonRow>
          <h2 className="ion-text-center">{order.paymentStatus === "success" ? "Thank you, your payment was successful!" : "Sorry, your payment was not successful!"}</h2>
          <p className="ion-text-center">{order.paymentStatus === "success" ? `Order Number: ${orderId}` : paymentIntent?.last_payment_error?.message}</p>
          {order.paymentStatus === "success" && <p className="ion-text-center">Payment method: {order.paymentType === "card" ? "Credit/Debit Card" : "FPX Online Banking"}</p>}
          <IonRow className="ion-justify-content-center">
            <IonButton onClick={() => window.location.replace("/")}>Back to home page</IonButton>
          </IonRow>
        </IonContent>
      </IonPage>
    )
  }

  return null
}

export default PaymentStatus;