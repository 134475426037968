
export function findObjInArray(array: any[], field: string | number, value: any) {
  if (value === undefined)
    return undefined;
  const filteredArray = array.filter(d => d[field] === value);
  if (filteredArray.length > 0)
    return filteredArray[0];
  return null;
}

export function moveArrayItem(array: any[], orgIndex: number, newIndex: number) {
  let tmp = array[orgIndex];
  array.splice(orgIndex, 1);
  array.splice(newIndex, 0, tmp);
}

export function findIndexInArray(array: string | any[], key: string | number | null, value: any) {
  if (key === null) {
    for (var i = 0; i < array.length; i++) {
      if (array[i] === value) {
        return i;
      }
    }
  }
  else {
    for (var j = 0; j < array.length; j++) {
      if (array[j][key] === value) {
        return j;
      }
    }
  }
  return null;
}

export function stripTypenames(value: any): any {
  if (Array.isArray(value)) {
    return value.map(stripTypenames)
  } else if (value !== null && typeof (value) === "object") {
    const newObject = {} as any;
    for (const property in value) {
      if (property !== '__typename') {
        newObject[property] = stripTypenames(value[property])
      }
    }
    return newObject
  } else {
    return value
  }
}