import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import TagManager from "react-gtm-module";
import Helmet from "react-helmet";

TagManager.initialize({
  gtmId: "GTM-NMCW5VC",
});

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

ReactDOM.render(
  <>
    <Helmet>
      <script>window.prerenderReady = true;</script>
    </Helmet>
    <App />
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Listen to callbacks here and dispatch to context callbacks returned are onSuccess & onUpdate
// serviceWorker.register();
