import React, { useEffect, useState } from 'react';
import { IonModal, IonHeader, IonToolbar, IonButton, IonIcon, IonTitle, IonContent, IonItem, IonInput, IonLoading, IonAlert } from '@ionic/react';
import { useConsumerUpdateMutation } from '../generated/graphql';
import { useForm, Controller } from 'react-hook-form';
import { chevronBack } from 'ionicons/icons';
import './PhoneOtpModal.css'

interface Props {
  isOpen: boolean,
  setShowModal: (isOpen: boolean) => void,
  user: any
}

const NameModal: React.FC<Props> = ({ isOpen, setShowModal, user }) => {
  const [consumerUpdate, { loading }] = useConsumerUpdateMutation();
  const [isError, setIsError] = useState(false)
  const { control, handleSubmit, formState, errors, setValue } = useForm({
    defaultValues: { name: user.name },
    mode: "onChange"
  });

  useEffect(() => {
    if (isOpen) setValue("name", user.name)
  }, [isOpen, setValue, user.name])

  /**
   *
   * @param _fieldName
   */
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="error-message">
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  /**
   *
   * @param data
   */
  const onSubmit = (data: any) => {
    consumerUpdate({
      variables: {
        id: user._id,
        record: {
          name: data.name
        }
      }
    }).then(res => {
      console.log("data change name", data)
      if (res.data?.consumer?.meUpdate) {
        setShowModal(false)
      }
    }).catch(error => {
      console.log("err change name ", error)
      setIsError(true)
    })
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setShowModal(false)}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => setShowModal(false)}>
            <IonIcon icon={chevronBack} size="100px"></IonIcon>
          </IonButton>
          <IonTitle>Change Name</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
          <IonItem>
            <Controller
              as={IonInput}
              placeholder="Enter name"
              control={control}
              onChangeName="onIonChange"
              onChange={([selected]) => {
                return selected.detail.value;
              }}
              name="name"
              rules={{
                required: true,
              }}
            />
          </IonItem>
          {showError("name")}

          <IonButton className="submit-button" type="submit" disabled={formState.isValid === false}>Change</IonButton>
        </form>
        <IonLoading
          isOpen={loading}
          message={'Please wait...'}
        />
        <IonAlert
          isOpen={isError}
          message="Unable to change name"
          onDidDismiss={() => setIsError(false)}
          buttons={[
            {
              text: "OK",
              handler: () => setIsError(false)
            }
          ]}
        />
      </IonContent>
    </IonModal>
  )
}

export default NameModal;